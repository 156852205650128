var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeCategory)?_c('page-layout-sidebar',{staticClass:"item-list-view",class:{ 'item-list-view--loading': _vm.isLoading },attrs:{"breadcrumb":_vm.breadcrumb,"filter-title":"Фильтр поиска","is-sticky":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.pageTitle))]},proxy:true},{key:"filter",fn:function(){return [_c('form-group',{attrs:{"id":"articleSearch","show-label":false,"size":"sm","icon":{ name: 'search', width: '16', height: '16' }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Поиск по наименованию")]},proxy:true},{key:"default",fn:function(props){return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.filterSearchQuery),expression:"filterSearchQuery",modifiers:{"trim":true}}],class:props.inputClass,attrs:{"id":props.id,"type":"text","placeholder":"Поиск по наименованию"},domProps:{"value":(_vm.filterSearchQuery)},on:{"change":_vm.onSearch,"input":function($event){if($event.target.composing)return;_vm.filterSearchQuery=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]}}],null,false,1240921567)}),(_vm.isFilterReady)?_vm._l((_vm.filterList),function(filter,key){return _c('filter-prop-default',{key:`filter-${key}`,attrs:{"item":filter.values},scopedSlots:_vm._u([{key:"default",fn:function({ list }){return [_c('filter-item',{attrs:{"is-ready":_vm.isFilterReady},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(filter.title)+" ")]},proxy:true},{key:"default",fn:function({ isReady }){return [_c('filter-checkbox-list',{attrs:{"name":`filter-${filter.alias}`,"trackby":"id","labelby":"title","is-ready":isReady,"list":list,"selected":filter.alias in _vm.filterSelected
                    ? _vm.filterSelected[filter.alias]
                    : []},on:{"change":(list) => {
                    _vm.onChange(filter.alias, list);
                  },"reset":() => {
                    _vm.onReset(filter.alias);
                  }}})]}}],null,true)})]}}],null,true)})}):_vm._e()]},proxy:true},{key:"filter-action",fn:function(){return [(_vm.hasFilterSelected)?_c('base-btn',{attrs:{"size":"sm","view":"outline-secondary","display":"block"},on:{"click":_vm.onClean}},[_vm._v(" Сбросить ")]):_vm._e()]},proxy:true},(_vm.pageCount > 1)?{key:"footer",fn:function(){return [_c('the-pagination',{attrs:{"total":_vm.pageCount,"active":_vm.activePage,"disabled":_vm.isLoading}})]},proxy:true}:null],null,true)},[(_vm.isFilterReady && _vm.hasFilterSelected)?_c('selected-filter-list',{staticClass:"item-list-view__selected-filter-list",attrs:{"filter-list":_vm.filterList},on:{"remove-search":_vm.onRemoveSearch,"remove-filter":_vm.onRemoveFilter}}):_vm._e(),_c('div',{staticClass:"item-list-view__settings"},[_c('view-settings',{staticClass:"item-list-view__view-settings"})],1),(_vm.isLoading)?_c('base-loading'):(!_vm.isLoading && _vm.itemList.length > 0)?_c('product-list',{staticClass:"item-list-view__goods",attrs:{"list":_vm.itemList}}):_c('the-empty',{staticClass:"item-list-view__the-empty",scopedSlots:_vm._u([{key:"filter",fn:function(){return [(_vm.isFilterReady && _vm.hasFilterSelected)?_c('selected-filter-list',{attrs:{"filter-list":_vm.filterList,"view":"center"},on:{"remove-search":_vm.onRemoveSearch,"remove-filter":_vm.onRemoveFilter}}):_vm._e()]},proxy:true}],null,false,1704136274)}),(_vm.activePage < _vm.pageCount)?_c('div',{staticClass:"item-list-view__loadmore"},[_c('base-btn',{attrs:{"view":"outline-secondary","loading":_vm.isLoadMore,"disabled":_vm.isLoading},on:{"click":_vm.onLoadMore}},[_vm._v(" Показать еще ")])],1):_vm._e()],1):_c('base-loading')
}
var staticRenderFns = []

export { render, staticRenderFns }