<template>
  <div
    class="form-group"
    :class="[
      {
        'form-group--error': hasError,
        'form-group--hide-label': !showLabel,
        'form-group--has-icon': !!icon,
      },
      `form-group--${size}`,
    ]"
  >
    <label :for="id" class="form-group__label">
      <slot name="label"></slot>
    </label>

    <div class="form-group__group">
      <base-icon
        v-if="icon"
        class="form-group__icon"
        :name="icon.name"
        :width="icon.width"
        :height="icon.height"
      />
      <slot
        :id="id"
        :disabled="disabled"
        inputClass="form-group__control"
      ></slot>

      <div v-if="!!$slots.suggest" class="form-group__suggest">
        <slot name="suggest"></slot>
      </div>
    </div>

    <div v-if="$slots.hint" class="form-group__hint">
      <slot name="hint"></slot>
    </div>

    <div v-if="hasError" class="form-group__error">
      <slot name="error"></slot>
    </div>

    <div v-if="$slots.extra" class="form-group__extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from "./BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "default",
    },
    icon: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.form-group {
  margin-bottom: 1.5rem;
  position: relative;

  &__label {
    color: $stone;
    display: block;
    margin-bottom: 7px;

    .form-group--hide-label & {
      @include visually-hidden;
    }
  }

  &__group {
    position: relative;
  }

  &__icon {
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__suggest {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &__control {
    @include font-size($input-font-size);
    background-clip: padding-box;
    background-color: $white;
    border: 1px solid $grey-200;
    color: $coal;
    display: block;
    height: $input-height;
    line-height: $input-line-height;
    padding: $input-padding-y $input-padding-x;
    width: 100%;

    .form-group--error & {
      border-color: $red;
    }

    .form-group--sm & {
      height: 40px;
      padding: $input-padding-y-sm $input-padding-x-sm;
      @include border-radius($input-border-radius-sm);
    }

    .form-group--has-icon & {
      padding-left: 32px;
    }

    @at-root {
      textarea#{&} {
        height: 68px;
      }
    }

    @include border-radius(4px, 0);

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $coal;
    }

    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $mint-100;
      outline: 0;
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    }

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      opacity: 1;
    }
  }

  &__hint {
    color: $grey-100;
  }

  &__extra {
    margin-top: 10px;
  }

  &__error {
    color: $red;
  }
}
</style>
