<template>
  <div v-if="hasList" class="footer-navbar">
    <div class="row">
      <div
        v-for="(section, sIndex) in list"
        :key="`section-${sIndex}`"
        class="col-lg"
      >
        <footer-navbar-nav :view="view" class="footer-navbar__item">
          <template #title>{{ section.title }}</template>

          <template #default="{ itemClass, linkClass }">
            <li
              v-for="(item, iIndex) in section.children"
              :key="`item-${sIndex}-${iIndex}`"
              :class="itemClass"
            >
              <footer-navbar-nav-link :item="item" :class="linkClass" />
            </li>
          </template>
        </footer-navbar-nav>
      </div>
    </div>
  </div>
</template>

<script>
import FooterNavbarNav from "./FooterNavbarNav";
import FooterNavbarNavLink from "./FooterNavbarNavLink";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    FooterNavbarNav,
    FooterNavbarNavLink,
  },
  props: {
    view: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("catalog", {
      hasCatalogList: "hasList",
    }),
    hasList() {
      return this.hasCatalogList && this.list.length > 0;
    },
  },
};
</script>
