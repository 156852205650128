<template>
  <account-form-layout class="profile-form" @submit="onSubmit">
    <div class="row">
      <div class="col-lg-3">
        <form-group id="profileName" :has-error="$v.profileForm.name.$error">
          <template #label>Имя*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="profileForm.name"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Необходимо заполнить поле </template>
        </form-group>

        <form-group
          id="profileSurname"
          :has-error="$v.profileForm.surname.$error"
        >
          <template #label>Фамилия*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="profileForm.surname"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Необходимо заполнить поле </template>
        </form-group>

        <form-group id="profilePatronymic">
          <template #label>Отчество</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="profileForm.patronymic"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Необходимо заполнить поле </template>
        </form-group>
      </div>

      <div class="col-lg-3">
        <form-group id="profilePhone" :has-error="$v.profileForm.phone.$error">
          <template #label>Телефон*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="profileForm.phone"
              v-mask="'# (###) ### ##-##'"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Необходимо заполнить поле </template>
        </form-group>

        <form-group id="profileEmail" :has-error="$v.profileForm.email.$error">
          <template #label>E-mail*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="profileForm.email"
              type="email"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Неверный email </template>
          <template #hint> Электронный адрес для связи и уведомлений </template>
        </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <form-suggestion
          id="profileAddress"
          :value="profileForm.address"
          :has-error="$v.profileForm.address.$error"
          url="suggest/address"
          :disabled="isDisabled"
          restrict-value
          :count="3"
          name="suggest-profile"
          @input="onInputAddress"
          @select="onSelectAddress"
          @focus="onFocus"
          @blur="onBlur"
        >
          <template #label>Адрес для доставки *</template>
          <template #error>Необходимо заполнить поле</template>
        </form-suggestion>
      </div>
    </div>

    <template #footer>
      <base-btn type="submit" :loading="isDisabled">Сохранить</base-btn>
    </template>
  </account-form-layout>
</template>

<script>
import AccountFormLayout from "@/modules/account/components/AccountFormLayout";
import FormGroup from "@/components/shared/FormGroup";
import FormSuggestion from "@/components/shared/FormSuggestion";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    AccountFormLayout,
    FormGroup,
    FormSuggestion,
    BaseBtn,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          phone: "",
          surname: "",
          name: "",
          patronymic: "",
          address: "",
          email: "",
        };
      },
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      profileForm: this.value,
      isAddressEdit: false,
    };
  },
  validations: {
    profileForm: {
      phone: {
        required,
      },
      surname: {
        required,
      },
      name: {
        required,
      },
      email: {
        email,
      },
      address: {
        required,
      },
    },
  },
  methods: {
    onFocus() {
      this.isAddressEdit = true;
    },
    onBlur() {
      this.isAddressEdit = false;
    },
    onInputAddress(value) {
      this.profileForm.address = value;
    },
    onSelectAddress(payload) {
      this.profileForm.address = payload.value;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid && !this.isAddressEdit) {
        this.submit();
      }
    },
    submit() {
      this.$emit("submit", this.profileForm);
    },
  },
};
</script>
