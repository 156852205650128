<template>
  <section v-if="list.length > 0" class="featured-good-list">
    <h2>Лучшие предложения</h2>

    <splide :options="options">
      <splide-slide
        v-for="item in list"
        :key="`best-${item.id}`"
        style="width: 230px"
      >
        <featured-good-list-item :item="item" />
      </splide-slide>
    </splide>
  </section>
</template>

<script>
import FeaturedGoodListItem from "./FeaturedGoodListItem";
// uitls
import { mapGetters } from "vuex";

export default {
  components: {
    FeaturedGoodListItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("mq", {
      isMd: "getIsMd",
      isLg: "getIsLg",
    }),
    options() {
      const options = {
        perPage: 2,
        gap: "30px",
        arrows: false,
      };

      if (this.isMd) {
        options.perPage = 3;
      }

      if (this.isLg) {
        options.perPage = 6;
      }

      return options;
    },
  },
};
</script>
