var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-changer",class:[
    { 'base-changer--disabled': _vm.disabled },
    `base-changer--${_vm.position}`,
    `base-changer--${_vm.view}`,
  ]},[_c('label',{staticClass:"base-changer__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"base-changer__container"},[_c('button',{staticClass:"base-changer__btn",class:{
        'base-changer__btn--checked': _vm.value,
      },attrs:{"id":_vm.id,"role":"switch","type":"button","aria-readonly":_vm.disabled,"disabled":_vm.disabled},on:{"click":_vm.onChange}},[_c('div',{staticClass:"base-changer__circle"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }