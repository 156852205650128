var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"catalog-list-item",class:{ 'catalog-list-item--open': _vm.isOpen }},[_c('div',{staticClass:"catalog-list-item__header"},[_c('router-link',{staticClass:"catalog-list-item__link",attrs:{"to":{
        name: 'category-item',
        params: { town: _vm.$route.params.town, category: _vm.category.url },
      }}},[_vm._v(" "+_vm._s(_vm.category.title)+" ")]),_c('button',{staticClass:"catalog-list-item__toggle",attrs:{"type":"button"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('base-icon',{staticClass:"catalog-list-item__icon",attrs:{"name":"down-outline","width":"24","height":"24"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"catalog-list-item__content"},[_c('ul',{staticClass:"catalog-list-item__list"},[(_vm.isAutoparts)?_c('li',{staticClass:"catalog-list-item__item"},[_c('a',{staticClass:"catalog-list-item__link text-bold",attrs:{"href":`${_vm.laximoPath}auto`}},[_vm._v(" Каталоги для подбора автозапчастей ")])]):_vm._e(),_vm._l((_vm.category.items),function(item,index){return _c('li',{key:`item-${item.url}-${index}`,staticClass:"catalog-list-item__item"},[_c('router-link',{staticClass:"catalog-list-item__link",attrs:{"to":{
            name: 'item-list',
            params: {
              town: _vm.$route.params.town,
              category: _vm.category.url,
              subCategory: item.url,
            },
          }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),(_vm.isAutoparts && _vm.feedback.liveEnabled)?_c('li',{staticClass:"catalog-list-item__item catalog-list-item__item--has-button"},[_c('base-btn',{staticClass:"text-nowrap",attrs:{"size":"sm","view":"secondary"},on:{"click":_vm.onOpenLivechat}},[_vm._v(" Помощь в подборе → ")])],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }