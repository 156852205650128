import SignupView from "../views/SignupView.vue";
import AccessView from "../views/AccessView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";

export default [
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/access",
    name: "access",
    component: AccessView,
  },
  {
    path: "/reset-password/:userId/:code",
    name: "reset-password",
    component: ResetPasswordView,
  },
];
