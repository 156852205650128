const mixinSlider = {
  props: {
    list: Array,
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    listLength() {
      return this.list.length;
    },
  },
  methods: {
    onPrev() {
      this.active -= 1;

      if (this.active < 0) {
        this.active = this.listLength - 1;
      }
    },
    onNext() {
      this.active += 1;

      if (this.active >= this.listLength) {
        this.active = 0;
      }
    },
    onSelect(index) {
      this.active = index;
    },
  },
};

export default mixinSlider;
