<template>
  <div class="image-gallery-action">
    <div class="image-gallery-action__info">
      <span class="image-gallery-action__step">
        <slot name="step"></slot>
      </span>
      <span class="image-gallery-action__size">
        <slot name="size"></slot>
      </span>
    </div>
    <div class="image-gallery-action__btn-group">
      <button
        type="button"
        class="image-gallery-action__control"
        @click="onPrev"
      >
        <base-icon
          class="image-gallery-action__icon"
          name="left-outline"
          width="24"
          height="24"
        />
      </button>
      <button
        type="button"
        class="image-gallery-action__control"
        @click="onNext"
      >
        <base-icon
          class="image-gallery-action__icon"
          name="right-outline"
          width="24"
          height="24"
        />
      </button>
    </div>
  </div>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  methods: {
    onPrev() {
      this.$emit("prev");
    },
    onNext() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.image-gallery-action {
  align-items: center;
  display: flex;

  &__info {
    font-family: $headings-font-family;
    margin-right: 10px;
  }

  &__step {
    @include font-size(32px);
    font-weight: bold;
  }

  &__size {
    @include font-size(18px);
    color: $grey-100;
  }

  &__control {
    @extend %btn;
    background: $smoke;
    border-radius: 3px;
    height: 36px;
    width: 36px;

    & + & {
      margin-left: 2px;
    }
  }
}
</style>
