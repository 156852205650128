<template>
  <div class="add-cart">
    <base-counter
      class="add-cart__base-counter"
      :value="value"
      :max="item.quantity"
      :name="name"
      @update="onUpdate"
    />
    <base-btn
      class="add-cart__btn"
      :icon="{ name: 'basket-solid', width: '24', height: '24' }"
      :disabled="isDisabled || isLoading"
      aria-label="Добавить в корзину"
      size="sm"
      @click="
        () => {
          isLogged ? onAdd() : onAddLocal();
        }
      "
    />
  </div>
</template>

<script>
import BaseCounter from "@/components/shared/BaseCounter";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseCounter,
    BaseBtn,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    image: {
      type: [Object, Boolean],
      default: null,
    },
    isLoading: Boolean,
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: 1,
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isLogged: "isLogged",
    }),
  },
  methods: {
    ...mapActions("cart", {
      addItem: "onAddItem",
    }),
    onUpdate(value) {
      this.value = value;
    },
    onAddLocal() {
      const item = {
        article: this.item.article,
        brand: this.item.brand,
        image: this.image ? this.image : null,
        title: this.item.title,
        deliveryMaxHours: this.item.deliveryMaxHours,
        deliveryMinHours: this.item.deliveryMinHours,
        quantity: this.item.quantity,
        price: this.item.price,
        count: this.value,
        hash: this.item.hash,
        comment: null,
        time: new Date(),
      };

      this.addItem(item);
      this.$toast.success("Товар добавлен в корзину.");
    },
    onAdd() {
      this.isDisabled = true;

      const payload = {
        hash: this.item.hash,
        count: this.value,
      };

      this.$http
        .post("/shop/cart/add", null, {
          params: payload,
        })
        .then(({ data }) => {
          this.isDisabled = false;

          if (data.status === "ok") {
            this.$eventHub.$emit(this.name, data.count);
            this.$nextTick(() => {
              this.onAddLocal();
            });
          } else {
            this.$toast.error("Не удалось добавить товар в корзину");
          }
        })
        .catch(() => {
          this.isDisabled = false;
          this.$toast.error("Не удалось добавить товар в корзину");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-cart {
  align-items: center;
  display: flex;

  &__base-counter {
    margin-right: 8px;
  }
}
</style>
