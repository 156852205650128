import HomeView from "../modules/home/views/HomeView.vue";
import AddressView from "../modules/address/views/AddressView.vue";
import ContentView from "../views/ContentView.vue";
import NotFoundView from "../views/NotFoundView.vue";

export default [
  {
    path: "/404",
    name: "not-found",
    component: NotFoundView,
  },
  {
    path: "/adresa",
    name: "address",
    component: AddressView,
  },
  {
    path: "/:town?/content/:alias",
    name: "content",
    component: ContentView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
];
