<template>
  <div class="account-nav">
    <template v-if="isGuest">
      <a class="account-nav__link" @click.prevent="onLogin">Войти</a>
      <router-link :to="{ name: 'signup' }" class="account-nav__link">
        Регистрация
      </router-link>
    </template>
    <template v-else>
      <router-link :to="{ name: 'account-profile' }" class="account-nav__link">
        Личные данные
      </router-link>
      <router-link :to="{ name: 'account-password' }" class="account-nav__link">
        Сменить пароль
      </router-link>
      <router-link :to="{ name: 'account-area' }" class="account-nav__link">
        Сменить регион
      </router-link>
      <router-link
        :to="{ name: 'account-order-list' }"
        class="account-nav__link"
      >
        Заказы
      </router-link>
      <router-link :to="{ name: 'account-garage' }" class="account-nav__link">
        Гараж
      </router-link>
      <router-link :to="{ name: 'account-balance' }" class="account-nav__link">
        Платежи и баланс
      </router-link>
      <a class="account-nav__link" href="#" @click.prevent="onLogout">Выход</a>
    </template>
  </div>
</template>

<script>
// utils
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", {
      isGuest: "isGuest",
    }),
  },
  methods: {
    onLogin() {
      this.$emit("click");
      this.$modal.show("login");
    },
    onLogout() {
      this.$eventHub.$emit("unauthorized");
    },
  },
};
</script>

<style lang="scss" scoped>
.account-nav {
  &.account-nav--base-popup {
    margin-top: -6px;
    margin-bottom: -14px;
  }

  &__link {
    color: $black;
    display: block;
    padding: 7px 0;
    text-decoration: none;

    .account-nav--base-popup & {
      padding: 6px 24px 10px;
      margin-left: -24px;
      margin-right: -24px;
    }

    &:hover {
      color: $link-color;

      .account-nav--base-popup & {
        background: $smoke;
        color: $black;
      }
    }

    &.router-link-exact-active,
    &.router-link-exact-active:hover {
      color: $link-color;
    }
  }
}
</style>
