<template>
  <vuescroll ref="scrollbar" :ops="scrollOptions">
    <nav-tab-list class="news-category-nav">
      <nav-tab-item
        ref="category-all"
        :to="{ name: routeName }"
        :active="active === 'all'"
      >
        Все
      </nav-tab-item>
      <nav-tab-item
        v-for="category in list"
        :key="`category-${category.id}`"
        :ref="`category-${category.slug}`"
        :to="{ name: routeName, params: { alias: category.slug } }"
        :active="category.slug === active"
      >
        {{ category.title }}
      </nav-tab-item>
    </nav-tab-list>
  </vuescroll>
</template>

<script>
import vuescroll from "vuescroll";
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";

export default {
  components: {
    vuescroll,
    NavTabList,
    NavTabItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "all",
    },
  },
  data() {
    return {
      scrollOptions: {
        bar: {
          size: "3px",
        },
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    activeRef() {
      return `category-${this.active}`;
    },
  },
  watch: {
    activeRef() {
      this.updateScroll();
    },
  },
  mounted() {
    this.updateScroll();
  },
  methods: {
    updateScroll() {
      const { scrollbar } = this.$refs;

      const item =
        this.activeRef === "category-all"
          ? this.$refs[this.activeRef]
          : this.$refs[this.activeRef][0];
      const offsetLeft = item.$el ? item.$el.offsetLeft : 0;

      setTimeout(() => {
        scrollbar.scrollTo({ x: offsetLeft });
      }, 100);
    },
  },
};
</script>
