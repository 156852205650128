import axios from "axios";

import { updateOptions } from "./helpers";

export default {
  install: (Vue, pluginOptions = {}) => {
    const options = updateOptions(pluginOptions);

    // suggest
    const $suggest = axios.create({
      baseURL: options.suggest,
      headers: {
        Authorization: `Token ${options.token}`,
      },
    });

    const $axios = {
      suggest: $suggest,
    };

    Object.defineProperty(Vue.prototype, options.name, { value: $axios });

    return null;
  },
};
