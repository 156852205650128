<template>
  <router-link v-if="to" :to="to">
    {{ item.title }}
  </router-link>
  <a v-else :href="href" target="_blank">
    {{ item.title }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getCategoryByUrl: "catalog/getCategoryByUrl",
      town: "settings/getTown",
    }),
    tag() {
      return this.to ? "router-link" : "a";
    },
    href() {
      if (this.item.meta.includes("_fr_partnership")) {
        return "https://avtovelikan.ru/franchise";
      }

      return null;
    },
    to() {
      if (this.item.type === "content/page") {
        return {
          name: "content",
          params: {
            alias: this.item.alias,
          },
        };
      }

      if (this.item.type === "content/franchise-page") {
        const alias = this.item.meta.replace("_fr_", "");
        return {
          name: "content",
          params: {
            town: this.town,
            alias: alias,
          },
        };
      }

      if (this.item.type === "catalog/category") {
        const category = this.getCategoryByUrl(this.item.alias);

        if (category) {
          if (category.category) {
            return {
              name: "item-list",
              params: {
                town: this.$route.params.town,
                category: category.alias,
                subCategory: category.category,
              },
            };
          } else if (category.alias) {
            return {
              town: this.$route.params.town,
              name: "category-item",
              params: { category: category.alias },
            };
          }
        }
      }

      if (this.item.meta.includes("_news")) {
        const list = this.item.meta
          .split("_")
          .filter((item) => item.length > 0);
        const params = {
          name: "article-list",
        };
        if (list.length > 0) {
          params.params = {
            alias: list[1],
          };
        }
        return params;
      }

      return null;
    },
  },
};
</script>
