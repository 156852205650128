<template>
  <modal
    name="feedback-call"
    class="feedback-call-modal"
    classes="rounded-0"
    :width="360"
    height="100%"
    :shift-x="1.0"
    :shift-y="0"
    overlay-transition="none"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <vuescroll>
      <div class="feedback-call-modal__content">
        <close-btn
          class="feedback-call-modal__close"
          position="custom"
          @click="$modal.hide('feedback-call')"
        />

        <div v-if="isForm" class="feedback-call-modal__body">
          <h3 class="feedback-call-modal__title">
            Укажите номер телефона для обратного звонка
          </h3>
          <feedback-call-form @success="isForm = false" />
          <p class="feedback-call-modal__note">Звонок бесплатный</p>
        </div>
        <template v-else>
          <div class="feedback-call-modal__body">
            <p class="feedback-call-modal__message">
              В течение 5 минут с Вами свяжется менеджер.
            </p>
          </div>
          <div class="feedback-call-modal__footer">
            <base-btn
              display="block"
              view="secondary"
              size="sm"
              @click="$modal.hide('feedback-call')"
              >Закрыть</base-btn
            >
          </div>
        </template>
      </div>
    </vuescroll>
  </modal>
</template>

<script>
import CloseBtn from "@/components/shared/CloseBtn";
import BaseBtn from "@/components/shared/BaseBtn";
import FeedbackCallForm from "./FeedbackCallForm";
import vuescroll from "vuescroll";
// utils
import { mapActions } from "vuex";

export default {
  components: {
    CloseBtn,
    BaseBtn,
    FeedbackCallForm,
    vuescroll,
  },
  data() {
    return {
      isForm: true,
    };
  },
  methods: {
    ...mapActions("settings", {
      setSettings: "onSetSettings",
    }),
    onBeforeOpen() {
      this.isForm = true;

      this.setSettings({ key: "isModal", value: true });
    },
    onBeforeClose() {
      this.setSettings({ key: "isModal", value: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-call-modal {
  &__close {
    right: 9px;
    top: 9px;
  }

  &__content {
    background-clip: padding-box;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    outline: 0;
    padding: 32px 28px 4rem;
    pointer-events: auto;
    position: relative;
    width: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
  }

  &__title {
    margin-bottom: 48px;
    text-align: center;
  }

  &__note {
    margin-bottom: 0;
    margin-top: 16px;
    text-align: center;
  }

  &__message {
    @extend %subtitle;
    color: $primary;
    text-align: center;
  }
}
</style>
