<template>
  <div class="filter-checkbox-list">
    <nav-tab-list v-if="popular" class="filter-checkbox-list__header">
      <nav-tab-item
        :active="isPopular"
        view="sm"
        @click.native="isPopular = true"
      >
        Популярное
      </nav-tab-item>
      <nav-tab-item
        :active="!isPopular"
        view="sm"
        @click.native="isPopular = false"
      >
        Все
      </nav-tab-item>
    </nav-tab-list>

    <div class="filter-checkbox-list__body" :style="bodyStyles">
      <vuescroll :ops="scrollOptions">
        <div
          v-for="item in filteredList"
          :key="getKey(item)"
          class="filter-checkbox-list__item"
        >
          <form-check :id="getKey(item)">
            <template #default="props">
              <input
                :id="props.id"
                v-model="selectedList"
                :class="props.inputClass"
                :type="props.type"
                :name="name"
                :value="getValue(item)"
                :disabled="!isReady"
                @change="onChange"
              />
            </template>
            <template #label>
              <slot name="label" :item="item">{{ getLabel(item) }}</slot>
            </template>
          </form-check>
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>
import vuescroll from "vuescroll";
import FormCheck from "@/components/shared/FormCheck";
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";
// utils
import isObject from "lodash/isObject";
import sortBy from "lodash/sortBy";

export default {
  components: {
    vuescroll,
    FormCheck,
    NavTabList,
    NavTabItem,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    isReady: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    popular: Boolean,
    trackby: {
      type: String,
      required: true,
    },
    labelby: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedList: [],
      isPopular: true,
      scrollOptions: {
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
        },
      },
    };
  },
  computed: {
    isObject() {
      return isObject(this.list[0]);
    },
    filteredList() {
      if (this.popular && this.isPopular) return this.list.slice(5);

      return sortBy(this.list, ["title"]);
    },
    bodyStyles() {
      if (this.filteredList.length > 20) {
        return {
          height: "200px",
        };
      }

      return {};
    },
  },
  watch: {
    list() {
      this.selectedList = [];
      this.onChange();
    },
    selected(val) {
      this.selectedList = val;
    },
  },
  mounted() {
    if (this.selected.length > 0) {
      this.selectedList = this.selected;
    }

    this.$eventHub.$on(`reset-${this.name}`, () => {
      this.onReset();
    });
  },
  methods: {
    onReset() {
      this.selectedList = [];
      this.$emit("reset");
    },
    onChange() {
      this.$emit("change", this.selectedList);
    },
    getKey(item) {
      const id = this.isObject
        ? item[this.trackby]
        : item.replace(/\s+/g, "-").toLowerCase();
      return `${this.name}-${id}`;
    },
    getLabel(item) {
      return this.isObject ? item[this.labelby] : item;
    },
    getValue(item) {
      return this.isObject ? item[this.trackby] : item;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-checkbox-list {
  &__header {
    margin-bottom: 1rem;
  }
}
</style>
