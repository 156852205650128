<template>
  <form-group :id="id" class="password" :has-error="hasError">
    <template #label>Пароль*</template>
    <template #default="props">
      <input
        :id="props.id"
        v-model.trim="password"
        :type="isShow ? 'text' : 'password'"
        :class="`${props.inputClass} password__control`"
        :disabled="isDisabled"
      />
      <button
        v-show="password.length > 0"
        class="password__btn"
        type="button"
        @click="isShow = !isShow"
      >
        <base-icon
          class="password__icon"
          :name="isShow ? 'eye-slash' : 'eye'"
          width="24"
          height="24"
        />
      </button>
    </template>
    <template #hint> Минимум 6 символов </template>
    <template #error> Необходимо заполнить поле </template>
    <template #extra>
      <form-check id="registerGenerate">
        <template #default="props">
          <input
            :id="props.id"
            v-model="isGenerate"
            :class="props.inputClass"
            :type="props.type"
            :disabled="isDisabled"
            name="registerGenerate"
          />
        </template>
        <template #label> Сгенерировать пароль </template>
      </form-check>
    </template>
  </form-group>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import FormCheck from "@/components/shared/FormCheck";
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import generatePassword from "@/utils/generatePassword";

export default {
  components: {
    FormGroup,
    FormCheck,
    BaseIcon,
  },
  props: {
    isDisabled: Boolean,
    hasError: Boolean,
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      password: this.value,
      isGenerate: false,
      isShow: false,
    };
  },
  watch: {
    isGenerate(val) {
      this.password = val ? generatePassword() : "";
    },
    password(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  &__btn {
    @extend %btn;

    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__control {
    padding-right: 40px;
  }

  &__icon {
    fill: $grey-100;
  }
}
</style>
