<template>
  <div class="delivery-form">
    <div class="delivery-form__header">
      <div class="delivery-form__row">
        <div
          v-for="item in list"
          :key="`delivery-nav-${item.id}`"
          class="delivery-form__col"
        >
          <btn-tab :active="id === item.id" @click="$emit('change', item)">
            {{ item.title }}
          </btn-tab>
        </div>
      </div>
    </div>

    <div class="delivery-form__body">
      <delivery-simple
        :id="value.id"
        :disabled="isDisabled"
        :is-address-required="value.isAddressRequired === 1"
      />

      <template v-if="false">
        <delivery-office v-if="value === 'office'" />
        <delivery-express v-if="value === 'express'" />
        <delivery-pickpoint v-if="value === 'pickpoint'" />
        <delivery-route-taxi v-if="value === 'routetaxi'" />
        <delivery-russian-post v-if="value === 'russianpost'" />
      </template>
    </div>
  </div>
</template>

<script>
import BtnTab from "@/components/shared/BtnTab";
import DeliveryOffice from "@/modules/delivery/DeliveryOffice";
import DeliveryPickpoint from "@/modules/delivery/DeliveryPickpoint";
import DeliveryRouteTaxi from "@/modules/delivery/DeliveryRouteTaxi";
import DeliveryRussianPost from "@/modules/delivery/DeliveryRussianPost";
import DeliveryExpress from "@/modules/delivery/DeliveryExpress";
import DeliverySimple from "@/modules/delivery/DeliverySimple";

export default {
  components: {
    BtnTab,
    DeliveryOffice,
    DeliveryPickpoint,
    DeliveryRouteTaxi,
    DeliveryRussianPost,
    DeliveryExpress,
    DeliverySimple,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    isDisabled: Boolean,
  },
  computed: {
    id() {
      return this.value ? this.value.id : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-form {
  &__row {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0 0.5rem;
  }

  &__header {
    margin-bottom: 8px;
  }
}
</style>
