<template>
  <modal
    name="gallery"
    width="100%"
    height="100%"
    class="gallery-modal"
    @before-open="onBeforeOpen"
  >
    <close-btn @click="onClose" />
    <div class="gallery-modal__body">
      <splide
        class="gallery-modal__splide"
        :options="{
          type: 'fade',
          perPage: 1,
          gap: 0,
          arrows: true,
          start: active,
        }"
      >
        <splide-slide
          v-for="item in list"
          :key="`gallery-${item.id}`"
          class="gallery-modal__item"
        >
          <base-image
            :url="item.files[field]"
            class="image-gallery__active"
            :width="width"
            :height="height"
          />
        </splide-slide>
      </splide>
    </div>
  </modal>
</template>

<script>
import BaseImage from "../shared/BaseImage.vue";
import CloseBtn from "../shared/CloseBtn.vue";

export default {
  components: {
    BaseImage,
    CloseBtn,
  },
  data() {
    return {
      list: [],
      active: -1,
      height: 0,
      width: 0,
      field: null,
    };
  },
  methods: {
    onBeforeOpen(e) {
      const { params } = e;

      if (params) {
        this.list = params.list;
        this.active = params.active;
        this.field = params.field;
        this.width = params.width;
        this.height = params.height;
      }
    },
    onClose() {
      this.$modal.hide("gallery");
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-modal {
  &__body {
    padding: 1rem 50px;
  }

  &__item {
    text-align: center;
  }
}
</style>
