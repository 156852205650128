const mixinCalc = {
  computed: {
    hasScript() {
      return this.routeAlias === "kasko" || this.routeAlias === "osago";
    },
  },
  watch: {
    isLoading(value) {
      if (!value && this.hasScript) {
        this.onSetScript(this.routeAlias);
      }
    },
  },
  mounted() {
    if (this.hasScript && !this.isLoading) {
      this.onSetScript(this.routeAlias);
    }
  },
  methods: {
    onSetScript() {
      this.$nextTick(() => {
        const { script } = this.$refs;

        if (script) {
          const scriptTag = document.createElement("script");
          scriptTag.type = "text/javascript";
          scriptTag.src = `https://${this.routeAlias}.insapp.ru/app/assets/scripts/iframe_loader.js`;
          scriptTag.setAttribute(
            "data-apikey",
            "5f9ecbf4291649b194249dc1a1f07d89"
          );

          script.appendChild(scriptTag);
        }
      });
    },
  },
};

export default mixinCalc;
