<template>
  <div class="feedback-nav" :class="{ 'feedback-nav--open': isOpen }">
    <a
      v-if="feedback.telegram"
      :href="feedback.telegram"
      role="button"
      class="feedback-nav__action feedback-nav__action--telegram"
      target="_blank"
      @click.prevent="$emit('click', 'social')"
    >
      <base-icon
        class="feedback-nav__icon"
        name="telegram"
        width="24"
        height="21"
      />
      <span class="feedback-nav__tooltip">Telegram</span>
    </a>
    <a
      v-if="feedback.viber"
      :href="feedback.viber"
      role="button"
      class="feedback-nav__action feedback-nav__action--viber"
      target="_blank"
      @click.prevent="$emit('click', 'social')"
    >
      <base-icon
        class="feedback-nav__icon"
        name="viber"
        width="30"
        height="32"
      />
      <span class="feedback-nav__tooltip">Viber</span>
    </a>
    <a
      v-if="feedback.whatsapp"
      :href="feedback.whatsapp"
      role="button"
      class="feedback-nav__action feedback-nav__action--whatsapp"
      target="_blank"
      @click.prevent="$emit('click', 'social')"
    >
      <base-icon
        class="feedback-nav__icon"
        name="whatsapp"
        width="26"
        height="28"
      />
      <span class="feedback-nav__tooltip">WhatsApp</span>
    </a>
    <a
      v-if="feedback.callEnabled"
      href="#"
      role="button"
      class="feedback-nav__action mt-4"
      @click.prevent="$emit('click', 'call')"
    >
      <base-icon
        class="feedback-nav__icon"
        name="feedback-call"
        width="28"
        height="28"
      />
      <span class="feedback-nav__tooltip">Заказать обратный звонок</span>
    </a>
    <a
      v-if="feedback.messageEnabled"
      href="#"
      role="button"
      class="feedback-nav__action"
      @click.prevent="$emit('click', 'message')"
    >
      <base-icon
        class="feedback-nav__icon"
        name="feedback-message"
        width="28"
        height="28"
      />
      <span class="feedback-nav__tooltip">Оставить сообщение</span>
    </a>
    <a
      v-if="feedback.liveEnabled"
      href="#"
      role="button"
      class="feedback-nav__action"
      @click="onOpenLivechat"
    >
      <base-icon
        class="feedback-nav__icon"
        name="feedback-livechat"
        width="28"
        height="28"
      />
      <span class="feedback-nav__tooltip">Задать вопрос онлайн</span>
    </a>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import { mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    BaseIcon,
  },
  props: {
    isOpen: Boolean,
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.feedback-nav {
  align-items: center;
  display: none;
  flex-direction: column;
  margin-bottom: 20px;

  &.feedback-nav--open {
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-name: slide-up;
    display: flex;
  }

  &__action {
    align-items: center;
    background: $white;
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.18), 0 2px 2px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: 48px;
    justify-content: center;
    position: relative;
    width: 48px;

    & + & {
      margin-top: 12px;
    }

    &--telegram {
      background-color: #61a8de;
    }

    &--viber {
      background-color: #7d3daf;
    }

    &--whatsapp {
      background-color: #1bd741;
    }
  }

  &__tooltip {
    background: $white;
    border-radius: 18px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.18), 0 2px 2px rgba(0, 0, 0, 0.12);
    color: $black;
    font-family: $headings-font-family;
    font-size: $h6-font-size;
    margin-right: 10px;
    opacity: 0;
    padding: 6px 16px;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 250ms ease;
    visibility: hidden;
    white-space: nowrap;

    :hover > & {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
