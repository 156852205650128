<template>
  <popper
    class="suggestion-info"
    :trigger="isMd ? 'hover' : 'clickToOpen'"
    :options="{
      placement: isMd ? 'top' : 'left',
    }"
  >
    <span class="popper">
      <span class="popper__content" v-html="title"></span>
    </span>

    <button slot="reference" class="suggestion-info__btn">
      <base-icon name="info" width="24" height="24" />
    </button>
  </popper>
</template>

<script>
import Popper from "vue-popperjs";
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    Popper,
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("mq", {
      isMd: "getIsMd",
    }),
  },
};
</script>

<style lang="scss" scoped>
.suggestion-info {
  &__btn {
    @extend %btn;
  }
}
</style>
