<template>
  <div class="selected-filter-list" :class="`selected-filter-list--${view}`">
    <button
      v-for="(item, index) in selectedFilterList"
      :key="`selected-filter-${index}`"
      type="button"
      class="selected-filter-list__item"
      @click="onRemove(item)"
    >
      {{ item.value }}
      <base-icon
        class="selected-filter-list__icon"
        name="close-solid"
        width="16"
        height="16"
      />
    </button>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    filterList: {
      type: Object,
      default: () => {},
    },
    view: {
      type: String,
      default: "default",
    },
  },
  computed: {
    routeQuery() {
      return this.$route.query;
    },
    filteredFilterList() {
      return Object.values(this.filterList).reduce((total, item) => {
        const { alias, values } = item;

        total[alias] = values;

        return total;
      }, {});
    },
    selectedFilterList() {
      const list = [];
      // eslint-disable-next-line no-unused-vars
      const { search, page, ...selectedFilter } = this.routeQuery;

      if (search) {
        list.push({ alias: "search", value: search });
      }

      if (selectedFilter) {
        for (let key in selectedFilter) {
          if (key in this.filteredFilterList) {
            const filterList = this.filteredFilterList[key];
            const valueList = selectedFilter[key].split(",");

            for (let i = 0; i < valueList.length; i++) {
              const value = filterList[valueList[i]];
              list.push({ alias: key, value: value, id: valueList[i] });
            }
          }
        }
      }

      return list;
    },
  },
  methods: {
    onRemove(item) {
      if (item.alias === "search") {
        this.$emit("remove-search", "");
      } else {
        this.$emit("remove-filter", { alias: item.alias, id: item.id });
        // this.$eventHub.$emit(`remove-filter-${item.alias}`, item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-filter-list {
  display: flex;
  position: relative;
  z-index: 1;

  &.selected-filter-list--center {
    justify-content: center;
  }

  &__item {
    @include font-size(14px);
    background: transparent;
    border: 1px solid $red;
    border-radius: 6px;
    color: $red;
    margin-right: 8px;

    &:focus {
      outline: 0;
    }
  }

  &__icon {
    fill: $red;
  }
}
</style>
