<template>
  <brand-list-table v-if="isMd" :list="list" />
  <brand-list-block v-else :list="list" />
</template>

<script>
import BrandListTable from "./BrandListTable";
import BrandListBlock from "./BrandListBlock";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BrandListTable,
    BrandListBlock,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("mq", {
      isMd: "getIsMd",
    }),
  },
};
</script>
