<template>
  <modal name="confirm-city" width="360" height="354" class="confirm-city">
    <div class="confirm-city__body">
      <div class="confirm-city__header">
        <h4 class="confirm-city__title">Подтвердите<br />Ваш Город</h4>
        <close-btn @click="onClose" />
      </div>

      <div class="confirm-city__content">
        <base-btn display="block" @click="onConfirm">
          Я из {{ cityNameFrom }}
        </base-btn>

        <base-btn view="outline-secondary" display="block" @click="onOther">
          Нет, я из другого города
        </base-btn>

        <div class="confirm-city__note">
          От выбранного города зависит цена и срок доставки
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseBtn from "../shared/CloseBtn";
import BaseBtn from "../shared/BaseBtn";
// utils
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CloseBtn,
    BaseBtn,
  },
  computed: {
    ...mapGetters({
      cityNameFrom: "settings/cityNameFrom",
    }),
  },
  methods: {
    ...mapActions("settings", {
      onSetSettings: "onSetSettings",
    }),
    onClose() {
      this.$modal.hide("confirm-city");
    },
    onConfirm() {
      this.onSetSettings({ key: "isCityСonfirm", value: true });
      this.onClose();
    },
    onOther() {
      this.onClose();
      this.$modal.show("city");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-city {
  &__body {
    padding: 36px;
    text-align: center;
  }

  &__header {
    margin-bottom: 30px;
  }

  &__note {
    margin-top: 30px;
  }
}
</style>
