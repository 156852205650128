import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import PagesRoutes from "@/modules/pages/router/index.js";
import TownsRoutes from "@/modules/pages/router/towns.js";
import CartRoutes from "@/modules/cart/router/";
import LogonRoutes from "@/modules/logon/router/";
import CatalogRoutes from "@/modules/catalog/router/";
import AccountRoutes from "@/modules/account/router/";
import ArticleRoutes from "@/modules/article/router/";
import AutoRoutes from "@/modules/auto/router/";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return savedPosition;
    } else {
      const header = document.getElementById("site-header");
      if (header) header.scrollIntoView();
    }
  },
  routes: [
    ...PagesRoutes,
    ...CartRoutes,
    ...LogonRoutes,
    ...CatalogRoutes,
    ...AccountRoutes,
    ...ArticleRoutes,
    ...AutoRoutes,
    ...TownsRoutes,
    {
      path: "*",
      redirect: {
        name: "not-found",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLogged = store.getters["auth/isLogged"];

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLogged) {
      next();
    } else {
      next({ name: "home" });
    }
  } else if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (isLogged) {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
