<template>
  <div
    class="filter-item"
    :class="{ 'filter-item--open': isOpen, 'filter-item--loading': !isReady }"
  >
    <div class="filter-item__header" @click="isOpen = !isOpen">
      <p class="filter-item__title"><slot name="title"></slot></p>

      <base-icon
        class="filter-item__icon"
        name="up-outline"
        width="15"
        height="8"
      />
    </div>

    <div class="filter-item__body">
      <slot :isReady="isReady"></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    isReady: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-item {
  margin-bottom: 16px;

  &--loading {
    opacity: 0.8;
    touch-action: none;
  }

  &__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;
  }

  &__title {
    @extend %subtitle;
    margin-bottom: 0;
  }

  &__icon {
    margin-left: auto;
    fill: $grey-200;
    transform: rotate(-180deg);
    transition: transform 150ms ease;

    .filter-item--open & {
      transform: rotate(0deg);
    }
  }

  &__body {
    display: none;

    .filter-item--open & {
      display: block;
    }
  }
}
</style>
