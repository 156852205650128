<template>
  <div class="city-search">
    <div class="city-search__header">
      <div class="city-search__search">
        <form-group
          id="citySearch"
          :show-label="false"
          size="sm"
          class="city-search__form-group"
          :icon="{ name: 'search', width: '16', height: '16' }"
        >
          <template #label>Поиск города</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="searchQuery"
              type="text"
              :class="props.inputClass"
              placeholder="Поиск города"
              @input="fetchSuggestCityList"
              @focus="onFocus"
              @blur="onBlur"
              @keyup.up="onUp"
              @keyup.down="onDown"
              @keyup.enter="onEnter"
            />
          </template>
          <template #suggest>
            <suggest-list
              :list="suggestionList"
              :is-show="searchQuery !== '' && isFocus"
              name="city"
              :pointer="pointer"
              @click="onSelect"
              @hover="onHover"
            >
              <template #title>
                <template v-if="suggestionList.length > 0">
                  Выберите вариант или продолжите ввод
                </template>
                <template v-else-if="searchQuery !== ''">
                  Неизвестный город
                </template>
              </template>
              <template #default="props">
                <span
                  :inner-html.prop="
                    props.item.title | higlightQuery(searchQuery)
                  "
                ></span>
              </template>
            </suggest-list>
          </template>
        </form-group>
        <div class="city-search__note">
          От выбранного города зависят цены на товар, а так же способ получения
          — через офис представительства или доставка транспортной компанией
        </div>
        <div class="city-search__office">
          <base-icon
            name="star"
            width="14"
            height="13"
            class="city-search__office-icon"
          />
          В городе имеется офис представительства интернет-магазина
          «АвтоВеликан»
        </div>
      </div>
      <nav-tab-list v-if="isLg" class="city-search__country-list" view="border">
        <nav-tab-item
          v-for="item in countryList"
          :key="`country-${item.id}`"
          :active="item.id === countryId"
          @click.native="country = item"
        >
          {{ item.title }}
        </nav-tab-item>
      </nav-tab-list>
      <form-group v-else :id="`${name}Country`" :show-label="false">
        <template #label>Страна</template>
        <template #default="props">
          <multiselect
            :id="props.id"
            v-model="country"
            :options="countryList"
            :searchable="false"
            :show-labels="false"
            track-by="id"
            label="title"
            placeholder="Выберите страну"
          >
            <span slot="noOptions">
              <template v-if="isCountryLoading">Подождите...</template>
              <template v-else>Список стран пуст</template>
            </span>
            <span slot="noResult">
              Ой, ничего не найдено. Попробуйте ввести иначе.
            </span>
          </multiselect>
        </template>
        <template #error>Необходимо заполнить поле </template>
      </form-group>
    </div>

    <base-loading v-if="isCityLoading" />

    <div v-else-if="filteredCityList.length > 0" class="city-search__body">
      <ul class="city-search__emphasize-list">
        <li
          v-for="item in emphasizeCityList"
          :key="`city-${item.id}`"
          class="city-search__emphasize-item"
        >
          <a
            class="city-search__emphasize-link"
            @click="onChangeCity(item.alias)"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>

      <div class="city-search__scroll">
        <vuescroll :ops="scrollOptions">
          <ul class="city-search__city-list">
            <li
              v-for="item in filteredCityList"
              :key="`city-${item.id}`"
              class="city-search__city-item"
            >
              <a
                class="city-search__city-link"
                @click="onChangeCity(item.alias)"
              >
                {{ item.title }}
              </a>
              <base-icon
                v-if="item.hasOwnOffices === 1"
                name="star"
                width="14"
                height="13"
                class="city-search__city-icon"
              />
            </li>
          </ul>
        </vuescroll>
      </div>

      <div v-if="hasShortList" class="city-search__footer">
        <base-btn
          size="sm"
          view="outline-primary"
          @click="isShortList = !isShortList"
        >
          <template v-if="isShortList">Полный список</template>
          <template v-else>Короткий список</template>
        </base-btn>
      </div>
    </div>
  </div>
</template>

<script>
import vuescroll from "vuescroll";
import BaseLoading from "@/components/shared/BaseLoading";
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";
import FormGroup from "@/components/shared/FormGroup";
import BaseIcon from "@/components/shared/BaseIcon";
import BaseBtn from "@/components/shared/BaseBtn";
import SuggestList from "@/components/shared/SuggestList";
// utils
import sortBy from "lodash/sortBy";
import suggestMixin from "@/utils/suggestMixin";
import { mapGetters } from "vuex";

export default {
  components: {
    vuescroll,
    BaseLoading,
    NavTabList,
    NavTabItem,
    FormGroup,
    BaseIcon,
    BaseBtn,
    SuggestList,
  },
  mixins: [suggestMixin],
  props: {
    isOpened: Boolean,
  },
  data() {
    return {
      countryList: [],
      cityList: [],
      isCountryLoading: false,
      isCityLoading: false,
      country: null,
      isShortList: true,
      searchQuery: "",
      scrollOptions: {
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLg: "mq/getIsLg",
    }),
    routeName() {
      return this.$route.name;
    },
    countryId() {
      return this.country ? this.country.id : null;
    },
    emphasizeCityList() {
      return this.cityList.filter((item) => item.emphasize === 1);
    },
    filteredCityList() {
      const shortList = this.isShortList
        ? this.cityList.filter((item) => item.inShortlist === 1)
        : this.cityList;
      return shortList.length > 0 ? shortList : this.cityList;
    },
    hasShortList() {
      return this.cityList.some((item) => item.inShortlist === 1);
    },
  },
  watch: {
    countryId(newId, oldId) {
      if (newId && newId !== oldId) {
        this.fetchCityList(null, newId);
      }

      this.cityList = [];
    },
  },
  mounted() {
    this.fetchCountryList();
  },
  methods: {
    fetchCountryList(query) {
      const params = {
        search: query,
      };

      this.isCountryLoading = true;

      this.$http
        .get("departments/default/countries", {
          params: {
            ...params,
          },
        })
        .then(({ data }) => {
          this.isCountryLoading = false;
          this.countryList = data;

          if (!this.country) this.country = this.countryList[0];
        })
        .catch(() => {
          this.isCountryLoading = false;
          this.$toast.error("Не удалось получить список стран.");
        });
    },
    fetchCityList(query, countryId) {
      const params = {};

      if (query) params.search = query;
      if (countryId) params.countryId = countryId;

      this.isCityLoading = true;

      this.$http
        .get("departments/default/towns", {
          params,
        })
        .then(({ data }) => {
          this.isCityLoading = false;
          this.cityList = sortBy(data, ["title"]);
        })
        .catch(() => {
          this.isCityLoading = false;
          this.$toast.error("Не удалось получить список городов.");
        });
    },
    fetchSuggestCityList() {
      const params = {
        search: this.searchQuery,
      };

      this.$http
        .get("departments/default/towns", {
          params,
        })
        .then(({ data }) => {
          this.suggestionList = data;
        })
        .catch(() => {
          this.$toast.error("Не удалось получить список городов.");
        });
    },
    onEnter() {
      const item = this.suggestionList[this.pointer];
      if (item) this.onSelect(item);
    },
    onChangeCity(alias) {
      this.$eventHub.$emit("start", alias);
      this.$modal.hide("city");
    },
    onSelect(item) {
      this.pointer = -1;
      this.onChangeCity(item.alias);
    },
  },
};
</script>

<style lang="scss" scoped>
.city-search {
  &__header {
    margin-top: 1rem;
  }

  &__body {
    @include media-breakpoint-up(lg) {
      padding-top: 1.8rem;
    }
  }

  &__scroll {
    height: calc(100vh - 360px);

    @include media-breakpoint-up(lg) {
      height: calc(590px - 280px);
    }
  }

  &__search {
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr 235px;
      gap: 0 24px;
      margin-bottom: 1rem;
    }
  }

  &__form-group {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__note {
    @extend %text-nav;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__office {
    @extend %text-nav;
    display: none;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 20px auto;
    }
  }

  &__office-icon {
    fill: $yellow;
  }

  &__emphasize-list {
    @include list-unstyled;
  }

  &__emphasize-item {
    & + & {
      margin-top: 0.5rem;
    }
  }

  &__emphasize-link {
    color: $coal;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }

  &__city-list {
    @include list-unstyled;
    display: grid;
    gap: 0.5rem 1rem;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 0;
    }
  }

  &__city-link {
    color: $stone;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }

  &__city-icon {
    margin-left: 0.5rem;
    fill: $yellow;
  }

  &__footer {
    margin-top: 1rem;
    text-align: center;
  }
}
</style>
