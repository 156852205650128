<template>
  <div class="catalog-list">
    <div
      v-for="item in navList"
      :key="`item-${item.id}`"
      class="catalog-list__item"
    >
      <catalog-list-item :category="item" />
    </div>
  </div>
</template>

<script>
import CatalogListItem from "./CatalogListItem";
// utils
import { mapState } from "vuex";

export default {
  components: {
    CatalogListItem,
  },
  computed: {
    ...mapState("catalog", {
      navList: (state) => state.list,
    }),
  },
};
</script>
