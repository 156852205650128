import Vue from "vue";
import fromUnixTime from "date-fns/fromUnixTime";
import isItemOld from "@/utils/isItemOld";

const state = {
  items: [],
  selectedItems: [],
  payment: null,
  delivery: null,
  deliveryAddress: "",
  comment: null,
  discountRules: null,
};

const getters = {
  itemsSize: (state) => {
    return state.items.reduce((total, item) => {
      return total + parseInt(item.count);
    }, 0);
  },
  itemsPrice: (state) => {
    const list =
      state.selectedItems.length > 0
        ? state.items.filter((item) => state.selectedItems.includes(item.hash))
        : state.items;

    return list.reduce((total, item) => {
      return total + parseInt(item.count) * item.price;
    }, 0);
  },
  dicsountPercent: (state, getters) => {
    if (
      state.discountRules &&
      getters.itemsPrice >= state.discountRules[0].from
    ) {
      const total = getters.itemsPrice;
      const filteredRules = state.discountRules.filter(
        (item) => item.from < total
      );
      return filteredRules.length >= 0
        ? filteredRules[filteredRules.length - 1].percent
        : null;
    }

    return null;
  },
  getItemsHash: (state) => {
    if (state.items.length === 0) return [];

    if (state.selectedItems.length > 0) {
      return state.selectedItems;
    }

    return state.items.map((item) => item.hash);
  },
  discountPrice: (state, getters) => {
    if (getters.dicsountPercent) {
      return ((getters.itemsPrice * getters.dicsountPercent) / 100) * -1;
    }

    return 0;
  },
  totalPrice: (state, getters) => {
    return getters.itemsPrice + getters.discountPrice + getters.deliveryPrice;
  },
  deliveryTitle: (state) => {
    return state.delivery ? state.delivery.title : null;
  },
  deliveryPrice: (state) => {
    return state.delivery ? state.delivery.price : null;
  },
  inCart: (state) => (hash) => {
    return state.items.find((item) => item.hash === hash);
  },
  isOld: (state) => {
    return state.items
      .filter((item) => state.selectedItems.includes(item.hash))
      .some((item) => {
        return isItemOld(item.time);
      });
  },
  isCheckoutReady: (state, getters) => {
    if (
      getters.isOld ||
      !state.delivery ||
      (state.delivery.isAddressRequired === 1 && !state.deliveryAddress)
    )
      return false;

    return true;
  },
  isEmpty: (state) => {
    return state.items.length === 0;
  },
};

const actions = {
  onAddItem: ({ commit }, item) => {
    commit("addItem", item);
  },
  onRemoveItemByIndex: ({ commit }, index) => {
    commit("removeItemByIndex", index);
  },
  onUpdateItem: ({ commit }, payload) => {
    commit("updateItem", payload);
  },
  onUpdateValue: ({ commit }, payload) => {
    commit("updateValue", payload);
  },
  onClean: ({ commit }) => {
    commit("clean");
  },
  onSetList: ({ commit }, obj) => {
    commit("setList", obj);
  },
  onSetDiscoutRules: ({ commit }, obj) => {
    commit("setDiscountRules", obj);
  },
};

const mutations = {
  addItem(state, newItem) {
    const index = state.items.findIndex((item) => item.hash === newItem.hash);
    if (index === -1) {
      state.items.push(newItem);
    } else {
      Vue.set(state.items[index], "count", newItem.count);
    }
  },
  removeItemByIndex(state, index) {
    Vue.delete(state.items, index);
  },
  updateItem(state, { index, key, value }) {
    Vue.set(state.items[index], key, value);
  },
  updateValue(state, { key, value }) {
    state[key] = value;
  },
  clean(state) {
    state.items = [];
    state.selectedItems = [];
    state.comment = null;
    state.payment = null;
    state.delivery = null;
    state.deliveryAddress = "";
  },
  setList(state, obj) {
    state.items = [];
    state.selectedItems = [];

    Object.keys(obj).forEach((key) => {
      const good = obj[key];

      const { image, title, deliveryMaxHours, deliveryMinHours } = good.item
        ? good.item
        : {};

      const {
        article,
        brand,
        storageQuantity,
        clientPrice,
        quantity,
        hash,
        time,
        comment,
      } = good;

      const item = {
        article: article,
        brand: brand,
        image: image ? image : null,
        title: title ? title : null,
        deliveryMaxHours: deliveryMaxHours ? deliveryMaxHours : null,
        deliveryMinHours: deliveryMinHours ? deliveryMinHours : null,
        quantity: storageQuantity,
        price: clientPrice,
        count: quantity,
        hash: hash,
        comment: comment,
        time: fromUnixTime(time),
      };

      state.items.push(item);
    });
  },
  setDiscountRules(state, obj) {
    state.discountRules = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
