<template>
  <router-link
    class="suggestion-link"
    :to="{
      name: 'search-vendors',
      params: {
        town: $route.params.town,
        brand: brand,
        article: article.replace(/\s/g, ''),
      },
    }"
  >
    <strong>{{ brand }}</strong
    ><br />
    {{ article.replace(/\s/g, "") }}
  </router-link>
</template>

<script>
export default {
  props: {
    brand: {
      type: String,
      required: true,
    },
    article: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-link {
  text-decoration: none;
}
</style>
