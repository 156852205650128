<template>
  <page-layout-full
    class="payment-result"
    :breadcrumb="breadcrumb"
    view="sm"
    header="center"
  >
    <template #title>
      {{ title }}
    </template>

    <div class="payment-result__icon">
      <base-icon
        v-if="status === 'SUCCEEDED'"
        fill="#65c366"
        name="payment-ok"
        width="200"
        height="200"
      />
      <base-icon
        v-else-if="status === 'CANCELED'"
        fill="#c62925"
        name="payment-cancel"
        width="200"
        height="200"
      />
      <base-icon
        v-else
        stroke="#ffb400"
        name="payment-wait"
        width="200"
        height="200"
      />
    </div>

    <p v-if="status === 'LOADING'" class="payment-result__info">
      Платежная система еще не передала информацию о статусе оплаты.<br />
      Проверка статуса будет выполнена автоматически через 1 минуту.
    </p>

    <p v-if="status === 'SUCCEEDED'" class="payment-result__info">
      <base-btn :to="{ name: 'account-order-list' }" tag="router-link">
        К списку заказов
      </base-btn>
    </p>

    <action-card v-if="status === 'CANCELED'" view="row">
      <template #title>Повторить оплату</template>
      <template #default>
        <p>Попробуйте еще раз повторить оплату</p>
      </template>
      <template #btn>
        <base-btn
          display="block"
          :to="
            orderId
              ? {
                  name: 'account-order-item-payment',
                  params: { orderId: orderId },
                }
              : { name: 'account-order-list' }
          "
          tag="router-link"
        >
          Повторить
        </base-btn>
      </template>
    </action-card>

    <action-card v-if="status === 'LOADING'" view="row">
      <template #title>Проверить статус платежа </template>
      <template #default>
        <p>Запросить статус платежа вручную при длительном ожидании.</p>
      </template>
      <template #btn>
        <base-btn
          display="block"
          type="button"
          view="outline-secondary"
          @click="onFetchData"
        >
          Проверить
        </base-btn>
      </template>
    </action-card>

    <action-card
      v-if="status === 'LOADING' || status === 'CANCELED'"
      view="row"
    >
      <template #title>Вернуться в личный кабинет</template>
      <template #default>
        <p>
          <template v-if="status === 'LOADING'">
            Прервать оплату и вернуться к заказу.
          </template>
          Вы можете выбрать другую платежную систему или поменять способ оплаты.
        </p>
      </template>
      <template #btn>
        <base-btn
          display="block"
          view="outline-danger"
          :to="
            orderId
              ? { name: 'account-order-item', params: { orderId: orderId } }
              : { name: 'account-order-list' }
          "
          tag="router-link"
        >
          Вернуться
        </base-btn>
      </template>
    </action-card>
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import ActionCard from "@/components/shared/ActionCard";
import BaseBtn from "@/components/shared/BaseBtn";
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    PageLayoutFull,
    ActionCard,
    BaseBtn,
    BaseIcon,
  },
  metaInfo() {
    return {
      title: this.title ? this.title : "Загружается",
    };
  },
  data() {
    return {
      isLoading: false,
      status: "LOADING",
      orderId: null,
    };
  },
  computed: {
    title() {
      switch (this.status) {
        case "LOADING": {
          return "Ожидаем информацию о платеже";
        }
        case "SUCCEEDED": {
          return "Оплата прошла";
        }
        case "CANCELED": {
          return "Оплата не прошла";
        }
        case "PENDING": {
          return "В ожидании платежа";
        }
        case "WAITING_FOR_CAPTURE": {
          return "Ожидает подтверждения";
        }
        case "NOT_FOUND": {
          return "404";
        }
        default: {
          return "Платеж не найден";
        }
      }
    },
    breadcrumb() {
      return [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Оплата",
        },
      ];
    },
    operationId() {
      return this.$route.params.operationId;
    },
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;

      this.$http
        .get("shop/default/payment-result", {
          params: {
            operationId: this.operationId,
          },
        })
        .then(({ data }) => {
          const { status, orderId } = data;
          this.isLoading = false;
          this.status = status.toUpperCase();
          this.orderId = orderId;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error("Не удалось получить данные.");
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-result {
  &__info {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__icon {
    margin-bottom: 2rem;
    text-align: center;
  }
}
</style>
