<template>
  <div class="header-nav" :class="`header-nav--${view}`">
    <router-link
      :to="{
        name: 'content',
        params: { alias: 'contacts', town: town },
      }"
      class="header-nav__link"
    >
      Контакты
    </router-link>
    <router-link
      :to="{ name: 'content', params: { alias: 'sposoby-oplaty-zakazov' } }"
      class="header-nav__link"
    >
      Оплата
    </router-link>
    <router-link
      :to="{ name: 'content', params: { alias: 'dostavka-avtozapchastei' } }"
      class="header-nav__link"
    >
      Доставка
    </router-link>
    <router-link
      :to="{
        name: 'content',
        params: { alias: 'garantiya-i-usloviya-vozvrata-detalei' },
      }"
      class="header-nav__link"
    >
      Возврат
    </router-link>
    <router-link
      :to="{
        name: 'content',
        params: { alias: 'osago' },
      }"
      class="header-nav__link"
    >
      ОСАГО
    </router-link>
    <router-link
      :to="{
        name: 'content',
        params: { alias: 'kasko' },
      }"
      class="header-nav__link"
    >
      КАСКО
    </router-link>
    <a
      class="header-nav__link"
      href="https://avtovelikan.ru/franchise"
      target="_blank"
    >
      Франшиза
    </a>
  </div>
</template>

<script>
// utils
import { mapGetters } from "vuex";

export default {
  props: {
    view: {
      type: String,
      default: "row",
    },
  },
  computed: {
    ...mapGetters("settings", {
      town: "getTown",
    }),
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  &__link {
    @extend %text-nav;

    padding: 7px 0;
    text-decoration: none;

    .header-nav--row & {
      color: $grey-200;
      display: inline-block;
    }

    .header-nav--column & {
      color: $coal;
      display: block;
    }

    &:hover,
    &.router-link-active {
      color: $link-color;
    }

    & + & {
      .header-nav--row & {
        margin-left: 24px;
        position: relative;

        &::before {
          content: "";
          background-image: escape-svg(
            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17'><path fill='#5A5C5C' d='M9.333 8.5a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z'/></svg>")
          );
          height: 17px;
          left: -20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
        }
      }
    }
  }
}
</style>
