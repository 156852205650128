const state = {
  list: [],
  limit: 20,
  view: "square",
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  toObject: (state) => {
    if (state.list.length === 0) return null;

    const obj = state.list.reduce(function (acc, cur) {
      acc[cur.url] = cur;
      return acc;
    }, {});

    return obj;
  },
  getCategory: (state, getters) => (url) => {
    if (state.list.length === 0) return null;

    return getters.toObject[url];
  },
  getCategoryById: (state) => (id) => {
    const item = state.list.find((item) => item.url === id);

    if (item) {
      return {
        alias: item.id,
      };
    }

    for (let i = 0; i < state.list.length; i++) {
      const { items, ...rest } = state.list[i];

      const category = items.find((item) => item.id === id);

      if (category) {
        return {
          alias: rest.url,
          category: category.url,
        };
      }
    }

    return null;
  },
  getCategoryByUrl: (state) => (url) => {
    const item = state.list.find((item) => item.url === url);

    if (item) {
      return {
        alias: item.url,
      };
    }

    for (let i = 0; i < state.list.length; i++) {
      const { items, ...rest } = state.list[i];

      const category = items.find((item) => item.url === url);

      if (category) {
        return {
          alias: rest.url,
          category: category.url,
        };
      }
    }

    return null;
  },
  getActiveCategoryById: (state) => (id) => {
    for (let i = 0; i < state.list.length; i++) {
      const { items, ...rest } = state.list[i];

      const category = items.find((item) => item.id === id);

      if (category) {
        return {
          parent: rest,
          active: category,
        };
      }
    }

    return null;
  },
  getActiveCategory: (state, getters) => (parentUrl, activeUrl) => {
    if (state.list.length === 0) return null;

    const parent = getters.getCategory(parentUrl);

    if (parent) {
      const { items, ...rest } = parent;
      const active = items.find((item) => item.url === activeUrl);

      return {
        parent: rest,
        active: active,
      };
    }

    return null;
  },
};

const actions = {
  onSetList: ({ commit }, list) => {
    commit("setList", list);
  },
  onSetField: ({ commit }, payload) => {
    commit("setField", payload);
  },
};

const mutations = {
  setList(state, list) {
    state.list = list;
  },
  setField(state, payload) {
    state[payload.key] = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
