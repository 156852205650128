<template>
  <div class="catalog-box-content">
    <h4 class="catalog-box-content__title">
      <slot name="title" linkClass="catalog-box-content__title-link"></slot>
    </h4>

    <div class="catalog-box-content__body">
      <div class="row">
        <div
          v-for="(chunk, chunkIndex) in splittedList"
          :key="`chunk-${chunkIndex}`"
          class="col"
        >
          <ul class="catalog-box-content__list">
            <li
              v-if="isAutoparts && chunkIndex === 0"
              class="catalog-box-content__item"
            >
              <a
                :href="`${laximoPath}auto`"
                class="catalog-box-content__link text-bold"
              >
                Каталоги для подбора автозапчастей
              </a>
            </li>
            <li
              v-for="(item, index) in chunk"
              :key="`item-${chunkIndex}-${index}`"
              class="catalog-box-content__item"
            >
              <router-link
                :to="{
                  name: 'item-list',
                  params: {
                    town: $route.params.town,
                    category: category,
                    subCategory: item.url,
                  },
                }"
                class="catalog-box-content__link"
              >
                {{ item.title }}
              </router-link>
            </li>
            <li
              v-if="
                isAutoparts &&
                chunkIndex === splittedList.length - 1 &&
                feedback.liveEnabled
              "
              class="catalog-box-content__item catalog-box-content__item--has-button"
            >
              <base-btn
                class="text-nowrap"
                size="sm"
                view="secondary"
                @click="onClick"
              >
                Помощь в подборе →
              </base-btn>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { mapState } from "vuex";
import chunk from "lodash/chunk";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    BaseBtn,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
    splittedList() {
      const size = this.list.length;
      if (size === 0) return [];

      return chunk(this.list, Math.round(size / 4));
    },
    isAutoparts() {
      return this.category === "avtozapchasti";
    },
    laximoPath() {
      return process.env.VUE_APP_LAXIMO_PATH;
    },
  },
  methods: {
    onClick() {
      onOpenLivechat();
      this.$eventHub.$emit("close-nav");
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-box-content {
  &__title {
    @extend %subtitle;
    margin-bottom: 16px;
  }

  &__title-link {
    text-decoration: none;
  }

  &__list {
    @include list-unstyled;
  }

  &__item {
    &--has-button {
      margin-top: 1rem;
    }
  }

  &__link {
    @extend %text-sm;

    color: $coal;
    display: block;
    padding: 6px 0;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }
}
</style>
