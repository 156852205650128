<template>
  <page-layout-full v-if="item" class="category-item" :breadcrumb="breadcrumb">
    <template #title>
      <base-icon
        class="category-item__icon"
        :name="item.url"
        width="32"
        height="32"
      />
      {{ item.title }}
    </template>

    <category :category="item" class="category-list__item" />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import BaseIcon from "@/components/shared/BaseIcon.vue";
import Category from "../components/category/Category.vue";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    PageLayoutFull,
    BaseIcon,
    Category,
  },
  metaInfo() {
    return {
      title: this.item ? this.item.title : "Загружается",
    };
  },
  computed: {
    ...mapGetters("catalog", {
      getCategory: "getCategory",
    }),
    item() {
      return this.getCategory(this.$route.params.category);
    },
    breadcrumb() {
      return [
        {
          title: "Все каталоги",
          to: {
            name: "category-list",
          },
        },
        { title: this.item.title },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.category-item {
  &__icon {
    margin-right: 0.5rem;
  }
}
</style>
