/**
 * Подсвечивает строку в строке
 * @param {String} query подстрока
 * @returns {String} content строка
 */
const higlightQuery = (content, query) => {
  if (!query || query === "") return content;

  return content.replace(new RegExp(query, "gi"), (match) => {
    return `<span class="text-bold">${match}</span>`;
  });
};

export default higlightQuery;
