var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"suggestion-link",attrs:{"to":{
    name: 'search-vendors',
    params: {
      town: _vm.$route.params.town,
      brand: _vm.brand,
      article: _vm.article.replace(/\s/g, ''),
    },
  }}},[_c('strong',[_vm._v(_vm._s(_vm.brand))]),_c('br'),_vm._v(" "+_vm._s(_vm.article.replace(/\s/g, ""))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }