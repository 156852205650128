<template>
  <div
    v-if="feedback.enabled"
    v-click-outside="onClickOutside"
    class="feedback-btn"
    :class="{ 'feedback-btn--open': isOpen }"
  >
    <feedback-nav :is-open="isOpen" @click="onClick" />

    <a
      href="#"
      role="button"
      class="feedback-btn__toggler"
      @click.prevent="isOpen = !isOpen"
    >
      <base-icon
        v-if="isOpen"
        class="feedback-btn__icon"
        name="feedback-close"
        width="18"
        height="18"
      />
      <base-icon
        v-else
        class="feedback-btn__icon"
        name="feedback-phone"
        width="28"
        height="28"
      />
    </a>
  </div>
</template>

<script>
import FeedbackNav from "./FeedbackNav.vue";
import BaseIcon from "@/components/shared/BaseIcon.vue";
// utils
import { mapState } from "vuex";

const body = document.body;

export default {
  components: {
    FeedbackNav,
    BaseIcon,
  },
  directives: {
    "click-outside": {
      bind(el, binding) {
        const ourClickEventHandler = (event) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unbind(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      },
    },
  },
  data() {
    return {
      isOpen: false,
      backdrop: null,
    };
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.onOpen();
      } else {
        this.onClose();
      }
    },
  },
  methods: {
    onClickOutside() {
      this.isOpen = false;
    },
    onClick(val) {
      if (val === "social") {
        this.onClickOutside();
      } else {
        this.onClickOutside();

        if (val === "call") {
          this.$modal.show("feedback-call");
        } else if (val === "message") {
          this.$modal.show("feedback-message");
        } else if (val === "livechat") {
          this.$modal.show("feedback-live");
        }
      }
    },
    onOpen() {
      if (!this.backdrop) {
        this.backdrop = document.createElement("div");
        this.backdrop.id = "backdrop";
        this.backdrop.className = "backdrop";
      }

      body.appendChild(this.backdrop);
    },
    onClose() {
      if (this.backdrop && this.backdrop.parentNode)
        this.backdrop.parentNode.removeChild(this.backdrop);
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-btn {
  bottom: 82px;
  position: fixed;
  right: 20px;
  z-index: $zindex-feedback;

  @include media-breakpoint-up(lg) {
    bottom: 38px;
    right: 56px;
  }

  &.feedback-btn--open {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
  }

  &__toggler {
    align-items: center;
    background: $primary;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 48px;
    justify-content: center;
    position: relative;
    transform: scale(1.167);
    transition: background-color 250ms ease, transform 250ms ease;
    width: 48px;

    .feedback-btn--open & {
      background-color: $coal;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.18), 0 2px 2px rgba(0, 0, 0, 0.12);
      transform: scale(1) rotate(90deg);
    }

    &::before {
      background: rgba($primary, 0.15);
      border-radius: 50%;
      content: "";
      display: block;
      height: 72px;
      position: absolute;
      width: 72px;

      .feedback-btn--open & {
        display: none;
      }
    }

    &::after {
      .feedback-btn--animated:not(.feedback--open) & {
        animation-duration: 0.9s;
        animation-iteration-count: infinite;
        animation-name: circle;
        border: 1px solid $primary;
        border-radius: 50%;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }
}
</style>
