<template>
  <div class="order-body" :class="{ 'order-body--has-extra': !!$slots.extra }">
    <div class="order-body__header">
      <h3 class="order-body__title">
        <slot name="title" linkClass="order-body__title-link"></slot>
      </h3>
      <div v-if="!!$slots.subtitle" class="order-body__subtitle">
        <slot name="subtitle"></slot>
      </div>
    </div>
    <div class="order-body__body">
      <order-body-item
        v-for="item in list"
        :key="`item-${item.id}`"
        :item="item"
        class="order-body__item"
      />
    </div>
    <div class="order-body__footer">
      <slot name="action"></slot>
      <div class="order-body__total">
        Итого:
        <span class="order-body__price">{{ price | formatNumber }} ₽</span>
      </div>
    </div>
    <div v-if="$slots.extra" class="order-body__extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
import OrderBodyItem from "./OrderBodyItem";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    OrderBodyItem,
  },
  filters: {
    formatNumber,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    price: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.order-body {
  &__header {
    background-color: $smoke;
    border: 1px solid $grey-300;
    border-bottom-color: $smoke;
    border-radius: 6px 6px 0 0;
    padding: 1rem 1.5rem;
  }

  &__footer {
    align-items: center;
    display: flex;
    background-color: $smoke;
    border: 1px solid $grey-300;
    border-top-color: $smoke;
    border-radius: 0 0 6px 6px;
    padding: 1rem 1.5rem;

    .order-body--has-extra & {
      border-radius: 0;
      border-bottom-color: $smoke;
    }
  }

  &__extra {
    border: 1px solid $grey-300;
    border-top: none;
    border-radius: 0 0 6px 6px;
    padding: 1rem 1.5rem;
  }

  &__title {
    margin-bottom: 0;
  }

  &__title-link {
    text-decoration: none;
  }

  &__subtitle {
    font-weight: bold;
    color: $stone;
  }

  &__total {
    font-weight: bold;
    margin-left: auto;
  }

  &__price {
    @extend %subtitle;
  }

  &__item {
    border-left: 1px solid $grey-300;
    border-right: 1px solid $grey-300;
    padding: 16px 24px;

    & + & {
      border-top: 1px solid $grey-300;
    }
  }
}
</style>
