<template>
  <form class="feedback-call-form" @submit.prevent="onSubmit">
    <form-group id="callbackNumber" :has-error="$v.number.$error">
      <template #label>Телефон</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="number"
          v-mask="'# (###) ### ##-##'"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
      <template #extra>
        <span class="text-sm text-muted">
          Нажимая на кнопку «Жду звонка», я соглашаюсь с
          <router-link
            :to="{
              name: 'content',
              params: { alias: 'politika-konfidencialnosti' },
            }"
            target="_blank"
          >
            политикой конфиденциальности
          </router-link>
        </span>
      </template>
    </form-group>

    <base-btn
      display="block"
      view="primary"
      size="sm"
      type="submit"
      :disabled="isDisabled"
    >
      Жду звонка
    </base-btn>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FormGroup,
    BaseBtn,
  },
  data() {
    return {
      number: "",
      isDisabled: false,
    };
  },
  validations: {
    number: {
      required,
    },
  },
  methods: {
    submit() {
      this.$emit("success");
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
  },
};
</script>
