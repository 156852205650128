<template>
  <form class="form-login" @submit.prevent="onSubmit">
    <form-group id="loginEmail" :has-error="$v.loginForm.login.$error">
      <template #label>E-mail / Номер телефона</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="loginForm.login"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="loginPassword" :has-error="$v.loginForm.password.$error">
      <template #label>
        Пароль (<a href="#" @click.prevent="$emit('forgot')">Забыли пароль?</a
        >)*
      </template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="loginForm.password"
          type="password"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="loginRememberGroup">
      <form-check id="loginRemember">
        <template #default="props">
          <input
            :id="props.id"
            v-model="loginForm.rememberme"
            :class="props.inputClass"
            :type="props.type"
            name="loginRememberme"
          />
        </template>
        <template #label>Запомнить меня</template>
      </form-check>
    </form-group>

    <base-btn display="block" type="submit" :loading="isDisabled">
      Войти
    </base-btn>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import FormCheck from "@/components/shared/FormCheck";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  components: {
    FormGroup,
    FormCheck,
    BaseBtn,
  },
  data() {
    return {
      isDisabled: false,
      loginForm: {
        login: "",
        password: "",
        rememberme: false,
      },
    };
  },
  validations: {
    loginForm: {
      login: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      onSetBaseUser: "account/onSetBaseUser",
      onSetToken: "auth/onSetToken",
    }),
    submit() {
      this.isDisabled = true;

      this.$http
        .post("/users/security/login", {
          login: this.loginForm.login,
          password: this.loginForm.password,
        })
        .then(({ data }) => {
          this.isDisabled = false;

          if (data.status === "ok") {
            this.$modal.hide("login");
            this.$toast.success("Вы успешно вошли в систему.");

            const { profile } = data.user;

            this.onSetToken(data.user.authKey);
            this.onSetBaseUser({
              id: data.user.id,
              profile: {
                email: profile.email,
                phone: profile.phone,
                name: profile.name,
                patronymic: profile.patronymic,
                surname: profile.surname,
                balanceD: profile.balanceD,
              },
            });

            this.$eventHub.$emit("start");
            this.$eventHub.$emit("authorized");
          } else {
            this.$toast.error("Неверный логин или пароль");
          }
        })
        .catch((error) => {
          this.isDisabled = false;
          console.error(error);
          this.$toast.error("Неверный логин или пароль");
        });
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
  },
};
</script>
