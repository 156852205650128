var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suggestion-list"},[(_vm.isMd)?_c('suggestion-list-table',{scopedSlots:_vm._u([{key:"original",fn:function(){return [(_vm.filteredOriginalList.length > 0)?_vm._l((_vm.filteredOriginalList),function(item,index){return _c('suggestion-list-table-tr',{key:`original-${index}`,scopedSlots:_vm._u([{key:"info",fn:function(){return [(item.brandDescription)?_c('suggestion-list-info',{attrs:{"title":item.brandDescription}}):_vm._e()]},proxy:true},{key:"link",fn:function(){return [_c('suggestion-list-link',{attrs:{"article":item.article,"brand":item.brand}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.title)+" ")]},proxy:true},{key:"quantity",fn:function(){return [_vm._v(" "+_vm._s(item.quantity)+" ")]},proxy:true},{key:"price",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price))+" ₽")]},proxy:true},{key:"warehouse",fn:function(){return [_c('base-warehouse',{attrs:{"view":item.contractor && item.contractor.color
                  ? item.contractor.color
                  : null}},[_vm._v(" "+_vm._s(item.routeTitle)+" ")])]},proxy:true},{key:"delivery",fn:function(){return [_c('base-delivery',{attrs:{"min":item.deliveryMinHours,"max":item.deliveryMaxHours}})]},proxy:true},{key:"cart",fn:function(){return [_c('add-cart',{attrs:{"item":item,"image":_vm.image,"is-loading":_vm.isLoading,"name":`original-${index}`}})]},proxy:true}],null,true)})}):(!_vm.isLoading)?_c('tr',[_c('td',{staticClass:"suggestion-list__empty",attrs:{"colspan":"8"}},[_vm._v(" Оригинальных позиций не найдено. ")])]):_vm._e()]},proxy:true},{key:"replacement",fn:function(){return [(_vm.filteredReplacementList.length > 0)?_vm._l((_vm.filteredReplacementList),function(item,index){return _c('suggestion-list-table-tr',{key:`replacement-${index}`,scopedSlots:_vm._u([{key:"info",fn:function(){return [(item.brandDescription)?_c('suggestion-list-info',{attrs:{"title":item.brandDescription}}):_vm._e()]},proxy:true},{key:"link",fn:function(){return [_c('suggestion-list-link',{attrs:{"article":item.article,"brand":item.brand}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.title)+" ")]},proxy:true},{key:"quantity",fn:function(){return [_vm._v(" "+_vm._s(item.quantity)+" ")]},proxy:true},{key:"price",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price))+" ₽")]},proxy:true},{key:"warehouse",fn:function(){return [_c('base-warehouse',{attrs:{"view":item.contractor && item.contractor.color
                  ? item.contractor.color
                  : null}},[_vm._v(" "+_vm._s(item.routeTitle)+" ")])]},proxy:true},{key:"delivery",fn:function(){return [_c('base-delivery',{attrs:{"min":item.deliveryMinHours,"max":item.deliveryMaxHours}})]},proxy:true},{key:"cart",fn:function(){return [_c('add-cart',{attrs:{"item":item,"image":_vm.image,"is-loading":_vm.isLoading,"name":`replacement-${index}`}})]},proxy:true}],null,true)})}):(!_vm.isLoading)?_c('tr',[_c('td',{staticClass:"suggestion-list__empty",attrs:{"colspan":"8"}},[_vm._v(" Аналогов для товара не найдено. ")])]):_vm._e()]},proxy:true}],null,false,1812221386)}):_c('suggestion-list-block',{scopedSlots:_vm._u([{key:"original",fn:function(){return [(_vm.filteredOriginalList.length > 0)?_vm._l((_vm.filteredOriginalList),function(item,index){return _c('suggestion-list-block-item',{key:`original-${index}`,scopedSlots:_vm._u([{key:"index",fn:function(){return [_vm._v(_vm._s(index + 1))]},proxy:true},{key:"info",fn:function(){return [(item.brandDescription)?_c('suggestion-list-info',{attrs:{"title":item.brandDescription}}):_vm._e()]},proxy:true},{key:"link",fn:function(){return [_c('suggestion-list-link',{attrs:{"article":item.article,"brand":item.brand}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.title)+" ")]},proxy:true},{key:"quantity",fn:function(){return [_vm._v(" "+_vm._s(item.quantity)+" ")]},proxy:true},{key:"price",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price))+" ₽")]},proxy:true},{key:"warehouse",fn:function(){return [_c('base-warehouse',{attrs:{"view":item.contractor && item.contractor.color
                  ? item.contractor.color
                  : null}},[_vm._v(" "+_vm._s(item.routeTitle)+" ")])]},proxy:true},{key:"delivery",fn:function(){return [_c('base-delivery',{attrs:{"min":item.deliveryMinHours,"max":item.deliveryMaxHours}})]},proxy:true},{key:"cart",fn:function(){return [_c('add-cart',{attrs:{"item":item,"image":_vm.image,"is-loading":_vm.isLoading,"name":`original-${index}`}})]},proxy:true}],null,true)})}):(!_vm.isLoading)?_c('div',{staticClass:"suggestion-list__empty"},[_vm._v(" Оригинальных позиций не найдено. ")]):_vm._e()]},proxy:true},{key:"replacement",fn:function(){return [(_vm.filteredReplacementList.length > 0)?_vm._l((_vm.filteredReplacementList),function(item,index){return _c('suggestion-list-block-item',{key:`replacement-${index}`,scopedSlots:_vm._u([{key:"index",fn:function(){return [_vm._v(_vm._s(index + 1))]},proxy:true},{key:"info",fn:function(){return [(item.brandDescription)?_c('suggestion-list-info',{attrs:{"title":item.brandDescription}}):_vm._e()]},proxy:true},{key:"link",fn:function(){return [_c('suggestion-list-link',{attrs:{"article":item.article,"brand":item.brand}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.title)+" ")]},proxy:true},{key:"quantity",fn:function(){return [_vm._v(" "+_vm._s(item.quantity)+" ")]},proxy:true},{key:"price",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.price))+" ₽")]},proxy:true},{key:"warehouse",fn:function(){return [_c('base-warehouse',{attrs:{"view":item.contractor && item.contractor.color
                  ? item.contractor.color
                  : null}},[_vm._v(" "+_vm._s(item.routeTitle)+" ")])]},proxy:true},{key:"delivery",fn:function(){return [_c('base-delivery',{attrs:{"min":item.deliveryMinHours,"max":item.deliveryMaxHours}})]},proxy:true},{key:"cart",fn:function(){return [_c('add-cart',{attrs:{"item":item,"image":_vm.image,"is-loading":_vm.isLoading,"name":`replacement-${index}`}})]},proxy:true}],null,true)})}):(!_vm.isLoading)?_c('div',{staticClass:"suggestion-list__empty"},[_vm._v(" Аналогов для товара не найдено. ")]):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }