import { render, staticRenderFns } from "./FeaturedNewsList.vue?vue&type=template&id=37ad72fd&scoped=true&"
import script from "./FeaturedNewsList.vue?vue&type=script&lang=js&"
export * from "./FeaturedNewsList.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedNewsList.vue?vue&type=style&index=0&id=37ad72fd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ad72fd",
  null
  
)

export default component.exports