<template>
  <component :is="tag" class="category">
    <header v-if="!isActive" class="category__header">
      <h3 class="category__title">
        <router-link
          :to="{
            name: 'category-item',
            params: { town: $route.params.town, category: category.url },
          }"
          class="category__title-link"
          @mouseenter.native="isHover = true"
          @mouseleave.native="isHover = false"
        >
          <base-icon
            class="category__title-icon"
            :name="isHover ? `${category.url}-hover` : category.url"
            width="32"
            height="32"
          />
          {{ category.title }}
        </router-link>
      </h3>
    </header>
    <div class="category__content">
      <div class="row">
        <div
          v-for="(chunk, chunkIndex) in splittedList"
          :key="`chunk-${chunkIndex}`"
          class="col-sm-6 col-lg-3"
        >
          <ul class="category__list">
            <li v-if="isAutoparts && chunkIndex === 0" class="category__item">
              <a
                :href="`${laximoPath}auto/lx/index`"
                class="category__link text-bold"
              >
                Каталоги для подбора автозапчастей
              </a>
            </li>
            <li
              v-for="(item, index) in chunk"
              :key="`item-${chunkIndex}-${index}`"
              class="category__item"
            >
              <router-link
                :to="{
                  name: 'item-list',
                  params: {
                    town: $route.params.town,
                    category: category.url,
                    subCategory: item.url,
                  },
                }"
                class="category__link"
              >
                {{ item.title }}
              </router-link>
            </li>
            <li
              v-if="
                isAutoparts &&
                chunkIndex === splittedList.length - 1 &&
                feedback.liveEnabled
              "
              class="category__item category__item--has-button"
            >
              <base-btn
                class="text-nowrap"
                size="sm"
                view="secondary"
                @click="onOpenLivechat($event)"
              >
                Помощь в подборе →
              </base-btn>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import chunk from "lodash/chunk";
import { mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    BaseIcon,
    BaseBtn,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
    list() {
      if (!this.category) return [];

      return this.category.items;
    },
    splittedList() {
      const size = this.list.length;
      if (size === 0) return [];

      return chunk(this.list, Math.round((size + 1) / 4));
    },
    isAutoparts() {
      return this.category.url === "avtozapchasti";
    },
    isActive() {
      if (
        this.$route.name === "category-item" &&
        this.$route.params.category === this.category.url
      ) {
        return true;
      }

      return false;
    },
    tag() {
      return this.isActive ? "div" : "section";
    },
    laximoPath() {
      return process.env.VUE_APP_LAXIMO_PATH;
    },
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.category {
  & + & {
    border-top: 1px solid $grey-300;
    margin-top: 32px;
    padding-top: 32px;

    @include media-breakpoint-up(lg) {
      margin-top: 64px;
      padding-top: 64px;
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  &__title-link {
    align-items: center;
    display: inline-flex;
    text-decoration: none;
  }

  &__title-icon {
    margin-right: 16px;
  }

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__item {
    &--has-button {
      margin-top: 1rem;
    }
  }

  &__link {
    color: $coal;
    display: block;
    padding: 6px 0;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }
}
</style>
