<template>
  <li
    class="nav-tab-item"
    :class="[{ 'nav-tab-item--active': active }, `nav-tab-item--${view}`]"
  >
    <component
      :is="tag"
      :to="to"
      class="nav-tab-item__link"
      @click.prevent="$emit('click')"
    >
      <slot></slot>
    </component>
  </li>
</template>

<script>
export default {
  props: {
    active: Boolean,
    to: {
      type: Object,
      default: null,
    },
    view: {
      type: String,
      default: "df",
    },
  },
  computed: {
    tag() {
      return this.to ? "router-link" : "a";
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tab-item {
  &__link {
    border-bottom: 1px solid transparent;
    color: $grey-100;
    cursor: pointer;
    display: block;
    text-decoration: none;
    white-space: nowrap;

    .nav-tab-item--df & {
      @extend %subtitle;

      border-bottom-width: 2px;
      padding-bottom: 9px;
    }

    .nav-tab-item--lg & {
      @extend %h3;

      border-bottom-width: 4px;
      padding-bottom: 12px;
    }

    .nav-tab-item--sm & {
      border-bottom-width: 2px;
      padding-bottom: 6px;
    }

    .nav-tab-item--active & {
      color: $primary;

      @include media-breakpoint-up(md) {
        border-bottom-color: $primary;
      }
    }
  }
}

.nav-tab-item--df + .nav-tab-item--df {
  margin-left: 24px;
}

.nav-tab-item--sm + .nav-tab-item--sm {
  margin-left: 16px;
}

.nav-tab-item--lg + .nav-tab-item--lg {
  margin-left: 48px;
}
</style>
