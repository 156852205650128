<template>
  <div class="delivery-office">
    <div class="row">
      <div class="col-lg-8">
        <p class="delivery-office__txt">
          <strong>Адрес доставки</strong>: г. Чита, ул. Ленина, 149 Б, ТЦ
          АвтоГрад, Магазин АвтоВеликан
        </p>
        <p class="delivery-office__txt">
          <strong>Стоимость доставки</strong>: бесплатно
        </p>
        <p class="delivery-office__txt"><strong>Дата</strong>: 30-31 июля</p>
      </div>
      <div class="col-lg-4 text-end">
        <base-btn
          view="outline-primary"
          display="block"
          @click="$modal.show('city-alert')"
        >
          Выбрать другой город
        </base-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    BaseBtn,
  },
};
</script>

<style lang="scss" scoped>
.delivery-office {
  &__txt {
    margin-bottom: 0.5rem;
  }
}
</style>
