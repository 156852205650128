<template>
  <div class="good-item">
    <div class="good-item__body">
      <div class="good-item__checkbox">
        <slot name="checkbox"></slot>
      </div>
      <figure class="good-item__picture">
        <base-image
          class="good-info__image"
          :url="imageSrc"
          width="40"
          height="40"
        />
      </figure>
      <div class="good-item__content">
        <div class="good-item__section">
          <strong>{{ item.brand }}</strong
          ><br />
          <span class="good-item__extra">Код детали: {{ item.article }}</span>
        </div>
        <div class="good-item__section good-item__section--desc">
          {{ item.title }}
          <base-delivery
            v-if="item.deliveryMinHours && item.deliveryMaxHours"
            class="good-item__extra"
            :min="item.deliveryMinHours"
            :max="item.deliveryMaxHours"
            title="Срок поставки"
          />
        </div>
        <div class="good-item__section">
          <div class="good-item__row">
            <base-counter
              class="good-item__base-counter"
              :name="`good-base-counter-${index}`"
              :value="item.count"
              :max="item.quantity"
              :disabled="isOld || isDisabled || isCartDisabled"
              @increase="onIncreaseCount"
              @reduce="onReduceCount"
            />

            <div class="good-item__price">
              {{ item.price | formatNumber }} ₽
            </div>
          </div>
          <a
            class="good-item__note-link"
            :class="{ 'good-item__note-link--open': isNoteShow }"
            @click="isNoteShow = !isNoteShow"
          >
            <base-icon
              name="comment"
              class="good-item__note-icon"
              width="24"
              height="24"
            />
            Оставить комментарий
          </a>
        </div>
      </div>
      <div class="good-item__remove">
        <button
          class="good-item__btn"
          type="button"
          :disabled="isDisabled || isCartDisabled"
          @click="
            () => {
              isLogged ? onRemove(item.count) : removeItemByIndex(index);
            }
          "
        >
          <base-icon
            class="good-item__delete"
            name="delete-outline"
            width="24"
            height="24"
          />
        </button>
      </div>
    </div>
    <div v-if="isNoteShow" class="good-item__note">
      <form-group id="messageText" class="good-item__form-group">
        <template #label>Комментарий</template>
        <template #default="props">
          <textarea
            :id="props.id"
            v-model="message"
            :class="props.inputClass"
            :disabled="isDisabled || isCartDisabled"
            @change="onChangeComment"
          >
          </textarea>
        </template>
      </form-group>
    </div>
    <base-notify v-if="isOld" class="good-item__danger">
      Информация устарела

      <template #btn="props">
        <base-btn
          size="sm"
          view="outline-secondary"
          :class="props.btnClass"
          @click="onSearch"
        >
          Повторить поиск
        </base-btn>
      </template>
    </base-notify>
    <base-notify v-if="false" class="good-item__danger">
      Не удалось выполнить проценку. Возможно данный товар отсутствует в
      продаже. Удалите его из корзины и выполните повторный поиск.
    </base-notify>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
import BaseBtn from "@/components/shared/BaseBtn";
import BaseNotify from "@/components/shared/BaseNotify";
import BaseCounter from "@/components/shared/BaseCounter";
import BaseDelivery from "@/modules/catalog/components/list/BaseDelivery";
import FormGroup from "@/components/shared/FormGroup";
import BaseImage from "@/components/shared/BaseImage";
// utils
import { mapActions, mapGetters } from "vuex";
import formatNumber from "@/utils/formatNumber";
import isItemOld from "@/utils/isItemOld";

export default {
  components: {
    BaseIcon,
    BaseBtn,
    BaseNotify,
    BaseCounter,
    BaseDelivery,
    FormGroup,
    BaseImage,
  },
  filters: {
    formatNumber,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isCartDisabled: Boolean,
  },
  data() {
    return {
      isNoteShow: false,
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isLogged: "isLogged",
    }),
    imageSrc() {
      return this.item && this.item.image && this.item.image.source
        ? this.item.image.source
        : null;
    },
    message: {
      get() {
        return this.$store.state.cart.items[this.index].comment;
      },
      set(value) {
        this.updateValue({ index: this.index, value: value, key: "comment" });
      },
    },
    isOld() {
      return isItemOld(this.item.time);
    },
  },
  methods: {
    ...mapActions("cart", {
      removeItemByIndex: "onRemoveItemByIndex",
      updateValue: "onUpdateItem",
    }),
    onError(message) {
      this.$eventHub.$emit(`good-base-counter-${this.index}`, this.item.count);
      this.$toast.error(message);
    },
    onRemove(count) {
      this.isDisabled = true;

      const payload = {
        hash: this.item.hash,
        count: count,
      };

      this.$http
        .post("/shop/cart/remove", null, {
          params: payload,
        })
        .then(({ data }) => {
          this.isDisabled = false;

          if (data.status === "ok") {
            this.removeItemByIndex(this.index);
          } else {
            this.onError("Не удалось удалить товар из корзины");
          }
        })
        .catch(() => {
          this.isDisabled = false;
          this.onError("Не удалось удалить товар из корзины");
        });
    },
    onUpdate(form, key) {
      this.isDisabled = true;

      this.$http
        .post(
          `/shop/cart/set`,
          {
            ...form,
          },
          {
            params: {
              hash: this.item.hash,
            },
          }
        )
        .then(({ data }) => {
          this.isDisabled = false;

          if (data.status === "ok") {
            this.updateValue({ index: this.index, value: form[key], key: key });
          } else {
            this.onError("Не удалось обновить данные товара");
          }
        })
        .catch(() => {
          this.isDisabled = false;
          this.onError("Не удалось обновить данные товара");
        });
    },
    onIncreaseCount(value) {
      if (this.isLogged) {
        this.onUpdate({ count: value, comment: this.item.comment }, "count");
      } else {
        this.updateValue({ index: this.index, value: value, key: "count" });
      }
    },
    onReduceCount(value) {
      if (this.isLogged) {
        this.onUpdate({ count: value, comment: this.item.comment }, "count");
      } else {
        this.updateValue({ index: this.index, value: value, key: "count" });
      }
    },
    onChangeComment(event) {
      if (this.isLogged) {
        this.onUpdate(
          { comment: event.target.value, count: this.item.count },
          "comment"
        );
      }
    },
    onSearch() {
      this.$nextTick(() => {
        this.$router.push({
          name: "search-vendors",
          params: {
            town: this.$route.params.town,
            brand: this.item.brand,
            article: this.item.article,
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.good-item {
  border-bottom: 1px solid $grey-300;
  padding-top: 1rem;
  padding-bottom: 1rem;
  word-break: break-all;

  &__body {
    display: grid;
    grid-template-columns: 24px 64px auto;
    gap: 0 1rem;
    position: relative;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 24px 40px auto;
      padding-right: 2rem;
    }
  }

  &__picture {
    margin-bottom: 0;
  }

  &__image {
    @include img-fluid;
  }

  &__remove {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__btn {
    @extend %btn;
    border: none;
    padding: 0;
  }

  &__content {
    display: grid;
    gap: 0.5rem 0;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 145px 1fr auto;
      gap: 0 0.5rem;
    }
  }

  &__row {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__extra {
    @include font-size(14px);
    color: $grey-100;
    display: block;
    margin-top: 0.5rem;
  }

  &__price {
    @extend %h3;
    text-align: right;
    white-space: nowrap;
    width: 100px;
  }

  &__note-link {
    @include font-size(15px);
    color: $grey-100;
    cursor: pointer;
    display: inline-block;
    margin-top: 0.5rem;
    text-decoration: none;
    white-space: nowrap;
  }

  &__note-icon {
    fill: $white;
    stroke: $grey-200;

    .good-item__note-link--open & {
      fill: $grey-200;
    }
  }

  &__form-group {
    margin-bottom: 0;
  }

  &__danger {
    margin-top: 0.5rem;
  }

  &__delete {
    fill: $grey-200;

    :hover > & {
      fill: $red;
    }
  }
}
</style>
