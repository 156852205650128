<template>
  <footer class="site-footer">
    <div v-if="!isLg" class="site-footer__start">
      <base-container>
        <footer-navbar :list="navList" view="collapse" />
      </base-container>
    </div>
    <div class="site-footer__end">
      <base-container>
        <div class="row site-footer__row">
          <div class="col-sm-4 col-lg-3">
            <logo class="site-footer__logo" view="dark" />
            <base-contact view="dark" class="site-footer__contact" />
            <social class="site-footer__social" />
          </div>
          <div v-if="isLg" class="col-lg-8">
            <footer-navbar :list="navList" view="dark" />
          </div>
        </div>

        <base-copyright class="site-footer__copyright" />
      </base-container>
    </div>
  </footer>
</template>

<script>
import Logo from "./shared/Logo";
import Social from "./shared/Social";
import BaseContact from "./footer/BaseContact";
import BaseCopyright from "./footer/BaseCopyright";
import FooterNavbar from "./footer/FooterNavbar";
import BaseContainer from "../shared/BaseContainer";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    Logo,
    BaseContact,
    Social,
    BaseCopyright,
    FooterNavbar,
    BaseContainer,
  },
  data() {
    return {
      navList: [],
    };
  },
  computed: {
    ...mapGetters({
      isLg: "mq/getIsLg",
    }),
  },
  mounted() {
    this.onFetchNav();
  },
  methods: {
    onFetchNav() {
      this.$http
        .get("app/site/bottom-menu")
        .then(({ data }) => {
          this.navList = data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Ошибка. Не удалось получить данные.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.site-footer {
  padding-top: 50px;

  &__start {
    border-top: 1px solid $grey-300;
    padding-bottom: 8px;
    padding-top: 32px;
  }

  &__end {
    background: $black;
    padding-bottom: 32px;
    padding-top: 32px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 80px;
      padding-top: 80px;
    }
  }

  &__row {
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }
  }

  &__contact,
  &__social {
    margin-top: 25px;

    @include media-breakpoint-up(lg) {
      margin-top: 50px;
    }
  }

  &__copyright {
    margin-top: 25px;

    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }
}
</style>
