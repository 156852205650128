<template>
  <div class="delivery-pickpoint">
    <div class="delivery-pickpoint__header">
      <p class="delivery-pickpoint__address">
        <strong>Адрес доставки:</strong>
        <a class="link-action" @click="$modal.show('city-alert')">{{
          cityName
        }}</a>
      </p>

      <p>Для доставки необходимо выбрать пункт выдачи</p>

      <div class="delivery-pickpoint__list">
        <a
          class="delivery-pickpoint__point"
          :class="{ 'delivery-pickpoint__point--active': active === 0 }"
          role="button"
          @click.prevent="active = 0"
        >
          СДЭК
        </a>
        <a
          class="delivery-pickpoint__point"
          :class="{ 'delivery-pickpoint__point--active': active === 1 }"
          role="button"
          @click.prevent="active = 1"
        >
          ПЭК
        </a>
      </div>

      <div class="delivery-pickpoint__wrapper">
        <delivery-search-form
          class="delivery-pickpoint__form"
          :city="cityName"
          @submit="onSubmitSearch"
        />
        <div id="mapDelivery" class="delivery-pickpoint__map"></div>
      </div>

      <delivery-calculate class="delivery-pickpoint__calculate" />
    </div>
  </div>
</template>

<script>
/* global ymaps :true */
import DeliveryCalculate from "./DeliveryCalculate";
import DeliverySearchForm from "./DeliverySearchForm";
import throttle from "lodash/throttle";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    DeliveryCalculate,
    DeliverySearchForm,
  },
  data() {
    return {
      active: 0,
      map: null,
    };
  },
  computed: {
    ...mapGetters("settings", {
      cityName: "cityName",
    }),
  },
  watch: {
    map(val) {
      if (val) this.setObjects();
    },
  },
  mounted() {
    ymaps.ready(this.initMap);

    window.addEventListener("resize", this.optimizeResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.optimizeResize);
  },
  methods: {
    optimizeResize: throttle(
      function () {
        this.map.container.fitToViewport();
      },
      250,
      true
    ),
    initMap() {
      this.map = new ymaps.Map("mapDelivery", {
        center: [55.751574, 37.573856],
        zoom: 9,
        controls: ["zoomControl", "rulerControl"],
      });
    },
    setObjects() {
      const myCollection = new ymaps.GeoObjectCollection();

      const myPlacemark = new ymaps.Placemark([55.826479, 37.487208], {
        balloonContentHeader: "На жулебинском бул.",
        balloonContentBody:
          '<p><strong>Адрес:</strong> г. Москва, Жулебинский бульвар, 30 корп. 1</p><p><strong>Время работы:</strong> Пн-Пт 10:00-20:00<br>Сб-Вс 10:00-18:00</p><p><strong>Как к нам проехать:</strong> 1 этаж, вход со стороны Жулебинского бульвара</p><button class="map__btn" type="button">Выбрать</button>',
        hintContent: "На жулебинском бул.",
      });
      myCollection.add(myPlacemark);
      myCollection.options.set({
        balloonMaxWidth: 300,
      });
      this.map.geoObjects.add(myCollection);
    },
    onSubmitSearch() {
      console.info("on submit search");
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-pickpoint {
  &__list {
    display: flex;
    font-weight: bold;
    margin-bottom: 0.5rem;
    justify-content: center;
  }

  &__point {
    @extend %subtitle;

    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: $grey-100;
    display: block;
    padding-bottom: 12px;

    & + & {
      margin-left: 24px;
    }

    &.delivery-pickpoint__point--active {
      border-bottom-color: $primary;
      color: $primary;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__form {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 320px;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      width: 370px;
    }
  }

  &__map {
    height: 507px;

    @include media-breakpoint-up(lg) {
      height: 804px;
    }
  }

  &__calculate {
    margin-top: 8px;
  }
}
</style>
