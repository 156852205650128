<template>
  <base-loading v-if="isLoading" />

  <page-layout-sidebar
    v-else
    class="cart"
    :breadcrumb="breadcrumb"
    position="right"
    :size="3"
  >
    <template #title>Корзина</template>

    <template #sidebar>
      <template v-if="!isEmpty">
        <cart-info
          :is-disabled="isCheckoutDisabled"
          :is-checking="isChecking"
          @clean="onClean"
          @checkout="onCheckout"
        />

        <base-alert v-if="isCartOld" view="danger">
          В корзине присутствуют товары с устаревшей ценой.
        </base-alert>
      </template>
    </template>

    <p v-if="!isLoading && isEmpty">В корзине пусто.</p>

    <template v-else>
      <base-alert v-if="false" view="success"> Корзина сохранена. </base-alert>

      <base-alert v-if="isCartOld" view="danger">
        В корзине присутствуют товары с устаревшей ценой.
      </base-alert>

      <base-alert v-if="false">
        <strong>Доставка</strong><br />
        Цены и сроки указаны с учетом доставки в
        <router-link
          :to="{
            name: 'content',
            params: { alias: 'contacts', town: town },
          }"
        >
          пункты выдачи заказов г. {{ cityName }}
        </router-link>
      </base-alert>

      <base-alert>
        <strong>Доставка стекол и кузовных деталей</strong><br />
        Для транспортировки автомобильных стекол и кузовных деталей используется
        обрешетка. Перед оформлением и оплатой заказа уточняйте детали у
        менджера для расчета стоимости доставки.
      </base-alert>

      <good-list class="cart__list" :list="items" :is-disabled="isDisabled" />

      <section class="cart__section">
        <h3>Способ доставки</h3>
        <delivery-form
          :value="delivery"
          :list="deliveryList"
          :is-disabled="isDisabled"
          @change="onChangeDelivery"
        />
      </section>

      <section v-if="false" class="cart__section">
        <h3>Способ оплаты</h3>
        <payment-form :value="payment" @change="onChangePayment" />
      </section>

      <section v-if="false" class="cart__section">
        <h3>Способ доставки</h3>

        <recipient-form />
      </section>

      <footer class="cart__footer">
        <base-btn
          :disabled="isCheckoutDisabled"
          :loading="isChecking"
          @click="onCheckout"
        >
          Оформить заказ
        </base-btn>
      </footer>
    </template>

    <template #extra>
      <modal-cart-alert />
    </template>
  </page-layout-sidebar>
</template>

<script>
import PageLayoutSidebar from "@/components/page/PageLayoutSidebar.vue";
import BaseLoading from "@/components/shared/BaseLoading";
import BaseAlert from "@/components/shared/BaseAlert";
import BaseBtn from "@/components/shared/BaseBtn";
import GoodList from "../components/GoodList";
import CartInfo from "../components/CartInfo";
import DeliveryForm from "../components/DeliveryForm";
import PaymentForm from "../components/PaymentForm";
import RecipientForm from "../components/RecipientForm";
import ModalCartAlert from "../components/ModalCartAlert";
// utils
import { mapActions, mapGetters, mapState } from "vuex";
import orderBy from "lodash/orderBy";

export default {
  name: "CartView",
  components: {
    PageLayoutSidebar,
    BaseLoading,
    BaseAlert,
    GoodList,
    CartInfo,
    DeliveryForm,
    PaymentForm,
    RecipientForm,
    ModalCartAlert,
    BaseBtn,
  },
  metaInfo: {
    title: "Корзина",
  },
  data() {
    return {
      breadcrumb: [{ title: "Корзина" }],
      deliveryList: [],
      isLoading: false,
      isDisabled: false,
      isChecking: false,
    };
  },
  computed: {
    ...mapState("cart", {
      items: (state) => state.items,
      selectedItems: (state) => state.selectedItems,
      payment: (state) => state.payment,
      delivery: (state) => state.delivery,
      deliveryAddress: (state) => state.deliveryAddress,
      comment: (state) => state.comment,
    }),
    ...mapGetters({
      cityName: "settings/cityName",
      isCartOld: "cart/isOld",
      itemsHash: "cart/getItemsHash",
      isCheckoutReady: "cart/isCheckoutReady",
      isLogged: "auth/isLogged",
      town: "settings/getTown",
    }),
    isEmpty() {
      return this.items.length === 0;
    },
    isCheckoutDisabled() {
      return this.isDisabled || this.selectedItems.length === 0;
    },
  },
  watch: {
    isLogged(val) {
      if (val) {
        this.onFetchCartList();
      }
    },
    town(newTown, oldTown) {
      if (newTown !== oldTown) this.onFetchDeliveryList();
    },
  },
  created() {
    if (this.isLogged) {
      this.onFetchCartList();
    }
    this.onFetchDeliveryList();
  },
  methods: {
    ...mapActions("cart", {
      onCleanCart: "onClean",
      onChangeValue: "onUpdateValue",
      setCart: "onSetList",
    }),
    onFetchDeliveryList() {
      const params = {};

      this.$http
        .get("delivery/delivery/index", {
          params: params,
        })
        .then(({ data }) => {
          this.deliveryList = orderBy(data, ["order"]);

          if (!this.deliver) this.onChangeDelivery(this.deliveryList[0]);
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.error(
            "Не удалось получить список доступных способо доставки."
          );
        });
    },
    onFetchCartList() {
      this.isLoading = true;

      this.$http
        .get("shop/cart/index")
        .then(({ data }) => {
          this.isLoading = false;

          this.setCart(data);
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          this.$toast.error("Не удалось получить товары в корзине.");
        });
    },
    onClean() {
      if (this.isLogged) {
        this.isLoading = true;
        this.$http
          .post("/shop/cart/clean")
          .then(({ data }) => {
            this.isLoading = false;

            if (data.status === "ok") {
              this.onCleanCart();
            } else {
              this.$toast.error("Не удалось очистить корзину");
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.error("Не удалось очистить корзину");
          });
      } else {
        this.onCleanCart();
      }
    },
    checkout() {
      this.isDisabled = true;
      this.isChecking = true;

      const form = {
        deliveryId: this.delivery.id,
        clientComment: this.comment,
        deliveryAddress: this.deliveryAddress,
        itemsIds: this.itemsHash,
      };

      this.$http
        .post("shop/cart/checkout", form)
        .then(({ data }) => {
          this.isChecking = false;
          this.isDisabled = false;
          const { status, orderId } = data;

          if (status === "ok") {
            this.$toast.success("Заказ оформлен.");
            if (this.selectedItems.length === 0) {
              this.onCleanCart();
            } else {
              this.onFetchCartList();
            }
            if (orderId) {
              this.$router.push({
                name: "account-order-item",
                params: { orderId: orderId },
              });
            } else {
              this.$router.push({ name: "account-order-list" });
            }
          }
        })
        .catch(() => {
          this.isDisabled = false;
          this.isChecking = false;
          this.$toast.error("Не удалось оформить заказ.");
        });
    },
    onChangeDelivery(obj) {
      this.onChangeValue({ key: "delivery", value: obj });
    },
    onChangePayment(obj) {
      this.onChangeValue({ key: "payment", value: obj });
    },
    onCheckout() {
      if (this.isCheckoutReady) {
        if (this.isLogged) {
          this.checkout();
        } else {
          this.$modal.show("login");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  &__list {
    margin-top: 1rem;
  }

  &__section {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
      margin-top: 80px;
    }
  }

  &__footer {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-top: 80px;
      text-align: center;
    }
  }
}
</style>
