<template>
  <div class="image-gallery">
    <div class="image-gallery__body" @click="onClick">
      <base-image
        :url="activeItem.files['news-full']"
        class="image-gallery__active"
        width="1072"
        height="600"
      />
    </div>
    <div class="image-gallery__footer">
      <div class="image-gallery__thumb-list">
        <a
          v-for="(item, index) in list"
          :key="`gallery-${item.id}`"
          class="image-gallery__thumb-link"
          @click="onSelect(index)"
        >
          <base-image
            :url="item.files['news-full']"
            class="image-gallery__thumb-image"
            :class="{ 'image-gallery__thumb-image--active': active === index }"
            width="125"
            height="70"
          />
        </a>
      </div>
      <image-gallery-action
        class="image-gallery__action"
        @prev="onPrev"
        @next="onNext"
      >
        <template #step>
          {{ active + 1 }}
        </template>
        <template #size> / {{ listLength }} </template>
      </image-gallery-action>
    </div>
  </div>
</template>

<script>
import BaseImage from "./BaseImage.vue";
import ImageGalleryAction from "./ImageGalleryAction.vue";
// utils
import mixinSlider from "@/utils/mixinSlider";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseImage,
    ImageGalleryAction,
  },
  mixins: [mixinSlider],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("mq", {
      isLg: "getIsLg",
    }),
    activeItem() {
      return this.list[this.active];
    },
  },
  methods: {
    onClick() {
      if (this.isLg) {
        this.$modal.show("gallery", {
          list: this.list,
          active: this.active,
          field: "news-full",
          width: 1072,
          height: 600,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-gallery {
  &__body {
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      cursor: pointer;
    }
  }

  &__footer {
    align-items: flex-start;
    display: flex;
  }

  &__action {
    flex: 0 0 160px;
    margin-left: auto;
    justify-content: flex-end;
  }

  &__thumb-list {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, 85px);
    gap: 8px;
  }

  &__thumb-link {
    cursor: pointer;
  }

  &__thumb-image {
    border-radius: 3px;
    opacity: 0.7;

    &--active {
      opacity: 1;
    }
  }
}
</style>
