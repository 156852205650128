<template>
  <div class="brand-list-block">
    <div
      v-for="(item, index) in list"
      :key="`cross-${index}`"
      class="brand-list-block__item"
    >
      <div class="brand-list-block__unit brand-list-block__unit--brand">
        <div class="brand-list-block__title">Бренд</div>
        <div class="brand-list-block__value">
          {{ item.brand }}
        </div>
      </div>
      <div class="brand-list-block__unit">
        <div class="brand-list-block__title">Артикул</div>
        <div class="brand-list-block__value">
          {{ item.article }}
        </div>
      </div>
      <div v-if="item.title" class="brand-list-block__unit">
        <div class="brand-list-block__title">Наменование</div>
        <div class="brand-list-block__value">
          {{ item.title }}
        </div>
      </div>
      <div class="brand-list-block__footer">
        <base-btn
          tag="router-link"
          view="outline-primary"
          size="sm"
          :to="{
            name: 'search-vendors',
            params: {
              town: $route.params.town,
              brand: item.brand,
              article: item.article,
            },
          }"
        >
          Перейти
        </base-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    BaseBtn,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-list-block {
  border-bottom: 1px solid $grey-300;
  margin: 0 math.div($grid-gutter-width, 2) * -1;

  &__unit {
    border-bottom: 1px solid $grey-300;
    padding: 0.5rem math.div($grid-gutter-width, 2);

    &--brand {
      background: $grey-300;
      font-weight: bold;
    }

    &--row {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    @include font-size(14px);
    color: $grey-100;
  }

  &__value {
    word-break: break-all;
  }

  &__footer {
    text-align: center;
    padding: 0.7rem 0 1rem;
  }
}
</style>
