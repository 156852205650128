<template>
  <a
    :href="`${laximoPath}auto/lx/catalog?code=${item.laximoCode}`"
    class="model-item"
  >
    <base-icon
      class="model-item__icon"
      :sprite="sprite"
      :name="item.alias"
      width="40"
      height="40"
    />
    <slot></slot>
  </a>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
import Models from "@/assets/shared/models.svg";

export default {
  components: {
    BaseIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sprite: Models,
    };
  },
  computed: {
    laximoPath() {
      return process.env.VUE_APP_LAXIMO_PATH;
    },
  },
};
</script>

<style lang="scss" scoped>
.model-item {
  @extend %text-sm;

  color: $coal;
  display: block;
  padding-bottom: 8px;
  padding-top: 8px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $link-color;
  }

  &__icon {
    fill: $grey-100;
    margin-right: 8px;
    transition: fill 250ms ease-in;

    :hover > & {
      fill: $link-color;
    }
  }
}
</style>
