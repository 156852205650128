<template>
  <modal
    name="vin"
    class="vin-search-modal"
    width="100%"
    height="100%"
    transition="none"
    :classes="['vin-search-modal__dialog', 'bg-black']"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <div class="vin-search-modal__content">
      <close-btn
        class="vin-search-modal__close"
        position="custom"
        @click="$modal.hide('vin')"
      />

      <splide
        v-if="isShow"
        :options="{
          type: 'fade',
          perPage: 1,
          gap: 0,
          classes: {
            pagination: 'splide__pagination vin-search-modal__pagination',
          },
          arrows: false,
        }"
      >
        <splide-slide>
          <div class="vin-search-modal__item">
            <img
              src="../../../assets/pages/home__certificate.jpg"
              class="vin-search-modal__image"
              width="270"
              height="380"
            />
          </div>
        </splide-slide>
        <splide-slide>
          <div class="vin-search-modal__item">
            <img
              src="../../../assets/pages/home__pts.jpg"
              class="vin-search-modal__image"
              width="270"
              height="380"
            />
          </div>
        </splide-slide>
      </splide>
    </div>
  </modal>
</template>

<script>
import CloseBtn from "@/components/shared/CloseBtn";

export default {
  components: {
    CloseBtn,
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    onBeforeOpen() {
      this.isShow = true;
    },
    onBeforeClose() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.vin-search-modal {
  &__dialog {
    justify-content: center;
    display: flex;
    flex-flow: column;
    padding: 30px 0;
    border-radius: 0 !important;
  }

  &__close {
    right: 0;
    top: 0;
  }

  &__image {
    border-radius: 8px;
  }

  &__item {
    text-align: center;
  }

  &__pagination {
    bottom: -30px !important;
  }
}
</style>
