<template>
  <div class="action-box">
    <div class="action-box__price">от {{ price | formatNumber }} ₽</div>
    <div v-if="false" class="action-box__delivery">
      <base-icon
        name="local-shipping"
        class="action-box__delivery-icon"
        width="24"
        height="24"
      />
      <a class="action-box__delivery-link">Доставка</a>, 2 дня
    </div>
    <base-btn
      class="action-box__btn"
      display="block"
      tag="router-link"
      :to="route"
    >
      Найти у поставщиков
    </base-btn>
    <base-btn
      v-if="feedback.liveEnabled"
      display="block"
      view="outline-secondary"
      @click="onOpenLivechat($event)"
    >
      Спросить
    </base-btn>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import formatNumber from "@/utils/formatNumber";
import { mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    BaseBtn,
    BaseIcon,
  },
  filters: {
    formatNumber,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.action-box {
  margin: 30px 0;

  @include media-breakpoint-up(lg) {
    border: 1px solid $grey-300;
    border-radius: 6px;
    margin: 0;
    padding: 24px;
  }

  &__price {
    @extend %h2;
    border-bottom: 1px solid $grey-300;
    line-height: 1;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  &__delivery {
    display: flex;
    margin-bottom: 1rem;
  }

  &__delivery-link {
    text-decoration: none;
  }

  &__delivery-icon {
    fill: $primary;
    margin-right: 8px;
  }
}
</style>
