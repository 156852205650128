<template>
  <account-form-layout class="area-form" @submit="onSubmit">
    <div class="row">
      <div class="col-lg-4">
        <form-country-city
          name="signup"
          :has-country-error="$v.areaForm.country.$error"
          :has-city-error="$v.areaForm.city.$error"
          is-city-required
          is-country-required
          :country="areaForm.country"
          :city="areaForm.city"
          :is-disabled="isDisabled"
          @change-city="onChangeCity"
          @change-country="onChangeCountry"
        />
      </div>
    </div>

    <template #footer>
      <base-btn type="submit" :loading="isDisabled">Изменить</base-btn>
    </template>
  </account-form-layout>
</template>

<script>
import AccountFormLayout from "@/modules/account/components/AccountFormLayout";
import FormCountryCity from "@/components/shared/FormCountryCity";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    AccountFormLayout,
    FormCountryCity,
    BaseBtn,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          country: null,
          city: null,
        };
      },
    },
  },
  data() {
    return {
      isDisabled: false,
      areaForm: this.value,
    };
  },
  validations: {
    areaForm: {
      country: {
        required,
      },
      city: {
        required,
      },
    },
  },
  methods: {
    onChangeCountry(country) {
      this.areaForm.country = country;
    },
    onChangeCity(city) {
      this.areaForm.city = city;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("submit", {
          id: this.areaForm.city.id,
          alias: this.areaForm.city.alias,
        });
      }
    },
  },
};
</script>
