import TheIndex from "@/components/shared/TheIndex.vue";
import ProfileView from "../modules/profile/views/ProfileView.vue";
import PasswordView from "../modules/password/views/PasswordView.vue";
import AreaView from "../modules/area/views/AreaView.vue";
import OrderListView from "../modules/order/views/OrderListView.vue";
import OrderItemView from "../modules/order/views/OrderItemView.vue";
import OrderItemPaymentView from "../modules/order/views/OrderItemPaymentView.vue";
import GarageView from "../modules/garage/views/GarageView.vue";
import BalanceView from "../modules/balance/views/BalanceView.vue";
import PaymentDepositView from "../modules/payment/views/PaymentDepositView.vue";
import PaymentResultView from "../modules/payment/views/PaymentResultView.vue";

export default [
  {
    path: "/account",
    component: TheIndex,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        redirect: { name: "account-profile" },
      },
      {
        path: "/profile",
        name: "account-profile",
        component: ProfileView,
      },
      {
        path: "password",
        name: "account-password",
        component: PasswordView,
      },
      {
        path: "area",
        name: "account-area",
        component: AreaView,
      },
      {
        path: "orders",
        name: "account-order-list",
        component: OrderListView,
      },
      {
        path: "orders/:orderId",
        name: "account-order-item",
        component: OrderItemView,
      },
      {
        path: "orders/:orderId/payment",
        name: "account-order-item-payment",
        component: OrderItemPaymentView,
      },
      {
        path: "garage",
        name: "account-garage",
        component: GarageView,
      },
      {
        path: "payment",
        name: "account-balance",
        component: BalanceView,
      },
      {
        path: "payment/:operationId(\\d+)",
        name: "account-payment-result",
        component: PaymentResultView,
      },
      {
        path: "payment/deposit",
        name: "account-payment-deposit",
        component: PaymentDepositView,
      },
    ],
  },
];
