<template>
  <div class="cross-list">
    <span
      v-for="(item, index) in cuttedList"
      :key="`cross-${index}`"
      class="cross-list__item"
    >
      <router-link
        :to="{
          name: 'search-brands',
          params: { town: $route.params.town, article: item },
        }"
        class="cross-list__link"
      >
        {{ item }}
      </router-link>
      <span v-if="index < cuttedList.length - 1" class="cross-list__comma"
        >,</span
      >
    </span>

    <a v-if="listSize > 3" class="cross-list__more" @click="isMore = !isMore">
      <template v-if="isMore">скрыть</template>
      <template v-else
        >+ {{ moreSize | declOfNum(["номер", "номера", "номеров"]) }}</template
      >
    </a>
  </div>
</template>

<script>
// utils
import declOfNum from "@/utils/declOfNum";

export default {
  filters: {
    declOfNum,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMore: false,
    };
  },
  computed: {
    moreSize() {
      return this.listSize - this.cuttedListSize;
    },
    listSize() {
      return this.list.length;
    },
    cuttedListSize() {
      return this.cuttedList.length;
    },
    cuttedList() {
      if (this.isMore) return this.list;
      if (this.listSize > 3) return this.list.slice(0, 3);

      return this.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.cross-list {
  &__item {
    display: inline-flex;
  }

  &__link {
    color: $coal;
  }

  &__comma {
    margin-right: 5px;
  }

  &__more {
    display: block;
    white-space: nowrap;
  }
}
</style>
