import TheIndex from "@/components/shared/TheIndex.vue";
import ArticleListView from "../views/ArticleListView.vue";
import ArticleItemView from "../views/ArticleItemView.vue";

export default [
  {
    path: "/articles",
    component: TheIndex,
    children: [
      {
        path: ":alias?",
        name: "article-list",
        component: ArticleListView,
      },
      {
        path: "item/:alias",
        name: "article-item",
        component: ArticleItemView,
      },
    ],
  },
];
