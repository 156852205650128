<template>
  <div
    class="base-changer"
    :class="[
      { 'base-changer--disabled': disabled },
      `base-changer--${position}`,
      `base-changer--${view}`,
    ]"
  >
    <label :for="id" class="base-changer__label">
      {{ label }}
    </label>
    <div class="base-changer__container">
      <button
        :id="id"
        class="base-changer__btn"
        role="switch"
        type="button"
        :aria-readonly="disabled"
        :disabled="disabled"
        :class="{
          'base-changer__btn--checked': value,
        }"
        @click="onChange"
      >
        <div class="base-changer__circle"></div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: Boolean,
    disabled: Boolean,
    view: {
      type: String,
      default: "df",
    },
    position: {
      type: String,
      default: "right",
    },
  },
  methods: {
    onChange() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-changer {
  align-items: center;
  display: flex;

  &.base-changer--right {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  & + & {
    margin-left: 30px;
  }

  &__label {
    margin-bottom: 0;

    .base-changer--title & {
      @extend %subtitle;
    }
  }

  &__container {
    .base-changer--left & {
      margin-left: 10px;
    }

    .base-changer--right & {
      margin-right: 10px;
    }
  }

  &__btn {
    background-color: $grey-300;
    border: 1px solid $grey-300;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    height: 26px;
    padding: 1px;
    position: relative;
    transition: background-color 150ms ease;
    width: 40px;

    .base-changer--disabled & {
      cursor: default;
    }

    &:focus {
      outline: 0;
    }

    &--checked {
      background-color: $primary;
      border-color: $primary;
    }
  }

  &__circle {
    background-color: $white;
    border-radius: 50%;
    height: 22px;
    left: 1px;
    position: absolute;
    top: 1px;
    transition: left 150ms ease;
    width: 22px;

    .base-changer__btn--checked & {
      left: 15px;
    }
  }
}
</style>
