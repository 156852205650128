<template>
  <div class="suggestion-block">
    <div class="suggestion-block__head">
      <div class="row">
        <div class="col-sm-6">
          <form-group id="sortingField" class="suggestion-block__form-group">
            <template #label>Сортировать по</template>
            <template #default="props">
              <multiselect
                :id="props.id"
                v-model="sorting.field"
                :options="sortingList"
                :searchable="false"
                :show-labels="false"
                track-by="id"
                label="title"
                placeholder=""
              ></multiselect>
            </template>
          </form-group>
        </div>
        <div class="col-sm-6">
          <form-group id="sortingField" class="suggestion-block__form-group">
            <template #label>Порядок сортировки</template>
            <template #default="props">
              <multiselect
                :id="props.id"
                v-model="sorting.order"
                :options="sortingOrder"
                :searchable="false"
                :show-labels="false"
                :preselect-first="true"
                :allow-empty="false"
                track-by="id"
                label="title"
                placeholder="Выберите вид сортировки"
                :disabled="!sorting.field"
              ></multiselect>
            </template>
          </form-group>
        </div>
      </div>
    </div>
    <div v-if="$slots.original" class="suggestion-block__section">
      <div class="suggestion-block__title">Запрашиваемый артикул</div>
      <slot name="original"></slot>
    </div>
    <div v-if="$slots.replacement" class="suggestion-block__section">
      <div class="suggestion-block__title">Аналоги</div>
      <slot name="replacement"></slot>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";

export default {
  components: {
    FormGroup,
  },
  data() {
    return {
      sorting: {
        field: {
          id: "delivery",
          title: "сроку доставки",
        },
        order: {
          id: "asc",
          title: "по возрастанию",
        },
      },
      sortingList: [
        {
          id: "quantity",
          title: "количеству",
        },
        {
          id: "warehouse",
          title: "складу",
        },
        {
          id: "delivery",
          title: "сроку доставки",
        },
        {
          id: "price",
          title: "цене",
        },
      ],
      sortingOrder: [
        {
          id: "asc",
          title: "по возрастанию",
        },
        {
          id: "desc",
          title: "по убыванию",
        },
      ],
    };
  },
  computed: {
    route() {
      return {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      };
    },
    routeSorting() {
      return this.$route.query.sorting;
    },
    routeOrder() {
      return this.$route.query.order;
    },
  },
  watch: {
    "sorting.field"(val) {
      this.setSorting(val);
    },
    "sorting.order"(val) {
      this.setOrder(val);
    },
  },
  methods: {
    setSorting(sorting) {
      if (sorting) {
        this.$router.push({
          ...this.route,
          query: { sorting: sorting.id, order: this.sorting.order.id },
        });
      } else {
        this.$router.push({ ...this.route, query: {} });
      }
    },
    setOrder(order) {
      this.$router.push({
        ...this.route,
        query: { sorting: this.route.query.sorting, order: order.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-block {
  &__title {
    @extend %subtitle;
    background-color: $primary;
    color: $white;
    margin: 0 math.div($grid-gutter-width, 2) * -1;
    padding: 0.7rem math.div($grid-gutter-width, 2);
    position: sticky;
    top: 0;
    z-index: 3;
  }

  &__form-group {
    margin-bottom: 1rem;
  }
}
</style>
