<template>
  <header class="page-header" :class="`page-header--${view}`">
    <h1 class="page-header__title">
      <slot></slot>
    </h1>

    <slot name="extra"></slot>
  </header>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  margin-bottom: 24px;

  &__title {
    @extend %h2;
    display: flex;
    flex-flow: column;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-flow: row;

      .page-header--center & {
        justify-content: center;
      }
    }
  }
}
</style>
