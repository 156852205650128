<template>
  <div class="order-body-item">
    <div class="order-body-item__body">
      <figure class="order-body-item__picture">
        <base-image
          class="order-body-item__image"
          :url="item.image ? item.image : null"
          width="80"
          height="80"
        />
      </figure>
      <div class="order-body-item__info">
        <div class="order-body-item__brand">
          {{ item.itemBrand }}
          <div class="order-body-item__article">
            Артикул: {{ item.itemArticle }}
          </div>
          <div class="order-body-item__status">
            Статус:
            <span :style="{ color: item.status.color }">
              {{ item.status.title }}
            </span>
          </div>
          <div
            v-if="item.managerComment && item.managerComment.length > 0"
            class="order-body-item__note"
          >
            Комментарий: {{ item.managerComment }}
          </div>
        </div>
        <div class="order-body-item__title">
          {{ item.itemTitle }}
        </div>
      </div>
    </div>
    <div class="order-body-item__footer">
      <div class="order-body-item__price">
        {{ item.priceWithDiscount | formatNumber }} ₽
      </div>
      <div class="order-body-item__count">{{ item.count }} шт.</div>
      <div class="order-body-item__total">
        {{ item.sumWithDiscount | formatNumber }} ₽
      </div>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseImage,
  },
  filters: {
    formatNumber,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.order-body-item {
  @include media-breakpoint-up(md) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__body {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 80px 1fr;
  }

  &__info {
    @include media-breakpoint-up(md) {
      align-items: flex-start;
      display: grid;
      gap: 0.5rem;
      grid-template-columns: 200px 1fr;
    }
  }

  &__footer {
    align-items: flex-start;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 40px 65px;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 65px 40px 65px;
      margin-top: 0;
    }
  }

  &__picture {
    margin-bottom: 0;
  }

  &__article {
    @include font-size(14px);
    color: $grey-100;
  }

  &__status {
    @include font-size(12px);
  }

  &__note {
    @include font-size(12px);
    color: $grey-100;
    margin-top: 5px;
  }

  &__title {
    word-break: break-all;

    @include media-breakpoint-down(sm) {
      margin-top: 0.5rem;
    }
  }

  &__price {
    color: $grey-100;
  }

  &__price,
  &__total,
  &__count {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
  }
}
</style>
