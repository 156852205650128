<template>
  <page-layout-full :breadcrumb="breadcrumb" view="sm" header="center">
    <template #title>Пополнить баланс</template>

    <payment-form />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import PaymentForm from "../components/PaymentForm";

export default {
  components: {
    PageLayoutFull,
    PaymentForm,
  },
  metaInfo: {
    title: "Пополнить баланс",
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Платежи и баланс",
          to: {
            name: "account-balance",
          },
        },
        {
          title: "Пополнить баланс",
        },
      ],
    };
  },
};
</script>
