<template>
  <div class="delivery-calculate">
    <div class="row">
      <div class="col-lg-8">
        <p class="delivery-calculate__txt">
          <strong>Стоимость доставки:</strong> Оплачивается при получении
        </p>
        <p class="delivery-calculate__txt">
          <strong>Расчет стоимости доставки:</strong>
          <a
            class="delivery-calculate__price link-action"
            @click="$modal.show('delivery-calculate')"
            >{{ price | formatNumber }} ₽</a
          >
        </p>
        <p><strong>Дата:</strong> 30-31 июля</p>
      </div>
      <div class="col-lg-4 text-lg-end">
        <base-btn
          view="outline-primary"
          display="block"
          @click="$modal.show('delivery-calculate')"
        >
          Рассчитать
        </base-btn>
      </div>
    </div>

    <delivery-calculate-modal @submit="onSubmit" />
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import DeliveryCalculateModal from "./DeliveryCalculateModal";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseBtn,
    DeliveryCalculateModal,
  },
  filters: {
    formatNumber,
  },
  data() {
    return {
      price: 1250,
    };
  },
  methods: {
    onSubmit(payload) {
      console.info(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-calculate {
  &__txt {
    margin-bottom: 0.5rem;
  }

  &__price {
    font-weight: bold;
    text-decoration: none;
  }
}
</style>
