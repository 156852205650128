<template>
  <div
    class="page-layout-sidebar"
    :class="[`page-layout-sidebar--${position}`]"
  >
    <base-container>
      <div v-if="breadcrumb.length > 0" class="page-layout-sidebar__breadcrumb">
        <base-breadcrumb :list="breadcrumb" />
      </div>

      <div v-if="$slots.nav" class="page-layout-sidebar__nav">
        <slot name="nav"></slot>
      </div>

      <page-header v-if="$slots.title" class="page-layout-sidebar__header">
        <slot name="title"></slot>

        <template #extra>
          <slot name="subtitle"></slot>
        </template>
      </page-header>

      <div class="row">
        <div :class="`page-layout-sidebar__sidebar ${sidebarClass}`">
          <template v-if="isFilterModal">
            <base-btn
              display="block"
              :disabled="isFilterLoading"
              @click="$modal.show('filter')"
            >
              {{ filterTitle }}
            </base-btn>
          </template>

          <the-sidebar
            v-if="$slots.sidebar || !isFilterModal"
            :is-loading="isFilterLoading"
            :is-sticky="isSticky"
          >
            <slot name="sidebar"></slot>

            <template v-if="!isFilterModal">
              <slot name="filter"></slot>
              <slot name="filter-action"></slot>
            </template>
          </the-sidebar>
        </div>

        <div :class="contentClass">
          <slot></slot>

          <footer v-if="$slots.footer" class="page-layout-sidebar__footer">
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>

      <filter-modal v-if="isFilterModal">
        <template #default>
          <slot name="filter"></slot>
        </template>
        <template #action>
          <slot name="filter-action"></slot>
        </template>
      </filter-modal>

      <slot name="extra"></slot>
    </base-container>
  </div>
</template>

<script>
import BaseContainer from "../shared/BaseContainer";
import BaseBreadcrumb from "../shared/BaseBreadcrumb";
import PageHeader from "./PageHeader";
import BaseBtn from "../shared/BaseBtn";
import FilterModal from "../modal/FilterModal";
import TheSidebar from "./TheSidebar";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BaseBreadcrumb,
    PageHeader,
    BaseContainer,
    BaseBtn,
    FilterModal,
    TheSidebar,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: "left",
    },
    size: {
      type: Number,
      default: 2,
    },
    filterTitle: {
      type: String,
      default: "Отфильтровать",
    },
    isFilterLoading: {
      type: Boolean,
      default: false,
    },
    isSticky: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("mq", {
      isLg: "getIsLg",
    }),
    isFilterModal() {
      return !!this.$slots.filter && !this.isLg;
    },
    sidebarClass() {
      return `col-lg-${this.size + 1} col-xl-${this.size}`;
    },
    contentClass() {
      return `col-lg-${12 - (this.size + 1)} col-xl-${12 - this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-layout-sidebar {
  &__breadcrumb {
    margin-bottom: 24px;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__nav {
    margin-bottom: 40px;
  }

  &__sidebar {
    .page-layout-sidebar--left & {
      @include media-breakpoint-down(lg) {
        margin-bottom: 2rem;
      }
    }

    .page-layout-sidebar--right & {
      order: 1;

      @include media-breakpoint-down(lg) {
        margin-top: 2rem;
      }
    }
  }

  &__footer {
    border-top: 1px solid $grey-300;
    margin-top: 2rem;
    padding-top: 1.5rem;
  }
}
</style>
