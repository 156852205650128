<template>
  <router-link
    :to="{ name: 'article-item', params: { alias: item.slug } }"
    class="news-item"
    :class="`news-item--${view}`"
  >
    <figure class="news-item__picture">
      <base-image
        class="news-item__image"
        :url="imageSrc"
        :height="imageSize.height"
        :width="imageSize.width"
      />
    </figure>
    <div class="news-item__body">
      <div class="news-item__category">
        <router-link
          :to="{ name: 'article-list', params: { alias: item.category.slug } }"
          class="news-item__category"
        >
          {{ item.category.title }}
        </router-link>
      </div>
      <h4 class="news-item__title">
        {{ item.title }}
      </h4>
      <div class="news-item__date">
        {{ date | formatDate("d MMMM yyyy") }}
      </div>
    </div>
  </router-link>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";
// utils
import fromUnixTime from "date-fns/fromUnixTime";
import formatDate from "@/utils/formatDate";

export default {
  components: {
    BaseImage,
  },
  filters: {
    formatDate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    view: {
      type: String,
      default: "news-medium",
    },
  },
  computed: {
    imageSize() {
      return {
        height: this.view === "news-medium" ? 287 : 440,
        width: this.view === "news-medium" ? 460 : 705,
      };
    },
    imageSrc() {
      return this.item.image && this.item.image.files
        ? this.item.image.files["news-full"]
        : null;
    },
    date() {
      return fromUnixTime(this.item.publishTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-item {
  color: inherit;
  overflow: hidden;
  text-decoration: none;

  &.news-item--news-medium {
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  &.news-item--news-full {
    border-radius: 4px;
    display: block;
    position: relative;

    &::after {
      background: linear-gradient(rgba(20, 20, 20, 0), rgba(20, 20, 20, 0.6));
      position: absolute;
      bottom: 0;
      left: 0;
      height: 150px;
      content: "";
      width: 100%;
      z-index: 1;
    }
  }

  &__picture {
    margin-bottom: 0;
  }

  &__image {
    @include img-fluid;

    .news-item--news-medium & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__body {
    padding: 1rem;

    .news-item--news-medium & {
      border: 1px solid $grey-300;
      border-top: none;
      display: flex;
      flex-flow: column;
      flex-grow: 1;
    }

    .news-item--news-full & {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__category {
    @extend %text-nav;

    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__date {
    @extend %text-nav;
    color: $grey-100;

    .news-item--news-medium & {
      margin-top: auto;
    }
  }

  &__title {
    @extend %subtitle;

    .news-item--news-full & {
      color: $white;
    }
  }

  &__title-link {
    color: inherit;
    text-decoration: none;
  }
}
</style>
