<template>
  <page-layout-full :breadcrumb="breadcrumb">
    <template #title>Сменить регион</template>
    <template #nav>
      <nav-tab />
    </template>

    <base-loading v-if="isLoading" />
    <area-form v-else :value="area" @submit="onSubmit" />
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import NavTab from "@/modules/account/components/NavTab";
import AreaForm from "../components/AreaForm";
// utils
import { mapState } from "vuex";

export default {
  components: {
    BaseLoading,
    PageLayoutFull,
    NavTab,
    AreaForm,
  },
  metaInfo: {
    title: "Сменить регион",
  },
  data() {
    return {
      isLoading: true,
      area: {
        country: null,
        city: null,
      },
      breadcrumb: [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Сменить регион",
        },
      ],
    };
  },
  computed: {
    ...mapState("settings", {
      city: (state) => state.city,
    }),
    cityId() {
      if (!this.city) return null;
      return this.city.id;
    },
  },
  watch: {
    cityId(id) {
      if (id) this.onSetArea();
    },
  },
  mounted() {
    if (this.cityId) {
      this.onSetArea();
    }
  },
  methods: {
    onSetArea() {
      this.isLoading = true;

      const { country, data } = this.city;

      this.area = {
        country: country,
        city: data,
      };

      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    },
    onSubmit({ id, alias }) {
      this.isLoading = true;
      this.$http
        .post(
          "users/profile/change-office",
          {
            townId: id,
          },
          {
            params: {
              town: alias,
            },
          }
        )
        .then(({ data }) => {
          this.isLoading = false;
          if (data.status === "ok") {
            this.$toast.success("Офис изменен.");
            this.$eventHub.$emit("start");
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.error("Не удалось изменить офис. Попробуйте позже.");
        });
    },
  },
};
</script>
