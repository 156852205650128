import isPlainObject from "lodash/isPlainObject";

const onSetMeta = (source) => {
  const metaList = [];

  const list = isPlainObject(source)
    ? Object.keys(source).reduce((total, item) => {
        const meta = {
          name: item,
          content: source[item],
        };

        total.push(meta);

        return total;
      }, [])
    : source;

  if (list && list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const meta = { ...item };
      meta.vmid = item.name || item.property;
      metaList.push(meta);
    }
  }

  return metaList;
};

export default onSetMeta;
