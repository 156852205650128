<template>
  <button
    class="btn-tab"
    type="button"
    :disabled="disabled"
    :class="[
      { 'btn-tab--active': active, 'btn-tab--disabled': disabled },
      `btn-tab--${view}`,
    ]"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
    disabled: Boolean,
    view: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-tab {
  @extend %btn;
  @include font-size(17px);
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 12px 8px;
  width: 100%;

  @include media-breakpoint-up(lg) {
    @include font-size(18px);
    padding: 18px 24px;
  }

  &.btn-tab--primary {
    background: $white;
    border-radius: 6px;
    border: 1px solid $grey-300;
    line-height: 1;
    padding: 12px 8px;

    &.btn-tab--active {
      background-color: $mint-20;
      border-color: $mint-100;
    }

    &:disabled,
    &.btn-tab--disabled {
      background-color: $grey-300;
      border-color: $smoke;
      color: $grey-200;
    }
  }

  &.btn-tab--secondary {
    background: $grey-300;
    border: 1px solid $grey-300;
    box-shadow: -1px 0px 0px $grey-300;

    &.btn-tab--active {
      background: $white;
      box-shadow: inset 0px -4px 0px $mint-100;
    }
  }
}
</style>
