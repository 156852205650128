<template>
  <site-layout id="app">
    <template #header>
      <site-header />
    </template>
    <template #alert>
      <base-alert v-if="isGuest" view="success">
        <router-link :to="{ name: 'signup' }">Зарегистрируйтесь</router-link>
        или
        <a href="#login" @click.prevent="$modal.show('login')"
          >зайдите на сайт</a
        >
        и получите персональное обслуживание и специальные цены со скидками
      </base-alert>
    </template>
    <template #footer>
      <site-footer />
    </template>
    <template #extra>
      <city-modal />
      <delete-alert-modal />
      <confirm-city-modal v-if="isNeedToConfirmCity" />
      <modal-city-alert />
      <login-modal v-if="isGuest" />
      <feedback-btn />
      <feedback-call-modal v-if="feedback.callEnabled" />
      <feedback-message-modal v-if="feedback.messageEnabled" />
      <gallery-modal />
    </template>

    <template #cookie>
      <base-alert v-if="!isCookieСonfirm" view="light" @close="onConfirmCookie">
        Мы используем cookies для быстрой и удобной работы сайта. Продолжая
        пользоваться сайтом, вы принимаете
        <router-link
          class="text-nowrap"
          :to="{
            name: 'content',
            params: { alias: 'politika-konfidencialnosti' },
          }"
          target="_blank"
        >
          условия обработки персональных данных
        </router-link>
      </base-alert>
    </template>

    <router-view></router-view>
  </site-layout>
</template>

<script>
import SiteLayout from "./components/layout/SiteLayout";
import SiteHeader from "./components/layout/SiteHeader";
import SiteFooter from "./components/layout/SiteFooter";
import LoginModal from "./components/layout/header/LoginModal";
import CityModal from "./components/layout/header/CityModal";
import FeedbackBtn from "./modules/feedback/FeedbackBtn";
import FeedbackCallModal from "./modules/feedback/FeedbackCallModal";
import FeedbackMessageModal from "./modules/feedback/FeedbackMessageModal";
import DeleteAlertModal from "./components/modal/DeleteAlertModal";
import ConfirmCityModal from "./components/modal/ConfirmCityModal";
import ModalCityAlert from "./components/modal/ModalCityAlert";
import GalleryModal from "./components/modal/GalleryModal";
import BaseAlert from "./components/shared/BaseAlert";
// utils
import throttle from "lodash/throttle";
import { mapActions, mapGetters, mapState } from "vuex";
import onSetMeta from "@/utils/onSetMeta";
import onSetMetrika from "@/utils/onSetMetrika";
import onSetLivechat from "@/utils/onSetLivechat";

export default {
  name: "App",
  components: {
    SiteLayout,
    SiteHeader,
    SiteFooter,
    LoginModal,
    CityModal,
    FeedbackBtn,
    FeedbackCallModal,
    FeedbackMessageModal,
    DeleteAlertModal,
    ConfirmCityModal,
    ModalCityAlert,
    GalleryModal,
    BaseAlert,
  },
  data() {
    return {
      metaList: [],
      title: null,
      pathname: location.pathname,
    };
  },
  metaInfo() {
    return {
      title: this.title
        ? `${this.title}`
        : "АвтоВеликан.ру – Интернет-магазин автозапчастей и автотоваров по доступным ценам",
      meta: this.metaList,
      link: this.metaLinkList,
    };
  },
  computed: {
    ...mapState({
      isModal: (state) => state.settings.isModal,
      isCityСonfirm: (state) => state.settings.isCityСonfirm,
      isCookieСonfirm: (state) => state.settings.isCookieСonfirm,
      sWidth: (state) => state.settings.sWidth,
      feedback: (state) => state.settings.feedback,
      cartList: (state) => state.cart.items,
    }),
    ...mapGetters({
      isGuest: "auth/isGuest",
      isLogged: "auth/isLogged",
      town: "settings/getTown",
    }),
    isNeedToConfirmCity() {
      return (
        this.isGuest && !this.isCityСonfirm && this.$route.name !== "access"
      );
    },
    metaLinkList() {
      return [
        {
          rel: "canonical",
          href: process.env.VUE_APP_CANONICAL_HOST + this.pathname,
        },
      ];
    },
  },
  watch: {
    "$route.name"() {
      if (this.isNeedToConfirmCity) {
        this.$modal.show("confirm-city");
      }
    },
    isModal(val) {
      this.onChangeModal(val);
    },
    $route() {
      this.pathname = location.pathname;
    },
  },
  created() {
    window.addEventListener("resize", this.optimizeResize);
    this.onFetchStart();

    window.addEventListener("load", this.onLoad);
    this.$eventHub.$on("disable-on-load", () =>
      window.removeEventListener("load", this.onLoad)
    );
  },
  mounted() {
    this.$nextTick(() => {
      this.getScrollbarWidth();
    });

    this.$eventHub.$on("start", (alias) => {
      if (!alias) {
        return;
      }
      if (alias) {
        this.onSetSettings({ key: "isCityСonfirm", value: true });
      }

      const path = location.hostname.split(".");
      const subdomain = path.shift();

      if (["vue", "avtovelikan", "localhost"].includes(subdomain)) {
        console.log(location.origin);
        console.log(location.pathname);
        let pathname = location.pathname;
        let oldTown = this.$route.params.town;
        if (oldTown) {
          let subRoute = pathname.substring(1, oldTown.length + 1);
          let equal = subRoute === oldTown;
          console.log([oldTown, subRoute, equal, pathname, oldTown.length]);
          if (equal) {
            pathname = pathname.substring(oldTown.length + 1, pathname.length);
          }
        }
        location.href = location.origin + "/" + alias + pathname;
      } else {
        location.href =
          location.origin.replace(subdomain, alias) + location.pathname;
      }
    });

    this.$eventHub.$on("authorized", () => {
      setTimeout(() => {
        this.onMergeCartList();
      }, 100);

      if (this.$route.name === "signup") {
        this.$router.push({ name: "home" });
      }
    });

    this.$eventHub.$on("unauthorized", () => {
      setTimeout(() => {
        this.onCleanBaseUser({ id: null, profile: {} });
        this.onCleanCart();
        this.onCleanToken(null);
        this.onSetSettings({ key: "isCityСonfirm", value: false });
        this.onSetSettings({ key: "isCookieСonfirm", value: false });
        this.$router.push({ name: "home" });
      }, 100);
    });

    if (this.isNeedToConfirmCity) {
      this.$modal.show("confirm-city");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.optimizeResize);
    window.removeEventListener("load", this.onLoad);
  },
  methods: {
    ...mapActions({
      onSetWindowWidth: "mq/onSetWindowWidth",
      onSetWindowHeight: "mq/onSetWindowHeight",
      onSetSettings: "settings/onSetSettings",
      onSetCity: "settings/onSetCity",
      onSetFeedback: "settings/onSetFeedback",
      onSetModelList: "auto/onSetList",
      onSetNavList: "catalog/onSetList",
      onSetDiscoutRules: "cart/onSetDiscoutRules",
      onSetBaseUser: "account/onSetBaseUser",
      onCleanToken: "auth/onSetToken",
      onCleanBaseUser: "account/onSetBaseUser",
      onCleanCart: "cart/onClean",
      onSetCart: "cart/onSetList",
    }),
    onSetSize() {
      this.onSetWindowWidth();
      this.onSetWindowHeight();
    },
    optimizeResize: throttle(
      function () {
        this.onSetSize();
      },
      50,
      true
    ),
    getScrollbarWidth() {
      const outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.overflow = "scroll";
      outer.style.msOverflowStyle = "scrollbar";
      document.body.appendChild(outer);

      const inner = document.createElement("div");
      outer.appendChild(inner);
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
      outer.parentNode.removeChild(outer);

      this.onSetSettings({ key: "sWidth", value: scrollbarWidth });
    },
    onChangeModal(val) {
      const body = document.body;

      if (val) {
        const scrollY = window.scrollY;
        body.classList.add("open-modal");
        body.style.top = "-" + scrollY + "px";
        body.style.paddingRight = this.sWidth + "px";
      } else {
        const scrollY = body.style.top;
        body.classList.remove("open-modal");
        body.style.top = "";
        body.style.paddingRight = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    },
    onFetchStart() {
      let params = {};
      if (this.$route.params && this.$route.params.town) {
        params.town = this.$route.params.town;
      }

      this.$http
        .get("users/profile/start", {
          params: params,
        })
        .then(({ data }) => {
          const {
            geo,
            franchise,
            discount,
            user,
            gtm,
            yandexMetrikaId,
            talkMeId,
          } = data;
          const { country, region, ...town } = geo.town;
          const {
            address,
            phoneCode,
            phoneNumber,
            phones,
            metaTitle,
            socialFb,
            socialOk,
            socialTg,
            socialVk,
            yandexVerification,
            googleVerification,
          } = franchise.info;

          // проверяем, основная ли франшиза

          this.onSetSettings({
            key: "noFranchise",
            value: franchise.params.id === null && geo.town.alias !== "msk",
          });

          // добавляем мета-теги верификации
          if (yandexVerification) {
            this.metaList.push({
              name: "yandex-verification",
              content: yandexVerification,
            });
          }

          if (googleVerification) {
            this.metaList.push({
              name: "google-site-verification",
              content: googleVerification,
            });
          }

          // задаем город
          const city = {
            id: town.id,
            country: {
              id: country.id,
              title: country.title,
            },
            data: town,
            name: town.title,
            nameFull: `${country.title}, ${region.title}, ${town.title}`,
            address: address,
            social: {
              socialFb,
              socialOk,
              socialTg,
              socialVk,
            },
          };

          const phoneList = phones.filter((item) => !item.hidden);

          if (phoneCode && phoneNumber) {
            city.phone = `(${phoneCode}) ${phoneNumber}`;
            city.phoneList = [
              {
                phoneCode: phoneCode,
                phoneNumber: phoneNumber,
                phoneComment: "Основной",
              },
              ...phoneList,
            ];
          } else {
            city.phoneList = phoneList;
          }

          this.onSetCity(city);

          // задаем скидки
          this.onSetDiscoutRules(discount);

          // задаем заголовок
          this.title = metaTitle;

          // задаем пользователя
          if (user && this.isLogged) {
            this.onSetBaseUser({
              id: user.id,
              profile: {
                email: user.email,
                phone: user.phone,
                name: user.name,
                patronymic: user.patronymic,
                balanceD: user.balance,
              },
            });
          }
          this.onFetchMeta();
          this.onFetchData();

          onSetMetrika(gtm, yandexMetrikaId);

          if (talkMeId && process.env.NODE_ENV === "production") {
            onSetLivechat(talkMeId);
            this.onSetFeedback({ key: "liveEnabled", value: true });
          }
        })
        .catch(() => {
          this.$toast.error("Не удалось получить данные");
        });
    },
    onMergeCartList() {
      const form =
        this.cartList.length > 0
          ? this.cartList
              .map((item) => {
                const { count, hash, comment } = item;

                return {
                  hash: hash,
                  quantity: count,
                  comment: comment,
                };
              })
              .reduce((total, item) => {
                total[item.hash] = item;
                return total;
              }, {})
          : null;

      this.$http
        .post("shop/cart/merge", form)
        .then(({ data }) => {
          this.onSetCart(data);
        })
        .catch(() => {
          this.isDisabled = false;
          this.$toast.error("Не удалось обновить корзину.");
        });
    },
    onFetchCatalog() {
      this.$http
        .get("catalog/default/catalog-menu")
        .then(({ data }) => {
          this.onSetNavList(data.items);
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Ошибка. Не удалось получить меню.");
        });
    },
    onFetchModel() {
      this.$http
        .get("catalog/default/model-menu")
        .then(({ data }) => {
          this.onSetModelList(data);
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Ошибка. Не удалось получить список моделей.");
        });
    },
    onFetchMeta() {
      const params = {};

      this.$http
        .get("app/site/meta", { params: params })
        .then(({ data }) => {
          this.metaList = [...this.metaList, ...onSetMeta(data)];
          if (
            ![
              "item",
              "item-list",
              "search-brands",
              "search-vendors",
              "article-list",
              "article-item",
            ].includes(this.$route.name)
          ) {
            this.$nextTick(() => {
              const body = document.body;
              body.classList.add("is-loaded");
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Ошибка. Не удалось получить данные.");
        });
    },
    onFetchData() {
      this.onFetchCatalog();
      this.onFetchModel();
    },
    onConfirmCookie() {
      this.onSetSettings({ key: "isCookieСonfirm", value: true });
    },
    onLoad() {
      // this.$nextTick(() => {
      //   const body = document.body;
      //   body.classList.add("is-loaded");
      // });
    },
  },
};
</script>

<style lang="scss">
.site,
.site-body {
  height: 100%;
}

.site-shape {
  display: flex;
  flex-direction: column;
}

.site-body {
  &.open-modal {
    position: fixed;
    left: 0;
    right: 0;
  }
}
</style>
