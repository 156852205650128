import { render, staticRenderFns } from "./BaseAlert.vue?vue&type=template&id=4e49e782&scoped=true&"
import script from "./BaseAlert.vue?vue&type=script&lang=js&"
export * from "./BaseAlert.vue?vue&type=script&lang=js&"
import style0 from "./BaseAlert.vue?vue&type=style&index=0&id=4e49e782&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e49e782",
  null
  
)

export default component.exports