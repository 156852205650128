import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";
import router from "@/router";

const onSetMetrika = (gtm, yandexMetrikaId) => {
  if (gtm) {
    Vue.use(VueGtm, {
      id: gtm,
      defer: false,
      debug: !(process.env.NODE_ENV === "production"),
      loadScript: true,
      vueRouter: router,
    });
  } else if (yandexMetrikaId) {
    const script_tag = document.createElement("script");
    script_tag.type = "text/javascript";
    script_tag.text = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(${yandexMetrikaId}, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, ecommerce:"dataLayer" });`;
    document.head.appendChild(script_tag);
  }
};

export default onSetMetrika;
