<template>
  <div class="good-info">
    <figure class="good-info__picture">
      <base-image
        class="good-info__image"
        :url="imageSrc"
        width="96"
        height="96"
      />
    </figure>
    <div class="good-info__body">
      <h2 class="good-info__title">
        <slot name="title"></slot>
      </h2>
      <p class="good-info__subtitle">
        <slot name="subtitle"></slot>
      </p>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";

export default {
  components: {
    BaseImage,
  },
  props: {
    image: {
      type: [Object, Boolean],
      required: true,
    },
  },
  computed: {
    imageSrc() {
      return this.image ? this.image.source : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.good-info {
  display: flex;

  &__picture {
    flex: 0 0 96px;
    max-width: 96px;
    margin-bottom: 0;
    margin-right: 24px;
  }

  &__image {
    @include img-fluid;
  }

  &__subtitle {
    margin-bottom: 0;
  }
}
</style>
