<template>
  <nav-tab-list view="center">
    <nav-tab-item :active="active === 0" view="lg" @click="$emit('change', 0)">
      Физическое лицо
    </nav-tab-item>
    <nav-tab-item :active="active === 1" view="lg" @click="$emit('change', 1)">
      Организация
    </nav-tab-item>
  </nav-tab-list>
</template>

<script>
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";

export default {
  components: {
    NavTabList,
    NavTabItem,
  },
  props: {
    active: {
      type: Number,
      required: true,
    },
  },
};
</script>
