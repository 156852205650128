import TheIndex from "@/components/shared/TheIndex.vue";
import ModelListView from "../views/ModelListView.vue";
import SearchView from "../views/SearchView.vue";

export default [
  {
    path: "/auto",
    component: TheIndex,
    children: [
      {
        path: "/",
        name: "auto-model-list",
        component: ModelListView,
      },
      {
        path: "search/:id?",
        name: "auto-search",
        component: SearchView,
      },
    ],
  },
];
