<template>
  <ul class="nav-tab-list" :class="[`nav-tab-list--${view}`]">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: "df",
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tab-list {
  @include list-unstyled;
  display: flex;
  margin-bottom: 0;

  &.nav-tab-list--center {
    justify-content: center;
  }

  &.nav-tab-list--border {
    position: relative;
    z-index: 0;

    &::after {
      background: $grey-300;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
}
</style>
