<template>
  <div class="base-counter">
    <button
      class="base-counter__btn base-counter__btn--prev"
      type="button"
      :disabled="disabled || mutableValue <= min"
      aria-label="Уменьшить количество"
      @click="onReduce"
    >
      <base-icon name="minus" width="14" height="2" />
    </button>
    <input
      v-model.number="mutableValue"
      disabled
      class="base-counter__control"
      type="text"
    />
    <button
      class="base-counter__btn base-counter__btn--next"
      type="button"
      aria-label="Увеличить количество"
      :disabled="disabled || mutableValue >= max"
      @click="onIncrease"
    >
      <base-icon name="plus" width="14" height="14" />
    </button>
  </div>
</template>

<script>
import BaseIcon from "./BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableValue: parseInt(this.value),
    };
  },
  created() {
    if (this.name) {
      this.$eventHub.$on(this.name, (value) => {
        this.mutableValue = parseInt(value);
      });
    }
  },
  mounted() {
    this.mutableValue = this.value;
  },
  methods: {
    onIncrease() {
      this.mutableValue++;
      this.$emit("update", this.mutableValue);
      this.$emit("increase", this.mutableValue);
    },
    onReduce() {
      this.mutableValue--;
      this.$emit("update", this.mutableValue);
      this.$emit("reduce", this.mutableValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-counter {
  align-items: center;
  display: flex;
  position: relative;

  &__btn {
    @extend %btn;

    background: $smoke;
    border: 1px solid $grey-300;
    height: 36px;
    padding: 0;
    width: 38px;
    z-index: 1;

    &:disabled {
      background: lighten($smoke, 5%);
    }

    &--prev {
      border-radius: 6px 0px 0px 6px;
    }

    &--next {
      border-radius: 0px 6px 6px 0px;
    }
  }

  &__control {
    @include font-size(1rem);

    border: 1px solid $grey-300;
    color: $coal;
    font-weight: bold;
    height: 36px;
    margin-left: -2px;
    margin-right: -2px;
    text-align: center;
    width: 38px;

    &:disabled {
      background: $white;
      color: $coal;
    }

    &:focus {
      outline: 0;
    }
  }
}
</style>
