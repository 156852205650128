<template>
  <base-loading v-if="isLoading" />
  <page-layout-full v-else :breadcrumb="breadcrumb" view="sm" header="center">
    <template #title>Оплатить заказ №{{ orderId }}</template>

    <payment-form
      :value="value"
      is-price-disabled
      :is-disabled="isDisabled"
      @submit="onSubmit"
    />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import BaseLoading from "@/components/shared/BaseLoading";
import PaymentForm from "@/modules/account/modules/payment/components/PaymentForm";

export default {
  components: {
    PageLayoutFull,
    BaseLoading,
    PaymentForm,
  },
  metaInfo() {
    return {
      title: this.orderId ? `Оплатить заказ №${this.orderId}` : "Загружается",
    };
  },
  data() {
    return {
      isLoading: true,
      isDisabled: false,
      value: null,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
    breadcrumb() {
      return [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Заказы",
          to: {
            name: "account-order-list",
          },
        },
        {
          title: `Заказ №${this.orderId}`,
          to: {
            name: "account-order-item",
            params: {
              orderId: this.orderId,
            },
          },
        },
        {
          title: "Оплатить",
        },
      ];
    },
  },
  created() {
    if (!this.$route.params.orderId) {
      this.$router.replace({
        name: "account-order-list",
      });
    }

    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;

      this.$http
        .get(`shop/orders/${this.orderId}`)
        .then(({ data }) => {
          this.isLoading = false;
          this.value = data.debt;
        })
        .catch((error) => {
          this.$toast.error("Не удалось получить сумму заказа.");
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
    onSubmit(form) {
      this.isDisabled = true;

      this.$http
        .post(
          "shop/balance/pay",
          {
            serviceId: form.method,
          },
          {
            params: {
              orderId: this.orderId,
            },
          }
        )
        .then(({ data }) => {
          const { action, redirectUrl } = data;

          switch (action) {
            case "redirect": {
              if (redirectUrl) {
                window.location.replace(redirectUrl);
              }
              break;
            }
            case "paid": {
              this.$toast.success(" Вы выбрали оплату при получении");
              this.$router.replace({
                name: "account-order-item",
                params: this.orderId,
              });
              break;
            }
            case "paid-cashless": {
              this.$toast.success("Вы выбрали безналичную оплату");
              this.$router.replace({
                name: "account-order-item",
                params: this.orderId,
              });
              break;
            }
            case "error": {
              this.$toast.success(
                "Не удалось провести оплату данным способом."
              );
              break;
            }
            default: {
              this.$toast.error("Ошибка. Попробуйте позже");
            }
          }

          this.isDisabled = false;
        })
        .catch(() => {
          this.isDisabled = false;
          this.$toast.error("Ошибка. Попробуйте позже");
        });
    },
  },
};
</script>
