<template>
  <div class="filter-date-range">
    <div class="filter-date-range__row">
      <div class="filter-date-range__col">
        <form-group
          :id="`date-range-${name}-form`"
          class="filter-date-range__form-group"
        >
          <template #label>От</template>
          <template #default="props">
            <date-picker
              v-model="dateFrom"
              type="date"
              :input-class="props.inputClass"
              :disabled-date="getDisabledDateFrom"
              @change="onChangeDateFrom"
            ></date-picker>
          </template>
        </form-group>
      </div>
      <div class="filter-date-range__col">
        <form-group
          :id="`date-range-${name}-to`"
          class="filter-date-range__form-group"
        >
          <template #label>До</template>
          <template #default="props">
            <date-picker
              v-model="dateTo"
              type="date"
              :input-class="props.inputClass"
              :disabled-date="getDsabledDateTo"
              @change="onChangeDateTo"
            ></date-picker>
          </template>
        </form-group>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
// utils
import isBefore from "date-fns/isBefore";
import isWithinInterval from "date-fns/isWithinInterval";

const TODAY = new Date().setHours(0, 0, 0, 0);

export default {
  components: {
    FormGroup,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    isReady: {
      type: Boolean,
      default: false,
    },
    selectedDateFrom: {
      type: Date,
      default: null,
    },
    selectedDateTo: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo,
    };
  },
  watch: {
    selectedDateFrom(val) {
      this.dateFrom = val;
    },
    selectedDateTo(val) {
      this.dateTo = val;
    },
  },
  mounted() {
    this.$eventHub.$on(`reset-${this.name}`, () => {
      this.onReset();
    });
  },
  methods: {
    getDisabledDateFrom(date) {
      return isBefore(TODAY, date);
    },
    getDsabledDateTo(date) {
      if (this.dateFrom) {
        return !isWithinInterval(date, { start: this.dateFrom, end: TODAY });
      }
      return isBefore(TODAY, date);
    },
    onChangeDateFrom(date) {
      this.$emit("change-from", date);
    },
    onChangeDateTo(date) {
      this.$emit("change-to", date);
    },
    onReset() {
      this.dateFrom = null;
      this.dateTo = null;
      this.$emit("reset");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-date-range {
  margin-bottom: 1.5rem;

  &__row {
    @include make-row(8px);

    > * {
      @include make-col-ready(8px);
    }
  }

  &__col {
    @include make-col(6);
  }

  &__form-group {
    margin-bottom: 0;
  }
}
</style>
