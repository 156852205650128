<template>
  <page-layout-sidebar
    :breadcrumb="breadcrumb"
    class="account-balance-view"
    :size="3"
    filter-title="Отфильтровать платежи"
  >
    <template #title> Платежи и баланс </template>

    <template #nav>
      <nav-tab />
    </template>

    <template #sidebar>
      <base-btn
        v-if="false"
        class="account-balance-view__btn"
        tag="router-link"
        :to="{ name: 'account-payment-deposit' }"
        view="secondary"
        display="block"
      >
        Пополнить баланс
      </base-btn>
    </template>

    <template #filter>
      <filter-item :is-ready="isFilterReady">
        <template #title>Период</template>

        <template #default="{ isReady }">
          <filter-date-range
            name="balance-date"
            :is-ready="isReady"
            :selected-date-from="filterSelected.date.from"
            :selected-date-to="filterSelected.date.to"
            @change-from="onChangeDateFrom"
            @change-to="onChangeDateTo"
            @reset="onResetDate"
          />
        </template>
      </filter-item>

      <filter-item
        v-if="filterMinPrice > 0 && filterMaxPrice > 0"
        :is-ready="isFilterReady"
      >
        <template #title>Сумма, р</template>

        <template #default="{ isReady }">
          <filter-range
            name="balance-price"
            :min="filterMinPrice"
            :max="filterMaxPrice"
            :is-ready="isReady"
            :selected="filterSelected.price"
            @change="onChangePrice"
            @reset="onResetPrice"
          />
        </template>
      </filter-item>
    </template>

    <template #filter-action>
      <base-btn
        v-if="hasFilterSelected"
        size="sm"
        view="outline-secondary"
        display="block"
        @click="onClean"
      >
        Сбросить
      </base-btn>
    </template>

    <base-loading v-if="isLoading" />

    <template v-else>
      <balance-info class="account-balance-view__info">
        <template #balance> {{ balanceBalance | formatNumber }} ₽ </template>
        <template #debt> {{ balanceDebt | formatNumber }} ₽ </template>
        <template #sum> {{ balanceSum | formatNumber }} ₽ </template>
      </balance-info>

      <balance-list :list="balanceOperationList" />
    </template>

    <template v-if="pageCount > 0" #footer>
      <the-pagination :total="pageCount" :active="activePage" />
    </template>
  </page-layout-sidebar>
</template>

<script>
import PageLayoutSidebar from "@/components/page/PageLayoutSidebar.vue";
import BaseLoading from "@/components/shared/BaseLoading";
import NavTab from "@/modules/account/components/NavTab";
import BaseBtn from "@/components/shared/BaseBtn";
import FilterItem from "@/components/shared/FilterItem";
import FilterRange from "@/components/shared/FilterRange";
import FilterDateRange from "@/components/shared/FilterDateRange";
import ThePagination from "@/components/shared/ThePagination";
import BalanceInfo from "../components/BalanceInfo";
import BalanceList from "../components/BalanceList";
// utils
import formatDate from "@/utils/formatDate";
import parse from "date-fns/parse";
import formatNumber from "@/utils/formatNumber";
import isEqual from "lodash/isEqual";

export default {
  components: {
    PageLayoutSidebar,
    BaseLoading,
    NavTab,
    BaseBtn,
    FilterItem,
    FilterRange,
    FilterDateRange,
    ThePagination,
    BalanceInfo,
    BalanceList,
  },
  filters: {
    formatNumber,
  },
  metaInfo() {
    return {
      title: this.metaTitle,
    };
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Платежи и баланс",
        },
      ],
      balanceBalance: 0,
      balanceDebt: 0,
      balanceOperationList: [],
      balanceSum: 0,
      isFilterReady: false,
      isLoading: true,
      limit: 20,
      pageCount: 0,
      filterMinPrice: 0,
      filterMaxPrice: 0,
      filterSelected: {
        date: {
          from: null,
          to: null,
        },
        price: {
          min: 0,
          max: 0,
        },
      },
    };
  },
  computed: {
    routeQuery() {
      return this.$route.query;
    },
    hasFilterSelected() {
      const { dateFrom, dateTo, sumFrom, sumTo } = this.routeQuery;

      return !!dateFrom || !!dateTo || !!sumFrom || !!sumTo;
    },
    activePage() {
      if (!this.routeQuery.page) return 1;

      return parseInt(this.routeQuery.page);
    },
    metaTitle() {
      if (this.activePage > 1) {
        return `Платежи и баланс — страница ${this.activePage}`;
      }

      return "Платежи и баланс";
    },
    params() {
      const params = {};

      params.page = this.activePage;
      params["per-page"] = this.limit;

      const { dateFrom, dateTo, sumFrom, sumTo } = this.routeQuery;

      if (dateFrom) params.dateFrom = dateFrom;
      if (dateTo) params.dateTo = dateTo;
      if (sumFrom) params.sumFrom = sumFrom;
      if (sumTo) params.sumTo = sumTo;

      return params;
    },
  },
  watch: {
    params(newParams, oldParams) {
      if (!isEqual(newParams, oldParams)) {
        this.fetchBalance();
      }
    },
  },
  created() {
    this.onFetchData();
    this.onSetFilter();
  },
  methods: {
    onFetchData() {
      this.fetchStatistic();
      this.fetchBalance();
    },
    fetchBalance() {
      this.isLoading = true;

      this.$http
        .get("shop/balance/index", {
          params: this.params,
        })
        .then(({ headers, data }) => {
          this.pageCount = parseInt(headers["x-pagination-page-count"]);
          const { operations, sum } = data;

          this.balanceOperationList = operations ? operations : [];
          this.balanceSum = parseFloat(sum);

          this.isLoading = false;
        })
        .catch((error) => {
          this.$toast.error("Не удалось получить список заказов.");
          this.isLoading = false;
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
    fetchStatistic() {
      this.$http
        .get("shop/balance/stats")
        .then(({ data }) => {
          const { debt, balance, maxPaymentSum } = data;

          this.balanceDebt = debt;
          this.balanceBalance = balance;
          this.filterMinPrice = 1;
          this.filterMaxPrice = parseFloat(maxPaymentSum);

          this.filterSelected.price = {
            min: this.filterMinPrice,
            max: this.filterMaxPrice,
          };
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }

          this.$toast.error("Не удалось получить список заказов.");
        });
    },
    onSetFilter() {
      const { dateFrom, dateTo, sumFrom, sumTo } = this.routeQuery;

      if (dateFrom) {
        this.filterSelected.date.from = parse(
          dateFrom,
          "yyyy-MM-dd",
          new Date()
        );
      }

      if (dateTo) {
        this.filterSelected.date.to = parse(dateTo, "yyyy-MM-dd", new Date());
      }

      if (sumFrom) {
        this.filterSelected.price.min = parseInt(sumFrom);
      }

      if (sumTo) {
        this.filterSelected.price.max = parseInt(sumTo);
      }

      this.isFilterReady = true;
    },
    onChangePrice(price) {
      this.filterSelected.price = { ...price };

      const { min, max } = price;
      // eslint-disable-next-line no-unused-vars
      const { sumFrom, sumTo, page, ...query } = this.routeQuery;

      if (min !== this.filterMinPrice) query.sumFrom = min;
      if (max !== this.filterMaxPrice) query.sumTo = max;

      this.$router.push({ query: query }).catch(() => {});
    },
    onChangeDateFrom(date) {
      this.filterSelected.date.from = date;

      // eslint-disable-next-line no-unused-vars
      const { dateFrom, page, ...query } = this.routeQuery;

      if (date) query.dateFrom = formatDate(date, "yyyy-MM-dd");
      this.$router.push({ query: query }).catch(() => {});
    },
    onChangeDateTo(date) {
      this.filterSelected.date.to = date;

      // eslint-disable-next-line no-unused-vars
      const { dateTo, page, ...query } = this.routeQuery;

      if (date) query.dateTo = formatDate(date, "yyyy-MM-dd");
      this.$router.push({ query: query }).catch(() => {});
    },
    onResetPrice() {
      this.filterSelected.price = {
        min: this.filterMinPrice,
        max: this.filterMaxPrice,
      };
    },
    onResetDate() {
      this.filterSelected.date = {
        from: null,
        to: null,
      };
    },
    onClean() {
      this.$router.push({ query: {} }).catch(() => {});
      this.$eventHub.$emit("reset-balance-date");
      this.$eventHub.$emit("reset-balance-price");
      this.$modal.hide("filter");
    },
  },
};
</script>

<style lang="scss" scoped>
.account-balance-view {
  &__btn {
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
      margin-top: 0;
    }
  }

  &__info {
    margin-bottom: 1.5rem;
  }
}
</style>
