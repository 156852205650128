<template>
  <div class="page-layout-full" :class="`page-layout-full--${view}`">
    <div v-if="breadcrumb.length > 0" class="page-layout-full__breadcrumb">
      <base-container>
        <base-breadcrumb :list="breadcrumb" />
      </base-container>
    </div>

    <base-container id="container" class="page-layout-full__container">
      <div v-if="$slots.nav" class="page-layout-full__nav">
        <slot name="nav"></slot>
      </div>

      <page-header
        v-if="$slots.title"
        class="page-layout-full__header"
        :view="header"
      >
        <slot name="title"></slot>

        <template #extra>
          <slot name="subtitle"></slot>
        </template>
      </page-header>

      <slot></slot>
    </base-container>

    <slot name="footer"></slot>
  </div>
</template>

<script>
import BaseContainer from "../shared/BaseContainer";
import BaseBreadcrumb from "../shared/BaseBreadcrumb";
import PageHeader from "./PageHeader";

export default {
  components: {
    BaseBreadcrumb,
    PageHeader,
    BaseContainer,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
      default: "default",
    },
    header: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-layout-full {
  &__breadcrumb {
    margin-bottom: 24px;
  }

  &__nav {
    margin-bottom: 40px;
  }

  &__container {
    .page-layout-full--sm & {
      max-width: 950px;
    }
  }
}
</style>
