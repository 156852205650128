<template>
  <form class="forgot-password-form" @submit.prevent="onSubmit">
    <p>
      Если при регистрации вы не указывали почту или не помните её, обратитесь к
      менеджеру интернет-магазина по телефонам в разделе
      <router-link
        :to="{
          name: 'content',
          params: { alias: 'contacts', town: town },
        }"
        class="header-nav__link"
        @click.native="onClose"
        >Контакты</router-link
      >.
    </p>

    <form-group id="loginLogin" :has-error="$v.forgotPasswordForm.login.$error">
      <template #label>E-mail*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="forgotPasswordForm.login"
          type="email"
          :class="props.inputClass"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <div v-if="countDown > 0" class="forgot-password-form__countdown">
      Повторно запросить ссылку для восстановления пароля можно через:
      {{ countDown | declOfNum(["секунду", "секунды", "секунд"]) }}
    </div>

    <base-btn
      display="block"
      type="submit"
      :loading="isDisable || countDown > 0"
    >
      Отправить
    </base-btn>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { required, email } from "vuelidate/lib/validators";
import declOfNum from "@/utils/declOfNum";
import { mapGetters } from "vuex";

export default {
  components: {
    FormGroup,
    BaseBtn,
  },
  filters: {
    declOfNum,
  },
  data() {
    return {
      isDisable: false,
      forgotPasswordForm: {
        login: "",
      },
      countDown: 0,
    };
  },
  computed: {
    ...mapGetters("settings", {
      town: "getTown",
    }),
  },
  validations: {
    forgotPasswordForm: {
      login: {
        required,
        email,
      },
    },
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    submit(login) {
      this.isDisable = true;
      this.countDown = 30;
      this.countDownTimer();

      this.$http
        .post("users/recovery/request", {
          login: login,
        })
        .then(({ data }) => {
          const { status } = data;

          if (status === "ok") {
            this.$toast.success(
              `На ${login} отправлена ссылка для восстановления пароля.`
            );
            this.$emit("change");
          } else {
            this.$toast.error(
              "Не удалось восстановить пароль к личному кабинету. Для восстановления пароля  обратитесь к менеджеру интернет-магазина по телефонам в разделе Контакты."
            );
          }

          this.isDisable = false;
        })
        .catch(() => {
          this.isDisable = false;
          this.$toast.error(
            "Не удалось восстановить пароль к личному кабинету. Для восстановления пароля  обратитесь к менеджеру интернет-магазина по телефонам в разделе Контакты."
          );
        });
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit(this.forgotPasswordForm.login);
      }
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-form {
  &__countdown {
    @include font-size(14px);
    color: $stone;
    margin-bottom: 1rem;
    text-align: center;
  }
}
</style>
