import { mapGetters } from "vuex";

const mixinContent = {
  data() {
    return {
      isLoading: true,
      item: {},
      meta: null,
    };
  },
  metaInfo() {
    return this.meta;
  },
  computed: {
    ...mapGetters("settings", {
      town: "getTown",
    }),
    breadcrumb() {
      return [
        {
          title: this.item.title,
        },
      ];
    },
    gallery() {
      if (!this.item) return [];
      return this.item.gallery ? this.item.gallery : [];
    },
    routeAlias() {
      return this.$route.params.alias;
    },
    routeTown() {
      return this.$route.params.town;
    },
    httpLink() {
      return this.routeTown
        ? "content/page/view-franchise"
        : "content/page/view";
    },
  },
  watch: {
    town(town) {
      if (this.routeTown !== town) {
        this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params, town: town },
        });
      }
    },
    routeAlias() {
      this.onFetchData();
    },
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;

      this.$http
        .get(this.httpLink, {
          params: {
            alias: this.routeAlias,
          },
        })
        .then(({ data }) => {
          this.isLoading = false;
          this.item = data;
          this.meta = {
            title: data.metaTitle,
            meta: [
              {
                name: "description",
                content: data.metaDescription,
              },
              {
                name: "keywords",
                content: data.metaKeywords,
              },
            ],
          };
        })
        .catch(() => {
          this.isLoading = false;
          this.$router.push({ name: "not-found" });
        });
    },
  },
};

export default mixinContent;
