<template>
  <vue-recaptcha
    class="form-re-captcha"
    :class="{ 'form-re-captcha--error': hasError }"
    :sitekey="key"
    :load-recaptcha-script="true"
    @verify="onVerify"
    @expired="onExpired"
  ></vue-recaptcha>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  components: {
    VueRecaptcha,
  },
  props: {
    hasError: Boolean,
  },
  computed: {
    key() {
      return process.env.VUE_APP_RECAPTCHA_KEY;
    },
  },
  methods: {
    onVerify(response) {
      this.$emit("change", response);
    },
    onExpired() {
      this.$emit("change", null);
    },
  },
};
</script>

<style lang="scss">
.form-re-captcha {
  margin-bottom: 1.5rem;

  > div {
    margin-left: auto;
    margin-right: auto;
  }
}

.form-re-captcha--error {
  > div {
    border: 1px solid $red;
  }
}
</style>
