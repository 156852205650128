<template>
  <div
    class="payment-item"
    :class="[{ 'payment-item--active': active }, `payment-item--${icon.name}`]"
    @click="$emit('click', id)"
  >
    <div class="payment-item__picture">
      <base-icon :name="icon.name" :width="icon.width" :height="icon.height" />
    </div>
    <div class="payment-item__body">
      <div class="payment-item__title">{{ name }}</div>
      <div v-if="subname" class="payment-item__subtitle">{{ subname }}</div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    active: Boolean,
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    name() {
      return this.title.replace(/ *\([^)]*\) */g, "");
    },
    subname() {
      const regExp = /\(([^)]+)\)/;
      const value = regExp.exec(this.title);
      return value && value.length > 1 ? value[1] : null;
    },
    icon() {
      if (!this.type) return null;

      return this.getIcon(this.type.toLowerCase());
    },
  },
  methods: {
    getIcon(str) {
      switch (str) {
        case "sberbank-online": {
          return {
            name: "sberbank",
            width: "83",
            height: "23",
          };
        }
        case "yoomoney": {
          return {
            name: "yookassa",
            width: "82",
            height: "19",
          };
        }
        default: {
          return {
            name: "bank-card",
            width: "105",
            height: "70",
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-item {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  padding: 6px 12px;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 120px auto;
    padding: 16px 12px;
  }

  &.payment-item--active {
    background: $mint-40;
  }

  &__picture {
    height: 64px;
    width: 104px;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    .payment-item--sberbank &,
    .payment-item--yookassa & {
      background: $grey-300;
      border-radius: 6px;
      padding: 12px 10px;
    }

    .payment-item--active.payment-item--sberbank &,
    .payment-item--active.payment-item--yookassa & {
      background: $white;
    }
  }

  &__body {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__title {
    @include font-size(14px);
    font-family: $headings-font-family;

    @include media-breakpoint-up(md) {
      @include font-size(18px);
    }
  }

  &__subtitle {
    @include font-size(11px);

    @include media-breakpoint-up(md) {
      @include font-size(12px);
    }
  }
}
</style>
