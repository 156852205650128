var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"gallery-modal",attrs:{"name":"gallery","width":"100%","height":"100%"},on:{"before-open":_vm.onBeforeOpen}},[_c('close-btn',{on:{"click":_vm.onClose}}),_c('div',{staticClass:"gallery-modal__body"},[_c('splide',{staticClass:"gallery-modal__splide",attrs:{"options":{
        type: 'fade',
        perPage: 1,
        gap: 0,
        arrows: true,
        start: _vm.active,
      }}},_vm._l((_vm.list),function(item){return _c('splide-slide',{key:`gallery-${item.id}`,staticClass:"gallery-modal__item"},[_c('base-image',{staticClass:"image-gallery__active",attrs:{"url":item.files[_vm.field],"width":_vm.width,"height":_vm.height}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }