<template>
  <nav
    v-click-outside="onClose"
    class="header-navbar"
    :class="{ 'header-navbar--open': isOpen }"
  >
    <base-panel @menu="onClickMenu" />

    <div class="header-navbar__collapse">
      <div class="header-navbar__header">
        <base-phone view="light" />
      </div>
      <vuescroll>
        <div class="header-navbar__body">
          <base-user class="header-navbar__user" />
          <account-nav @click="onClickOutside" />
          <div class="header-navbar__city">
            <base-city />
          </div>
          <header-nav view="column" />
          <social view="small" class="header-navbar__social" />
        </div>
      </vuescroll>
    </div>

    <catalog-modal />
  </nav>
</template>

<script>
import BasePhone from "./BasePhone";
import AccountNav from "./AccountNav";
import Social from "../shared/Social";
import BaseCity from "./BaseCity";
import HeaderNav from "./HeaderNav";
import BasePanel from "./BasePanel";
import BaseUser from "./BaseUser";
import vuescroll from "vuescroll";
import CatalogModal from "./CatalogModal";
// utils
import { mapActions, mapState } from "vuex";

const body = document.body;

export default {
  components: {
    BasePhone,
    HeaderNav,
    Social,
    BaseCity,
    AccountNav,
    BasePanel,
    BaseUser,
    vuescroll,
    CatalogModal,
  },
  directives: {
    "click-outside": {
      bind(el, binding) {
        const ourClickEventHandler = (event) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unbind(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      },
    },
  },
  data() {
    return {
      backdrop: null,
    };
  },
  computed: {
    ...mapState({
      wWidth: (state) => state.mq.windowWidth,
      wHeight: (state) => state.mq.windowHeight,
      isOpen: (state) => state.settings.isMenu,
    }),
  },
  watch: {
    wWidth() {
      if (this.isOpen) {
        this.onClose();
      }
    },
    $route() {
      this.onClose();
    },
  },
  methods: {
    ...mapActions("settings", {
      onChangeMenu: "onSetSettings",
    }),
    onClickOutside() {
      this.onClose();
    },
    onClickMenu() {
      if (this.isOpen) {
        this.onClose();
      } else {
        this.onOpen();
      }
    },
    onOpen() {
      this.onChangeMenu({ key: "isMenu", value: true });

      this.$nextTick(() => {
        if (!this.backdrop) {
          this.backdrop = document.createElement("div");
          this.backdrop.id = "backdrop";
          this.backdrop.className = "backdrop";
        }

        body.appendChild(this.backdrop);
        body.classList.add("has-backdrop");
      });
    },
    onClose() {
      this.onChangeMenu({ key: "isMenu", value: false });

      this.$nextTick(() => {
        body.classList.remove("has-backdrop");
        if (this.backdrop && this.backdrop.parentNode)
          this.backdrop.parentNode.removeChild(this.backdrop);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-navbar {
  &__collapse {
    background: $white;
    height: 100vh;
    left: -280px;
    position: fixed;
    top: 0;
    transition: left 250ms ease;
    width: 280px;
    z-index: $zindex-header-navbar;

    .header-navbar--open & {
      left: 0;
    }
  }

  &__header {
    @extend %text-nav;

    background: $smoke;
    padding: 6px 16px;
  }

  &__body {
    padding: 8px 16px 100px;
  }

  &__user {
    margin-bottom: 8px;
  }

  &__city {
    border-bottom: 1px solid $grey-300;
    border-top: 1px solid $grey-300;
    padding: 8px 16px;
    margin: 8px -16px;
  }

  &__social {
    margin-top: 30px;
  }
}
</style>
