<template>
  <div class="delivery">
    <template v-if="title">{{ title }}</template>

    <template v-if="max < 24">
      <template v-if="max === 0">В наличии</template>
      <template v-else>{{
        max | declOfNum(["час", "часа", "часов"])
      }}</template>
    </template>

    <template v-else-if="min === max">
      {{ min | convertHoursToDays | declOfNum(["день", "дня", "дней"]) }}
    </template>
    <template v-else>
      от
      {{ min | convertHoursToDays }}
      до
      {{ max | convertHoursToDays }}
      дней
    </template>
  </div>
</template>

<script>
// utils
import declOfNum from "@/utils/declOfNum";
import convertHoursToDays from "@/utils/convertHoursToDays";

export default {
  filters: {
    declOfNum,
    convertHoursToDays,
  },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
