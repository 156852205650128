<template>
  <div
    class="base-popup"
    :class="[
      { 'base-popup--show': isHover },
      `base-popup--${position}`,
      `base-popup--${theme}`,
    ]"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <div class="base-popup__title">
      <slot name="title"></slot>
    </div>

    <div v-if="!disabled" v-show="isHover" class="base-popup__dropdown">
      <div class="base-popup__content" :style="contentStyle">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: "top-center",
    },
    width: {
      type: Number,
      default: 330,
    },
    theme: {
      type: String,
      default: "light",
    },
    disabled: {
      type: Boolean,
      disabled: false,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    contentStyle() {
      return {
        width: `${this.width}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-popup {
  position: relative;

  &__title {
    line-height: 1;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    z-index: $zindex-base-popup;

    .base-popup--top-center & {
      top: 100%;
      transform: translateX(-50%);
      left: 50%;
      padding-top: 8px;
    }

    .base-popup--top-left & {
      top: 100%;
      padding-top: 8px;
      left: 0;
    }

    .base-popup--top-right & {
      top: 100%;
      padding-top: 8px;
      right: -30px;
    }
  }

  &__content {
    background: $white;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(47, 50, 63, 0.25);
    color: $black;
    opacity: 0.96;
    padding: 24px;

    .base-popup--dark & {
      background: $black;
    }

    &::before {
      content: "";
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8'><path fill='#{$white}' d='M6 0L0 8h12L6 0z' opacity='.96'/></svg>")
      );
      width: 12px;
      height: 8px;
      position: absolute;

      .base-popup--dark & {
        background-image: escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8'><path fill='#{$black}' d='M6 0L0 8h12L6 0z' opacity='.96'/></svg>")
        );
      }

      .base-popup--top-center & {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .base-popup--top-left & {
        top: 0;
      }

      .base-popup--top-right & {
        top: 0;
        right: 30px;
      }
    }
  }
}
</style>
