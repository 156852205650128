<template>
  <page-layout-full :breadcrumb="breadcrumb">
    <template #title>Сбросить пароль</template>
    <template #nav>
      <nav-tab />
    </template>

    <password-form :is-disabled="isDisabled" @submit="onSubmit" />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import NavTab from "@/modules/account/components/NavTab.vue";
import PasswordForm from "../components/PasswordForm.vue";

export default {
  components: {
    PageLayoutFull,
    NavTab,
    PasswordForm,
  },
  metaInfo: {
    title: "Сменить пароль",
  },
  data() {
    return {
      isDisabled: false,
      breadcrumb: [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Сменить пароль",
        },
      ],
    };
  },
  methods: {
    onSubmit(email) {
      this.isDisabled = true;

      this.$http
        .post("users/profile/reset-password", {
          login: email,
        })
        .then(({ data }) => {
          const { status } = data;

          if (status === "ok") {
            this.$toast.success(
              `На ${email} отправлена ссылка для смены пароля.`
            );
          } else {
            this.$toast.error(
              "Не удалось отправить ссылку. Свяжитесь с администратором"
            );
          }

          this.isDisabled = false;
        })
        .catch(({ response }) => {
          this.isDisabled = false;

          const { data } = response;

          if (data.errors) {
            this.$toast.error(Object.values(data.errors).join("\n"));
          } else {
            this.$toast.error(
              "Не удалось отправить ссылку. Свяжитесь с администратором"
            );
          }
        });
    },
  },
};
</script>
