<template>
  <page-layout-full :breadcrumb="breadcrumb">
    <template #title>Контактное лицо</template>
    <template #nav>
      <nav-tab />
    </template>

    <base-loading v-if="isLoading" />

    <profile-form
      v-else
      :value="profileForm"
      :town="town"
      :country="country"
      :is-disabled="isDisabled"
      @submit="onSubmit"
    />
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading.vue";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import NavTab from "@/modules/account/components/NavTab.vue";
import ProfileForm from "../components/ProfileForm.vue";

export default {
  components: {
    BaseLoading,
    PageLayoutFull,
    NavTab,
    ProfileForm,
  },
  metaInfo: {
    title: "Личный кабинет",
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Личный кабинет",
        },
      ],
      isLoading: true,
      isDisabled: false,
      profileForm: {},
      country: null,
      town: null,
    };
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;
      this.$http
        .get("users/profile/info")
        .then(({ data }) => {
          this.isLoading = false;
          this.onSetData(data);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error("Не удалось получить данные профиля.");
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
    onSetData(data) {
      const { email, name, surname, patronymic, address, phone } = data;

      this.profileForm = {
        phone: phone,
        surname: surname,
        name: name,
        patronymic: patronymic,
        address: address,
        email: email,
      };
    },
    onSubmit(form) {
      this.isDisabled = true;

      this.$http
        .post("users/profile/info", form)
        .then(({ data }) => {
          this.isDisabled = false;
          if (data.status === "ok") this.$toast.success("Данные сохранены.");
        })
        .catch(() => {
          this.isDisabled = false;
          this.$toast.error("Не удалось сохранить данные. Попробуйте позже.");
        });
    },
  },
};
</script>
