<template>
  <modal name="filter" width="100%" height="auto" :shift-y="0" scrollable>
    <modal-filter-layout @close="onClose">
      <template #title> Фильтр поиска </template>
      <slot></slot>

      <template #footer>
        <slot name="action"></slot>
        <base-btn size="sm" display="block" @click="onClose">
          Показать
        </base-btn>
      </template>
    </modal-filter-layout>
  </modal>
</template>

<script>
import ModalFilterLayout from "@/components/shared/ModalFilterLayout";
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    ModalFilterLayout,
    BaseBtn,
  },
  methods: {
    onClose() {
      this.$modal.hide("filter");
    },
  },
};
</script>
