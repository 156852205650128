<template>
  <div class="rating">
    <div class="rating__stars">
      <base-icon
        class="rating__star rating__star--up"
        name="star"
        width="24"
        height="24"
      />
      <base-icon
        class="rating__star rating__star--up"
        name="star"
        width="24"
        height="24"
      />
      <base-icon
        class="rating__star rating__star--up"
        name="star"
        width="24"
        height="24"
      />
      <base-icon class="rating__star" name="star" width="24" height="24" />
      <base-icon class="rating__star" name="star" width="24" height="24" />
    </div>
    <div class="rating__note">0 отзывов</div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
};
</script>

<style lang="scss" scoped>
.rating {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0 1rem;

  &__star {
    fill: $grey-300;

    &--up {
      fill: $yellow;
    }

    & + & {
      margin-left: 0.2rem;
    }
  }
}
</style>
