<template>
  <div class="not-found">
    <base-container>
      <div class="row">
        <div class="col-lg-5">
          <figure class="not-found__figure">
            <img
              class="not-found__image"
              src="../../../assets/pages/404.jpg"
              alt="Изображение"
            />
          </figure>
        </div>
        <div class="col-lg-7">
          <header class="not-found__header">
            <h1 class="not-found__title">404</h1>
            <p class="not-found__subtitle">Запрашиваемая страница не найдена</p>
          </header>
          <p v-if="feedback.liveEnabled">
            Если Вам нужна помощь в выборе автозапчастей, задайте
            <a href="#" @click="onOpenLivechat($event)">вопрос онлайн</a>
            и наши специалисты бесплатно подберут нужные детали.
          </p>
          <p>
            Или попробуйте перейти на
            <router-link :to="{ name: 'home' }">главную</router-link>
            и выбрать детали через
            <router-link
              :to="{
                name: 'category-list',
                params: { town: $route.params.town },
              }"
              >каталоги</router-link
            >.
          </p>
        </div>
      </div>
    </base-container>
  </div>
</template>

<script>
import BaseContainer from "@/components/shared/BaseContainer";
// utils
import onOpenLivechat from "@/utils/onOpenLivechat";
import { mapState } from "vuex";

export default {
  components: {
    BaseContainer,
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
  },
  metaInfo: {
    title: "Запрашиваемая страница не найдена",
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }

  &__header {
    margin-bottom: 30px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__title {
    font-size: 72px;
    line-height: 93px;

    @include media-breakpoint-up(lg) {
      font-size: 96px;
      line-height: 124px;
    }
  }

  &__subtitle {
    font-size: $h1-font-size;
    font-weight: bold;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
    }
  }

  &__figure {
    padding: 0 64px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      height: 100%;
      margin-bottom: 0;
    }
  }

  &__image {
    @include img-fluid;
  }
}
</style>
