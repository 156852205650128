<template>
  <modal
    name="delete-alert"
    :max-width="360"
    adaptive
    height="auto"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <modal-alert-layout @close="$modal.hide('delete-alert')">
      <p>{{ title }}</p>

      <template #btns="props">
        <base-btn
          view="outline-secondary"
          :class="props.btnClass"
          @click="onCallback"
        >
          Удалить
        </base-btn>
        <base-btn :class="props.btnClass" @click="$modal.hide('delete-alert')">
          Нет
        </base-btn>
      </template>
    </modal-alert-layout>
  </modal>
</template>

<script>
import ModalAlertLayout from "@/components/shared/ModalAlertLayout";
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    ModalAlertLayout,
    BaseBtn,
  },
  data() {
    return {
      title: null,
      onCallback: null,
    };
  },
  methods: {
    onBeforeOpen(e) {
      const { params } = e;

      if (params) {
        this.title = params.title;
        this.onCallback = params.callBack;
      }
    },
    onBeforeClose() {
      this.title = null;
      this.onCallback = null;
    },
  },
};
</script>
