<template>
  <div class="form-check" :class="`form-check--${type}`">
    <slot :id="id" inputClass="form-check__input" :type="type"></slot>
    <label :for="id" class="form-check__label">
      <slot name="label"></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "checkbox",
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;

  &__label {
    display: block;
  }

  &__input {
    float: left;
    margin-left: $form-check-padding-start * -1;

    width: $form-check-input-width;
    height: $form-check-input-width;
    margin-top: math.div(
      $line-height-base - $form-check-input-width,
      2
    ); // line-height minus check height
    vertical-align: top;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    appearance: none;
    color-adjust: exact; // Keep themed appearance for print
    @include transition($form-check-transition);

    &[type="checkbox"] {
      @include border-radius($form-check-input-border-radius);
    }

    &[type="radio"] {
      border-radius: $form-check-radio-border-radius;
    }

    &:active {
      filter: $form-check-input-active-filter;
    }

    &:focus {
      border-color: $form-check-input-focus-border;
      outline: 0;
    }

    &:checked {
      background-color: $form-check-input-checked-bg-color;
      border-color: $form-check-input-checked-border-color;

      &[type="checkbox"] {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }

      &[type="radio"] {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }

    &[type="checkbox"]:indeterminate {
      background-color: $form-check-input-indeterminate-bg-color;
      border-color: $form-check-input-indeterminate-border-color;

      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }

    &:disabled {
      pointer-events: none;
      filter: none;
      opacity: $form-check-input-disabled-opacity;
    }

    &[disabled],
    &:disabled {
      ~ .form-check-label {
        opacity: $form-check-label-disabled-opacity;
      }
    }
  }
}
</style>
