<template>
  <div class="order-info">
    <div class="order-info__section">
      <div class="order-info__field">
        <strong class="order-info__subtitle">Номер заказа:</strong>
        <span class="order-info__accent">{{ orderId }}</span>
      </div>
    </div>
    <div
      v-if="managerComment && managerComment.length > 0"
      class="order-info__section"
    >
      <h4 class="order-info__title">Комментарий</h4>
      <p>{{ managerComment }}</p>
    </div>
    <div class="order-info__section">
      <h4 class="order-info__title">Детали получения</h4>
      <div v-if="delivery && delivery.title" class="order-info__field">
        <strong class="order-info__subtitle">Способ получения:</strong>
        {{ delivery.title }}
      </div>
      <div v-if="deliveryPrice > 0" class="order-info__field">
        <strong class="order-info__subtitle">Стоимость доставки:</strong>
        {{ deliveryPrice | formatNumber }}
      </div>
      <div v-if="receiverAddress" class="order-info__field">
        <strong class="order-info__subtitle">Адрес получателя:</strong>
        {{ receiverAddress }}
      </div>
      <div class="order-info__field">
        <strong>Срок:</strong> до {{ deadline }}
      </div>
    </div>
    <div class="order-info__section">
      <h4 class="order-info__title">Оплата заказа</h4>
      <div v-if="paymentService" class="order-info__field">
        <strong class="order-info__subtitle">Способ оплаты:</strong>
        <span class="order-info__accent">{{ paymentService.title }}</span>
      </div>
      <div class="order-info__field">
        <strong class="order-info__subtitle">Статус оплаты:</strong>
        <span class="order-info__accent">{{
          isDebt ? "ожидает оплаты" : "оплачен"
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  filters: {
    formatNumber,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    paymentService: {
      type: Object,
      default: null,
    },
    delivery: {
      type: Object,
      default: null,
    },
    deadline: {
      type: String,
      required: true,
    },
    deliveryPayer: {
      type: Number,
      required: true,
    },
    deliveryPrice: {
      type: Number,
      required: true,
    },
    receiverAddress: {
      type: String,
      default: null,
    },
    managerComment: {
      type: String,
      default: "",
    },
    isDebt: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.order-info {
  border: 1px solid $grey-300;
  padding: 24px;

  &__field {
    margin-bottom: 0.5rem;
  }

  &__accent {
    color: $primary;
  }

  &__title {
    @extend %subtitle;
    margin-bottom: 0.75rem;
  }

  &__subtitle {
    margin-right: 0.345rem;
  }

  &__section {
    & + & {
      border-top: 1px solid $grey-300;
      margin-top: 16px;
      padding-top: 16px;
    }
  }
}
</style>
