<template>
  <div class="vin-search-help">
    <div class="vin-search-help__title">Как узнать номер?</div>
    <div class="vin-search-help__desc">
      Он есть в свидетельстве и в ПТС

      <base-popup
        v-if="isLg"
        class="vin-search-help__popup"
        :width="600"
        :position="isXXl ? 'top-center' : 'top-right'"
        theme="dark"
      >
        <template #title>
          <base-icon name="exclude" width="14" height="14" />
        </template>

        <div class="row">
          <div class="col-6">
            <img
              src="../../../assets/pages/home__certificate.jpg"
              width="270"
              height="380"
            />
          </div>
          <div class="col-6">
            <img
              src="../../../assets/pages/home__pts.jpg"
              width="270"
              height="380"
            />
          </div>
        </div>
      </base-popup>

      <template v-else>
        <a href="#" @click.prevent="$modal.show('vin')">
          <base-icon name="exclude" width="24" height="24" />
        </a>
        <vin-search-modal />
      </template>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
import BasePopup from "@/components/shared/BasePopup";
import VinSearchModal from "./VinSearchModal";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BaseIcon,
    BasePopup,
    VinSearchModal,
  },
  computed: {
    ...mapGetters("mq", {
      isLg: "getIsLg",
      isXXl: "getIsXXl",
    }),
  },
};
</script>

<style lang="scss" scoped>
.vin-search-help {
  @extend %text-nav;
  color: $grey-100;

  &__title {
    color: $stone;
    font-weight: bold;
  }

  &__popup {
    display: inline-block;
  }
}
</style>
