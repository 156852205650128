<template>
  <div class="action-list">
    <div class="action-list__row">
      <div class="action-list__item">
        <base-btn
          v-if="feedback.liveEnabled"
          class="action-list__btn"
          view="outline-secondary"
          @click="onOpenLivechat($event)"
        >
          Спросить
        </base-btn>
      </div>

      <div v-if="good.params.id" class="action-list__item">
        <base-btn
          class="action-list__btn action-list__btn--good"
          tag="router-link"
          :to="good"
        >
          Подробнее о товаре
        </base-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn.vue";
// utils
import { mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    BaseBtn,
  },
  props: {
    good: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.action-list {
  &__row {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 1rem;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: auto auto;
    }
  }

  &__item {
    & + & {
      margin-top: 0.5rem;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }

  &__btn {
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
    }
  }
}
</style>
