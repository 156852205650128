<template>
  <modal
    name="login"
    class="login-modal"
    :max-width="385"
    adaptive
    height="auto"
    @before-close="onBeforeClose"
  >
    <div class="login-modal__content">
      <close-btn @click="onClose" />

      <template v-if="isLogin">
        <h2 class="login-modal__title">Вход на сайт</h2>

        <div class="login-modal__body">
          <login-form @forgot="isLogin = false" />
        </div>

        <div class="login-modal__footer">
          Нет аккаунта?
          <a class="login-modal__link" @click.prevent="onSignUp">
            Зарегистрируйтесь!
          </a>
        </div>
      </template>

      <template v-else>
        <h2 class="login-modal__title">Напомнить пароль</h2>

        <div class="login-modal__body">
          <forgot-password-form @change="isLogin = true" @close="onClose" />
        </div>

        <div class="login-modal__footer">
          <a class="login-modal__link" @click.prevent="isLogin = true">
            Я помню пароль
          </a>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import LoginForm from "@/modules/logon/components/LoginForm";
import ForgotPasswordForm from "@/modules/logon/components/ForgotPasswordForm";
import CloseBtn from "../../shared/CloseBtn";

export default {
  components: {
    LoginForm,
    ForgotPasswordForm,
    CloseBtn,
  },
  data() {
    return {
      isLogin: true,
    };
  },
  methods: {
    onClose() {
      this.$modal.hide("login");
    },
    onBeforeClose() {
      this.isLogin = true;
    },
    onSignUp() {
      if (this.$route.name !== "signup") this.$router.push({ name: "signup" });
      this.$modal.hide("login");
    },
  },
};
</script>

<style lang="scss" scoped>
.login-modal {
  &__content {
    padding: 36px 18px 44px;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;
  }

  &__footer {
    @extend %text-nav;

    color: $stone;
    margin-top: 24px;
    text-align: center;
  }

  &__link {
    cursor: pointer;
  }
}
</style>
