<template>
  <page-layout-full :breadcrumb="breadcrumb" class="article-list">
    <template #nav>
      <header-tab-list>
        <template #title>Новости и обзоры</template>
        <template #nav>
          <news-category-nav
            v-if="!isCategoryLoading"
            :list="categoryList"
            :active="categoryAlias"
          />
        </template>
      </header-tab-list>
    </template>

    <base-loading v-if="isNewsLoading && !isLoadMore" />

    <news-list v-else :list="list" />

    <footer class="article-list__footer">
      <div v-if="activePage < total" class="article-list__loadmore">
        <base-btn
          view="outline-secondary"
          :loading="isLoadMore"
          :disabled="isNewsLoading"
          @click="onLoadMore"
        >
          Показать еще
        </base-btn>
      </div>

      <the-pagination
        class="article-list__pagination"
        :total="total"
        :active="activePage"
      />
    </footer>
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading.vue";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import HeaderTabList from "@/components/shared/HeaderTabList";
import ThePagination from "@/components/shared/ThePagination";
import BaseBtn from "@/components/shared/BaseBtn";
import NewsCategoryNav from "../components/NewsCategoryNav";
import NewsList from "../components/NewsList";

export default {
  components: {
    BaseLoading,
    PageLayoutFull,
    HeaderTabList,
    ThePagination,
    BaseBtn,
    NewsCategoryNav,
    NewsList,
  },
  metaInfo() {
    return {
      title: this.metaTitle || "Загружается...",
      meta: this.metaList,
    };
  },
  data() {
    return {
      list: [],
      categoryList: [],
      isCategoryLoading: true,
      isNewsLoading: true,
      isLoadMore: false,
      itemsCount: 0,
      total: 0,
      limit: 21,
    };
  },
  computed: {
    breadcrumb() {
      return [
        {
          title: "Новости",
        },
      ];
    },
    categoryAlias() {
      if (this.$route.params && this.$route.params.alias) {
        return this.$route.params.alias;
      }

      return "all";
    },
    activePage() {
      if (!this.$route.query.page) return 1;

      return parseInt(this.$route.query.page);
    },
    params() {
      const params = {};

      params["per-page"] = this.limit;
      params.page = this.activePage;

      return params;
    },
    activeCategory() {
      const item = this.categoryList.find(
        (item) => item.slug === this.categoryAlias
      );

      return item || this.categoryList[0];
    },
    categoryTitle() {
      return this.activeCategory
        ? this.activeCategory.title
        : "Новости и обзоры";
    },
    metaTitle() {
      if (this.activePage > 1) {
        return `${this.categoryTitle} — страница ${this.activePage}`;
      }

      return this.categoryTitle;
    },
    metaList() {
      if (this.activeCategory) {
        return [
          {
            name: "description",
            content: this.activeCategory.metaDescription,
          },
          {
            name: "keywords",
            content: this.activeCategory.metaKeywords,
          },
        ];
      }

      return [];
    },
  },
  watch: {
    categoryAlias(newAlias, oldAlias) {
      if (newAlias !== oldAlias) this.fetchList(newAlias);
    },
    activePage(newPage, oldPage) {
      if (newPage !== oldPage) this.fetchList(this.categoryAlias);
    },
  },
  mounted() {
    this.fetchList(this.categoryAlias);
    this.fetchCategoryList();
  },
  methods: {
    onLoadMore() {
      this.isLoadMore = true;

      this.$nextTick(() => {
        const query = Object.assign({}, this.$route.query);
        query.page = this.activePage + 1;

        this.$router.push({ query: query });
      });
    },
    fetchList(categoryAlias) {
      this.isNewsLoading = true;

      const params = this.params;

      if (categoryAlias && categoryAlias !== "all") {
        params.category = categoryAlias;
      }

      this.$http
        .get("content/article/index", {
          params: params,
        })
        .then(({ data, headers }) => {
          this.isNewsLoading = false;

          this.itemsCount = parseInt(headers["x-pagination-total-count"]);
          this.total = parseInt(headers["x-pagination-page-count"]);

          if (this.isLoadMore) {
            this.list = [...this.list, ...data];
            this.isLoadMore = false;
          } else {
            this.list = data;
          }
        })
        .catch((error) => {
          this.$toast.error(error);
          this.isNewsLoading = false;
          this.isLoadMore = false;
        });
    },
    fetchCategoryList() {
      const params = {};

      this.isCategoryLoading = true;

      this.$http
        .get("content/article/categories", {
          params: params,
        })
        .then(({ data }) => {
          this.categoryList = data;
          this.isCategoryLoading = false;

          this.$nextTick(() => {
            this.$meta().refresh();
            this.$nextTick(() => {
              document.body.classList.add("is-loaded");
            });
          });
        })
        .catch((error) => {
          this.$toast.error(error);
          this.isCategoryLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list {
  &__pagination {
    border-top: 1px solid $grey-300;
    margin-top: 1.8rem;
    padding-top: 1rem;
  }

  &__loadmore {
    margin-top: 1.8rem;
    text-align: center;
  }
}
</style>
