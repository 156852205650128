<template>
  <vuescroll ref="scrollbar" :ops="scrollOptions">
    <nav-tab-list class="nav-tab" view="border">
      <nav-tab-item
        ref="account-profile"
        :to="{ name: 'account-profile' }"
        :active="routeName === 'account-profile'"
      >
        Личные данные
      </nav-tab-item>
      <nav-tab-item
        ref="account-password"
        :to="{ name: 'account-password' }"
        :active="routeName === 'account-password'"
      >
        Сменить пароль
      </nav-tab-item>
      <nav-tab-item
        ref="account-area"
        :to="{ name: 'account-area' }"
        :active="routeName === 'account-area'"
      >
        Сменить регион
      </nav-tab-item>
      <nav-tab-item
        ref="account-order-list"
        :to="{ name: 'account-order-list' }"
        :active="routeName === 'account-order-list'"
      >
        Заказы
      </nav-tab-item>
      <nav-tab-item
        ref="account-balance"
        :to="{ name: 'account-balance' }"
        :active="routeName === 'account-balance'"
      >
        Платежи и баланс
      </nav-tab-item>
      <nav-tab-item
        ref="account-garage"
        :to="{ name: 'account-garage' }"
        :active="routeName === 'account-garage'"
      >
        Гараж
      </nav-tab-item>
    </nav-tab-list>
  </vuescroll>
</template>

<script>
import vuescroll from "vuescroll";
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";

export default {
  components: {
    vuescroll,
    NavTabList,
    NavTabItem,
  },
  data() {
    return {
      scrollOptions: {
        bar: {
          size: "3px",
        },
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  mounted() {
    this.updateScroll();
  },
  methods: {
    updateScroll() {
      const { scrollbar } = this.$refs;

      const item = this.$refs[this.routeName];
      if (item) {
        const offsetLeft = item.$el.offsetLeft;

        setTimeout(() => {
          scrollbar.scrollTo({ x: offsetLeft }, 100);
        }, 100);
      }
    },
  },
};
</script>
