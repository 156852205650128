<template>
  <div class="the-empty">
    <div class="the-empty__picture">
      <base-icon
        class="the-empty__icon"
        name="search-outline"
        width="104"
        height="104"
      />
    </div>
    <div class="the-empty__body">
      <h2>Странно, но ничего нет</h2>
      <p>Попробуйте изменить критерии поиска</p>
    </div>
    <div v-if="!!$slots.filter" class="the-empty__filter">
      <slot name="filter"></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
};
</script>

<style lang="scss" scoped>
.the-empty {
  &__picture {
    align-items: center;
    background: $grey-300;
    border-radius: 50%;
    display: flex;
    height: 160px;
    justify-content: center;
    margin: 0 auto size(30px);
    width: 160px;
  }

  &__body {
    text-align: center;
  }

  &__filter {
    margin-top: size(30px);
  }
}
</style>
