<template>
  <form class="recipient-form" @submit.prevent="onSubmit">
    <div class="recipient-form__section">
      <div class="row">
        <div class="col-lg-6">
          <form-group
            id="recipientSurname"
            :has-error="$v.recipientForm.surname.$error"
          >
            <template #label>Фамилия*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="recipientForm.surname"
                type="text"
                :class="props.inputClass"
                :disabled="isRecipientFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>

          <form-group
            id="recipientName"
            :has-error="$v.recipientForm.name.$error"
          >
            <template #label>Имя*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="recipientForm.name"
                type="text"
                :class="props.inputClass"
                :disabled="isRecipientFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>

          <form-group id="recipientPatronymic">
            <template #label>Отчество</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="recipientForm.patronymic"
                type="text"
                :class="props.inputClass"
                :disabled="isRecipientFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>
        </div>
        <div class="col-lg-6">
          <form-group
            id="recipientPhone"
            :has-error="$v.recipientForm.phone.$error"
          >
            <template #label>Телефон*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="recipientForm.phone"
                v-mask="'# (###) ### ##-##'"
                type="text"
                :class="props.inputClass"
                :disabled="isRecipientFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>

          <form-group
            id="recipientEmail"
            :has-error="$v.recipientForm.email.$error"
          >
            <template #label>E-mail*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="recipientForm.email"
                type="email"
                :class="props.inputClass"
                :disabled="isRecipientFormDisabled"
              />
            </template>
            <template #error> Неверный email </template>
            <template #hint>
              Электронный адрес для связи и уведомлений
            </template>
          </form-group>
        </div>
      </div>

      <div class="recipient-form__btns">
        <base-btn type="button" view="outline-primary">Редактировать</base-btn>
      </div>

      <form-check id="recipientOther">
        <template #default="props">
          <input
            :id="props.id"
            v-model="isOther"
            :class="props.inputClass"
            :type="props.type"
            name="recipientOther"
          />
        </template>
        <template #label> Другой получатель </template>
      </form-check>
    </div>

    <div v-if="isOther" class="recipient-form__section">
      <p>
        Получение только для оплаченного заказа при наличии документа,
        подтверждающего личность (паспорт). Укажите данные получателя:
      </p>
      <div class="row">
        <div class="col-lg-6">
          <form-group
            id="otherSurname"
            :has-error="$v.otherForm.surname.$error"
          >
            <template #label>Фамилия*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="otherForm.surname"
                type="text"
                :class="props.inputClass"
                :disabled="isOtherFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>

          <form-group id="otherName" :has-error="$v.otherForm.name.$error">
            <template #label>Имя*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="otherForm.name"
                type="text"
                :class="props.inputClass"
                :disabled="isOtherFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>

          <form-group id="otherPatronymic">
            <template #label>Отчество</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="otherForm.patronymic"
                type="text"
                :class="props.inputClass"
                :disabled="isOtherFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>
        </div>
        <div class="col-lg-6">
          <form-group id="otherPhone" :has-error="$v.otherForm.phone.$error">
            <template #label>Телефон*</template>
            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="otherForm.phone"
                v-mask="'# (###) ### ##-##'"
                type="text"
                :class="props.inputClass"
                :disabled="isOtherFormDisabled"
              />
            </template>
            <template #error> Необходимо заполнить поле </template>
          </form-group>

          <form-check id="recipientInform">
            <template #default="props">
              <input
                :id="props.id"
                v-model="otherForm.isInform"
                :class="props.inputClass"
                :type="props.type"
                name="recipientInform"
              />
            </template>
            <template #label>
              Информировать получателя по СМС о готовности заказа для выдачи
            </template>
          </form-check>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import FormCheck from "@/components/shared/FormCheck";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { requiredIf, email } from "vuelidate/lib/validators";

export default {
  components: {
    FormGroup,
    FormCheck,
    BaseBtn,
  },
  data() {
    return {
      recipientForm: {
        surname: "",
        name: "",
        patronymic: "",
        phone: "",
        email: "",
      },
      otherForm: {
        surname: "",
        name: "",
        patronymic: "",
        phone: "",
        isInform: true,
      },
      isRecipientFormDisabled: true,
      isOtherFormDisabled: false,
      isOther: false,
    };
  },
  validations() {
    return {
      recipientForm: {
        phone: {
          required: requiredIf(() => {
            return !this.isOther;
          }),
        },
        surname: {
          required: requiredIf(() => {
            return !this.isOther;
          }),
        },
        name: {
          required: requiredIf(() => {
            return !this.isOther;
          }),
        },
        email: {
          email,
        },
      },
      otherForm: {
        phone: {
          required: requiredIf(() => {
            return this.isOther;
          }),
        },
        surname: {
          required: requiredIf(() => {
            return this.isOther;
          }),
        },
        name: {
          required: requiredIf(() => {
            return this.isOther;
          }),
        },
      },
    };
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.info("submit");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recipient-form {
  &__btns {
    margin-bottom: 24px;
  }

  &__section {
    & + & {
      margin-top: 24px;
    }
  }
}
</style>
