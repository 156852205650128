<template>
  <div class="delivery-express">
    <form class="delivery-express__form">
      <div class="row">
        <div class="col-md-6">
          <form-suggestion
            v-if="isAddressRequired"
            id="addressSearch"
            :value="address"
            :locations="locations"
            url="suggest/address"
            :disabled="isDisabled || !country"
            restrict-value
            :count="3"
            name="suggest-profile"
            @input="onInputAddress"
            @select="onSelectAddress"
          >
            <template #label>Адрес</template>
            <template #error>Необходимо заполнить поле</template>
          </form-suggestion>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <form-group id="comment">
            <template #label>Комментарий</template>
            <template #default="props">
              <textarea
                :id="props.id"
                :value="comment"
                :disabled="isDisabled"
                :class="props.inputClass"
                @input="onUpdateComment"
              ></textarea>
            </template>
          </form-group>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import FormSuggestion from "@/components/shared/FormSuggestion";
// utils
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {
    FormGroup,
    FormSuggestion,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isAddressRequired: Boolean,
    isDisabled: Boolean,
  },
  computed: {
    ...mapState("cart", {
      address: (state) => state.deliveryAddress,
      comment: (state) => state.comment,
    }),
    ...mapGetters("settings", {
      country: "countryName",
    }),
    locations() {
      const locations = [];

      if (this.country) {
        locations.push({
          country: this.country,
        });
      }

      return locations;
    },
  },
  watch: {
    isAddressRequired(val) {
      if (!val) this.onUpdateValue({ key: "deliveryAddress", value: null });
    },
  },
  methods: {
    ...mapActions("cart", {
      onUpdateValue: "onUpdateValue",
    }),
    onInputAddress(value) {
      this.onUpdateValue({ key: "deliveryAddress", value: value });
    },
    onSelectAddress(payload) {
      this.onUpdateValue({ key: "deliveryAddress", value: payload.value });
    },
    onUpdateAddress(event) {
      this.onUpdateValue({ key: "deliveryAddress", value: event.target.value });
    },
    onUpdateComment(event) {
      this.onUpdateValue({ key: "comment", value: event.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-express {
  &__txt,
  &__form-group {
    margin-bottom: 0.5rem;
  }
}
</style>
