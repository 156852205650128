<template>
  <div class="cart-info">
    <div class="cart-info__header">
      <div class="row">
        <div class="col-5">
          <router-link
            class="cart-info__action"
            :to="{
              name: 'category-list',
              params: { town: $route.params.town },
            }"
          >
            &larr; К покупкам
          </router-link>
        </div>
        <div class="col-7 test-end">
          <a class="cart-info__action" @click="$emit('clean')">
            <base-icon
              name="delete-outline"
              class="cart-info__delete"
              width="16"
              height="16"
            />
            Очистить корзину
          </a>
        </div>
      </div>
    </div>

    <div class="cart-info__body">
      <div class="cart-info__section">
        <div class="cart-info__list">
          <div class="cart-info__title">Товары ({{ count }})</div>
          <div class="cart-info__value">{{ itemsPrice | formatNumber }} ₽</div>
          <template v-if="discountPrice < 0">
            <div class="cart-info__title">Скидка</div>
            <div class="cart-info__value cart-info__discount">
              {{ discountPrice | formatNumber }} ₽
            </div>
          </template>
          <div class="cart-info__title">Доставка</div>
          <div class="cart-info__value">
            <template v-if="deliveryPrice > 0">
              {{ deliveryPrice | formatNumber }} ₽
            </template>
            <template v-else> Бесплатно </template>
          </div>
        </div>
      </div>
      <div v-if="deliveryTitle" class="cart-info__section">
        <div class="cart-info__list">
          <div class="cart-info__title">Доставка</div>
          <div class="cart-info__value">{{ deliveryTitle }}</div>
        </div>
        <div v-if="deliveryAddress && deliveryAddress.length > 0">
          {{ deliveryAddress }}
        </div>
      </div>
      <div class="cart-info__section">
        <div class="cart-info__list">
          <div class="cart-info__title">
            <strong>Итого</strong>
          </div>
          <div class="cart-info__value cart-info__price">
            {{ totalPrice | formatNumber }} ₽
          </div>
        </div>
      </div>

      <base-btn
        display="block"
        :disabled="isDisabled"
        :loading="isChecking"
        @click="$emit('checkout')"
      >
        Оформить заказ
      </base-btn>
      <base-btn
        display="block"
        view="outline-danger"
        :icon="{
          name: 'delete-outline',
          fill: '#c62925',
          width: '24',
          height: '24',
        }"
        :is-disabled="isDisabled"
        @click="$emit('clean')"
      >
        Очистить
      </base-btn>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import { mapState, mapGetters } from "vuex";
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseBtn,
    BaseIcon,
  },
  filters: {
    formatNumber,
  },
  props: {
    isDisabled: Boolean,
    isChecking: Boolean,
  },
  computed: {
    ...mapState("cart", {
      deliveryAddress: (state) => state.deliveryAddress,
    }),
    ...mapGetters("cart", {
      count: "itemsSize",
      itemsPrice: "itemsPrice",
      discountPrice: "discountPrice",
      totalPrice: "totalPrice",
      deliveryTitle: "deliveryTitle",
      deliveryPrice: "deliveryPrice",
    }),
  },
};
</script>

<style lang="scss" scoped>
.cart-info {
  margin-bottom: 8px;

  &__header {
    @extend %text-sm;
    margin-bottom: 8px;
  }

  &__action {
    color: $stone;
    cursor: pointer;
    text-decoration: none;
  }

  &__body {
    border: 1px solid $grey-300;
    border-radius: 6px;
    padding: 24px;
  }

  &__section {
    margin-bottom: 1rem;

    & + & {
      border-top: 1px solid $grey-300;
      padding-top: 1rem;
    }
  }

  &__list {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  &__title {
    font-weight: normal;
  }

  &__value {
    text-align: right;
  }

  &__discount {
    color: $red;
  }

  &__price {
    @include font-size(22px);
    color: $black;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.4;
    white-space: nowrap;
  }

  &__delete {
    fill: $grey-200;
  }
}
</style>
