<template>
  <tr
    class="balance-list-table-tr"
    :class="{ 'balance-list-table-tr--success': isExecuted }"
  >
    <td class="balance-list-table-tr__value">
      <slot name="date"></slot>
    </td>
    <td class="balance-list-table-tr__value">
      <slot name="type"></slot>
    </td>
    <td class="balance-list-table-tr__value">
      <slot name="sum"></slot>
    </td>
    <td class="balance-list-table-tr__value balance-list-table-tr__status">
      <slot name="status"></slot>
    </td>
    <td class="balance-list-table-tr__value">
      <slot name="order" linkClass="balance-list-table-tr__link"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    isExecuted: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.balance-list-table-tr {
  &__value {
    border-bottom: 1px solid $grey-300;
    padding: 0.5em;
  }

  &__status {
    color: $red;

    .balance-list-table-tr--success & {
      color: $primary;
    }
  }

  &__link {
    color: inherit;
  }
}
</style>
