<template>
  <page-layout-full class="signup" :breadcrumb="breadcrumb">
    <template #title>Регистрация</template>

    <div class="signup__container">
      <signup-nav class="signup__nav" :active="active" @change="onChangeTab" />

      <component :is="signupForm" class="signup__form" />

      <footer class="signup__footer">
        <a href="#" @click.prevent="$modal.show('login')">
          Войти в личный кабинет
        </a>
      </footer>
    </div>
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import SignupNav from "../components/SignupNav";
import SignupCompanyForm from "../components/SignupCompanyForm";
import SignupPersonForm from "../components/SignupPersonForm";

export default {
  components: {
    PageLayoutFull,
    SignupNav,
    SignupCompanyForm,
    SignupPersonForm,
  },
  data() {
    return {
      active: 0,
      breadcrumb: [
        {
          title: "Регистрация",
        },
      ],
    };
  },
  computed: {
    signupForm() {
      return this.active === 0 ? SignupPersonForm : SignupPersonForm;
    },
  },
  methods: {
    onChangeTab(active) {
      this.active = active;
    },
  },
};
</script>

<style lang="scss" scoped>
.signup {
  text-align: center;

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
  }

  &__nav {
    margin-bottom: 30px;
  }

  &__form {
    text-align: left;
  }

  &__footer {
    margin-top: 32px;
  }
}
</style>
