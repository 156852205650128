<template>
  <div class="modal-alert-layout">
    <div class="modal-alert-layout__body">
      <close-btn
        v-if="clickToClose"
        position="top-right"
        @click="$emit('close')"
      />

      <base-icon
        class="modal-alert-layout__icon"
        name="attention-treengle-solid"
        widht="120"
        height="120"
      />

      <h4 v-if="$slots.title" class="modal-alert-layout__title">
        <slot name="title"></slot>
      </h4>

      <div class="modal-alert-layout__content">
        <slot></slot>
      </div>

      <div class="modal-alert-layout__footer">
        <slot name="btns" btnClass="modal-alert-layout__btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CloseBtn from "./CloseBtn";
import BaseIcon from "./BaseIcon";

export default {
  components: {
    CloseBtn,
    BaseIcon,
  },
  props: {
    clickToClose: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-alert-layout {
  &__body {
    padding: 36px 12px;
    text-align: center;
  }

  &__btn {
    & + & {
      margin-left: 1rem;
      min-width: 150px;
    }
  }

  &__title {
    @extend %subtitle;
    margin-bottom: 0;
  }

  &__content {
    color: $stone;
    font-weight: normal;
  }

  &__icon {
    fill: $grey-300;
  }
}
</style>
