<template>
  <button
    class="close-btn"
    :class="[`close-btn--${position}`]"
    type="button"
    @click="$emit('click')"
  >
    <base-icon
      class="close-btn__icon"
      name="close-outline"
      width="24"
      height="24"
    />
  </button>
</template>

<script>
import BaseIcon from "./BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    position: {
      type: String,
      default: "top-right",
    },
  },
};
</script>

<style lang="scss" scoped>
.close-btn {
  @extend %btn;

  fill: $grey-200;
  position: absolute;

  &--top-right {
    right: 12px;
    top: 12px;
  }

  &--top-left {
    left: 12px;
    top: 12px;
  }

  &:hover,
  &:focus {
    fill: $link-color;
  }
}
</style>
