<template>
  <page-layout-full class="category-list" :breadcrumb="breadcrumb">
    <template #title>Все каталоги</template>

    <category
      v-for="item in list"
      :key="`item-${item.id}`"
      class="category-list__item"
      :category="item"
    />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import Category from "../components/category/Category";
// utils
import { mapState } from "vuex";

export default {
  components: {
    PageLayoutFull,
    Category,
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Все каталоги",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Каталог товаров",
    };
  },
  computed: {
    ...mapState("catalog", {
      list: (state) => state.list,
    }),
  },
};
</script>

<style lang="scss" scoped>
.category-list {
  &__item {
    & + & {
      margin-top: 24px;
    }
  }
}
</style>
