<template>
  <div class="news-list">
    <div
      v-for="(item, index) in list"
      :key="`news-${item.slug}-${index}`"
      class="news-list__item"
    >
      <news-item
        :item="item"
        :view="specialList.includes(index) ? 'news-full' : 'news-medium'"
      />
    </div>
  </div>
</template>

<script>
import NewsItem from "./NewsItem";

export default {
  components: {
    NewsItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    specialList() {
      return [0, 1, 2, 7, 8, 9, 14, 15, 16];
    },
  },
  methods: {
    checkIndex() {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.news-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: dense;
  }

  &__item {
    grid-column: auto / span 12;

    @include media-breakpoint-up(sm) {
      grid-column: auto / span 6;
    }

    @include media-breakpoint-up(md) {
      grid-column: auto / span 3;
    }

    &:nth-child(7n + 1),
    &:nth-child(7n + 2),
    &:nth-child(7n + 3) {
      @include media-breakpoint-up(sm) {
        grid-column: auto / span 12;
      }

      @include media-breakpoint-up(md) {
        grid-column: auto / span 4;
      }
    }
  }
}
</style>
