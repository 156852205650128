<template>
  <table class="suggestion-table">
    <thead class="suggestion-table__head">
      <tr>
        <th
          colspan="2"
          class="suggestion-table__title suggestion-table__title--brand"
        >
          Бренд и код детали
        </th>
        <th class="suggestion-table__title suggestion-table__title--name">
          Наименование
        </th>
        <th class="suggestion-table__title suggestion-table__title--quantity">
          <router-link
            class="suggestion-table__link"
            :to="{ ...route, query: { sorting: 'quantity', order: order } }"
          >
            Шт.
            <sorting-symbol
              v-if="routeSorting === 'quantity'"
              :order="routeOrder"
            />
          </router-link>
        </th>
        <th class="suggestion-table__title suggestion-table__title--warehouse">
          <router-link
            class="suggestion-table__link"
            :to="{ ...route, query: { sorting: 'warehouse', order: order } }"
          >
            Склады
            <sorting-symbol
              v-if="routeSorting === 'warehouse'"
              :order="routeOrder"
            />
          </router-link>
        </th>
        <th class="suggestion-table__title suggestion-table__title--delivery">
          <router-link
            class="suggestion-table__link"
            :to="{ ...route, query: { sorting: 'delivery', order: order } }"
          >
            Срок
            <sorting-symbol
              v-if="routeSorting === 'delivery'"
              :order="routeOrder"
            />
          </router-link>
        </th>
        <th class="suggestion-table__title suggestion-table__title--price">
          <router-link
            class="suggestion-table__link"
            :to="{ ...route, query: { sorting: 'price', order: order } }"
          >
            Цена
            <sorting-symbol
              v-if="routeSorting === 'price'"
              :order="routeOrder"
            />
          </router-link>
        </th>
        <th class="suggestion-table__title suggestion-table__title--order">
          Заказ
        </th>
      </tr>
    </thead>
    <tbody v-if="$slots.original">
      <tr>
        <th colspan="8" class="suggestion-table__subtitle">
          Запрашиваемый артикул
        </th>
      </tr>
      <slot name="original"></slot>
    </tbody>
    <tbody v-if="$slots.replacement">
      <tr>
        <th colspan="8" class="suggestion-table__subtitle">Аналоги</th>
      </tr>
      <slot name="replacement"></slot>
    </tbody>
  </table>
</template>

<script>
import SortingSymbol from "./SortingSymbol";

export default {
  components: {
    SortingSymbol,
  },
  computed: {
    route() {
      return {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      };
    },
    routeSorting() {
      if (this.$route.query.sorting) return this.$route.query.sorting;

      return "delivery";
    },
    routeOrder() {
      if (this.$route.query.order) return this.$route.query.order;

      return "asc";
    },
    order() {
      return this.routeOrder === "asc" ? "desc" : "asc";
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-table {
  width: 100%;

  &__title {
    background-color: $primary;
    color: $white;
    font-weight: bold;
    padding: 6px 8px;
    white-space: nowrap;
    top: 0;
    position: sticky;
    z-index: 2;

    &--brand {
      width: 190px;
    }

    &--quantity {
      width: 60px;
    }

    &--warehouse {
      width: 100px;
    }

    &--delivery {
      width: 150px;
    }

    &--price {
      text-align: right;
      width: 100px;
    }

    &--order {
      text-align: center;
      width: 190px;
    }
  }

  &__link {
    color: $white;
    position: relative;
    padding-right: 5px;

    > span {
      position: absolute;
      right: -11px;
      top: 0;
      z-index: 1;
    }
  }

  &__subtitle {
    @extend %text-nav;

    background: $smoke;
    font-weight: bold;
    padding: 6px 8px;
    top: 34px;
    position: sticky;
    z-index: 2;
  }
}
</style>
