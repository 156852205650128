<template>
  <form class="feedback-message-form" @submit.prevent="onSubmit">
    <form-group id="messageName" :has-error="$v.messageForm.name.$error">
      <template #label>Имя*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="messageForm.name"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="messageSurname">
      <template #label>Фамилия</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="messageForm.surname"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group
      id="messagePhoneOrEmail"
      :has-error="$v.messageForm.phoneOrEmail.$error"
    >
      <template #label>Телефон или E-mail*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="messageForm.phoneOrEmail"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="messageVin">
      <template #label>Vin-номер</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="messageForm.vin"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="messageText" :has-error="$v.messageForm.text.$error">
      <template #label>Сообщение*</template>
      <template #default="props">
        <textarea
          :id="props.id"
          v-model.trim="messageForm.text"
          :class="props.inputClass"
          :disabled="isDisabled"
        ></textarea>
      </template>
      <template #error> Необходимо заполнить поле </template>
      <template #extra>
        <span class="text-sm text-muted">
          Нажимая на кнопку «Отправить», я соглашаюсь с
          <router-link
            class="text-nowrap"
            :to="{
              name: 'content',
              params: { alias: 'politika-konfidencialnosti' },
            }"
            target="_blank"
          >
            политикой конфиденциальности
          </router-link>
        </span>
      </template>
    </form-group>

    <base-btn
      display="block"
      view="primary"
      size="sm"
      type="submit"
      :disabled="isDisabled"
    >
      Отправить
    </base-btn>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import BaseBtn from "@/components/shared/BaseBtn";
// utils
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FormGroup,
    BaseBtn,
  },
  data() {
    return {
      isDisabled: false,
      messageForm: {
        phoneOrEmail: "",
        surname: "",
        name: "",
        vin: "",
        text: "",
      },
    };
  },
  validations: {
    messageForm: {
      name: {
        required,
      },
      phoneOrEmail: {
        required,
      },
      text: {
        required,
      },
    },
  },
  methods: {
    submit() {
      this.$emit("success");
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
  },
};
</script>
