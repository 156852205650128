var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"featured-good-list-item",attrs:{"to":{
    name: 'search-vendors',
    params: {
      town: _vm.$route.params.town,
      brand: _vm.item.brand,
      article: _vm.item.article,
    },
  }}},[_c('figure',{staticClass:"featured-good-list-item__picture"},[_c('base-image',{staticClass:"featured-good-list-item__image",attrs:{"url":_vm.image,"width":"150","height":"150","resizing-type":"fill"}})],1),_c('div',{staticClass:"featured-good-list-item__price-box",class:{
      'featured-good-list-item__price-box--has-old': !!_vm.item.priceOld,
    }},[(_vm.item.price > 0)?_c('span',{staticClass:"featured-good-list-item__price-current"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.item.price))+" ₽ ")]):_vm._e(),(_vm.item.priceOld)?_c('span',{staticClass:"featured-good-list-item__price-old"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.item.priceOld))+" ₽ ")]):_vm._e()]),_c('div',{staticClass:"featured-good-list-item__title"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }