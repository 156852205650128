<template>
  <router-link
    :to="{ name: 'article-item', params: { alias: item.slug } }"
    class="news-card"
  >
    <figure class="news-card__picture">
      <base-image
        :url="imageSrc"
        class="news-card__image"
        width="270"
        height="152"
      />
    </figure>
    <div class="news-card__header">
      <router-link
        :to="{ name: 'article-list', params: { alias: item.category.slug } }"
        class="news-card__category"
      >
        {{ item.category.title }}
      </router-link>
      <span class="news-card__date">{{
        date | formatDate("d MMMM yyyy")
      }}</span>
    </div>
    <h4 class="news-card__title">
      {{ item.title }}
    </h4>
  </router-link>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";
// utils
import fromUnixTime from "date-fns/fromUnixTime";
import formatDate from "@/utils/formatDate";

export default {
  components: {
    BaseImage,
  },
  filters: {
    formatDate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSrc() {
      return this.item.image && this.item.image.files
        ? this.item.image.files["news-full"]
        : null;
    },
    date() {
      return fromUnixTime(this.item.publishTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-card {
  color: inherit;
  text-decoration: none;

  &__image {
    border-radius: 4px;
  }

  &__header {
    margin-bottom: 0.5rem;
  }

  &__category {
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__date {
    @extend %text-nav;
    color: $grey-100;
    margin-left: 0.5rem;
  }

  &__title {
    @extend %subtitle;
  }

  &__title-link {
    color: inherit;
    text-decoration: none;
  }
}
</style>
