<template>
  <div class="social" :class="`social--${view}`">
    <h4 v-if="isShowTitle" class="social__title">Мы в соц. сетях</h4>

    <div class="social__list">
      <a
        class="social__link"
        :href="social.socialOk || `https://ok.ru/group/54020497211620`"
        target="_blank"
      >
        <base-icon class="social__icon" name="ok" />
      </a>
      <a
        class="social__link"
        :href="social.socialVk || `https://vk.com/avtovelikan`"
        target="_blank"
      >
        <base-icon class="social__icon" name="vk" />
      </a>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../../shared/BaseIcon";
// utils
import { mapState } from "vuex";

export default {
  components: {
    BaseIcon,
  },
  props: {
    view: {
      type: String,
      default: "default",
    },
  },
  computed: {
    isShowTitle() {
      return this.view === "default";
    },
    ...mapState("settings", {
      social: (state) => state.city.social,
    }),
  },
};
</script>

<style lang="scss" scoped>
.social {
  &__title {
    @extend %subtitle;

    color: $smoke;
    margin-bottom: 14px;
  }

  &__list {
    display: flex;
  }

  &__link {
    display: block;

    & + & {
      margin-left: 16px;
    }
  }

  &__icon {
    height: 24px;
    width: 24px;

    .social--simple & {
      height: 16px;
      width: 16px;
    }
  }
}
</style>
