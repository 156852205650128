var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"base-btn",class:[
    `base-btn--${_vm.view}`,
    `base-btn--${_vm.size}`,
    _vm.display ? `base-btn--${_vm.display}` : '',
    _vm.loading ? 'base-btn--loading' : '',
    _vm.disabled && !_vm.loading ? 'base-btn--disabled' : '',
    _vm.icon ? 'has-icon' : '',
    _vm.icon && !_vm.$slots.default ? 'base-btn--icon' : '',
    _vm.icon && _vm.icon.position ? `icon--${_vm.icon.position}` : '',
    _vm.rotate ? 'has-rotate' : '',
  ],attrs:{"to":_vm.to,"disabled":(_vm.disabled || _vm.loading) && _vm.tag === 'button'},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('img',{staticClass:"base-btn__loading",attrs:{"width":"24","height":"24","src":_vm.image}}):_vm._e(),(_vm.icon)?_c('base-icon',{staticClass:"base-btn__icon",attrs:{"name":_vm.icon.name,"width":_vm.icon.width,"height":_vm.icon.height,"fill":_vm.icon.fill}}):_vm._e(),_c('span',{staticClass:"base-btn__text"},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }