/**
 * Склонение
 * @param {Number} value — число
 * @param {Array} titles — заголовки
 */
const declOfNum = (value, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    value +
    " " +
    titles[
      value % 100 > 4 && value % 100 < 20
        ? 2
        : cases[value % 10 < 5 ? value % 10 : 5]
    ]
  );
};

export default declOfNum;
