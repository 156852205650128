<template>
  <div
    ref="nav"
    class="catalog-box-item"
    :class="{ 'catalog-box-item--show': isHover }"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <router-link class="catalog-box-item__link" :to="to">
      <base-icon
        class="catalog-box-item__icon"
        :name="isHover ? `${icon}-hover` : icon"
        width="32"
        height="32"
      />
      <slot></slot>
    </router-link>

    <div v-show="isHover" class="catalog-box-item__dropdown">
      <div class="catalog-box-item__content" :style="{ width: `${width}px` }">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import eventHub from "@/utils/eventHub";

export default {
  components: {
    BaseIcon,
  },
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  mounted() {
    eventHub.$on("close-nav", () => {
      this.isHover = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.catalog-box-item {
  position: relative;

  &__link {
    align-items: center;
    background: transparent;
    color: $coal;
    display: flex;
    padding: 8px 26px;
    text-decoration: none;

    &:hover {
      background-color: $smoke;
      color: $link-color;
    }
  }

  &__icon {
    margin-right: 16px;
  }

  &__dropdown {
    left: 100%;
    position: absolute;
    top: -18px;
    min-width: max-content;
    padding-left: 9px;
    z-index: 1;

    .catalog-box-item--show & {
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-name: slide-left;
    }
  }

  &__content {
    background: $white;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(47, 50, 63, 0.25);
    padding: 24px 32px;
  }
}
</style>
