<template>
  <modal
    name="delivery-calculate"
    :max-width="512"
    height="auto"
    adaptive
    class="delivery-calculate-modal"
  >
    <vuescroll>
      <modal-layout @close="$modal.hide('delivery-calculate')">
        <nav-tab-list class="delivery-calculate-modal__nav" view="center">
          <nav-tab-item :active="active === 0" view="lg" @click="active = 0">
            СДЭК
          </nav-tab-item>
        </nav-tab-list>
        <base-alert class="delivery-calculate-modal__alert">
          <strong>Доставка стекол и кузовных деталей</strong><br />
          Для транспортировки автомобильных стекол и кузовных деталей
          используется обрешетка. Перед оформлением и оплатой заказа уточняйте
          детали у менджера для расчета стоимости доставки.
        </base-alert>

        <delivery-calculate-form @submit="onSubmit" />

        <div v-if="sum > 0" class="delivery-calculate-modal__price">
          Цена: {{ sum | formatNumber }} р.
        </div>
      </modal-layout>
    </vuescroll>
  </modal>
</template>

<script>
import BaseAlert from "@/components/shared/BaseAlert";
import ModalLayout from "@/components/shared/ModalLayout";
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";
import DeliveryCalculateForm from "./DeliveryCalculateForm";
import vuescroll from "vuescroll";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseAlert,
    ModalLayout,
    NavTabList,
    NavTabItem,
    DeliveryCalculateForm,
    vuescroll,
  },
  filters: {
    formatNumber,
  },
  data() {
    return {
      active: 0,
      sum: 0,
    };
  },
  methods: {
    onSubmit(form) {
      this.sum = 0;
      this.$http
        .post("shop/default/delivery", form)
        .then(({ data }) => {
          this.sum = data.sum;
        })
        .catch(() => {
          this.$toast.error("Не удалось рассчитать. Попробуйте позже.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-calculate-modal {
  &__nav,
  &__alert {
    margin-bottom: 24px;
  }

  &__price {
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
  }
}
</style>
