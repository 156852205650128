<template>
  <page-layout-sidebar
    class="order-list"
    :breadcrumb="breadcrumb"
    :size="3"
    filter-title="Отфильтровать заказы"
  >
    <template #title>Информация о заказах</template>
    <template #nav>
      <nav-tab />
    </template>

    <template #filter>
      <filter-item :is-ready="isFilterReady">
        <template #title>Поиск заказов</template>

        <template #default="{ isReady }">
          <filter-date-range
            name="order-date"
            :is-ready="isReady"
            :selected-date-from="filterSelected.date.from"
            :selected-date-to="filterSelected.date.to"
            @change-from="onChangeDateFrom"
            @change-to="onChangeDateTo"
            @reset="onResetDate"
          />

          <form-group id="filterStatus">
            <template #label> Статус оплаты </template>

            <template #default="props">
              <multiselect
                :id="props.id"
                v-model="filterSelected.paidId"
                :options="[
                  { id: 1, title: 'Оплачен' },
                  { id: 0, title: 'Не оплачен' },
                ]"
                :searchable="false"
                :show-labels="false"
                track-by="id"
                label="title"
                placeholder="Не выбрано"
                @input="onChangeStatus"
              ></multiselect>
            </template>
          </form-group>

          <form-group id="filterNumber">
            <template #label> Номер заказа </template>

            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="filterSelected.number"
                min="1"
                type="number"
                :class="props.inputClass"
                @change="onChangeNumber"
              />
            </template>
          </form-group>

          <form-group id="filterArticle">
            <template #label> Номер детали/артикул </template>

            <template #default="props">
              <input
                :id="props.id"
                v-model.trim="filterSelected.article"
                :class="props.inputClass"
                @change="onChangeArticle"
              />
            </template>
          </form-group>
        </template>
      </filter-item>
    </template>

    <template #filter-action>
      <base-btn
        v-if="hasFilterSelected"
        size="sm"
        view="outline-secondary"
        display="block"
        @click="onClean"
      >
        Сбросить
      </base-btn>
    </template>

    <base-loading v-if="isLoading" />

    <template v-else-if="orderList.length > 0">
      <order-card
        v-for="item in orderList"
        :key="`order-${item.id}`"
        :item="item"
      />
    </template>

    <p v-else-if="hasFilterSelected">
      Ничего не найдено по заданным параметрам.
    </p>

    <p v-else>У вас пока нет заказов.</p>

    <template v-if="pageCount > 1" #footer>
      <the-pagination :total="pageCount" :active="activePage" />
    </template>
  </page-layout-sidebar>
</template>

<script>
import PageLayoutSidebar from "@/components/page/PageLayoutSidebar.vue";
import BaseLoading from "@/components/shared/BaseLoading";
import ThePagination from "@/components/shared/ThePagination";
import FilterDateRange from "@/components/shared/FilterDateRange";
import FormGroup from "@/components/shared/FormGroup";
import BaseBtn from "@/components/shared/BaseBtn";
import FilterItem from "@/components/shared/FilterItem";
import NavTab from "@/modules/account/components/NavTab";
import OrderCard from "../components/OrderCard";
// utils
import formatDate from "@/utils/formatDate";
import parse from "date-fns/parse";
import isEqual from "lodash/isEqual";

export default {
  components: {
    PageLayoutSidebar,
    BaseLoading,
    ThePagination,
    NavTab,
    FilterItem,
    FilterDateRange,
    FormGroup,
    BaseBtn,
    OrderCard,
  },
  metaInfo() {
    return {
      title: this.metaTitle,
    };
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Заказы",
        },
      ],
      orderList: [],
      filterSelected: {
        date: {
          min: null,
          max: null,
        },
        number: null,
        article: null,
        paidId: null,
      },
      isLoading: false,
      pageCount: 0,
      limit: 25,
      isFilterReady: false,
    };
  },
  computed: {
    routeQuery() {
      return this.$route.query;
    },
    hasFilterSelected() {
      const { dateFrom, dateTo, paidId, article, number } = this.routeQuery;

      return !!dateFrom || !!dateTo || !!paidId || !!article || !!number;
    },
    activePage() {
      if (!this.$route.query.page) return 1;

      return parseInt(this.$route.query.page);
    },
    metaTitle() {
      if (this.activePage > 1) {
        return `Заказы — страница ${this.activePage}`;
      }

      return "Заказы";
    },
    params() {
      const params = {};

      params["per-page"] = this.limit;
      params.page = this.activePage;

      const { paidId, dateFrom, dateTo, article, number } = this.routeQuery;

      if (paidId) params.isPaid = paidId;
      if (dateFrom) params.dateFrom = dateFrom;
      if (dateTo) params.dateTo = dateTo;
      if (article) params.article = article;
      if (number) params.number = number;

      return params;
    },
  },
  watch: {
    params(newParams, oldParams) {
      if (!isEqual(newParams, oldParams)) {
        this.onFetchData();
      }
    },
  },
  created() {
    this.onFetchData();
    this.onSetFilter();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;

      this.$http
        .get("shop/order/index", {
          params: this.params,
        })
        .then(({ data, headers }) => {
          this.orderList = data;
          this.isLoading = false;
          this.pageCount = parseInt(headers["x-pagination-page-count"]);
        })
        .catch((error) => {
          this.$toast.error("Не удалось получить список заказов.");
          this.isLoading = false;
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
    onSetFilter() {
      const { dateFrom, dateTo, article, number } = this.routeQuery;

      if (dateFrom) {
        this.filterSelected.date.from = parse(
          dateFrom,
          "yyyy-MM-dd",
          new Date()
        );
      }

      if (dateTo) {
        this.filterSelected.date.to = parse(dateTo, "yyyy-MM-dd", new Date());
      }

      if (article) {
        this.filterSelected.article = article;
      }

      if (number) {
        this.filterSelected.number = number;
      }

      this.isFilterReady = true;
    },
    onResetDate() {
      this.filterSelected.date = {
        from: null,
        to: null,
      };
    },
    onChangeDateFrom(date) {
      this.filterSelected.date.from = date;

      // eslint-disable-next-line no-unused-vars
      const { dateFrom, page, ...query } = this.routeQuery;

      if (date) query.dateFrom = formatDate(date, "yyyy-MM-dd");
      this.$router.push({ query: query }).catch(() => {});
    },
    onChangeDateTo(date) {
      this.filterSelected.date.to = date;

      // eslint-disable-next-line no-unused-vars
      const { dateTo, page, ...query } = this.routeQuery;

      if (date) query.dateTo = formatDate(date, "yyyy-MM-dd");
      this.$router.push({ query: query }).catch(() => {});
    },
    onChangeArticle() {
      const { article: articleId } = this.filterSelected;
      // eslint-disable-next-line no-unused-vars
      const { article, page, ...query } = this.routeQuery;

      if (articleId) query.article = articleId;
      this.$router.push({ query: query }).catch(() => {});
    },
    onChangeNumber() {
      const { number: numberId } = this.filterSelected;
      // eslint-disable-next-line no-unused-vars
      const { number, page, ...query } = this.routeQuery;

      if (numberId) query.number = numberId;
      this.$router.push({ query: query }).catch(() => {});
    },
    onChangeStatus() {
      const { paidId: status } = this.filterSelected;

      // eslint-disable-next-line no-unused-vars
      const { paidId, page, ...query } = this.routeQuery;

      if (status) query.paidId = status.id;
      this.$router.push({ query: query }).catch(() => {});
    },
    onClean() {
      this.$router.push({ query: {} }).catch(() => {});
      this.$eventHub.$emit("reset-order-date");
      this.filterSelected.paidId = null;
      this.filterSelected.number = null;
      this.filterSelected.article = null;
      this.$modal.hide("filter");
    },
  },
};
</script>
