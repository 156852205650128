<template>
  <page-layout-full class="reset-password-view" :breadcrumb="breadcrumb">
    <template #title>Изменение пароля</template>

    <reset-password-form
      class="reset-password-view__form"
      :user-id="userId"
      :code="code"
    />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import ResetPasswordForm from "../components/ResetPasswordForm.vue";

export default {
  components: {
    PageLayoutFull,
    ResetPasswordForm,
  },
  data() {
    return {
      active: 0,
      breadcrumb: [
        {
          title: "Изменение пароля",
        },
      ],
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    code() {
      return this.$route.params.code;
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-view {
  &__form {
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
  }
}
</style>
