<template>
  <router-link :to="to" class="lamp-item">
    <figure class="lamp-item__picture">
      <img
        class="lamp-item__image"
        :src="require(`../../../../assets/light/${image}.jpg`)"
      />
    </figure>
    <div class="lamp-item__title">
      {{ name }}
    </div>

    <div v-if="isLg" class="lamp-item__hover">
      <img
        class="lamp-item__hover-image"
        :src="require(`../../../../assets/light/${image}.jpg`)"
      />
    </div>
  </router-link>
</template>

<script>
// utils
import { mapGetters } from "vuex";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    brand: {
      type: String,
      required: true,
    },
    article: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("mq", {
      isLg: "getIsLg",
    }),
    image() {
      return this.name.replace(/[,/]/g, "_");
    },
    to() {
      if (this.brand) {
        return {
          name: "search-vendors",
          params: {
            town: this.$route.params.town,
            brand: this.brand,
            article: this.article,
          },
        };
      }

      return {
        name: "search-brands",
        params: {
          town: this.$route.params.town,
          article: this.article,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.lamp-item {
  background: $white;
  color: inherit;
  display: block;
  margin-bottom: 10px;
  position: relative;
  text-decoration: none;

  &:hover {
    color: inherit;
  }

  @include media-breakpoint-up(lg) {
    border-top: 1px solid $grey-300;
    border-right: 1px solid $grey-300;
    margin-bottom: 0;

    &:last-child {
      // border-right: 1px solid $grey-300;
    }
  }

  &__picture {
    border: 1px solid $grey-300;
    border-bottom: 0;
    margin-bottom: 0;
    padding: 4px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      border: none;
      padding: 2px;
    }
  }

  &__image {
    @include img-fluid;

    @include media-breakpoint-up(lg) {
      height: 55px;
    }
  }

  &__title {
    background-color: $grey-300;
    font-weight: normal;
    padding: 6px 8px;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      @include font-size(0.75rem);
    }
  }

  &__hover {
    background: $white;
    border: 2px solid $primary;
    bottom: 100%;
    display: none;
    height: 220px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 160px;
    z-index: 10;

    :hover > & {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
}
</style>
