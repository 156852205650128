<template>
  <router-link :to="{ name: 'home' }" class="logo" :class="`logo--${view}`">
    <img class="logo__image" :src="image" :width="width" :height="height" />
  </router-link>
</template>

<script>
// image
import Logo from "@/assets/shared/logo.svg";
import LogoGrey from "@/assets/shared/logo-grey.svg";

export default {
  props: {
    view: {
      type: String,
      default: "standart",
    },
    width: {
      type: Number,
      default: 260,
    },
    height: {
      type: Number,
      default: 47,
    },
  },
  computed: {
    image() {
      return this.view === "standart" ? Logo : LogoGrey;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: block;

  &__image {
    @include img-fluid;

    @include media-breakpoint-down(md) {
      .logo--standart & {
        max-width: 160px;
      }
    }
  }
}
</style>
