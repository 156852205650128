<template>
  <site-action
    class="cart-link"
    tag="router-link"
    :to="{ name: 'cart' }"
    :icon="{ name: 'basket-solid', width: '24', height: '24' }"
  >
    Корзина
    <span v-if="size > 0" class="cart-link__count">{{ size }}</span>
  </site-action>
</template>

<script>
import SiteAction from "./SiteAction";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    SiteAction,
  },
  computed: {
    ...mapGetters("cart", {
      size: "itemsSize",
    }),
  },
};
</script>

<style lang="scss" scoped>
.cart-link {
  position: relative;

  &__count {
    @include font-size(9px);

    align-items: center;
    background: $red;
    border: 2px solid $white;
    border-radius: 50%;
    color: $white;
    display: flex;
    height: 18px;
    justify-content: center;
    position: absolute;
    right: 6px;
    top: -2px;
    width: 18px;
  }
}
</style>
