<template>
  <base-loading v-if="isLoading" />
  <form v-else class="payment-form" @submit.prevent="onSubmit">
    <div class="payment-form__sidebar">
      <vuescroll ref="scrollbar" :ops="scrollOptions">
        <div class="payment-form__list">
          <payment-item
            v-for="{ type, alias, id, title } in paymentList"
            :id="id"
            :key="`${type}-${id}`"
            :type="alias.length > 0 ? alias : type"
            :active="id === paymentForm.method"
            :title="title"
            class="payment-form__item"
            @click="onChangeMethod"
          />
        </div>
      </vuescroll>
    </div>
    <div class="payment-form__price">
      <form-group
        id="paymentPrice"
        :has-error="$v.paymentForm.price.$error"
        class="payment-form__form-group"
        :icon="{ name: 'rouble', width: '10', height: '13' }"
      >
        <template #label>Сумма*</template>
        <template #default="props">
          <input
            :id="props.id"
            v-model.trim="paymentForm.price"
            type="number"
            step="0.01"
            :class="props.inputClass"
            :disabled="isDisabled || isPriceDisabled"
          />
        </template>
        <template #error>Необходимо заполнить поле</template>
      </form-group>

      <base-btn
        type="submit"
        view="secondary"
        :loading="isDisabled"
        display="block"
        class="payment-form__btn"
      >
        {{ value ? "Оплатить" : "Пополнить" }}
      </base-btn>
    </div>
  </form>
</template>

<script>
import vuescroll from "vuescroll";
import BaseLoading from "@/components/shared/BaseLoading";
import FormGroup from "@/components/shared/FormGroup";
import BaseBtn from "@/components/shared/BaseBtn";
import PaymentItem from "./PaymentItem";
// utils
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    vuescroll,
    BaseLoading,
    FormGroup,
    BaseBtn,
    PaymentItem,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    isDisabled: Boolean,
    isPriceDisabled: Boolean,
  },
  data() {
    return {
      paymentForm: {
        price: this.value,
        method: null,
      },
      paymentList: [],
      isLoading: true,
      scrollOptions: {
        bar: {
          size: "3px",
        },
      },
    };
  },
  validations: {
    paymentForm: {
      price: {
        required,
      },
      method: {
        required,
      },
    },
  },
  mounted() {
    this.fetchPaymentLit();
  },
  methods: {
    fetchPaymentLit() {
      this.isLoading = true;

      this.$http
        .get("payment/service/index")
        .then(({ data }) => {
          this.isLoading = false;
          this.paymentList = data.filter((item) => item.enabled === 1);
          this.paymentForm.method = this.paymentList[0].id;
        })
        .catch(() => {
          this.isCountryLoading = false;
          this.$toast.error("Не удалось получить список стран.");
        });
    },
    submit() {
      this.$emit("submit", this.paymentForm);
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    onChangeMethod(value) {
      this.paymentForm.method = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-form {
  @include media-breakpoint-up(md) {
    border-radius: 6px;
    border: 1px solid $grey-300;
    display: grid;
    grid-template-columns: 350px auto;
  }

  &__sidebar {
    @include media-breakpoint-up(md) {
      border-right: 1px solid $grey-300;
      padding: 18px 9px;
    }
  }

  &__list {
    @include media-breakpoint-down(md) {
      display: flex;
    }
  }

  &__item {
    @include media-breakpoint-down(md) {
      flex: 0 0 144px;
    }
  }

  &__price {
    padding: 30px 60px;
  }

  &__form-group {
    max-width: 235px;
    margin: 0 auto 28px;
  }

  &__btn {
    max-width: 206px;
    margin: 0 auto;
  }
}
</style>
