<template>
  <div v-if="list" v-show="isShow" ref="list" class="suggest-list">
    <ul class="suggest-list__list">
      <li v-if="$slots.title" ref="title" class="suggest-list__title">
        <slot name="title"></slot>
      </li>
      <li
        v-for="(item, index) in list"
        :key="`${name}-${index}`"
        :ref="`${name}-${index}`"
        class="suggest-list__item"
        :class="{ 'suggest-list__item--active': pointer === index }"
        @click="$emit('click', item)"
        @mouseenter="$emit('hover')"
      >
        <slot :item="item"></slot>
      </li>
      <li
        v-if="list.length === 0 && !isLoading"
        class="suggest-list__no-options"
      >
        <slot name="empty"> По вашему запросу ничего не найдено. </slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isShow: Boolean,
    isLoading: Boolean,
    pointer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      optimizedHeight: 200,
    };
  },
  computed: {
    optionHeight() {
      const item = this.$refs[`${this.name}-0`];

      return item[0].clientHeight;
    },
    pointerPosition() {
      const { title } = this.$refs;

      const position = this.pointer * this.optionHeight;

      if (title) return position + title.clientHeight;

      return position;
    },
    visibleElements() {
      return this.optimizedHeight / this.optionHeight;
    },
  },
  watch: {
    pointer(newPointer, oldPointer) {
      if (oldPointer > newPointer) {
        this.onBackward();
      } else {
        this.onForward();
      }
    },
  },
  methods: {
    onBackward() {
      if (this.$refs.list.scrollTop >= this.pointerPosition) {
        this.$refs.list.scrollTop = this.pointerPosition;
      }
    },
    onForward() {
      if (
        this.$refs.list.scrollTop <=
        this.pointerPosition - (this.visibleElements - 1) * this.optionHeight
      ) {
        this.$refs.list.scrollTop =
          this.pointerPosition - (this.visibleElements - 1) * this.optionHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.suggest-list {
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 8px 16px rgba(47, 50, 63, 0.25);
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__title {
    @extend %text-nav;
    color: $grey-100;
    padding: 0.5rem 1.8rem;
  }

  &__item {
    cursor: pointer;
    padding: 0.5rem 1.8rem;
    // white-space: nowrap;

    &--active,
    &:hover {
      background: $grey-300;
    }
  }

  &__no-options {
    padding: 0.5rem 1.8rem;
  }
}
</style>
