<template>
  <nav class="base-breadcrumb" aria-label="base-breadcrumb">
    <ol class="base-breadcrumb__list">
      <li class="base-breadcrumb__item">
        <router-link :to="{ name: 'home' }"> Главная </router-link>
      </li>
      <li
        v-for="(item, index) in list"
        :key="`base-breadcrumb-${index}`"
        class="base-breadcrumb__item"
      >
        <router-link v-if="item.to" :to="item.to">{{ item.title }}</router-link>
        <template v-else>{{ item.title }}</template>
      </li>
    </ol>
  </nav>
</template>

<script>
// utils
import { mapGetters } from "vuex";

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("settings", {
      town: "getTown",
    }),
  },
};
</script>

<style lang="scss" scoped>
.base-breadcrumb {
  &__list {
    @include border-radius($breadcrumb-border-radius);
    @include font-size($breadcrumb-font-size);
    background-color: $breadcrumb-bg;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: $breadcrumb-margin-bottom;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  }

  &__item {
    color: $breadcrumb-divider-color;

    @include media-breakpoint-down(md) {
      display: none;

      &:last-child,
      &:nth-last-child(2) {
        display: block;
      }
    }

    & + & {
      padding-left: $breadcrumb-item-padding-x * 2;
      position: relative;

      &::before {
        background: no-repeat 50% 50%;
        background-image: escape-svg(
          url("data:image/svg+xml,<svg width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$breadcrumb-divider-color}'><path fill-rule='evenodd' d='M15.707 11.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L13.586 12 8.293 6.707a1 1 0 011.414-1.414l6 6z' clip-rule='evenodd'/></svg>")
        );
        background-size: 16px 16px;
        color: $breadcrumb-divider-color;
        content: "";
        display: block;
        height: 100%;
        padding-right: $breadcrumb-item-padding-x;
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
      }
    }

    &.active {
      color: $breadcrumb-active-color;
    }
  }
}
</style>
