var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-list-block"},_vm._l((_vm.list),function(item,index){return _c('div',{key:`cross-${index}`,staticClass:"brand-list-block__item"},[_c('div',{staticClass:"brand-list-block__unit brand-list-block__unit--brand"},[_c('div',{staticClass:"brand-list-block__title"},[_vm._v("Бренд")]),_c('div',{staticClass:"brand-list-block__value"},[_vm._v(" "+_vm._s(item.brand)+" ")])]),_c('div',{staticClass:"brand-list-block__unit"},[_c('div',{staticClass:"brand-list-block__title"},[_vm._v("Артикул")]),_c('div',{staticClass:"brand-list-block__value"},[_vm._v(" "+_vm._s(item.article)+" ")])]),(item.title)?_c('div',{staticClass:"brand-list-block__unit"},[_c('div',{staticClass:"brand-list-block__title"},[_vm._v("Наменование")]),_c('div',{staticClass:"brand-list-block__value"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e(),_c('div',{staticClass:"brand-list-block__footer"},[_c('base-btn',{attrs:{"tag":"router-link","view":"outline-primary","size":"sm","to":{
          name: 'search-vendors',
          params: {
            town: _vm.$route.params.town,
            brand: item.brand,
            article: item.article,
          },
        }}},[_vm._v(" Перейти ")])],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }