<template>
  <modal
    name="catalog"
    width="100%"
    height="auto"
    scrollable
    class="catalog-modal"
  >
    <div class="catalog-modal__body">
      <div class="catalog-modal__header">
        <p class="catalog-modal__title">Каталог товаров</p>
        <close-btn @click="$modal.hide('catalog')" />
      </div>
      <div class="catalog-modal__content">
        <catalog-list />
      </div>
    </div>
  </modal>
</template>

<script>
import CloseBtn from "../../shared/CloseBtn";
import CatalogList from "@/modules/catalog/components/nav/CatalogList";

export default {
  components: {
    CloseBtn,
    CatalogList,
  },
  watch: {
    $route() {
      this.$modal.hide("catalog");
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-modal {
  &__header {
    border-bottom: 1px solid $grey-300;
    padding: 16px;
    text-align: center;
  }

  &__title {
    @extend %subtitle;
    margin-bottom: 0;
  }

  &__body {
    min-height: 100vh;
  }

  &__content {
    padding: 0 16px;
  }
}
</style>
