<template>
  <form class="signup-person-form" @submit.prevent="onSubmit">
    <form-group id="signupEmail" :has-error="$v.signupForm.email.$error">
      <template #label>E-mail*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="signupForm.email"
          type="email"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error>Неверный email</template>
    </form-group>

    <password
      id="signupPassword"
      :has-error="$v.signupForm.password.$error"
      :is-disabled="isDisabled"
      :value="signupForm.password"
      @change="onChangePassword"
    />

    <h3 class="signup-person-form__title">
      Контактная информация для доставки
    </h3>

    <form-group id="signupSurname" :has-error="$v.signupForm.surname.$error">
      <template #label>Фамилия*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="signupForm.surname"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="signupName" :has-error="$v.signupForm.name.$error">
      <template #label>Имя*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="signupForm.name"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="signupPatronymic">
      <template #label>Отчество</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="signupForm.patronymic"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-country-city
      name="signup"
      :has-country-error="$v.signupForm.country.$error"
      :has-city-error="$v.signupForm.city.$error"
      is-city-required
      is-country-required
      :is-disabled="isDisabled"
      :city="signupForm.city"
      @change-city="onChangeCity"
      @change-country="onChangeCountry"
    />

    <form-suggestion
      id="signupAddress"
      name="signupAddress"
      :value="signupForm.address"
      :placeholder="!signupForm.city ? 'Сначала выберите страну и город' : ''"
      :locations="locations"
      url="suggest/address"
      :disabled="isDisabled || !signupForm.city || !signupForm.country"
      restrict-value
      :count="3"
      @input="onInputAddress"
      @select="onSelectAddress"
    >
      <template #label>Адрес для доставки</template>
      <template #error>Необходимо заполнить поле</template>
    </form-suggestion>

    <form-group id="signupPhone" :has-error="$v.signupForm.phone.$error">
      <template #label>Телефон*</template>
      <template #default="props">
        <input
          :id="props.id"
          v-model.trim="signupForm.phone"
          v-mask="'# (###) ### ##-##'"
          type="text"
          :class="props.inputClass"
          :disabled="isDisabled"
        />
      </template>
      <template #error> Необходимо заполнить поле </template>
    </form-group>

    <form-group id="registerIsAgreedGroup">
      <form-check id="registerIsAgreed">
        <template #default="props">
          <input
            :id="props.id"
            v-model="signupForm.isAgreed"
            :class="props.inputClass"
            :type="props.type"
            name="registerIsAgreed"
          />
        </template>
        <template #label>
          Я ознакомился с соглашением
          <router-link :to="{ path: '/content/politika-konfidencialnosti' }">
            на обработку персональных данных
          </router-link>
          и принимаю его условия
        </template>
      </form-check>
    </form-group>

    <form-re-captcha
      :has-error="$v.signupForm.reCaptcha.$error"
      @change="onGetToken"
    />

    <footer class="signup-person-form__footer">
      <base-btn
        type="submit"
        :loading="isDisabled"
        :disabled="!signupForm.isAgreed"
      >
        Зарегистироватьcя
      </base-btn>
    </footer>

    <base-alert
      v-if="errorList"
      view="danger"
      class="signup-person-form__alert"
    >
      <template #default="props">
        <ul :class="props.listClass">
          <li v-for="(item, key) in errorList" :key="`error-${key}`">
            {{ item.join(",") }}
          </li>
        </ul>
      </template>
    </base-alert>
  </form>
</template>

<script>
import BaseAlert from "@/components/shared/BaseAlert";
import FormGroup from "@/components/shared/FormGroup";
import FormCountryCity from "@/components/shared/FormCountryCity";
import FormSuggestion from "@/components/shared/FormSuggestion";
import FormCheck from "@/components/shared/FormCheck";
import FormReCaptcha from "@/components/shared/FormReCaptcha";
import BaseBtn from "@/components/shared/BaseBtn";
import Password from "./Password";
// utils
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseAlert,
    FormGroup,
    FormCountryCity,
    FormSuggestion,
    FormCheck,
    FormReCaptcha,
    BaseBtn,
    Password,
  },
  data() {
    return {
      isDisabled: false,
      errorList: null,
      signupForm: {
        type: 0,
        phone: "",
        password: "",
        surname: "",
        name: "",
        patronymic: "",
        country: null,
        city: null,
        address: "",
        email: "",
        reCaptcha: null,
        isAgreed: false,
      },
    };
  },
  computed: {
    ...mapState("settings", {
      appCity: (state) => state.city.data,
    }),
    locations() {
      const locations = [];

      if (this.signupForm.country && this.signupForm.city) {
        locations.push({
          country: this.signupForm.country.title,
          city: this.signupForm.city.title,
        });
      }

      return locations;
    },
  },
  validations: {
    signupForm: {
      phone: {
        required,
      },
      password: {
        required,
      },
      surname: {
        required,
      },
      name: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      email: {
        required,
        email,
      },
      reCaptcha: {
        required,
      },
    },
  },
  watch: {
    appCity: {
      handler(city) {
        this.signupForm.city = city;
      },
      deep: true,
    },
  },
  mounted() {
    this.signupForm.city = this.appCity;
  },
  methods: {
    ...mapActions({
      onSetBaseUser: "account/onSetBaseUser",
      onSetToken: "auth/onSetToken",
    }),
    onChangeCountry(country) {
      this.signupForm.country = country;
    },
    onChangeCity(city) {
      this.signupForm.city = city;
    },
    onChangePassword(password) {
      this.signupForm.password = password;
    },
    onInputAddress(value) {
      this.signupForm.address = value;
    },
    onSelectAddress(payload) {
      this.signupForm.address = payload.value;
    },
    onGetToken(token) {
      this.signupForm.reCaptcha = token;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit() {
      this.isDisabled = true;
      this.errorList = null;

      const { country, city, ...form } = this.signupForm;

      this.$http
        .post("users/registration/register", {
          ...form,
          countryId: country.id,
          townId: city.id,
        })
        .then(({ data }) => {
          this.isDisabled = false;
          this.$toast.success("Вы успешно зарегистрировались.");

          this.onSetToken(data.authKey);
          this.onSetBaseUser({
            id: data.id,
            profile: {
              balanceD: data.balanceD,
              email: data.email,
              name: data.name,
              patronymic: data.patronymic,
              phone: data.phone,
              surname: data.surname,
            },
          });

          this.$eventHub.$emit("start", city.alias);

          setTimeout(() => {
            this.$eventHub.$emit("authorized");
          }, 10);
        })
        .catch(() => {
          this.isDisabled = false;

          // if (response && response.status === 422) this.errorList = response.data.errors;
          this.$toast.error("Не удалось зарегистрироваться.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-person-form {
  &__title {
    @extend %subtitle;

    margin-bottom: 24px;
    margin-top: 48px;
    text-align: center;
  }

  &__alert {
    margin-top: 1.5rem;
  }

  &__footer {
    text-align: center;
  }
}
</style>
