<template>
  <page-layout-full :breadcrumb="breadcrumb" class="account-garage">
    <template #title>
      Список автомобилей
      <base-btn
        class="account-garage__btn"
        @click="
          $modal.show('garage', {
            title: 'Добавить автомобиль в гараж',
            callBack: onCreate,
          })
        "
      >
        Добавить автомобиль
      </base-btn>
    </template>
    <template #nav>
      <nav-tab />
    </template>

    <base-loading v-if="isLoading" />

    <garage-list v-else-if="autoList.length > 0" :list="autoList">
      <template #default="props">
        <garage-list-item :item="props.item">
          <template #default="itemProps">
            <base-btn
              v-if="itemProps.code"
              size="sm"
              tag="a"
              :class="itemProps.btnClass"
              :href="`${laximoPath}auto/lx/catalog?code=${itemProps.code}`"
            >
              Подобрать запчасти
            </base-btn>
            <base-btn
              size="sm"
              type="button"
              :class="itemProps.btnClass"
              view="outline-danger"
              :loading="isDeleting"
              :icon="{
                name: 'delete-outline',
                fill: '#c62925',
                width: '20',
                height: '20',
              }"
              @click="
                $modal.show('delete-alert', {
                  title: 'Убрать автомобиль из гаража?',
                  callBack: () => onRemove(itemProps.id),
                })
              "
            >
              Удалить
            </base-btn>
            <base-btn
              size="sm"
              type="button"
              :class="itemProps.btnClass"
              view="outline-primary"
              @click="
                $modal.show('garage', {
                  title: 'Изменить автомобиль',
                  callBack: onUpdate,
                  value: props.item,
                })
              "
            >
              Изменить
            </base-btn>
          </template>
        </garage-list-item>
      </template>
    </garage-list>

    <template v-else>
      <p>У вас пока нет автомобилей.</p>
    </template>

    <garage-modal />
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import BaseBtn from "@/components/shared/BaseBtn";
import NavTab from "@/modules/account/components/NavTab";
import GarageModal from "../components/GarageModal";
import GarageList from "../components/GarageList";
import GarageListItem from "../components/GarageListItem";

export default {
  components: {
    PageLayoutFull,
    BaseBtn,
    NavTab,
    GarageModal,
    GarageList,
    GarageListItem,
    BaseLoading,
  },
  metaInfo: {
    title: "Гараж",
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Гараж",
        },
      ],
      autoList: [],
      isLoading: true,
      isDeleting: false,
    };
  },
  computed: {
    laximoPath() {
      return process.env.VUE_APP_LAXIMO_PATH;
    },
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.$http
        .get("auto/auto/index")
        .then(({ data }) => {
          this.isLoading = false;
          this.autoList = data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error("Не удалось получить список гаражей.");

          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
    onCreate(form) {
      this.$modal.hide("garage");

      this.$http
        .post("auto/auto/create", form)
        .then(() => {
          this.$toast.success("Автомобиль добавлен.");
          setTimeout(() => {
            this.onFetchData();
          }, 50);
        })
        .catch(() => {
          this.$toast.error(
            "Не удалось сохранить автомобиль. Попробуйте позже."
          );
        });
    },
    onRemove(id) {
      this.isDeleting = true;
      this.$modal.hide("delete-alert");
      this.$http
        .post("auto/auto/delete", null, {
          params: {
            id: id,
          },
        })
        .then(({ data }) => {
          if (data.status === "ok") {
            this.$toast.success("Автомобиль удален.");
            setTimeout(() => {
              this.onFetchData();
            }, 50);
            setTimeout(() => {
              this.isDeleting = false;
            }, 150);
          }
        })
        .catch(() => {
          this.isDeleting = false;
          this.$toast.error(
            "Не удалось удалить автомобиль из гаража. Попробуйте позже."
          );
        });
    },
    onUpdate({ id, ...form }) {
      this.$modal.hide("garage");

      this.$http
        .post("auto/auto/update", form, {
          params: {
            id: id,
          },
        })
        .then(() => {
          this.$toast.success("Автомобиль обновлен.");
          setTimeout(() => {
            this.onFetchData();
          }, 50);
        })
        .catch(() => {
          this.$toast.error(
            "Не удалось сохранить изменения. Попробуйте позже."
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-garage {
  &__btn {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-left: auto;
      margin-top: 0;
    }
  }
}
</style>
