<template>
  <component
    :is="tag"
    :to="to"
    class="base-btn"
    :class="[
      `base-btn--${view}`,
      `base-btn--${size}`,
      display ? `base-btn--${display}` : '',
      loading ? 'base-btn--loading' : '',
      disabled && !loading ? 'base-btn--disabled' : '',
      icon ? 'has-icon' : '',
      icon && !$slots.default ? 'base-btn--icon' : '',
      icon && icon.position ? `icon--${icon.position}` : '',
      rotate ? 'has-rotate' : '',
    ]"
    :disabled="(disabled || loading) && tag === 'button'"
    @click="$emit('click')"
  >
    <img
      v-if="loading"
      class="base-btn__loading"
      width="24"
      height="24"
      :src="image"
    />
    <base-icon
      v-if="icon"
      class="base-btn__icon"
      :name="icon.name"
      :width="icon.width"
      :height="icon.height"
      :fill="icon.fill"
    />
    <span class="base-btn__text">
      <slot></slot>
    </span>
  </component>
</template>

<script>
import BaseIcon from "./BaseIcon";
// image
import Loading from "@/assets/shared/loading.svg";
import LoadingGrey from "@/assets/shared/loading-grey.svg";
import LoadingOrange from "@/assets/shared/loading-orange.svg";

export default {
  components: {
    BaseIcon,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    view: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "df",
    },
    display: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "button",
    },
    rotate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image() {
      if (["outline-secondary", "outline-danger"].includes(this.view))
        return LoadingGrey;
      if (this.view === "secondary") return LoadingOrange;

      return Loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-btn {
  @extend %btn;

  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
  position: relative;
  text-decoration: none;
  transition: background-color 250ms ease-in;

  &.has-icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    &.icon--right {
      flex-direction: row-reverse;
    }
  }

  &:disabled:not(.base-btn--loading),
  &.base-btn--disabled {
    background-color: $grey-300;
    border-color: $grey-300;
    color: $grey-100;
    cursor: default;
  }

  &__loading {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    margin-right: size(10px);
    transition: transform 250ms ease;

    .has-rotate & {
      transform: rotate(-180deg);
    }

    .icon--right & {
      margin-left: size(10px);
      margin-right: 0;
    }

    .base-btn--primary &,
    .base-btn--secondary & {
      fill: $white;
    }

    .base-btn--outline-primary & {
      fill: $mint-100;
    }

    .base-btn--outline-secondary & {
      fill: $coal;
    }

    :hover > &,
    :active > & {
      fill: $white;
    }

    .base-btn--disabled & {
      fill: $grey-100;
    }

    .base-btn--loading & {
      opacity: 0;
      visibility: hidden;
    }

    .base-btn--icon & {
      margin: 0;
    }
  }

  &__text {
    .base-btn--loading & {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.base-btn--icon {
  align-items: center;
  min-width: auto;
  height: auto;
  padding: size(5px) size(13px);
}

.base-btn--df {
  padding: 0.94rem 1.5rem;

  &.has-icon {
    padding-bottom: 0.69rem;
    padding-top: 0.69rem;
  }
}

.base-btn--sm {
  padding: 0.625rem 1rem;

  &.has-icon {
    padding-bottom: 0.375rem;
    padding-top: 0.375rem;
  }
}

.base-btn--block {
  display: block;
  width: 100%;

  & + & {
    margin-top: 1rem;
  }
}

.base-btn--primary {
  background-color: $mint-100;
  border-color: $mint-100;
  border-radius: size(6px);
  color: $white;

  &:hover,
  &:active {
    background-color: darken($mint-100, 5%);
    border-color: darken($mint-100, 5%);
    color: $white;
  }
}

.base-btn--secondary {
  background-color: $melon-100;
  border-color: $melon-100;
  border-radius: size(4px);
  color: $white;

  &:hover,
  &:active {
    background-color: darken($melon-100, 5%);
    border-color: darken($melon-100, 5%);
    color: $white;
  }
}

.base-btn--light {
  background-color: $grey-300;
  border-color: $grey-300;
  border-radius: size(6px);
  color: $coal;

  &:hover,
  &:active {
    background-color: darken($mint-100, 5%);
    border-color: darken($mint-100, 5%);
    color: $white;
  }
}

.base-btn--outline-primary {
  background-color: $white;
  border-color: $mint-100;
  border-radius: size(4px);
  color: $mint-100;

  &:hover,
  &:active {
    background-color: darken($mint-100, 5%);
    border-color: darken($mint-100, 5%);
    color: $white;
  }
}

.base-btn--outline-secondary {
  background-color: $white;
  border-color: $grey-200;
  border-radius: size(6px);
  color: $coal;

  &:hover,
  &:active {
    background-color: $grey-300;
    border-color: $grey-200;
    color: $coal;
  }
}

.base-btn--outline-danger {
  background-color: $white;
  border-color: $red;
  border-radius: size(6px);
  color: $red;

  &:hover,
  &:active {
    background-color: $red;
    border-color: $red;
    color: $white;
  }
}
</style>
