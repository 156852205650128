<template>
  <component :is="tag" :to="to" class="site-action" @click="$emit('click')">
    <base-icon-block :icon="icon" view="column" align="center">
      <slot></slot>
    </base-icon-block>
  </component>
</template>

<script>
import BaseIconBlock from "../../shared/BaseIconBlock";

export default {
  components: {
    BaseIconBlock,
  },
  props: {
    to: {
      type: Object,
      default: null,
    },
    icon: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      default: "a",
    },
  },
};
</script>

<style lang="scss" scoped>
.site-action {
  @extend %text-nav;
  color: $stone;
  cursor: pointer;
  fill: $grey-100;
  text-decoration: none;

  &.router-link-exact-active,
  &.site-action--active,
  &:hover,
  .base-popup--show & {
    fill: $mint-100;
    color: $mint-100;
  }
}
</style>
