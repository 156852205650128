<template>
  <div class="modal-layout">
    <div class="modal-layout__body">
      <close-btn position="top-right" @click="$emit('close')" />

      <div class="modal-layout__content">
        <slot></slot>
      </div>

      <div class="modal-layout__footer">
        <slot name="btns" btnClass="modal-layout__btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CloseBtn from "./CloseBtn";

export default {
  components: {
    CloseBtn,
  },
};
</script>

<style lang="scss" scoped>
.modal-layout {
  &__body {
    padding: 36px 36px 44px;
  }

  &__btn {
    & + & {
      margin-left: 1rem;
      min-width: 150px;
    }
  }
}
</style>
