<template>
  <div v-if="isLogged" class="base-user">
    <div class="base-user__phone">{{ phone }}</div>
    <button class="base-user__logout" type="button" @click="onLogout">
      <base-icon name="exit-outline" width="24" height="24" />
    </button>
  </div>
</template>

<script>
import BaseIcon from "../../shared/BaseIcon";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BaseIcon,
  },
  computed: {
    ...mapGetters({
      phone: "account/getPhone",
      isLogged: "auth/isLogged",
    }),
  },
  methods: {
    onLogout() {
      this.$eventHub.$emit("unauthorized");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-user {
  align-items: center;
  display: flex;

  &__phone {
    @extend %subtitle;
  }

  &__logout {
    @extend %btn;

    margin-left: auto;
  }
}
</style>
