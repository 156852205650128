/**
 *
 * @param {*} startYear
 * @param {*} endYear
 */
const renderYears = (startYear, endYear) => {
  const years = [];

  while (endYear >= startYear) {
    years.push(endYear--);
  }

  return years;
};

export default renderYears;
