const onOpenLivechat = (event) => {
  if (event) {
    event.preventDefault();
  }

  const button = document.getElementById("supportTrigger");

  if (button) button.click();
};

export default onOpenLivechat;
