<template>
  <div class="base-notify">
    <div class="base-notify__body">
      <base-icon
        v-if="icon"
        class="base-notify__icon"
        :name="icon"
        width="24"
        height="24"
      />
      <slot></slot>
    </div>

    <slot name="btn" btnClass="base-notify__btn"></slot>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  computed: {
    icon() {
      return "attention-treengle-solid";
    },
  },
};
</script>

<style lang="scss" scoped>
.base-notify {
  background: #fdeaea;
  padding: 8px 16px;

  @include media-breakpoint-up(sm) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    color: $red;
    display: flex;

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
    }
  }

  &__icon {
    margin-right: 8px;
    flex: 0 0 24px;
    fill: #c62925;
  }

  &__btn {
    @include media-breakpoint-down(xs) {
      display: block;
    }
  }
}
</style>
