<template>
  <page-layout-full class="address-view">
    <template #title>
      Адреса пунктов выдачи транспортных компаний и магазинов
    </template>

    <div class="address-view__description">
      <h3>
        Выбранный город:
        <a class="link-action" @click="$modal.show('city-alert')">{{
          cityName
        }}</a>
      </h3>

      <base-btn @click="$modal.show('delivery-calculate')">
        Расчитать стоимость доставки
      </base-btn>
    </div>

    <base-loading v-if="isLoading && !isLoadMore" />

    <address-list v-else :list="list">
      <template #default="{ item }">
        <address-list-item :item="item" />
      </template>
    </address-list>

    <footer class="address-view__footer">
      <div v-if="activePage < total" class="address-view__loadmore">
        <base-btn
          view="outline-secondary"
          :loading="isLoadMore"
          :disabled="isLoading"
          @click="onLoadMore"
        >
          Показать еще
        </base-btn>
      </div>

      <the-pagination
        class="address-view__pagination"
        :total="total"
        :active="activePage"
      />
    </footer>

    <delivery-calculate-modal />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import BaseLoading from "@/components/shared/BaseLoading.vue";
import ThePagination from "@/components/shared/ThePagination.vue";
import BaseBtn from "@/components/shared/BaseBtn.vue";
import DeliveryCalculateModal from "@/modules/delivery/DeliveryCalculateModal.vue";
import AddressList from "../components/AddressList.vue";
import AddressListItem from "../components/AddressListItem.vue";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    PageLayoutFull,
    BaseLoading,
    ThePagination,
    BaseBtn,
    AddressList,
    AddressListItem,
    DeliveryCalculateModal,
  },
  data() {
    return {
      isLoading: true,
      isLoadMore: false,
      total: 0,
      limit: 20,
      list: [],
    };
  },
  computed: {
    ...mapGetters("settings", {
      cityId: "cityId",
      cityName: "cityName",
    }),
    activePage() {
      if (!this.$route.query.page) return 1;

      return parseInt(this.$route.query.page);
    },
    params() {
      const params = {};

      params["per-page"] = this.limit;
      params.page = this.activePage;
      params.townId = this.cityId;

      return params;
    },
  },
  watch: {
    activePage(newPage, oldPage) {
      if (newPage !== oldPage) this.onFetchData();
    },
    cityId() {
      this.onFetchData();
    },
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;

      this.$http
        .get("https://avtovelikan.ru/api/delivery/delivery/offices", {
          params: this.params,
        })
        .then(({ data, headers }) => {
          this.total = parseInt(headers["x-pagination-page-count"]);

          if (this.isLoadMore) {
            this.list = [...this.list, ...data];
            this.isLoadMore = false;
          } else {
            this.list = data;
          }

          this.isLoading = false;
        })
        .catch(() => {
          this.$toast.error("Не удалось получить список адресов.");
          this.isLoading = false;
          this.isLoadMore = false;
        });
    },
    onLoadMore() {
      this.isLoadMore = true;

      this.$nextTick(() => {
        const query = Object.assign({}, this.$route.query);
        query.page = this.activePage + 1;

        this.$router.push({ query: query });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.address-view {
  &__description {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 1rem;
  }

  &__pagination {
    border-top: 1px solid $grey-300;
    margin-top: 1.8rem;
    padding-top: 1rem;
  }

  &__loadmore {
    margin-top: 1.8rem;
    text-align: center;
  }
}
</style>
