<template>
  <section class="featured-news-list">
    <header-tab-list>
      <template #title>Новости и обзоры</template>
      <template #nav>
        <slot name="nav"></slot>
      </template>
      <template #extra>
        <router-link
          :to="{ name: 'article-list' }"
          class="featured-news-list__link"
        >
          Все новости
          <base-icon
            name="arrow-right"
            width="24"
            height="24"
            class="featured-news-list__icon"
          />
        </router-link>
      </template>
    </header-tab-list>

    <base-loading v-if="isLoading" />

    <splide v-else-if="list && list.length > 0" ref="slider" :options="options">
      <splide-slide v-for="item in list" :key="`news-${item.slug}`">
        <news-card :item="item" />
      </splide-slide>
    </splide>
  </section>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading";
import HeaderTabList from "@/components/shared/HeaderTabList";
import BaseIcon from "@/components/shared/BaseIcon";
import NewsCard from "@/modules/article/components/NewsCard";
// uitls
import { mapGetters } from "vuex";

export default {
  components: {
    BaseLoading,
    HeaderTabList,
    BaseIcon,
    NewsCard,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    isLoading: Boolean,
  },
  computed: {
    ...mapGetters("mq", {
      isMd: "getIsMd",
      isLg: "getIsLg",
    }),
    options() {
      const options = {
        type: "slide",
        perPage: 2,
        gap: "20px",
        fixedWidth: 265,
        pagination: false,
      };

      return options;
    },
  },
  watch: {
    list() {
      if (this.$refs.slider) this.$refs.slider.remount();
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-news-list {
  &__link {
    text-decoration: none;
  }

  &__icon {
    fill: $link-color;

    :hover > & {
      fill: $link-hover-color;
    }
  }
}
</style>
