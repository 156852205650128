var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"vin-search-modal",attrs:{"name":"vin","width":"100%","height":"100%","transition":"none","classes":['vin-search-modal__dialog', 'bg-black']},on:{"before-open":_vm.onBeforeOpen,"before-close":_vm.onBeforeClose}},[_c('div',{staticClass:"vin-search-modal__content"},[_c('close-btn',{staticClass:"vin-search-modal__close",attrs:{"position":"custom"},on:{"click":function($event){return _vm.$modal.hide('vin')}}}),(_vm.isShow)?_c('splide',{attrs:{"options":{
        type: 'fade',
        perPage: 1,
        gap: 0,
        classes: {
          pagination: 'splide__pagination vin-search-modal__pagination',
        },
        arrows: false,
      }}},[_c('splide-slide',[_c('div',{staticClass:"vin-search-modal__item"},[_c('img',{staticClass:"vin-search-modal__image",attrs:{"src":require("../../../assets/pages/home__certificate.jpg"),"width":"270","height":"380"}})])]),_c('splide-slide',[_c('div',{staticClass:"vin-search-modal__item"},[_c('img',{staticClass:"vin-search-modal__image",attrs:{"src":require("../../../assets/pages/home__pts.jpg"),"width":"270","height":"380"}})])])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }