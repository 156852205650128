<template>
  <modal name="city-alert" :max-width="360" adaptive height="auto">
    <modal-alert-layout @close="$modal.hide('city-alert')">
      <p>
        Внимание! Смена города приведет к удалению товаров из корзины. Вам
        потребуется выполнить повторный поиск товаров с актуальными ценами и
        сроками доставки.
      </p>

      <template #btns="props">
        <base-btn
          view="outline-danger"
          size="sm"
          :class="props.btnClass"
          @click="onChangeCity"
        >
          Сменить город
        </base-btn>
        <base-btn
          size="sm"
          :class="props.btnClass"
          @click="$modal.hide('city-alert')"
        >
          Отмена
        </base-btn>
      </template>
    </modal-alert-layout>
  </modal>
</template>

<script>
import ModalAlertLayout from "@/components/shared/ModalAlertLayout";
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    ModalAlertLayout,
    BaseBtn,
  },
  methods: {
    onChangeCity() {
      this.$modal.show("city");
      this.$modal.hide("city-alert");
    },
  },
};
</script>
