<template>
  <form class="search-form" @submit.prevent="onSubmit">
    <div class="search-form__group">
      <form-group
        id="search"
        :show-label="false"
        class="search-form__form-group"
        :icon="{ name: 'search', width: '16', height: '16' }"
        :loading="isLoading"
        autocomplete="off"
      >
        <template #label>
          Поиск по артикулу (номеру детали) или по названию
        </template>
        <template #default="props">
          <input
            :id="props.id"
            v-model.trim="searchQuery"
            type="text"
            class="search-form__control"
            aria-label="Поисковая строка"
            placeholder="Поиск по артикулу (номеру детали) или по названию"
            :disabled="props.disabled"
            autocomplete="off"
            @input="fetchSuggestItemList"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.up="onUp"
            @keyup.down="onDown"
            @keyup.enter="onEnter"
          />
        </template>
        <template #suggest>
          <suggest-list
            :list="suggestionList"
            :is-show="searchQuery !== '' && isFocus"
            name="city"
            :pointer="pointer"
            :is-loading="isLoading"
            @click="onSelect"
            @hover="onHover"
          >
            <template #default="props">
              <span
                :inner-html.prop="props.item.value | higlightQuery(searchQuery)"
              ></span>
            </template>
            <template #empty> Для продолжения поиска нажмите Enter </template>
          </suggest-list>
        </template>
      </form-group>
      <button class="search-form__btn" type="submit" aria-label="Поиск">
        <template v-if="isXXl">Найти</template>
        <base-icon v-else name="search-outline" width="24" height="24" />
      </button>
    </div>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import BaseIcon from "@/components/shared/BaseIcon";
import SuggestList from "@/components/shared/SuggestList";
// utils
import { mapGetters } from "vuex";
import suggestMixin from "@/utils/suggestMixin";

export default {
  components: {
    FormGroup,
    BaseIcon,
    SuggestList,
  },
  mixins: [suggestMixin],
  data() {
    return {
      searchQuery: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isXXl: "mq/getIsXXl",
    }),
  },
  watch: {
    "$route.params"(val) {
      const { article } = val;

      if (article) {
        this.searchQuery = article;
      } else {
        this.searchQuery = "";
      }
    },
  },
  mounted() {
    this.onSetSearchQuery(this.$route.params);
  },
  methods: {
    onSetSearchQuery(params) {
      const { article } = params;

      if (article) {
        this.searchQuery = article;
        this.fetchSuggestItemList();
      } else {
        this.searchQuery = "";
      }
    },
    fetchSuggestItemList() {
      this.isLoading = true;

      const params = {
        query: this.searchQuery,
      };

      this.$http
        .get("catalog/search/possible-item-search", {
          params,
        })
        .then(({ data }) => {
          this.isLoading = false;
          this.suggestionList = data;
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.error("Поиск не работает.");
        });
    },
    onEnter() {
      if (this.pointer > -1) {
        const item = this.suggestionList[this.pointer];
        if (item) this.onSelect(item);
      } else {
        this.onSubmit();
      }
    },
    onSelect(item) {
      this.$router.push({
        name: "search-vendors",
        params: {
          town: this.$route.params.town,
          brand: item.brand,
          article: item.article,
        },
      });
      this.pointer = -1;
      this.searchQuery = null;
      this.suggestionList = [];
    },
    onSubmit() {
      this.$router
        .push({
          name: "search-brands",
          params: {
            town: this.$route.params.town,
            article: this.searchQuery,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.search-form {
  &__label {
    @include visually-hidden;
  }

  &__group {
    background: linear-gradient(
      180deg,
      #ffc844 13.02%,
      #ffbe11 36.46%,
      #ffbb00 44.27%
    );
    border-radius: 6px;
    display: flex;
    padding: 3px;
  }

  &__form-group {
    margin-bottom: 0;
    width: 100%;
  }

  &__control {
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 9px 8px 9px 32px;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }

  &__btn {
    @extend %btn;
    color: $white;
    padding: 0 18px;
  }
}
</style>
