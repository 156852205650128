<template>
  <div class="base-panel">
    <base-container>
      <div class="base-panel__row">
        <site-action
          class="base-panel__action"
          :class="{ 'site-action--active': isMenu }"
          :icon="{
            name: isMenu ? 'close-outline' : 'menu-solid',
            width: '24',
            height: '24',
          }"
          @click="$emit('menu')"
        >
          Меню
        </site-action>

        <site-action
          v-if="!isHome"
          class="base-panel__action"
          tag="router-link"
          :to="{ name: 'home' }"
          :icon="{ name: 'home-solid', width: '24', height: '24' }"
        >
          Главная
        </site-action>

        <site-action
          class="base-panel__action"
          :icon="{ name: 'apps-solid', width: '24', height: '24' }"
          @click="$modal.show('catalog')"
        >
          Каталог
        </site-action>

        <cart-link class="base-panel__action" />

        <site-action
          v-if="feedback.liveEnabled"
          class="base-panel__action"
          :icon="{ name: 'notification-solid', width: '24', height: '24' }"
          @click="onOpenLivechat($event)"
        >
          Чат
        </site-action>
      </div>
    </base-container>
  </div>
</template>

<script>
import SiteAction from "./SiteAction";
import CartLink from "./CartLink";
import BaseContainer from "../../shared/BaseContainer";
// utils
import { mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    SiteAction,
    CartLink,
    BaseContainer,
  },
  computed: {
    ...mapState("settings", {
      isMenu: (state) => state.isMenu,
      feedback: (state) => state.feedback,
    }),
    isHome() {
      return this.$route.name === "home";
    },
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.base-panel {
  @extend %text-details;

  background: $smoke;
  box-shadow: 0 -2px 8px rgb(0 0 0 / 15%);
  bottom: 0;
  left: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  position: fixed;
  width: 100%;
  z-index: $zindex-base-panel;

  &__row {
    display: flex;
    justify-content: space-between;
  }
}
</style>
