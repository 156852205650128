var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list-item",class:[`product-list-item--${_vm.view}`]},[_c('router-link',{staticClass:"product-list-item__link",attrs:{"to":{
      name: 'item',
      params: { town: _vm.$route.params.town, id: `${_vm.item.url}-${_vm.item.id}` },
    }}},[_c('figure',{staticClass:"product-list-item__picture"},[_c('base-image',{staticClass:"product-list-item__image",attrs:{"url":_vm.imageSrc,"width":_vm.imageSize,"height":_vm.imageSize}})],1),_c('div',{staticClass:"product-list-item__body"},[(_vm.item.brand || _vm.item.article)?_c('div',{staticClass:"product-list-item__desc"},[(_vm.item.brand)?_c('div',{staticClass:"product-list-item__brand"},[_vm._v(" "+_vm._s(_vm.item.brand)+" ")]):_vm._e(),(_vm.item.article)?_c('div',{staticClass:"product-list-item__article"},[_vm._v(" "+_vm._s(_vm.item.article)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"product-list-item__title"},[_vm._v(" "+_vm._s(_vm.item.fulltitle)+" ")])]),(_vm.item.quantity > 0)?_c('div',{staticClass:"product-list-item__deliver"},[(_vm.item.quantity > 0)?_c('base-badge',{attrs:{"title":"Количество:"}},[_vm._v(" "+_vm._s(_vm.item.quantity)+" шт ")]):_vm._e()],1):_vm._e()]),(_vm.item.brand && _vm.item.article)?_c('div',{staticClass:"product-list-item__action"},[_c('base-btn',{staticClass:"product-list-item__btn",attrs:{"size":"sm","tag":"router-link","to":{
        name: 'search-vendors',
        params: {
          town: _vm.$route.params.town,
          brand: _vm.item.brand,
          article: _vm.item.article,
        },
      }}},[_vm._v(" Найти у поставщиков ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }