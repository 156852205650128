var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"catalog-box-content"},[_c('h4',{staticClass:"catalog-box-content__title"},[_vm._t("title",null,{"linkClass":"catalog-box-content__title-link"})],2),_c('div',{staticClass:"catalog-box-content__body"},[_c('div',{staticClass:"row"},_vm._l((_vm.splittedList),function(chunk,chunkIndex){return _c('div',{key:`chunk-${chunkIndex}`,staticClass:"col"},[_c('ul',{staticClass:"catalog-box-content__list"},[(_vm.isAutoparts && chunkIndex === 0)?_c('li',{staticClass:"catalog-box-content__item"},[_c('a',{staticClass:"catalog-box-content__link text-bold",attrs:{"href":`${_vm.laximoPath}auto`}},[_vm._v(" Каталоги для подбора автозапчастей ")])]):_vm._e(),_vm._l((chunk),function(item,index){return _c('li',{key:`item-${chunkIndex}-${index}`,staticClass:"catalog-box-content__item"},[_c('router-link',{staticClass:"catalog-box-content__link",attrs:{"to":{
                name: 'item-list',
                params: {
                  town: _vm.$route.params.town,
                  category: _vm.category,
                  subCategory: item.url,
                },
              }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),(
              _vm.isAutoparts &&
              chunkIndex === _vm.splittedList.length - 1 &&
              _vm.feedback.liveEnabled
            )?_c('li',{staticClass:"catalog-box-content__item catalog-box-content__item--has-button"},[_c('base-btn',{staticClass:"text-nowrap",attrs:{"size":"sm","view":"secondary"},on:{"click":_vm.onClick}},[_vm._v(" Помощь в подборе → ")])],1):_vm._e()],2)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }