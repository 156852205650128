<template>
  <div v-if="isShow" class="base-alert" :class="`base-alert--${view}`">
    <base-icon
      v-if="icon"
      class="base-alert__icon"
      :name="icon"
      width="24"
      height="24"
    />

    <div class="base-alert__body">
      <slot listClass="base-alert__list"></slot>
    </div>

    <button
      class="base-alert__close"
      type="button"
      aria-label="Закрыть"
      @click="onClick"
    >
      <base-icon
        class="base-alert__close-icon"
        name="close-outline"
        width="24"
        height="24"
      />
    </button>
  </div>
</template>

<script>
import BaseIcon from "./BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    view: {
      type: String,
      default: "warning",
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    icon() {
      switch (this.view) {
        case "warning":
          return "attention-solid";
        case "danger":
          return "attention-treengle-solid";
        default:
          return null;
      }
    },
  },
  methods: {
    onClick() {
      this.isShow = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-alert {
  border: 1px solid transparent;
  border-radius: 6px;
  display: flex;
  padding: 8px 16px;
  position: relative;

  & + & {
    margin-top: 1rem;
  }

  &--warning {
    border-color: $yellow;
    background-color: $melon-10;
  }

  &--danger {
    border-color: $red;
    background-color: #fdeaea;
  }

  &--success {
    border-color: $mint-100;
    background-color: $mint-20;
  }

  &--light {
    border-color: $mint-100;
    background-color: $white;
  }

  &__close {
    @extend %btn;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__close-icon {
    .base-alert--warning & {
      fill: $melon-70;
    }

    .base-alert--danger & {
      fill: #c8b4b4;
    }

    .base-alert--success & {
      fill: #aacfca;
    }

    .base-alert--light & {
      fill: #aacfca;
    }
  }

  &__icon {
    margin-right: 8px;
    flex: 0 0 24px;
    max-width: 24px;

    .base-alert--danger & {
      fill: #c62925;
    }
  }

  &__list {
    @include list-unstyled;
    @extend %text-nav;
    margin-bottom: 0;
  }
}
</style>
