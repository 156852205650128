<template>
  <div class="garage-list">
    <div
      v-for="item in list"
      :key="`auto-${item.id}`"
      class="garage-list__item"
    >
      <slot :item="item"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.garage-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
