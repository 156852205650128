import { render, staticRenderFns } from "./BaseLoading.vue?vue&type=template&id=84d36a88&scoped=true&"
var script = {}
import style0 from "./BaseLoading.vue?vue&type=style&index=0&id=84d36a88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84d36a88",
  null
  
)

export default component.exports