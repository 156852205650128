<template>
  <account-form-layout class="password-form" @submit="onSubmit">
    <p>
      Введите вашу почту и вам будет отправлена ссылка для изменения пароля.
    </p>
    <div class="row">
      <div class="col-lg-3">
        <form-group
          id="passwordPhone"
          :has-error="$v.passwordForm.email.$error"
        >
          <template #label>Почта*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="passwordForm.email"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Необходимо заполнить поле </template>
        </form-group>
      </div>
    </div>

    <template #footer>
      <base-btn type="submit" :loading="isDisabled">Сбросить</base-btn>
    </template>
  </account-form-layout>
</template>

<script>
import AccountFormLayout from "@/modules/account/components/AccountFormLayout.vue";
import FormGroup from "@/components/shared/FormGroup.vue";
import BaseBtn from "@/components/shared/BaseBtn.vue";
// utils
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    AccountFormLayout,
    FormGroup,
    BaseBtn,
  },
  props: {
    isDisabled: Boolean,
  },
  data() {
    return {
      passwordForm: {
        email: "",
      },
    };
  },
  validations: {
    passwordForm: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("submit", this.passwordForm.email);
      }
    },
  },
};
</script>
