<template>
  <div class="form-country-city">
    <form-group :id="`${name}Country`" :has-error="hasCountryError">
      <template #label>
        Страна
        <template v-if="isCountryRequired">*</template>
      </template>
      <template #default="props">
        <multiselect
          :id="props.id"
          v-model="mutableCountry"
          :options="countryList"
          :searchable="false"
          :show-labels="false"
          track-by="id"
          label="title"
          placeholder="Выберите страну"
          :disabled="isDisabled"
          @input="onChangeCountry"
        >
          <span slot="noOptions">
            <template v-if="isCountryLoading">Подождите...</template>
            <template v-else>Список стран пуст</template>
          </span>
          <span slot="noResult">
            Ой, ничего не найдено. Попробуйте ввести иначе.
          </span>
        </multiselect>
      </template>
      <template #error>Необходимо заполнить поле </template>
    </form-group>

    <form-group :id="`${name}BaseCity`" :has-error="hasCityError">
      <template #label>
        Город
        <template v-if="isCityRequired">*</template>
      </template>
      <template #default="props">
        <multiselect
          :id="props.id"
          v-model="mutableCity"
          :options="cityList"
          searchable
          :show-labels="false"
          :internal-search="false"
          track-by="id"
          label="title"
          placeholder="Выберите город"
          :disabled="isDisabled"
          @search-change="fetchCityList"
          @input="onChangeCity"
        >
          <span slot="noOptions">
            <template v-if="isCityLoading">Подождите...</template>
            <template v-else>Список городов пуст</template>
          </span>
          <span slot="noResult">
            Ой, ничего не найдено. Попробуйте ввести иначе.
          </span>
        </multiselect>
      </template>
      <template #error>Необходимо заполнить поле </template>
    </form-group>
  </div>
</template>

<script>
import FormGroup from "./FormGroup";
// utils
import sortBy from "lodash/sortBy";

export default {
  components: {
    FormGroup,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    hasCountryError: Boolean,
    hasCityError: Boolean,
    isCityRequired: Boolean,
    isCountryRequired: Boolean,
    isDisabled: Boolean,
    country: {
      type: Object,
      default: () => null,
    },
    city: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      mutableCity: this.city,
      mutableCountry: this.country,
      countryList: [],
      cityList: [],
      isCountryLoading: false,
      isCityLoading: false,
    };
  },
  computed: {
    countryId() {
      if (this.mutableCountry) return this.mutableCountry.id;

      return null;
    },
  },
  watch: {
    country: {
      handler(country) {
        this.mutableCountry = country;
      },
      deep: true,
    },
    city: {
      handler(city) {
        this.mutableCity = city;
      },
      deep: true,
    },
    countryId(id) {
      this.mutableCity = null;
      this.onChangeCity(null);
      this.cityList = [];

      if (id) {
        this.fetchCityList();
      }
    },
  },
  mounted() {
    this.fetchCountryList();

    if (this.country) this.fetchCityList();
  },
  methods: {
    fetchCountryList(query) {
      const params = {
        query: query,
      };

      this.isCountryLoading = true;

      this.$http
        .get("departments/default/countries", {
          params: {
            ...params,
          },
        })
        .then(({ data }) => {
          this.isCountryLoading = false;
          this.countryList = data;
          if (!this.mutableCountry) {
            this.mutableCountry = this.countryList[0];
            this.onChangeCountry(this.mutableCountry);
          }
        })
        .catch(() => {
          this.isCountryLoading = false;
          this.$toast.error("Не удалось получить список стран.");
        });
    },
    fetchCityList(query) {
      const params = {
        countryId: this.countryId,
        search: query,
      };

      this.isCityLoading = true;

      this.$http
        .get("departments/default/towns", {
          params: {
            ...params,
          },
        })
        .then(({ data }) => {
          this.isCityLoading = false;
          this.cityList = sortBy(data, ["title"]);
        })
        .catch(() => {
          this.isCityLoading = false;
          this.$toast.error("Не удалось получить список городов.");
        });
    },
    onChangeCountry(country) {
      this.$emit("change-country", country);
    },
    onChangeCity(city) {
      this.$emit("change-city", city);
    },
  },
};
</script>
