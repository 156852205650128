var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.navList.length > 0)?_c('nav',{ref:"nav",staticClass:"catalog-box"},[_c('ul',{staticClass:"catalog-box__list"},_vm._l((_vm.navList),function(item){return _c('li',{key:`item-${item.id}`,staticClass:"catalog-box__item"},[_c('catalog-box-item',{attrs:{"to":{
          name: 'category-item',
          params: { town: _vm.$route.params.town, category: item.url },
        },"icon":item.url,"width":_vm.width},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('catalog-box-content',{attrs:{"list":item.items,"category":item.url},scopedSlots:_vm._u([{key:"title",fn:function(props){return [_c('router-link',{class:props.linkClass,attrs:{"to":{
                  name: 'category-item',
                  params: { town: _vm.$route.params.town, category: item.url },
                }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0),_c('footer',{staticClass:"catalog-box__footer"},[_c('base-btn',{attrs:{"tag":"router-link","to":{ name: 'category-list', params: { town: _vm.$route.params.town } },"size":"sm","display":"block"}},[_vm._v("Все категории")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }