<template>
  <svg
    v-if="name"
    class="icon"
    :height="height"
    :width="width"
    :fill="fill"
    :stroke="stroke"
  >
    <use :xlink:href="sprite + '#' + name" :href="sprite + '#' + name"></use>
  </svg>
</template>

<script>
import Sprite from "@/assets/shared/icons.svg";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    fill: {
      type: String,
      default: null,
    },
    stroke: {
      type: String,
      default: null,
    },
    sprite: {
      type: String,
      default: Sprite,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
}
</style>
