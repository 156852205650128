import Vue from "vue";
import Vuex from "vuex";

import VuexPersistence from "vuex-persist";
import createMutationsSharer from "vuex-shared-mutations";
import Cookies from "js-cookie";

import mq from "./modules/mq";
import settings from "./modules/settings";
import auth from "./modules/auth";
import favorite from "./modules/favorite";
import account from "../modules/account/store";
import auto from "../modules/auto/store";
import catalog from "../modules/catalog/store";
import cart from "../modules/cart/store";

Vue.use(Vuex);

// local storage
const vuexLocal = new VuexPersistence({
  key: process.env.VUE_APP_LOCAL_STORAGE_KEY,
  storage: window.localStorage,
  reducer: (state) => ({
    favorite: state.favorite,
    account: state.account,
    catalog: state.catalog,
    settings: {
      city: state.settings.city,
      isCityСonfirm: state.settings.isCityСonfirm,
      isCookieСonfirm: state.settings.isCookieСonfirm,
    },
    cart: state.cart,
  }),
});

// cookie
const cookieConfig = {
  expires: parseInt(process.env.VUE_APP_COOKIE_EXPIRE),
};

if (process.env.NODE_ENV === "production") {
  cookieConfig.domain = process.env.VUE_APP_COOKIE_DOMAIN;
  cookieConfig.secure = true;
}

const vuexCookie = new VuexPersistence({
  key: process.env.VUE_APP_COOKEY_KEY,
  restoreState: (key) => {
    const data = Cookies.get(key);
    return data ? JSON.parse(data) : "";
  },
  saveState: (key, state) =>
    Cookies.set(key, JSON.stringify(state), cookieConfig),
  modules: ["auth"],
});

export default new Vuex.Store({
  modules: {
    mq,
    settings,
    auth,
    favorite,
    account,
    auto,
    catalog,
    cart,
  },
  strict: true,
  plugins: [
    vuexCookie.plugin,
    vuexLocal.plugin,
    createMutationsSharer({
      predicate: [
        "auth/setToken",
        "account/setBaseUser",
        "account/cleanBaseUser",
        "cart/addItem",
        "cart/clean",
        "cart/removeItemByIndex",
        "cart/setList",
        "cart/updateItem",
        "cart/updateValue",
        "cart/setDiscountRules",
        "settings/setSettings",
      ],
    }),
  ],
});
