<template>
  <nav v-if="total > 1" class="the-pagination">
    <ul class="the-pagination__list">
      <li
        class="the-pagination__item pagination__item--first"
        :class="{ 'pagination__item--disabled': active <= 1 || disabled }"
      >
        <router-link
          class="the-pagination__link"
          :to="{ query: { ...routeQuery, page: active - 1 } }"
          :aria-disabled="active === 1 || disabled"
          >&larr; Назад</router-link
        >
      </li>
      <li
        v-for="(page, index) in filteredPagination"
        :key="`page-${index}`"
        class="the-pagination__item"
        :class="{
          'pagination__item--active': active === page,
          'pagination__item--disabled': disabled,
        }"
      >
        <span v-if="page === 'divider'" class="the-pagination__divider"
          >...</span
        >
        <router-link
          v-else
          class="the-pagination__link"
          :to="{ query: { ...routeQuery, page: page } }"
          :aria-disabled="disabled"
          >{{ page }}</router-link
        >
      </li>
      <li
        class="the-pagination__item pagination__item--last"
        :class="{ 'pagination__item--disabled': active === total || disabled }"
      >
        <router-link
          class="the-pagination__link"
          :to="{ query: { ...routeQuery, page: active + 1 } }"
          :aria-disabled="active >= total || disabled"
          >Вперед &rarr;</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "ThePagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 2,
    },
    active: {
      type: Number,
      default: 1,
    },
    disabled: Boolean,
  },
  computed: {
    filteredPagination() {
      const delta = this.limit;
      const left = this.active - delta;
      const right = this.active + delta + 1;
      const last = this.total;

      const pagination = [];

      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          pagination.push(i);
        }
      }

      const paginationWithDots = [];
      let j;

      for (const i of pagination) {
        if (j) {
          if (i - j === 2) {
            paginationWithDots.push(j + 1);
          } else if (i - j !== 1) {
            paginationWithDots.push("divider");
          }
        }
        paginationWithDots.push(i);
        j = i;
      }

      return paginationWithDots;
    },
    routeQuery() {
      return this.$route.query;
    },
  },
};
</script>

<style lang="scss" scoped>
.the-pagination {
  &__list {
    @include list-unstyled;

    align-items: center;
    display: flex;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;

    @include media-breakpoint-up(lg) {
      justify-content: center;
    }
  }

  &__item {
    padding: 0 5px;
  }

  &__divider {
    display: block;
    padding: 8px 16px;
  }

  &__link {
    background: $smoke;
    border-radius: 4px;
    color: $stone;
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 150ms ease-in;

    &:hover {
      color: $link-color;
    }

    .pagination__item--last &,
    .pagination__item--first & {
      background-color: transparent;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .pagination__item--active & {
      background-color: $link-color;
      color: $white;
    }

    .pagination__item--disabled & {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
