<template>
  <div class="payment-list">
    <h3>Платежи</h3>

    <balance-list-table v-if="isMd">
      <template #default>
        <balance-list-table-tr
          v-for="item in list"
          :key="`operation-${item.id}`"
          :is-executed="item.executed === 1"
        >
          <template #date>
            {{
              fromUnixTime(item.createTime) | formatDate("dd.MM.yyyy / HH:mm")
            }}
          </template>
          <template #type>
            {{
              item.type in typeList ? typeList[item.type] : "Неизвестный тип"
            }}
          </template>
          <template #sum>
            {{ item.sum | formatNumber }}
          </template>
          <template #status>
            {{ item.executed === 1 ? "Исполнено" : "Не подтверждено" }}
          </template>
          <template #order="{ linkClass }">
            <router-link
              v-if="item.orderId"
              :class="linkClass"
              :to="{
                name: 'account-order-item',
                params: { orderId: item.orderId },
              }"
            >
              Посмотреть заказ
            </router-link>
          </template>
        </balance-list-table-tr>
      </template>
    </balance-list-table>

    <balance-list-block v-else>
      <template #default>
        <balance-list-block-item
          v-for="item in list"
          :key="`operation-${item.id}`"
          :is-executed="item.executed === 1"
        >
          <template #date>
            {{ fromUnixTime(item.createTime) | formatDate("dd.MM.yyyy") }}<br />
            {{ fromUnixTime(item.createTime) | formatDate("HH:mm") }}
          </template>
          <template #type>
            {{
              item.type in typeList ? typeList[item.type] : "Неизвестный тип"
            }}
          </template>
          <template #sum>
            {{ item.sum | formatNumber }}
          </template>
          <template #status>
            {{ item.executed === 1 ? "Исполнено" : "Не подтверждено" }}
          </template>
          <template #order="{ linkClass }">
            <router-link
              v-if="item.orderId"
              :class="linkClass"
              :to="{
                name: 'account-order-item',
                params: { orderId: item.orderId },
              }"
            >
              Посмотреть заказ
            </router-link>
          </template>
        </balance-list-block-item>
      </template>
    </balance-list-block>
  </div>
</template>

<script>
import BalanceListTable from "./BalanceListTable";
import BalanceListTableTr from "./BalanceListTableTr";
import BalanceListBlock from "./BalanceListBlock";
import BalanceListBlockItem from "./BalanceListBlockItem";
// utils
import { mapGetters } from "vuex";
import formatNumber from "@/utils/formatNumber";
import formatDate from "@/utils/formatDate";
import fromUnixTime from "date-fns/fromUnixTime";

export default {
  components: {
    BalanceListTable,
    BalanceListTableTr,
    BalanceListBlock,
    BalanceListBlockItem,
  },
  filters: {
    formatNumber,
    formatDate,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      typeList: {
        payment_cash: "Платеж наличными",
        payment_bank: "Безналичный платеж",
        payment_withdraw: "Возврат",
        invoice: "Счет на оплату",
        correction: "Корректировка баланса",
      },
    };
  },
  computed: {
    ...mapGetters("mq", {
      isMd: "getIsMd",
    }),
  },
  methods: {
    fromUnixTime,
  },
};
</script>
