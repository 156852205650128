<template>
  <form-group :id="id" :has-error="hasError">
    <template #label>
      <slot name="label"></slot>
    </template>
    <template #default="props">
      <input
        :id="props.id"
        v-model.trim="searchQuery"
        type="text"
        :class="props.inputClass"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="onFetchData"
        @focus="onFocus"
        @blur="onBlur"
        @keyup.up="onUp"
        @keyup.down="onDown"
        @keyup.enter="onEnter"
      />
    </template>
    <template #suggest>
      <suggest-list
        :list="suggestionList"
        :is-show="searchQuery !== '' && isFocus"
        name="address"
        :pointer="pointer"
        :is-loading="isLoading"
        @click="onSelect"
      >
        <template #title>
          <template v-if="suggestionList.length > 0">
            Выберите вариант или продолжите ввод
          </template>
          <template v-else-if="searchQuery !== ''">
            Неизвестный адрес
          </template>
        </template>
        <template #default="props">
          <span
            :inner-html.prop="props.item.value | higlightQuery(searchQuery)"
          ></span>
        </template>
      </suggest-list>
    </template>
    <template #error>
      <slot name="error"></slot>
    </template>
  </form-group>
</template>

<script>
import FormGroup from "./FormGroup";
import SuggestList from "@/components/shared/SuggestList";
// utils
import suggestMixin from "@/utils/suggestMixin";

export default {
  components: {
    FormGroup,
    SuggestList,
  },
  mixins: [suggestMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasError: Boolean,
    disabled: Boolean,
    url: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 10,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    restrictValue: Boolean,
  },
  data() {
    return {
      searchQuery: this.value,
      isLoading: false,
    };
  },
  watch: {
    value(val) {
      this.searchQuery = val;
    },
  },
  methods: {
    onFetchData() {
      this.$emit("input", this.searchQuery);

      if (!this.url) return null;

      const data = {
        query: this.searchQuery,
        count: this.count,
      };

      if (this.locations.length > 0) {
        data.locations = this.locations;
      }

      if (this.restrictValue) data.restrict_value = this.restrictValue;

      this.isLoading = true;
      this.$dadata.suggest
        .post(this.url, data)
        .then(({ data }) => {
          this.isLoading = false;
          this.suggestionList = data.suggestions;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toast.error("Не удалось получить меню.");
        });
    },
    onEnter() {
      const item = this.suggestionList[this.pointer];
      if (item) this.onSelect(item);
    },
    onSelect(item) {
      this.$emit("select", item);
      this.pointer = -1;
    },
  },
};
</script>
