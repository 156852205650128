<template>
  <div class="order-card">
    <div class="order-card__header">
      <div class="order-card__heading">
        <h3 class="order-card__title">
          <router-link
            class="order-card__title-link"
            :to="{ name: 'account-order-item', params: { orderId: item.id } }"
            >Заказ № {{ item.id }}</router-link
          >
          <a
            href="#print-order"
            class="order-card__print"
            title="Печатная версия заказа"
            @click.prevent="onGetOrder"
          >
            <base-icon name="pdf" height="24" width="25" />
            Заказ
          </a>
        </h3>
        <p
          class="order-card__subtitle"
          :class="[
            isDebt > 0
              ? 'order-card__subtitle--warning'
              : 'order-card__subtitle--success',
          ]"
        >
          {{ isDebt ? "Не оплачен" : "Оплачен" }}
        </p>
      </div>
      <base-changer
        :id="`base-changer-${item.id}`"
        class="order-card__base-changer"
        label="Посмотреть детали"
        :value="isShow"
        @change="onToggle"
      />
    </div>
    <div class="order-card__body">
      <div class="order-card__section">
        <dl class="order-card__info">
          <dt>Дата оформления:</dt>
          <dd>{{ date | formatDate("dd MMMM yyy") }}</dd>
          <template v-if="item.paymentService">
            <dt>Способ оплаты:</dt>
            <dd>{{ item.paymentService.title }}</dd>
          </template>
          <template v-if="item.deliveryPrice > 0">
            <dt>Стоимость доставки:</dt>
            <dd>{{ item.deliveryPrice | formatNumber }} ₽</dd>
          </template>
          <dt>Общая сумма заказа:</dt>
          <dd>
            <strong>{{ total | formatNumber }} ₽</strong>
          </dd>
          <template
            v-if="item.managerComment && item.managerComment.length > 0"
          >
            <dt>Комментарий:</dt>
            <dd>
              {{ item.managerComment }}
            </dd>
          </template>
        </dl>
      </div>

      <template v-if="isShow">
        <order-card-item
          v-for="good in item.items"
          :key="good.id"
          :item="good"
          class="order-card__section"
        />

        <footer class="order-card__footer">
          <base-btn
            v-if="item.debt > 0"
            class="order-card__action-btn"
            size="sm"
            tag="router-link"
            :to="{
              name: 'account-order-item-payment',
              params: { orderId: item.id },
            }"
          >
            Оплатить
          </base-btn>
        </footer>
      </template>
    </div>
  </div>
</template>

<script>
import BaseChanger from "@/components/shared/BaseChanger";
import BaseBtn from "@/components/shared/BaseBtn";
import BaseIcon from "@/components/shared/BaseIcon";
import OrderCardItem from "./OrderCardItem";
// utils
import formatNumber from "@/utils/formatNumber";
import formatDate from "@/utils/formatDate";
import fromUnixTime from "date-fns/fromUnixTime";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseChanger,
    BaseBtn,
    BaseIcon,
    OrderCardItem,
  },
  filters: {
    formatNumber,
    formatDate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapGetters("mq", {
      isLg: "getIsLg",
    }),
    date() {
      return fromUnixTime(this.item.updateTime);
    },
    isDebt() {
      const { debt, itemsPrice } = this.item;
      return debt > 0 && parseFloat(itemsPrice) > 0;
    },
    total() {
      return (
        parseFloat(this.item.itemsPriceWithDiscount) +
        parseFloat(this.item.deliveryPrice)
      );
    },
  },
  methods: {
    onToggle(value) {
      this.isShow = !value;
    },
    onGetOrder() {
      this.$http
        .get("shop/order/pdf", {
          params: {
            id: this.item.id,
            type: "MAIN",
          },
        })
        .then(({ data }) => {
          if (data.file) {
            window.open(data.file, "_blank").focus();
          }
        })
        .catch(() => {
          this.$toast.error("Не удалось получить печатную версию заказа.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  border-bottom: 1px solid $grey-300;
  border-radius: 0 0 6px 6px;

  & + & {
    margin-top: 1rem;
  }

  &__header {
    background-color: $smoke;
    border: 1px solid $smoke;
    border-radius: 6px 6px 0 0;
    padding: 16px 24px;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    background-color: $smoke;
    border-left: 1px solid $grey-300;
    border-right: 1px solid $grey-300;
    padding: 16px 24px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  &__base-changer {
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-top: 0;
    }
  }

  &__title {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__print {
    @include font-size(0.9rem);
    align-items: center;
    display: flex;
    font-weight: normal;
    gap: 1px;
    letter-spacing: normal;
    text-decoration: none;
  }

  &__title-link {
    color: inherit;
    text-decoration: none;
  }

  &__subtitle {
    @include font-size(1rem);
    color: $stone;

    &--warning {
      color: $red;
    }

    &--success {
      color: $green;
    }
  }

  &__section {
    border-left: 1px solid $grey-300;
    border-right: 1px solid $grey-300;
    padding: 16px 24px;

    & + & {
      border-top: 1px solid $grey-300;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 0.2rem 0.5rem;
    margin-bottom: 0;

    & > * {
      margin-bottom: 0;
    }

    dt {
      font-weight: normal;
      color: $grey-200;
    }
  }
}
</style>
