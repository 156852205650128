<template>
  <div class="base-contact" :class="{ 'base-contact--dark': view === 'dark' }">
    <base-icon-block
      v-if="address"
      class="base-contact__icon"
      :icon="{ name: 'location-solid', width: '24', height: '24' }"
    >
      {{ address }}<br />
      <router-link
        :to="{
          name: 'content',
          params: { alias: 'contacts', town: town },
        }"
      >
        Посмотреть на карте
      </router-link>
    </base-icon-block>
    <base-icon-block
      v-if="phone"
      class="base-contact__icon"
      :icon="{ name: 'call-solid', width: '24', height: '24' }"
    >
      <a class="base-contact__link" :href="`tel:+7${phoneNumber}`">
        +7 {{ phone }}
      </a>
    </base-icon-block>
    <base-icon-block
      class="base-contact__icon"
      :icon="{ name: 'mail-solid', width: '24', height: '24' }"
    >
      <a class="base-contact__link" :href="`mailto:${email}`">
        {{ email }}
      </a>
    </base-icon-block>
  </div>
</template>

<script>
import BaseIconBlock from "../../shared/BaseIconBlock";
// utils
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    BaseIconBlock,
  },
  props: {
    view: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("settings", {
      address: (state) => state.city.address,
      phone: (state) => state.city.phone,
    }),
    ...mapGetters("settings", {
      town: "getTown",
    }),
    phoneNumber() {
      return this.phone.replace(/\D/g, "");
    },
    email() {
      if (this.town) {
        return `${this.town}@avtovelikan.ru`;
      }

      return "info@avtovelikan.ru";
    },
  },
};
</script>

<style lang="scss" scoped>
.base-contact {
  font-family: $headings-font-family;

  &--dark {
    color: $smoke;
  }

  &__icon {
    fill: $mint-100;

    & + & {
      margin-top: 25px;
    }
  }

  &__link {
    color: $body-color;
    text-decoration: none;

    .base-contact--dark & {
      color: $smoke;
    }

    &:hover {
      color: $link-color;
      text-decoration: underline;
    }
  }
}
</style>
