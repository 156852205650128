<template>
  <div
    class="the-sidebar"
    :class="{
      'the-sidebar--loading': isLoading,
      'the-sidebar--sticky': isSticky,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    isSticky: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.the-sidebar {
  &.the-sidebar--sticky {
    @include media-breakpoint-up(lg) {
      position: sticky;
      top: 20px;
      z-index: 1;
    }
  }

  &--loading {
    border-radius: 6px;
    background: rgba($grey-300, 0.5);
    height: 100%;
  }
}
</style>
