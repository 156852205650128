<template>
  <base-loading v-if="isLoading" />
  <page-layout-full v-else :breadcrumb="breadcrumb" class="search-brands">
    <template #title>{{
      title ? title : "Поиск артикула по брендам"
    }}</template>

    <progress-bar :count="contractorStatus" class="search-brands__line">
      Поиск по поставщикам
    </progress-bar>

    <template v-if="filterCrossList.length > 0">
      <h3>Список брендов</h3>

      <brand-list :list="filterCrossList" />
    </template>

    <p v-if="filterCrossList.length === 0 && contractorStatus === 100">
      Ничего не найдено.
    </p>
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import ProgressBar from "../components/list/ProgressBar";
import BrandList from "../components/list/BrandList";
// utils
import orderBy from "lodash/orderBy";
import onSetMeta from "@/utils/onSetMeta";

export default {
  components: {
    BaseLoading,
    PageLayoutFull,
    ProgressBar,
    BrandList,
  },
  metaInfo() {
    return {
      title: this.params
        ? `Поиск артикула ${this.params.article} по брендам`
        : "Загружается...",
      meta: this.metaList,
    };
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Поиск артикула по брендам",
        },
      ],
      title: "",
      metaList: [],
      isLoading: true,
      crossList: [],
      contractorStatus: 0,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params;
    },
    params() {
      const params = {};

      if (this.routeParams) {
        params.article = this.routeParams.article;
      }

      if (this.$route.params && this.$route.params.town) {
        params.town = this.$route.params.town;
      }

      return params;
    },
    filterCrossList() {
      const list = orderBy(
        this.crossList.filter((item) => item.brand && item.article),
        ["brand", "article", "title"],
        ["asc", "asc", "desc"]
      );

      const obj = list.reduce((total, item) => {
        const title = (item.brand + item.article)
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replace(/\s+/g, "")
          .toLowerCase();

        if (!(title in total)) {
          total[title] = item;
        }

        return total;
      }, {});

      return Object.values(obj);
    },
  },
  watch: {
    "$route.params.article"() {
      this.onFetchData();
    },
  },
  created() {
    if (this.routeParams) this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;
      this.crossList = [];
      this.contractorStatus = 0;

      this.$http
        .get("catalog/search/index", {
          params: this.params,
        })
        .then(({ data }) => {
          const { contractors, crosses, title, meta } = data;
          this.crossList = crosses;
          this.title = title;
          this.metaList = onSetMeta(meta);
          this.isLoading = false;

          this.$nextTick(() => {
            this.fetchSupplyList(contractors);
          });
        })
        .catch((error) => {
          this.$toast.error(error.toString());
          this.isLoading = false;
        });
    },
    fetchSupplyList(contractorList) {
      const size = contractorList.length;
      const step = size > 0 ? 100 / size : 0;

      for (let i = 0; i < size; i++) {
        this.fetchSupply(contractorList[i], step);
      }
    },
    fetchSupply({ id }, step) {
      this.$http
        .get("catalog/search/possible-article-search-by-contractor", {
          params: {
            ...this.params,
            provider: id,
          },
        })
        .then(({ data }) => {
          this.crossList = [...this.crossList, ...data];
          this.contractorStatus = this.contractorStatus + step;
        })
        .catch((error) => {
          this.$toast.error(error.toString());
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-brands {
  &__line {
    margin-bottom: 1rem;
  }
}
</style>
