<template>
  <div class="delivery-express">
    <p class="delivery-express__txt">
      <strong>Адрес доставки:</strong>
      <a class="link-action" @click="$modal.show('city-alert')">{{
        cityName
      }}</a
      ><br />
    </p>
    <p class="delivery-express__txt">Для доставки необходимо указать адрес</p>
    <form class="delivery-express__form">
      <form-group
        id="addressSearch"
        :show-label="false"
        size="sm"
        class="delivery-express__form-group"
        :icon="{ name: 'search', width: '16', height: '16' }"
      >
        <template #label>Марка или модель</template>
        <template #default="props">
          <input
            :id="props.id"
            v-model.trim="address"
            type="text"
            :class="props.inputClass"
            placeholder="Начните вводить адрес в строке поиска"
          />
        </template>
      </form-group>
    </form>

    <p class="delivery-express__txt">
      Курьер свяжется с Вами для уточнения времени и адреса
    </p>

    <delivery-calculate />
  </div>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import DeliveryCalculate from "./DeliveryCalculate";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    FormGroup,
    DeliveryCalculate,
  },
  data() {
    return {
      address: "",
      isShowAddress: false,
    };
  },
  computed: {
    ...mapGetters("settings", {
      cityName: "cityName",
    }),
  },
};
</script>

<style lang="scss" scoped>
.delivery-express {
  &__txt,
  &__form-group {
    margin-bottom: 0.5rem;
  }
}
</style>
