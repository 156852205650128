<template>
  <div class="balance-list-block-item">
    <dl class="balance-list-block-item__field">
      <dt class="balance-list-block-item__title">Дата и время операции</dt>
      <dd class="balance-list-block-item__value"><slot name="date"></slot></dd>
      <dt class="balance-list-block-item__title">Тип операции</dt>
      <dd class="balance-list-block-item__value"><slot name="type"></slot></dd>
      <dt class="balance-list-block-item__title">Сумма, р.</dt>
      <dd class="balance-list-block-item__value"><slot name="sum"></slot></dd>
      <dt class="balance-list-block-item__title">Коментарий к операции</dt>
      <dd
        class="balance-list-block-item__value balance-list-block-item__status"
      >
        <slot name="status"></slot>
        <slot name="comment"></slot>
      </dd>
    </dl>
    <div class="balance-list-block-item__order">
      <slot name="order" linkClass="balance-list-block-item__link"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isExecuted: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.balance-list-block-item {
  border: 1px solid $grey-300;
  border-radius: 6px;
  padding: 1rem;

  & + & {
    margin-top: 1rem;
  }

  &__field {
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    > * {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: normal;
  }

  &__status {
    color: $red;

    .balance-list-table-tr--success & {
      color: $primary;
    }
  }

  &__order {
    text-align: center;
  }
}
</style>
