<template>
  <base-loading v-if="isLoading" />
  <page-layout-full v-else :breadcrumb="breadcrumb" class="item-view">
    <template #title>
      {{ item.title }}
    </template>

    <template #subtitle>
      <div class="item-view__subtitle">
        <rating v-if="false" />
        <div class="item-view__article">Арт.{{ item.article }}</div>
      </div>
    </template>

    <div class="item-view__header">
      <div class="row">
        <div class="col-md-9">
          <figure class="item-view__picture" @click="$modal.show('gallery')">
            <base-image
              v-if="image"
              class="item-view__image"
              :url="image"
              width="250"
              height="250"
            />
          </figure>
        </div>
        <div class="col-md-3">
          <action-box
            :price="item.price"
            :route="{
              name: 'search-vendors',
              params: {
                town: $route.params.town,
                brand: item.brand,
                article: item.article,
              },
            }"
          />
        </div>
      </div>
    </div>

    <div class="item-view__body">
      <div v-if="false" class="item-view__tablist">
        <btn-tab
          view="secondary"
          :active="tab === 'desc'"
          @click="tab = 'desc'"
        >
          Описание
        </btn-tab>
        <btn-tab
          view="secondary"
          :active="tab === 'review'"
          @click="tab = 'review'"
        >
          Отзывы
        </btn-tab>
      </div>

      <div v-show="tab === 'desc'" class="item-view__tab">
        <div class="row">
          <div class="col-md-6">
            <div class="item-view__section">
              <h3>Описание</h3>

              <div v-html="item.description"></div>
            </div>
            <div class="item-view__section">
              <h3>Характеристики</h3>

              <div
                v-for="(prop, key) in item.properties"
                :key="`prop-${key}`"
                class="item-view__property"
              >
                <property v-if="prop.value && prop.value.length > 0">
                  <template #title>{{ prop.title }}</template>
                  <template #default>
                    <cross-list v-if="key === 'crosses'" :list="prop.value" />
                    <template v-else>{{ prop.value }}</template>
                  </template>
                </property>
              </div>
              <p class="item-view__warning">
                Информация о технических характеристиках, комплекте поставки,
                стране изготовления и внешнем виде товара носит справочный
                характер и основывается на последних доступных сведениях от
                производителя.
              </p>
            </div>

            <div class="item-view__section" v-if="item.contacts">
              <div v-html="item.contacts"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tab === 'review'" class="item-view__tab">
        <div class="item-view__review">
          <h3>Отзывов еще нет</h3>
          <p>
            Может, вы можете помочь? Вам будут благодарны многие пользователи. И
            мы тоже.
          </p>
        </div>
      </div>
    </div>
    <gallery-modal v-if="image" :image="image" />
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import BaseImage from "@/components/shared/BaseImage";
import BtnTab from "@/components/shared/BtnTab";
import Rating from "../components/item/Rating";
import ActionBox from "../components/item/ActionBox";
import Property from "../components/item/Property";
import CrossList from "../components/item/CrossList";
import GalleryModal from "../components/item/GalleryModal";
// utils
import { mapGetters } from "vuex";
import onSetMeta from "@/utils/onSetMeta";

export default {
  components: {
    BaseLoading,
    PageLayoutFull,
    BaseImage,
    BtnTab,
    Rating,
    ActionBox,
    Property,
    CrossList,
    GalleryModal,
  },
  data() {
    return {
      item: null,
      categoryId: null,
      tab: "desc",
      metaList: [],
      metaTitle: null,
      isLoading: true,
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: this.metaList,
    };
  },
  computed: {
    ...mapGetters({
      getActiveCategoryById: "catalog/getActiveCategoryById",
    }),
    id() {
      const match = this.$route.params.id.match(/[\w-]+-(\d+)$/);

      return match ? match[1] : null;
    },
    activeCategory() {
      return this.getActiveCategoryById(this.categoryId);
    },
    title() {
      return `${this.item.brand} ${this.item.article}`;
    },
    breadcrumb() {
      const list = [{ title: "Все каталоги", to: { name: "category-list" } }];

      if (this.activeCategory) {
        if (this.activeCategory.parent) {
          list.push({
            title: this.activeCategory.parent.title,
            to: {
              town: this.$route.params.town,
              name: "category-item",
              params: { category: this.activeCategory.parent.url },
            },
          });
        }
        if (this.activeCategory.active) {
          list.push({
            title: this.activeCategory.active.title,
            to: {
              name: "item-list",
              params: {
                town: this.$route.params.town,
                category: this.activeCategory.parent.url,
                subCategory: this.activeCategory.active.url,
              },
            },
          });
        }

        if (this.item) list.push({ title: this.title });
      }

      return list;
    },
    image() {
      return this.item.image ? this.item.image.source : null;
    },
  },
  watch: {
    id() {
      this.onFetchData();
    },
  },
  created() {
    this.$eventHub.$emit("disable-on-load");

    this.onFetchData();
  },
  methods: {
    onFetchData() {
      this.isLoading = true;

      const params = {
        id: this.id,
      };
      if (this.$route.params && this.$route.params.town) {
        params.town = this.$route.params.town;
      }

      this.$http
        .get("catalog/default/item", {
          headers: this.headers,
          params: params,
        })
        .then(({ data }) => {
          this.item = data;
          this.categoryId = this.item.categoryId;
          this.metaList = onSetMeta(this.item.meta);
          this.metaTitle = this.item.meta.title;
          this.isLoading = false;

          this.$nextTick(() => {
            this.$meta().refresh();
            this.$nextTick(() => {
              document.body.classList.add("is-loaded");
            });
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-view {
  &__header {
    margin-bottom: 60px;
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__article {
    color: $stone;
  }

  &__image {
    @include img-fluid;
  }

  &__tablist {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0;
    margin-bottom: 50px;
  }

  &__section {
    & + & {
      margin-top: 40px;
    }
  }

  &__warning {
    @include font-size(14px);
    color: $stone;
    margin-top: 30px;
  }

  &__review {
    max-width: 460px;
    margin: 160px auto;
    text-align: center;
  }
}
</style>
