<template>
  <div class="catalog-list-item" :class="{ 'catalog-list-item--open': isOpen }">
    <div class="catalog-list-item__header">
      <router-link
        :to="{
          name: 'category-item',
          params: { town: $route.params.town, category: category.url },
        }"
        class="catalog-list-item__link"
      >
        {{ category.title }}
      </router-link>
      <button
        class="catalog-list-item__toggle"
        type="button"
        @click="isOpen = !isOpen"
      >
        <base-icon
          class="catalog-list-item__icon"
          name="down-outline"
          width="24"
          height="24"
        />
      </button>
    </div>
    <div v-show="isOpen" class="catalog-list-item__content">
      <ul class="catalog-list-item__list">
        <li v-if="isAutoparts" class="catalog-list-item__item">
          <a
            :href="`${laximoPath}auto`"
            class="catalog-list-item__link text-bold"
          >
            Каталоги для подбора автозапчастей
          </a>
        </li>
        <li
          v-for="(item, index) in category.items"
          :key="`item-${item.url}-${index}`"
          class="catalog-list-item__item"
        >
          <router-link
            :to="{
              name: 'item-list',
              params: {
                town: $route.params.town,
                category: category.url,
                subCategory: item.url,
              },
            }"
            class="catalog-list-item__link"
          >
            {{ item.title }}
          </router-link>
        </li>
        <li
          v-if="isAutoparts && feedback.liveEnabled"
          class="catalog-list-item__item catalog-list-item__item--has-button"
        >
          <base-btn
            class="text-nowrap"
            size="sm"
            view="secondary"
            @click="onOpenLivechat"
          >
            Помощь в подборе →
          </base-btn>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import { mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    BaseBtn,
    BaseIcon,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
    isAutoparts() {
      return this.category.url === "avtozapchasti";
    },
    laximoPath() {
      return process.env.VUE_APP_LAXIMO_PATH;
    },
  },
  methods: {
    onOpenLivechat,
  },
};
</script>

<style lang="scss" scoped>
.catalog-list-item {
  &__header {
    border-bottom: 1px solid $grey-300;
    display: flex;
    padding-bottom: 13px;
    padding-top: 13px;
  }

  &__toggle {
    @extend %btn;
    margin-left: auto;
  }

  &__icon {
    fill: $stone;
    transition: transform 250ms ease;

    .catalog-list-item--open & {
      transform: rotate(-90deg);
    }
  }

  &__content {
    border-bottom: 1px solid $grey-300;
    padding-top: 10px;
  }

  &__list {
    @include list-unstyled;

    margin-left: 1rem;
  }

  &__item {
    &--has-button {
      margin-top: 1rem;
    }
  }

  &__link {
    @extend %text-sm;

    color: $coal;
    display: block;
    padding: 6px 0;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }
}
</style>
