<template>
  <div class="product-list" :class="[`list--${view}`]">
    <div class="product-list__row">
      <div
        v-for="item in list"
        :key="`item-${item.id}`"
        class="product-list__col"
      >
        <product-list-item :item="item" :view="productView" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductListItem from "./ProductListItem";
// utils
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    ProductListItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState("catalog", {
      view: (state) => state.view,
    }),
    ...mapGetters("mq", {
      isMd: "getIsMd",
    }),
    productView() {
      if (this.isMd) {
        return this.view === "square" ? "col" : "row";
      }

      return "col";
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list {
  &__row {
    display: grid;

    .list--square & {
      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  &__col {
    border-top: 1px solid $grey-300;
  }
}
</style>

<style lang="scss" scoped>
.product-list {
  overflow: hidden;
}
</style>
