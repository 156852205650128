import TheIndex from "@/components/shared/TheIndex.vue";
import CategoryListView from "../views/CategoryListView.vue";
import CategoryLampView from "../views/CategoryLampView.vue";
import CategoryItemView from "../views/CategoryItemView.vue";
import ItemListView from "../views/ItemListView.vue";
import ItemView from "../views/ItemView.vue";
import SearchVendorsView from "../views/SearchVendorsView.vue";
import SearchBrandsView from "../views/SearchBrandsView.vue";

export default [
  {
    path: "/:town?/catalog",
    component: TheIndex,
    children: [
      {
        path: "/",
        name: "category-list",
        component: CategoryListView,
      },
      {
        path: "lamps",
        name: "category-lamp",
        component: CategoryLampView,
      },
      {
        path: ":category",
        name: "category-item",
        component: CategoryItemView,
      },
      {
        path: ":category/:subCategory",
        name: "item-list",
        component: ItemListView,
      },
    ],
  },
  {
    path: "/:town?/parts/item_:id",
    name: "item",
    component: ItemView,
  },
  {
    path: "/:town?/parts/search/:article",
    name: "search-brands",
    component: SearchBrandsView,
  },
  {
    path: "/:town?/parts/search/:brand/:article",
    name: "search-vendors",
    component: SearchVendorsView,
  },
];
