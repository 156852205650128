<template>
  <section class="model-search">
    <header class="model-search__header">
      <h2 class="model-search__title">Найти по марке и модели</h2>

      <form class="model-search__form">
        <form-group
          id="modelSearch"
          :show-label="false"
          size="sm"
          class="model-search__form-group"
          :icon="{ name: 'search', width: '16', height: '16' }"
        >
          <template #label>Марка или модель</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="searchQuery"
              type="text"
              :class="props.inputClass"
              placeholder="Искать в списке"
            />
          </template>
        </form-group>
      </form>
    </header>

    <div
      v-for="(lang, key) in alphabetModelList"
      :key="`lang-${key}`"
      class="model-search__lang"
    >
      <div class="row">
        <div
          v-for="(langItem, langKey, langIndex) in lang"
          :key="`model-${lang}-${langIndex}`"
          class="col-sm-6 col-md-4 col-lg-3 col-xl-2"
        >
          <div class="model-search__item">
            <h3 class="model-search__letter">{{ langKey }}</h3>

            <div class="model-search__list">
              <model
                v-for="model in langItem"
                :key="`model-${langIndex}-${model.id}`"
                :item="model"
                >{{ model.title }}</model
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import Model from "./Model";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    FormGroup,
    Model,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters("auto", {
      modelList: "getFilteredList",
    }),
    filteredModelList() {
      if (!this.searchQuery || this.searchQuery === "") return this.modelList;

      return this.modelList.filter((model) => {
        const query = this.searchQuery.toLowerCase();

        return model.title.toLowerCase().includes(query);
      });
    },
    alphabetModelList() {
      return this.filteredModelList.reduce(
        (a, c) => {
          const k = c.title[0].toLocaleUpperCase();

          const lang = /[а-яё]/i.test(k) ? "ru" : "en";

          if (a[lang][k]) a[lang][k].push(c);
          else a[lang][k] = [c];

          return a;
        },
        { en: {}, ru: {} }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.model-search {
  &__header {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__form-group {
    max-width: 215px;
  }

  &__item {
    display: flex;
    margin-bottom: 32px;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 64px;
    }
  }

  &__letter {
    flex: 0 0 50px;
    margin-bottom: 0;
    max-width: 50px;
    padding-top: 8px;

    @include media-breakpoint-up(sm) {
      max-width: none;
      margin-bottom: 16px;
      padding-top: 0;
    }
  }

  &__lang {
    & + & {
      border-top: 1px solid $grey-300;
      padding-top: 32px;

      @include media-breakpoint-up(lg) {
        padding-top: 64px;
      }
    }
  }
}
</style>
