<template>
  <div class="order-card-item">
    <div class="order-card-item__title">
      {{ item.itemTitle }}
      <div class="order-card-item__brand">
        {{ item.itemBrand }}
      </div>
      <div class="order-card-item__article">
        Артикул: {{ item.itemArticle }}
      </div>
      <div class="order-card-item__status">
        <base-icon
          :fill="item.status.color"
          width="12"
          height="12"
          name="indicator"
        />
        {{ item.status.title }}
      </div>
      <div
        v-if="item.managerComment && item.managerComment.length > 0"
        class="order-card-item__note"
      >
        Комментарий: {{ item.managerComment }}
      </div>
    </div>
    <div class="order-card-item__body">
      <div class="order-card-item__price">
        {{ item.price | formatNumber }} ₽
      </div>
      <div class="order-card-item__count">{{ item.count }} шт.</div>
      <div class="order-card-item__total">{{ item.sum | formatNumber }} ₽</div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseIcon,
  },
  filters: {
    formatNumber,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card-item {
  @include media-breakpoint-up(md) {
    align-items: flex-start;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr auto;
  }

  &__body {
    align-items: flex-start;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 40px 65px;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 65px 40px 65px;
      margin-top: 0;
    }
  }

  &__btn {
    @extend %btn;
    border: none;
    padding: 0;
  }

  &__delete {
    fill: $red;
  }

  &__brand {
    font-weight: bold;
  }

  &__article {
    @include font-size(14px);
    color: $grey-100;
  }

  &__status {
    @include font-size(14px);
  }

  &__note {
    margin-top: 5px;
  }

  &__price {
    color: $grey-100;
  }

  &__price,
  &__total,
  &__count {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
  }
}
</style>
