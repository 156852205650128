<template>
  <div class="brand-list-table">
    <div class="brand-list-table__head">
      <div class="brand-list-table__title">#</div>
      <div class="brand-list-table__title">Бренд</div>
      <div class="brand-list-table__title">Артикул</div>
      <div class="brand-list-table__title">Название</div>
    </div>
    <router-link
      v-for="(item, index) in list"
      :key="`cross-${index}`"
      :to="{
        name: 'search-vendors',
        params: {
          town: $route.params.town,
          brand: item.brand,
          article: item.article,
        },
      }"
      class="brand-list-table__row"
    >
      <div class="brand-list-table__col brand-list-table__col--number">
        {{ index + 1 }}
      </div>
      <div class="brand-list-table__col brand-list-table__col--brand">
        {{ item.brand }}
      </div>
      <div class="brand-list-table__col">{{ item.article }}</div>
      <div class="brand-list-table__col">{{ item.title }}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-list-table {
  position: relative;
  width: 100%;

  &__head {
    top: 0;
    position: sticky;
    z-index: 2;
  }

  &__head,
  &__row {
    display: grid;
    grid-template-columns: 50px 140px 120px 1fr;
  }

  &__row {
    color: inherit;
    text-decoration: none;
    transition: none;

    &:hover {
      background: $link-color;
      color: $white;
    }
  }

  &__title {
    background-color: $primary;
    color: $white;
    font-weight: bold;
    padding: 6px 8px;
    white-space: nowrap;
  }

  &__col {
    @include font-size(14px);

    border-bottom: 1px solid $grey-300;
    padding: 4px 8px;
    word-break: break-all;

    &--number {
      color: $grey-100;

      :hover > & {
        color: $white;
      }
    }

    &--brand {
      font-weight: bold;
    }
  }
}
</style>
