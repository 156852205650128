<template>
  <base-popup
    v-if="isLg"
    class="base-city"
    :position="isXXl ? 'top-center' : 'top-left'"
    :disabled="isLogged"
  >
    <template #title>
      <a
        v-show="cityName"
        class="base-city__link"
        aria-label="Выбор города"
        :aria-disabled="isLogged"
      >
        <base-icon
          class="base-city__icon"
          name="location-solid"
          width="16"
          height="16"
        />
        {{ cityName }}
      </a>
    </template>

    <div class="base-city__content">
      <p class="base-city__title">Ваш город — {{ cityName }}?</p>

      <base-btn size="sm">Да, верно</base-btn>
      <a
        class="base-city__select"
        aria-label="Выбор города"
        @click.prevent="$modal.show('city')"
      >
        Выбрать другой
      </a>
    </div>
  </base-popup>
  <div v-else class="city">
    <a
      v-if="isGuest"
      class="base-city__link"
      aria-label="Выбор города"
      @click="onClick"
    >
      <base-icon
        class="base-city__icon"
        name="location-solid"
        width="16"
        height="16"
      />
      {{ cityName }}
    </a>
    <span v-else class="base-city__link">
      <base-icon
        class="base-city__icon"
        name="location-solid"
        width="16"
        height="16"
      />
      {{ cityName }}
    </span>
  </div>
</template>

<script>
import BaseIcon from "../../shared/BaseIcon";
import BasePopup from "../../shared/BasePopup";
import BaseBtn from "../../shared/BaseBtn";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BaseIcon,
    BasePopup,
    BaseBtn,
  },
  computed: {
    ...mapGetters({
      isGuest: "auth/isGuest",
      isLogged: "auth/isLogged",
      isLg: "mq/getIsLg",
      isXXl: "mq/getIsXXl",
      cityName: "settings/cityName",
    }),
  },
  methods: {
    onClick() {
      this.$modal.show("city");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-city {
  &__link {
    @extend %text-nav;

    align-items: center;
    color: $black;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      color: $grey-200;
      font-weight: normal;
      padding-bottom: 2px;
    }
  }

  &__icon {
    fill: $mint-100;
    margin-right: 4px;
  }

  &__title {
    @extend %subtitle;
  }

  &__select {
    margin-left: 15px;
    cursor: pointer;
  }
}
</style>
