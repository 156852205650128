<template>
  <section class="vin-search">
    <header class="vin-search__header">
      <h2 class="vin-search__title">Найти автомобиль по VIN-номеру</h2>
      <p class="vin-search__subtitle">Или номеру кузова (Frame)</p>
    </header>

    <div class="vin-search__body">
      <div class="vin-search__content">
        <vin-search-form class="vin-search__form" />
        <vin-search-help class="vin-search__help" />
      </div>

      <div class="vin-search__desc">
        <div class="row">
          <div class="col-auto">
            Пример VIN-номера:<br />
            WF0EXXGCDE7S80518
          </div>
          <div class="col-auto">
            Пример номера кузова:<br />
            sxa110207352
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VinSearchForm from "../components/VinSearchForm";
import VinSearchHelp from "../components/VinSearchHelp";

export default {
  components: {
    VinSearchForm,
    VinSearchHelp,
  },
};
</script>

<style lang="scss" scoped>
.vin-search {
  &__header {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    color: $stone;
  }

  &__body {
    background: $smoke;
    border-radius: 6px;
    max-width: 725px;
    padding: 16px 24px;
  }

  &__content {
    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
    }
  }

  &__form {
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-right: 20px;
      flex: 0 0 441px;
      max-width: 441px;
    }
  }

  &__desc {
    color: $stone;
    margin-top: 8px;
  }
}
</style>
