<template>
  <page-layout-sidebar
    v-if="activeCategory"
    :breadcrumb="breadcrumb"
    class="item-list-view"
    :class="{ 'item-list-view--loading': isLoading }"
    filter-title="Фильтр поиска"
    :is-sticky="false"
  >
    <template #title>{{ pageTitle }}</template>

    <template #filter>
      <form-group
        id="articleSearch"
        :show-label="false"
        size="sm"
        :icon="{ name: 'search', width: '16', height: '16' }"
      >
        <template #label>Поиск по наименованию</template>
        <template #default="props">
          <input
            :id="props.id"
            v-model.trim="filterSearchQuery"
            type="text"
            :class="props.inputClass"
            placeholder="Поиск по наименованию"
            @change="onSearch"
          />
        </template>
      </form-group>

      <template v-if="isFilterReady">
        <filter-prop-default
          v-for="(filter, key) in filterList"
          :key="`filter-${key}`"
          :item="filter.values"
        >
          <template #default="{ list }">
            <filter-item :is-ready="isFilterReady">
              <template #title>
                {{ filter.title }}
              </template>
              <template #default="{ isReady }">
                <filter-checkbox-list
                  :name="`filter-${filter.alias}`"
                  trackby="id"
                  labelby="title"
                  :is-ready="isReady"
                  :list="list"
                  :selected="
                    filter.alias in filterSelected
                      ? filterSelected[filter.alias]
                      : []
                  "
                  @change="
                    (list) => {
                      onChange(filter.alias, list);
                    }
                  "
                  @reset="
                    () => {
                      onReset(filter.alias);
                    }
                  "
                />
              </template>
            </filter-item>
          </template>
        </filter-prop-default>
      </template>
    </template>

    <template #filter-action>
      <base-btn
        v-if="hasFilterSelected"
        size="sm"
        view="outline-secondary"
        display="block"
        @click="onClean"
      >
        Сбросить
      </base-btn>
    </template>

    <selected-filter-list
      v-if="isFilterReady && hasFilterSelected"
      class="item-list-view__selected-filter-list"
      :filter-list="filterList"
      @remove-search="onRemoveSearch"
      @remove-filter="onRemoveFilter"
    />

    <div class="item-list-view__settings">
      <view-settings class="item-list-view__view-settings" />
    </div>

    <base-loading v-if="isLoading" />

    <product-list
      v-else-if="!isLoading && itemList.length > 0"
      :list="itemList"
      class="item-list-view__goods"
    />

    <the-empty v-else class="item-list-view__the-empty">
      <template #filter>
        <selected-filter-list
          v-if="isFilterReady && hasFilterSelected"
          :filter-list="filterList"
          view="center"
          @remove-search="onRemoveSearch"
          @remove-filter="onRemoveFilter"
        />
      </template>
    </the-empty>

    <div v-if="activePage < pageCount" class="item-list-view__loadmore">
      <base-btn
        view="outline-secondary"
        :loading="isLoadMore"
        :disabled="isLoading"
        @click="onLoadMore"
      >
        Показать еще
      </base-btn>
    </div>

    <template v-if="pageCount > 1" #footer>
      <the-pagination
        :total="pageCount"
        :active="activePage"
        :disabled="isLoading"
      />
    </template>
  </page-layout-sidebar>
  <base-loading v-else />
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import FormGroup from "@/components/shared/FormGroup";
import BaseLoading from "@/components/shared/BaseLoading";
import PageLayoutSidebar from "@/components/page/PageLayoutSidebar.vue";
import ThePagination from "@/components/shared/ThePagination";
import ProductList from "../components/list/ProductList";
import TheEmpty from "../components/list/TheEmpty";
import ViewSettings from "../components/list/ViewSettings";
import FilterItem from "@/components/shared/FilterItem";
import FilterCheckboxList from "../components/list/FilterCheckboxList";
import FilterPropDefault from "../components/list/FilterPropDefault";
import SelectedFilterList from "../components/list/SelectedFilterList";
// utils
import { mapGetters, mapState } from "vuex";
import onSetMeta from "@/utils/onSetMeta";
import isEqual from "lodash/isEqual";

export default {
  components: {
    BaseBtn,
    FormGroup,
    BaseLoading,
    PageLayoutSidebar,
    ThePagination,
    ProductList,
    TheEmpty,
    ViewSettings,
    FilterItem,
    FilterCheckboxList,
    FilterPropDefault,
    SelectedFilterList,
  },
  data() {
    return {
      isLoading: true,
      isLoadMore: false,
      itemList: [],
      itemsCount: 0,
      pageCount: 0,
      resetFilter: false,
      isFilterReady: false,
      filterList: null,
      filterSelected: {},
      filterSearchQuery: "",
      metaList: [],
      pageTitle: null,
      metaTitle: null,
    };
  },
  metaInfo() {
    return {
      title: this.metaTitlePaged || "Загружается...",
      meta: this.metaList,
    };
  },
  computed: {
    ...mapGetters({
      getActiveCategory: "catalog/getActiveCategory",
      cityName: "settings/cityName",
      cityNameIn: "settings/cityNameIn",
      isXl: "mq/getIsXl",
    }),
    ...mapState({
      limit: (state) => state.catalog.limit,
      view: (state) => state.catalog.view,
    }),
    categoryUrl() {
      return {
        parent: this.$route.params.category,
        active: this.$route.params.subCategory,
      };
    },
    activeCategory() {
      return this.getActiveCategory(
        this.categoryUrl.parent,
        this.categoryUrl.active
      );
    },
    id() {
      return this.activeCategory ? this.activeCategory.active.id : null;
    },
    breadcrumb() {
      const list = [{ title: "Все каталоги", to: { name: "category-list" } }];

      if (this.activeCategory) {
        if (this.activeCategory.parent) {
          list.push({
            title: this.activeCategory.parent.title,
            to: {
              town: this.$route.params.town,
              name: "category-item",
              params: { category: this.activeCategory.parent.url },
            },
          });
        }
        if (this.activeCategory.active) {
          list.push({ title: this.activeCategory.active.title });
        }
      }

      return list;
    },
    activePage() {
      if (!this.$route.query.page) return 1;

      return parseInt(this.$route.query.page);
    },
    routeQuery() {
      return this.$route.query;
    },
    paramsFilter() {
      const params = {};
      // eslint-disable-next-line no-unused-vars
      const { page, search, ...filter } = this.routeQuery;

      if (search) {
        params.search = search;
      }

      const props = {};

      if (filter) {
        for (let key in filter) {
          props[key] = filter[key].split(",");
        }
      }

      if (Object.keys(props).length > 0) {
        params.props = props;
      }

      return params;
    },
    params() {
      const params = {};

      params.id = this.id;
      params.page = this.activePage;
      params["per-page"] = this.limit;

      const result =
        Object.keys(this.paramsFilter).length > 0
          ? { ...params, ...this.paramsFilter }
          : params;

      return result;
    },
    metaTitlePaged() {
      if (this.activePage > 1) {
        return `${this.metaTitle} — страница ${this.activePage}`;
      }

      return this.metaTitle;
    },
    hasFilterSelected() {
      // eslint-disable-next-line no-unused-vars
      const { page, search, ...filter } = this.routeQuery;
      const keys = Object.keys(filter);

      return !!search || keys.length > 0;
    },
  },
  watch: {
    params(newParams, oldParams) {
      if (!isEqual(newParams, oldParams)) {
        this.onFetchData(newParams.id !== oldParams.id);
      }
    },
  },
  created() {
    this.$eventHub.$emit("disable-on-load");
    if (this.id) {
      this.onFetchData(true);
    }
  },
  methods: {
    onLoadMore() {
      this.isLoadMore = true;

      this.$nextTick(() => {
        const query = Object.assign({}, this.$route.query);
        query.page = this.activePage + 1;

        this.$router.push({ query: query });
      });
    },
    onFetchData(isNew) {
      this.isLoading = true;

      this.$http
        .get("catalog/default/items", {
          params: this.params,
        })
        .then(({ data, headers }) => {
          const { items } = data;
          this.itemsCount = parseInt(headers["x-pagination-pageCount-count"]);
          this.pageCount = parseInt(headers["x-pagination-page-count"]);

          if (this.isLoadMore) {
            this.itemList = [...this.itemList, ...items];
            this.isLoadMore = false;
          } else {
            this.itemList = items;
          }

          if (isNew) {
            const { filters, meta, info } = data;
            this.filterList = filters;

            this.metaTitle = info.metaTitle;
            this.pageTitle = info.pageTitle;
            this.metaList = onSetMeta(meta);
            this.$meta().refresh();
          }

          if (this.filterList) {
            this.onSetFilter(this.filterList);
          }

          this.$nextTick(() => {
            this.isLoading = false;
            document.body.classList.add("is-loaded");
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Не удалось получить список товаров.");
        });
    },
    onSetFilter(list) {
      const keys = Object.keys(this.routeQuery);

      for (let i in keys) {
        const key = keys[i];
        if (key in list) {
          this.$set(this.filterSelected, key, [
            ...this.routeQuery[key].split(","),
          ]);
        }
      }

      if ("search" in this.routeQuery) {
        this.filterSearchQuery = this.routeQuery.search;
      }

      setTimeout(() => {
        this.isFilterReady = true;
      }, 200);
    },
    onSearch() {
      // eslint-disable-next-line no-unused-vars
      const { search, ...query } = this.routeQuery;

      if (this.filterSearchQuery.length > 0) {
        query.search = this.filterSearchQuery;
      }

      this.$router.push({ query: query }).catch(() => {});
    },
    onRemoveSearch() {
      this.filterSearchQuery = "";
      this.onSearch();
    },
    onRemoveFilter({ alias, id }) {
      const list = this.filterSelected[alias];

      if (list) {
        const index = list.findIndex((item) => item === id);
        if (index > -1) {
          this.$delete(this.filterSelected[alias], index);
        }
      }

      if (alias in this.routeQuery) {
        const query = { ...this.routeQuery };
        const list = query[alias].split(",").filter((item) => item !== id);

        if (list.length === 0) {
          delete query[alias];
        } else {
          query[alias] = list.join(",");
        }

        this.$router.push({ query: query }).catch(() => {});
      }
    },
    onChange(alias, list) {
      this.filterSelected[alias] = list;

      const query = { ...this.routeQuery };

      delete query[alias];
      delete query.page;

      if (list.length > 0) query[alias] = list.join(",");
      this.$router.push({ query: query }).catch(() => {});
    },
    onReset(alias) {
      delete this.filterSelected[alias];
    },
    onClean() {
      this.$router.push({ query: {} }).catch(() => {});
      this.filterSearchQuery = "";
      for (let key in this.filterSelected) {
        this.$eventHub.$emit(`reset-filter-${key}`);
      }
      this.$modal.hide("filter");
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list-view {
  &__settings {
    padding-bottom: 9px;
  }

  &__goods {
    transition: opacity 150ms ease-in;

    .item-list-view--loading & {
      opacity: 0.5;
    }
  }

  &__loadmore {
    margin-top: 24px;
    text-align: center;
  }

  &__the-empty {
    margin-top: size(60px);
  }

  &__selected-filter-list {
    margin-bottom: size(10px);
  }
}
</style>
