import { onSetToken } from "@/plugins/api/helpers";

const state = {
  token: null,
};

const getToken = (token) => {
  if (!token) return null;

  return `Bearer ${token}`;
};

const getters = {
  isLogged: (state) => {
    return !!state.token;
  },
  isGuest: (state) => {
    return !state.token;
  },
  getHeaders: (state) => {
    if (!state.token) return null;

    return {
      Authorization: `Bearer ${state.token}`,
    };
  },
  getToken: (state) => {
    return getToken(state.token);
  },
};

const actions = {
  onSetToken: ({ commit }, token) => {
    commit("setToken", token);
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;

    onSetToken(getToken(state.token));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
