const state = {
  vin: "",
};

const actions = {
  onSetVin: ({ commit }, vin) => {
    commit("setVin", vin);
  },
};

const mutations = {
  setVin(state, vin) {
    state.vin = vin;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
