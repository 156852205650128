<template>
  <form class="delivery-calculate-form" @submit.prevent="onSubmit">
    <div class="row">
      <div class="col-sm-4">
        <form-group
          id="deliveryFormLength"
          :has-error="$v.deliveryForm.length.$error"
        >
          <template #label>Длина, см*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="deliveryForm.length"
              type="number"
              step="0.01"
              min="0"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Обязательное </template>
        </form-group>
      </div>
      <div class="col-sm-4">
        <form-group
          id="deliveryFormLength"
          :has-error="$v.deliveryForm.height.$error"
        >
          <template #label>Высота, см*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="deliveryForm.height"
              type="number"
              step="0.01"
              min="0"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Обязательное </template>
        </form-group>
      </div>
      <div class="col-sm-4">
        <form-group
          id="deliveryFormLength"
          :has-error="$v.deliveryForm.width.$error"
        >
          <template #label>Ширина, см*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="deliveryForm.width"
              type="number"
              step="0.01"
              min="0"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Обязательное </template>
        </form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <form-group
          id="deliveryFormLength"
          :has-error="$v.deliveryForm.weight.$error"
        >
          <template #label>Вес, кг*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="deliveryForm.weight"
              type="number"
              step="0.01"
              min="0"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Обязательное </template>
        </form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <form-group
          id="deliveryFormLength"
          :has-error="$v.deliveryForm.townFrom.$error"
        >
          <template #label>Откуда*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="deliveryForm.townFrom"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Обязательное </template>
        </form-group>
      </div>
      <div class="col-sm-6">
        <form-group
          id="deliveryFormLength"
          :has-error="$v.deliveryForm.townTo.$error"
        >
          <template #label>Куда*</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="deliveryForm.townTo"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
          <template #error> Обязательное </template>
        </form-group>
      </div>
    </div>

    <div class="delivery-calculate-form__footer">
      <base-btn type="submit">Рассчитать</base-btn>
    </div>
  </form>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import FormGroup from "@/components/shared/FormGroup";
// utils
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    BaseBtn,
    FormGroup,
  },
  data() {
    return {
      deliveryForm: {
        length: null,
        height: null,
        width: null,
        weight: null,
        townFrom: null,
        townTo: null,
      },
      isDisabled: false,
    };
  },
  validations: {
    deliveryForm: {
      length: {
        required,
      },
      height: {
        required,
      },
      width: {
        required,
      },
      weight: {
        required,
      },
      townFrom: {
        required,
      },
      townTo: {
        required,
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("submit", this.deliveryForm);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-calculate-form {
  &__footer {
    text-align: center;
  }
}
</style>
