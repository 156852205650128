<template>
  <page-layout-full>
    <template #title>
      {{ title }}
    </template>

    <base-loading v-if="isLoading" />
    <p v-else-if="isError">
      Не получилось зайти по пользователем. Проверьте ссылку.
    </p>
    <p v-else-if="isSuccess">
      Подождите. Сейчас вас перенаправит на главную страницу.
    </p>
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import BaseLoading from "@/components/shared/BaseLoading";
// utils
import { mapActions } from "vuex";

export default {
  components: {
    PageLayoutFull,
    BaseLoading,
  },
  data() {
    return {
      isLoading: true,
      isError: false,
      isSuccess: false,
      userForm: {
        login: null,
        key: null,
      },
    };
  },
  computed: {
    title() {
      if (this.isError) return "Ошибка";
      if (this.isSuccess) return "Успешно";

      return "Подождите...";
    },
  },
  mounted() {
    const { login, key } = this.$route.query;

    if (login && key) {
      this.userForm.login = login;
      this.userForm.key = key;

      this.onLoginByKey(this.userForm);
    } else {
      this.$router.replace({
        name: "home",
      });
    }
  },
  methods: {
    ...mapActions({
      onSetBaseUser: "account/onSetBaseUser",
      onSetToken: "auth/onSetToken",
      onCleanCart: "cart/onClean",
    }),
    onLoginByKey(form) {
      this.isLoading = true;
      this.isError = false;

      this.$http
        .get("/users/security/login-by-key", {
          params: form,
        })
        .then(({ data }) => {
          this.isLoading = false;
          this.isSuccess = true;

          if (data.status === "ok") {
            this.$modal.hide("login");
            this.onCleanCart();

            const { profile } = data.user;

            this.onSetToken(data.user.authKey);
            this.onSetBaseUser({
              id: data.user.id,
              profile: {
                email: profile.email,
                phone: profile.phone,
                name: profile.name,
                patronymic: profile.patronymic,
                surname: profile.surname,
                balanceD: profile.balanceD,
              },
            });

            this.$eventHub.$emit("start");
            this.$eventHub.$emit("authorized");

            this.$router.replace({
              name: "home",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.isError = true;
          console.error(error);
        });
    },
  },
};
</script>
