<template>
  <ul class="address-list">
    <li
      v-for="item in list"
      :key="`address-${item.id}`"
      class="address-list__item"
    >
      <slot :item="item"></slot>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.address-list {
  @include list-unstyled;
  max-width: 700px;
  margin: 0 auto;

  &__item {
    border-bottom: 1px solid $grey-300;
  }
}
</style>
