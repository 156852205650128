<template>
  <div class="filter-prop-default">
    <slot :list="list"></slot>
  </div>
</template>

<script>
import isObject from "lodash/isObject";

const isNumeric = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export default {
  props: {
    item: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    list() {
      if (isObject(this.item)) {
        const list = [];
        for (const [key, value] of Object.entries(this.item)) {
          list.push({
            id: key,
            title: isNumeric(value) ? parseFloat(value) : value,
          });
        }

        return list;
      }

      return this.item;
    },
  },
};
</script>
