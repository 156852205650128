import { render, staticRenderFns } from "./FeaturedGoodListItem.vue?vue&type=template&id=0d5e7fbc&scoped=true&"
import script from "./FeaturedGoodListItem.vue?vue&type=script&lang=js&"
export * from "./FeaturedGoodListItem.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedGoodListItem.vue?vue&type=style&index=0&id=0d5e7fbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5e7fbc",
  null
  
)

export default component.exports