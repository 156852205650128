<template>
  <div v-if="phoneList.length > 0" class="base-phone" :class="`phone--${view}`">
    <base-icon
      class="base-phone__cell"
      name="call-outline"
      width="16"
      height="16"
    />

    <a
      v-for="(phone, index) in phoneList"
      :key="`phone-${index}`"
      class="base-phone__item"
      :href="`tel:+7${phone.replace(/\D/g, '')}`"
    >
      +7 {{ phone }}
    </a>

    <template v-if="fullPhoneList.length > 1">
      <base-popup v-if="isLg" class="base-phone__popup">
        <template #title>
          <a class="base-phone__toggle" aria-label="Список телефонных номеров">
            Все номера
            <base-icon
              class="base-phone__down"
              name="down-outline"
              width="16"
              height="16"
            />
          </a>
        </template>

        <dl
          v-for="(item, index) in fullPhoneList"
          :key="`phone-full-${index}`"
          class="base-phone__list"
        >
          <dt class="base-phone__title">{{ item.phoneComment }}</dt>
          <dd class="base-phone__value">
            +7 ({{ item.phoneCode }}) {{ item.phoneNumber }}
          </dd>
        </dl>
      </base-popup>

      <template v-else>
        <a
          class="base-phone__toggle"
          aria-label="Список телефонных номеров"
          @click="$modal.show('phone')"
        >
          Все номера
          <base-icon
            class="base-phone__down"
            name="down-outline"
            width="16"
            height="16"
          />
        </a>

        <phone-modal>
          <template #default>
            <dl
              v-for="(item, index) in fullPhoneList"
              :key="`phone-full-${index}`"
              class="base-phone__list"
            >
              <dt class="base-phone__title">{{ item.phoneComment }}</dt>
              <dd class="base-phone__value">
                +7 ({{ item.phoneCode }}) {{ item.phoneNumber }}
              </dd>
            </dl>
          </template>
        </phone-modal>
      </template>
    </template>
  </div>
</template>

<script>
import BaseIcon from "../../shared/BaseIcon.vue";
import BasePopup from "../../shared/BasePopup.vue";
import PhoneModal from "./PhoneModal.vue";
// utils
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    BaseIcon,
    BasePopup,
    PhoneModal,
  },
  props: {
    view: {
      type: String,
      default: "dark",
    },
  },
  computed: {
    ...mapState("settings", {
      fullPhoneList: (state) => state.city.phoneList,
    }),
    ...mapGetters({
      isXl: "mq/getIsXl",
      isLg: "mq/getIsLg",
      phoneNumberList: "settings/phoneNumberList",
    }),
    size() {
      return this.isXl ? 2 : 1;
    },
    hasAll() {
      return this.phoneNumberList.length > this.size;
    },
    phoneList() {
      return this.hasAll
        ? this.phoneNumberList.slice(0, this.size)
        : this.phoneNumberList;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-phone {
  align-items: center;
  display: flex;

  &__cell {
    fill: $mint-100;
    margin-right: 4px;
  }

  &__item {
    @extend %text-nav;

    text-decoration: none;

    .phone--dark & {
      color: $white;
    }

    .phone--light & {
      color: $coal;
      font-weight: bold;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 24px;
    }
  }

  &__toggle {
    @extend %text-nav;

    color: $grey-200;
    cursor: pointer;
    display: inline-flex;
    margin-left: auto;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      padding-bottom: 2px;
    }
  }

  &__down {
    fill: $grey-200;
    margin-left: 2px;
  }

  &__list {
    margin-bottom: 0;

    & + & {
      margin-top: 10px;
    }
  }

  &__title {
    @extend %text-nav;
    color: $stone;
    font-weight: normal;
  }

  &__value {
    @extend %h3;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
