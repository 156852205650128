<template>
  <div class="header-tab-list">
    <h2 class="header-tab-list__title">
      <slot name="title"></slot>
    </h2>
    <div class="header-tab-list__nav">
      <slot name="nav"></slot>
    </div>
    <div v-if="hasExtraSlot" class="header-tab-list__extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasExtraSlot() {
      return this.$slots.extra;
    },
  },
};
</script>

<style lang="scss">
.header-tab-list {
  align-items: flex-end;
  border-bottom: 1px solid $grey-300;
  display: grid;
  gap: 28px 0;
  grid-template-areas: "title title extra" "nav nav nav";
  grid-template-columns: auto 1fr auto;
  margin-bottom: 1rem;
  margin-left: -$container-padding-x;
  margin-right: -$container-padding-x;
  padding: 0 $container-padding-x 10px;
  position: relative;

  @include media-breakpoint-up(xl) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    grid-template-areas: "title nav extra";
    gap: 0 28px;
  }

  &__title {
    grid-area: title;
    margin-bottom: 0;
  }

  &__nav {
    grid-area: nav;

    @include media-breakpoint-up(xl) {
      margin-bottom: -10px;
    }
  }

  &__extra {
    grid-area: extra;
    text-align: right;
  }
}
</style>
