<template>
  <router-link
    :to="{
      name: 'search-vendors',
      params: {
        town: $route.params.town,
        brand: item.brand,
        article: item.article,
      },
    }"
    class="featured-good-list-item"
  >
    <figure class="featured-good-list-item__picture">
      <base-image
        class="featured-good-list-item__image"
        :url="image"
        width="150"
        height="150"
        resizing-type="fill"
      />
    </figure>
    <div
      class="featured-good-list-item__price-box"
      :class="{
        'featured-good-list-item__price-box--has-old': !!item.priceOld,
      }"
    >
      <span
        v-if="item.price > 0"
        class="featured-good-list-item__price-current"
      >
        {{ item.price | formatNumber }} ₽
      </span>
      <span v-if="item.priceOld" class="featured-good-list-item__price-old">
        {{ item.priceOld | formatNumber }} ₽
      </span>
    </div>
    <div class="featured-good-list-item__title">
      {{ item.title }}
    </div>
  </router-link>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseImage,
  },
  filters: {
    formatNumber,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return this.item.image ? this.item.image.source : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-good-list-item {
  color: inherit;
  display: block;
  text-decoration: none;
  display: flex;
  flex-flow: column;
  padding-bottom: 16px;
  padding-top: 16px;

  @include media-breakpoint-up(lg) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__picture {
    text-align: center;
    margin-bottom: 8px;
  }

  &__price-box {
    white-space: nowrap;
  }

  &__price-current {
    @extend %h3;

    .featured-good-list-item__price-box--has-old & {
      color: $red;
    }
  }

  &__price-old {
    @extend %subtitle;

    color: $stone;
    margin-left: 10px;
    position: relative;

    &::after {
      border: 3px solid $red;
      content: "";
      left: 0;
      opacity: 0.7;
      position: absolute;
      right: 0;
      top: 7px;
      transform: matrix(1, -0.09, 0.08, 1, 0, 0);
      width: 100%;
    }
  }

  &__brand {
    @extend %subtitle;
    margin-right: 16px;
  }

  &__article {
    color: $primary;
  }

  &__title {
    @extend %subtitle;
  }
}
</style>
