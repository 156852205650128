<template>
  <div class="payment-form">
    <div class="row">
      <div class="col-6">
        <btn-tab
          :active="value === 'online'"
          @click="$emit('change', 'online')"
        >
          Оплата онлайн
          <span class="payment-form__system">
            <base-icon name="mir" width="57" height="28" />
            <base-icon name="visa" width="57" height="28" />
            <base-icon name="mastercard" width="57" height="28" />
            <base-icon name="maestro" width="57" height="28" />
          </span>
        </btn-tab>
      </div>
      <div class="col-6">
        <btn-tab
          :active="value === 'offline'"
          @click="$emit('change', 'offline')"
        >
          Оплата картой или наличными при получении
        </btn-tab>
      </div>
    </div>
  </div>
</template>

<script>
import BtnTab from "@/components/shared/BtnTab";
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BtnTab,
    BaseIcon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-form {
  &__system {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
      margin-left: 8px;
    }
  }
}
</style>
