var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"site-header",attrs:{"id":"site-header"}},[(_vm.isLg)?_c('div',{staticClass:"site-header__start"},[_c('base-container',[_c('div',{staticClass:"row site-header__row"},[_c('div',{staticClass:"col-auto"},[_c('base-city')],1),_c('div',{staticClass:"col-auto"},[_c('base-phone')],1),_c('div',{staticClass:"col-auto ms-auto"},[_c('header-nav')],1),_c('div',{staticClass:"col-auto"},[_c('social',{attrs:{"view":"simple"}})],1)])])],1):_c('header-navbar'),_c('div',{staticClass:"site-header__end"},[_c('base-container',[_c('div',{staticClass:"site-header__body"},[_c('div',{staticClass:"row site-header__row"},[_c('div',{staticClass:"col-md-auto"},[_c('logo',{staticClass:"site-header__logo"})],1),(!_vm.isHome)?_c('div',{staticClass:"d-none d-lg-block col-lg-auto"},[_c('base-btn',{attrs:{"id":"catalogToggler","size":"sm","icon":{
                name: 'down-outline',
                width: '24',
                height: '24',
                position: 'right',
              },"rotate":_vm.isCatalogShow},on:{"click":function($event){_vm.isCatalogShow = !_vm.isCatalogShow}}},[_vm._v(" Каталог товаров ")])],1):_vm._e(),_c('div',{staticClass:"col-md"},[_c('search-form')],1),_c('div',{staticClass:"d-none d-lg-block col-lg-3 col-xxl-2"},[_c('div',{staticClass:"site-header__actions"},[(_vm.feedback.liveEnabled)?_c('site-action',{staticClass:"site-header__action",attrs:{"icon":{
                  name: 'notification-solid',
                  width: '24',
                  height: '24',
                }},on:{"click":function($event){return _vm.onOpenLivechat($event)}}},[_vm._v(" Чат ")]):_vm._e(),(_vm.isLogged)?[_c('site-action',{staticClass:"site-header__action",attrs:{"tag":"router-link","to":{ name: 'account-order-list' },"icon":{ name: 'doc-solid', width: '24', height: '24' }}},[_vm._v(" Заказы ")]),_c('base-popup',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('site-action',{staticClass:"site-header__action",attrs:{"tag":"router-link","to":{ name: 'account-profile' },"icon":{
                        name: 'user-solid',
                        width: '24',
                        height: '24',
                      }}},[_vm._v(" Профиль ")])]},proxy:true}],null,false,3040669284)},[_c('account-nav',{staticClass:"account-nav--base-popup"})],1)]:[_c('site-action',{staticClass:"site-header__action",attrs:{"tag":"router-link","to":{
                    name: 'content',
                    params: { alias: 'contacts', town: _vm.town },
                  },"icon":{
                    name: 'location-solid',
                    width: '24',
                    height: '24',
                  }}},[_vm._v(" Адреса ")]),_c('site-action',{staticClass:"site-header__action",attrs:{"icon":{ name: 'user-solid', width: '24', height: '24' }},on:{"click":function($event){return _vm.$modal.show('login')}}},[_vm._v(" Вход ")])],_c('cart-link',{staticClass:"site-header__action"})],2)])]),(!_vm.isHome)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCatalogShow),expression:"isCatalogShow"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"site-header__catalog",class:{ 'site-header__catalog--open': _vm.isCatalogShow }},[_c('catalog-box')],1):_vm._e()])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }