var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout-full',{staticClass:"account-garage",attrs:{"breadcrumb":_vm.breadcrumb},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Список автомобилей "),_c('base-btn',{staticClass:"account-garage__btn",on:{"click":function($event){return _vm.$modal.show('garage', {
          title: 'Добавить автомобиль в гараж',
          callBack: _vm.onCreate,
        })}}},[_vm._v(" Добавить автомобиль ")])]},proxy:true},{key:"nav",fn:function(){return [_c('nav-tab')]},proxy:true}])},[(_vm.isLoading)?_c('base-loading'):(_vm.autoList.length > 0)?_c('garage-list',{attrs:{"list":_vm.autoList},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('garage-list-item',{attrs:{"item":props.item},scopedSlots:_vm._u([{key:"default",fn:function(itemProps){return [(itemProps.code)?_c('base-btn',{class:itemProps.btnClass,attrs:{"size":"sm","tag":"a","href":`${_vm.laximoPath}auto/lx/catalog?code=${itemProps.code}`}},[_vm._v(" Подобрать запчасти ")]):_vm._e(),_c('base-btn',{class:itemProps.btnClass,attrs:{"size":"sm","type":"button","view":"outline-danger","loading":_vm.isDeleting,"icon":{
              name: 'delete-outline',
              fill: '#c62925',
              width: '20',
              height: '20',
            }},on:{"click":function($event){_vm.$modal.show('delete-alert', {
                title: 'Убрать автомобиль из гаража?',
                callBack: () => _vm.onRemove(itemProps.id),
              })}}},[_vm._v(" Удалить ")]),_c('base-btn',{class:itemProps.btnClass,attrs:{"size":"sm","type":"button","view":"outline-primary"},on:{"click":function($event){return _vm.$modal.show('garage', {
                title: 'Изменить автомобиль',
                callBack: _vm.onUpdate,
                value: props.item,
              })}}},[_vm._v(" Изменить ")])]}}],null,true)})]}}])}):[_c('p',[_vm._v("У вас пока нет автомобилей.")])],_c('garage-modal')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }