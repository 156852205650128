<template>
  <div
    class="lamp-group"
    :class="[{ 'lamp-group--show': isOpen }, `lamp-group--${id}`]"
  >
    <div class="lamp-group__line"><div class="lamp-group__pointer"></div></div>

    <button class="lamp-group__toggle" type="button" @click="onToggle">
      <slot name="title"></slot>
      <base-icon
        class="lamp-group__caret"
        name="angle-right"
        width="24"
        height="24"
      />
    </button>

    <div class="lamp-group__body">
      <div class="lamp-group__grid">
        <slot></slot>
      </div>
    </div>

    <div class="lamp-group__title">
      <slot name="title"></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onToggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.lamp-group {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    position: absolute;
  }

  &__toggle,
  &__title {
    background: $primary;
    color: $white;
    font-weight: bold;
  }

  &__toggle {
    @extend %btn;
    @extend %subtitle;

    display: flex;
    padding: 10px 16px;
    width: 100%;

    &:hover {
      color: $white;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__title {
    @include font-size(0.875rem);
    display: none;
    padding: 5px 8px 6px;
    text-align: left;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__caret {
    fill: $white;
    display: block;
    margin-left: auto;
    transform: rotate(90deg);
    transition: transform 150ms ease;

    .lamp-group--show & {
      transform: rotate(-90deg);
    }
  }

  &__body {
    display: none;
    padding: 1rem 0;

    .lamp-group--show & {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      padding: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-auto-flow: column;
      gap: 0;
      border-left: 1px solid $grey-300;
    }
  }

  &__line {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    background: $primary;
    position: absolute;

    &::before,
    &::after {
      background: $primary;
      content: "";
      display: block;
      position: absolute;
    }
  }

  &__pointer {
    background: $primary;
    border-radius: 50%;
    height: 6px;
    position: absolute;
    width: 6px;
  }
}

.lamp-group--1 {
  @include media-breakpoint-up(lg) {
    left: 0;
    top: 0;
    width: 670px;
  }

  .lamp-group__line {
    bottom: -50px;
    height: 2px;
    left: 60px;
    width: 263px;

    &::before {
      bottom: 100%;
      height: 50px;
      left: 0;
      width: 2px;
    }

    &::after {
      height: 140px;
      right: 0;
      top: 100%;
      width: 2px;
    }
  }

  .lamp-group__pointer {
    bottom: -140px;
    left: 259px;
  }
}

.lamp-group--2 {
  @include media-breakpoint-up(lg) {
    right: 0;
    top: 0;
    width: 300px;
  }

  .lamp-group__line {
    bottom: -100px;
    height: 2px;
    right: 217px;
    width: 315px;

    &::before {
      height: 30px;
      left: 0;
      top: 100%;
      width: 2px;
    }

    &::after {
      bottom: 100%;
      height: 100px;
      right: 0;
      width: 2px;
    }
  }

  .lamp-group__pointer {
    bottom: -30px;
    right: 311px;
  }
}

.lamp-group--3 {
  @include media-breakpoint-up(lg) {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 150px;
    right: -50px;
    width: 2px;

    &::before {
      height: 2px;
      right: 0;
      top: 100%;
      width: 50px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      left: 0;
      width: 230px;
    }
  }

  .lamp-group__pointer {
    left: 226px;
    top: 5px;

    &::before {
      background: $primary;
      bottom: 100%;
      content: "";
      height: 5px;
      left: 2px;
      position: absolute;
      width: 2px;
    }
  }
}

.lamp-group--4 {
  @include media-breakpoint-up(lg) {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 55px);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 140px;
    left: -60px;
    width: 2px;

    &::before {
      height: 2px;
      left: 0;
      top: 100%;
      width: 60px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      right: 0;
      width: 55px;
    }
  }

  .lamp-group__pointer {
    left: -55px;
    top: -3px;
  }
}

.lamp-group--5 {
  @include media-breakpoint-up(lg) {
    bottom: 0;
    left: 0;
    width: 455px;
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fill, minmax(52px, 1fr));
      grid-auto-flow: row;
    }

    > a:nth-child(8) {
      grid-column: 1;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 315px;
    right: -20px;
    width: 2px;

    &::before {
      height: 2px;
      right: 0;
      top: 100%;
      width: 20px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      left: 0;
      width: 114px;
    }
  }

  .lamp-group__pointer {
    right: -114px;
    top: -4px;
  }
}

.lamp-group--6 {
  @include media-breakpoint-up(lg) {
    right: 329px;
    bottom: 0;
  }

  .lamp-group__line {
    bottom: 16px;
    height: 355px;
    left: -20px;
    width: 2px;

    &::before {
      height: 2px;
      left: 0;
      top: 100%;
      width: 20px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      left: 0;
      width: 35px;
    }
  }

  .lamp-group__pointer {
    right: -35px;
    top: -4px;
  }
}

.lamp-group--7 {
  @include media-breakpoint-up(lg) {
    bottom: 0;
    right: 0;
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 135px;
    left: -40px;
    width: 2px;

    &::before {
      height: 2px;
      left: 0;
      top: 100%;
      width: 40px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      right: 0;
      width: 110px;
    }
  }

  .lamp-group__pointer {
    left: -216px;
    top: -238px;

    &::before,
    &::after {
      background: $primary;
      content: "";
      display: block;
      position: absolute;
    }

    &::before {
      height: 2px;
      left: 0;
      top: 2px;
      width: 106px;
    }

    &::after {
      height: 236px;
      left: 106px;
      top: 2px;
      width: 2px;
    }
  }
}

.lamp-group--8 {
  @include media-breakpoint-up(lg) {
    left: 0;
    top: 0;
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: -50px;
    height: 2px;
    left: 60px;
    width: 280px;

    &::before {
      bottom: 100%;
      height: 50px;
      left: 0;
      width: 2px;
    }

    &::after {
      height: 114px;
      right: 0;
      top: 100%;
      width: 2px;
    }
  }

  .lamp-group__pointer {
    bottom: -114px;
    left: 276px;
  }
}

.lamp-group--9 {
  @include media-breakpoint-up(lg) {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 2px;
    left: -45px;
    width: 45px;

    &::before {
      height: 110px;
      left: 0;
      top: 100%;
      width: 2px;
    }

    &::after {
      display: none;
    }
  }

  .lamp-group__pointer {
    bottom: -110px;
    right: 41px;
  }
}

.lamp-group--10 {
  @include media-breakpoint-up(lg) {
    right: 0;
    top: 0;
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 2px;
    left: -30px;
    width: 30px;

    &::before {
      height: 50px;
      left: 0;
      top: 100%;
      width: 2px;
    }

    &::after {
      height: 2px;
      right: 100%;
      top: 50px;
      width: 447px;
    }
  }

  .lamp-group__pointer {
    bottom: -195px;
    right: 473px;

    &::before {
      background: $primary;
      bottom: 100%;
      content: "";
      display: block;
      height: 140px;
      left: 2px;
      position: absolute;
      width: 2px;
    }
  }
}

.lamp-group--11 {
  @include media-breakpoint-up(lg) {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 60px);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 100px;
    right: -30px;
    width: 2px;

    &::before {
      height: 2px;
      right: 0;
      top: 100%;
      width: 30px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      left: 0;
      width: 48px;
    }
  }

  .lamp-group__pointer {
    left: 48px;
    top: -4px;
  }
}

.lamp-group--12 {
  @include media-breakpoint-up(lg) {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .lamp-group__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: row;
    }
  }

  .lamp-group__line {
    bottom: 16px;
    height: 152px;
    left: -30px;
    width: 2px;

    &::before {
      height: 2px;
      left: 0;
      top: 100%;
      width: 30px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      right: 0;
      width: 225px;
    }
  }

  .lamp-group__pointer {
    left: -225px;
    top: -4px;
  }
}

.lamp-group--13 {
  @include media-breakpoint-up(lg) {
    bottom: 0;
    left: 0;
  }

  .lamp-group__line {
    bottom: 16px;
    height: 140px;
    right: -20px;
    width: 2px;

    &::before {
      height: 2px;
      right: 0;
      top: 100%;
      width: 20px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      right: 0;
      width: 28px;
    }
  }

  .lamp-group__pointer {
    left: -28px;
    top: -235px;

    &::before {
      background: $primary;
      content: "";
      display: block;
      height: 229px;
      left: 2px;
      position: absolute;
      top: 100%;
      width: 2px;
    }
  }
}

.lamp-group--14 {
  @include media-breakpoint-up(lg) {
    bottom: 0;
    right: 343px;
  }

  .lamp-group__line {
    bottom: 16px;
    height: 140px;
    left: -20px;
    width: 2px;

    &::before {
      height: 2px;
      left: 0;
      top: 100%;
      width: 20px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      left: 0;
      width: 110px;
    }
  }

  .lamp-group__pointer {
    right: -110px;
    top: -235px;

    &::before {
      background: $primary;
      content: "";
      display: block;
      height: 244px;
      left: 2px;
      position: absolute;
      top: 100%;
      width: 2px;
    }
  }
}

.lamp-group--15 {
  @include media-breakpoint-up(lg) {
    bottom: 0;
    right: 0;
  }

  .lamp-group__line {
    bottom: 16px;
    height: 380px;
    left: -20px;
    width: 2px;

    &::before {
      height: 2px;
      left: 0;
      top: 100%;
      width: 20px;
    }

    &::after {
      bottom: 100%;
      height: 2px;
      right: 0;
      width: 286px;
    }
  }

  .lamp-group__pointer {
    left: -286px;
    top: -4px;
  }
}
</style>
