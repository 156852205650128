const onSetLivechat = (id) => {
  const script_tag = document.createElement("script");
  script_tag.type = "text/javascript";
  script_tag.text = `(function(d, w, m) {
      window.supportAPIMethod = m;
      var s = d.createElement('script');
      s.type ='text/javascript'; s.id = 'supportScript'; s.charset = 'utf-8';
      s.async = true;
      var id = '${id}';
      s.src = 'https://lcab.talk-me.ru/support/support.js?h='+id;
      var sc = d.getElementsByTagName('script')[0];
      w[m] = w[m] || function() { (w[m].q = w[m].q || []).push(arguments); };
      if (sc) sc.parentNode.insertBefore(s, sc);
      else d.documentElement.firstChild.appendChild(s);
    })(document, window, 'TalkMe');`;
  document.head.appendChild(script_tag);
};

export default onSetLivechat;
