<template>
  <section v-if="list.length > 0" class="popular-category-list">
    <h2 class="popular-category-list__title">Популярные категории</h2>

    <div v-if="list.length > 0" class="popular-category-list__list">
      <popular-category-list-item
        v-for="item in list"
        :id="item.itemId"
        :key="`id-${item.itemId}`"
        :image="item.image.files.large"
        :title="item.title"
        :link="item.link"
      />
    </div>
  </section>
</template>

<script>
import PopularCategoryListItem from "./PopularCategoryListItem";

export default {
  components: {
    PopularCategoryListItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-category-list {
  &__title {
    margin-bottom: 40px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 0;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(6, 1fr);
      gap: 2.5rem 0;
    }
  }
}
</style>
