const state = {
  list: [],
};

const getters = {
  getFilteredList: (state) => {
    return state.list.filter(
      (item) => !!item.laximoCode && item.laximoCode.length > 0
    );
  },
  getShortList: (state, getters) => (size) => {
    const filteredList = getters.getFilteredList.filter(
      (item) => item.inShortlist === 1
    );
    if (filteredList.length < size) return filteredList;

    return filteredList.slice(0, size);
  },
  hasList: (state) => {
    return state.list.length > 0;
  },
};

const actions = {
  onSetList: ({ commit }, list) => {
    commit("setList", list);
  },
};

const mutations = {
  setList(state, list) {
    state.list = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
