<template>
  <div v-if="getModelList.length > 0" class="model-short-list">
    <div class="model-short-list__content">
      <div class="row">
        <div
          v-for="item in modelList"
          :key="`model-${item.id}`"
          class="col-6 col-sm-4 col-md-3 col-xxl-2"
        >
          <model :item="item">{{ item.title }}</model>
        </div>
      </div>
    </div>

    <footer class="model-short-list__footer">
      <base-btn
        tag="router-link"
        :to="{ name: 'auto-model-list' }"
        view="outline-primary"
        size="sm"
        :display="!isSm ? 'block' : null"
      >
        Показать все модели
      </base-btn>
    </footer>
  </div>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import Model from "@/modules/auto/components/Model";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BaseBtn,
    Model,
  },
  computed: {
    ...mapGetters({
      isSm: "mq/getIsSm",
      isMd: "mq/getIsMd",
      isXXl: "mq/getIsXXl",
      getModelList: "auto/getShortList",
    }),
    modelList() {
      if (this.isXXl) return this.getModelList(24);
      if (this.isMd) return this.getModelList(16);
      if (this.isSm) return this.getModelList(15);
      return this.getModelList(14);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-short-list {
  &__footer {
    margin-top: 40px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 26px;
      left: 0;
      width: 100%;
    }
  }
}
</style>
