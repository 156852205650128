import { cityIn, cityFrom } from "lvovich";
import { onSetTown } from "@/plugins/api/helpers";

const getTown = (city) => {
  if (!city || !city.data) return null;
  return city.data.alias;
};

const state = {
  isMenu: false,
  isModal: false,
  sWidth: 0,
  city: {
    id: null,
    country: null,
    name: null,
    nameFull: null,
    address: null,
    phone: null,
    phoneList: [],
    data: null,
    social: {},
  },
  feedback: {
    enabled: false,
    viber: null,
    telegram: null,
    whatsapp: null,
    callEnabled: false,
    messageEnabled: false,
    liveEnabled: false,
  },
  isCityСonfirm: false,
  isCookieСonfirm: false,
  noFranchise: false,
};

const getters = {
  getTown: (state) => {
    return getTown(state.city);
  },
  cityId: (state) => {
    if (!state.city.id) return null;

    return state.city.id;
  },
  cityName: (state) => {
    if (!state.city.name) return "";

    return state.city.name;
  },
  cityNameFull: (state) => {
    if (!state.city.nameFull) return "";

    return state.city.nameFull;
  },
  cityNameIn: (state) => {
    if (!state.city.name) return "";
    return cityIn(state.city.name);
  },
  cityNameFrom: (state) => {
    if (!state.city.name) return "";
    return cityFrom(state.city.name);
  },
  countryName: (state) => {
    if (!state.city || !state.city.country) return "";

    return state.city.country.title;
  },
  phoneNumberList: (state) => {
    if (state.city.phoneList.length === 0) return [];

    return state.city.phoneList
      .filter((item) => item.phoneNumber.length > 0)
      .map((item) => `(${item.phoneCode}) ${item.phoneNumber}`);
  },
};

const actions = {
  onSetSettings: ({ commit }, payload) => {
    commit("setSettings", payload);
  },
  onSetCity: ({ commit }, payload) => {
    commit("setCity", payload);
  },
  onSetFeedback: ({ commit }, payload) => {
    commit("setFeedback", payload);
  },
};

const mutations = {
  setSettings(state, { key, value }) {
    state[key] = value;
  },
  setCity(state, city) {
    state.city = city;
    onSetTown(getTown(city));
  },
  setFeedback(state, { key, value }) {
    state.feedback[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
