const state = {
  id: null,
  profile: {},
};

const getters = {
  getPhone: (state) => {
    return state.profile ? state.profile.phone : null;
  },
};

const actions = {
  onSetBaseUser: ({ commit }, user) => {
    commit("setBaseUser", user);
  },
};

const mutations = {
  setBaseUser(state, { id, type, profile }) {
    state.id = id;
    state.type = type;
    state.profile = profile;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
