<template>
  <div v-if="count >= 0 && count < 100" class="progress-bar">
    <div class="progress-bar__title">
      <slot></slot>
    </div>

    <div class="progress-bar__rail">
      <div class="progress-bar__bar" :style="{ width: `${count}%` }">
        {{ Math.round(count) }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  align-items: center;
  display: flex;

  &__title {
    margin-right: 16px;
  }

  &__rail {
    background-color: $smoke;
    border-radius: 42px;
    height: 16px;
    flex-grow: 1;
  }

  &__bar {
    @include font-size(12px);

    background-color: $primary;
    border-radius: 42px;
    color: $white;
    font-weight: bold;
    height: 16px;
    text-align: center;
    transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
</style>
