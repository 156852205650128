const state = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
};

const getters = {
  getIsXs: (state) => {
    return state.windowWidth >= 0;
  },
  getIsSm: (state) => {
    return state.windowWidth >= 576;
  },
  getIsMd: (state) => {
    return state.windowWidth >= 768;
  },
  getIsLg: (state) => {
    return state.windowWidth >= 992;
  },
  getIsXl: (state) => {
    return state.windowWidth >= 1200;
  },
  getIsXXl: (state) => {
    return state.windowWidth >= 1380;
  },
  isRetinaDisplay: () => {
    if (window.matchMedia) {
      var mq = window.matchMedia(
        "only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)"
      );
      return (mq && mq.matches) || window.devicePixelRatio > 1;
    }
  },
};

const actions = {
  onSetWindowWidth: ({ commit }) => {
    commit("setWindowWidth");
  },
  onSetWindowHeight: ({ commit }) => {
    commit("setWindowHeight");
  },
};

const mutations = {
  setWindowWidth(state) {
    state.windowWidth = window.innerWidth;
  },
  setWindowHeight(state) {
    state.windowHeight = window.innerHeight;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
