<template>
  <div class="good-list">
    <div class="good-list__header">
      <form-check id="selectAll">
        <template #default="props">
          <input
            :id="props.id"
            v-model="selectAll"
            :class="props.inputClass"
            :type="props.type"
          />
        </template>
        <template #label>Выбрать все</template>
      </form-check>
    </div>
    <good-list-item
      v-for="(item, index) in list"
      :key="`item-${item.hash}`"
      :index="index"
      :item="item"
      :is-cart-disabled="isDisabled"
      class="good-list__item"
    >
      <template #checkbox>
        <form-check id="selectAll">
          <template #default="props">
            <input
              :id="props.id"
              v-model="selected"
              :class="props.inputClass"
              :type="props.type"
              :value="item.hash"
            />
          </template>
        </form-check>
      </template>
    </good-list-item>
  </div>
</template>

<script>
import FormCheck from "@/components/shared/FormCheck";
import GoodListItem from "./GoodListItem";
// utils
import { mapActions } from "vuex";

export default {
  components: {
    GoodListItem,
    FormCheck,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    selectAll: {
      get() {
        return this.list ? this.selected.length === this.list.length : false;
      },
      set(value) {
        var selected = [];

        if (value) {
          this.list.forEach((item) => {
            selected.push(item.hash);
          });
        }

        this.selected = selected;
      },
    },
  },
  watch: {
    selected(val) {
      this.onChangeValue({ key: "selectedItems", value: val });
    },
  },
  mounted() {
    this.selected = this.list.map((item) => item.hash);
  },
  methods: {
    ...mapActions("cart", {
      onChangeValue: "onUpdateValue",
    }),
  },
};
</script>

<style lang="scss" scoped>
.good-list {
  &__header {
    border-bottom: 1px solid $grey-300;
    padding-bottom: 0.5rem;
  }
}
</style>
