<template>
  <a
    class="base-banner"
    :class="[`base-banner--${view}`]"
    href="https://avtovelikan.ru/franchise"
    target="_blank"
  >
    <div class="base-banner__row">
      <div class="base-banner__price">
        8 500 <span class="base-banner__rouble">₽</span>
      </div>
      <div class="base-banner__text">
        <div class="base-banner__title">Франшиза</div>
        <div class="base-banner__subtitle">
          интернет-магазина<br />
          автозапчастей
        </div>
      </div>
      <div class="base-banner__action">
        <base-btn tag="div" view="secondary" class="base-banner__btn"
          >Узнать условия</base-btn
        >
      </div>
    </div>
  </a>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    BaseBtn,
  },
  props: {
    view: {
      type: String,
      default: "blue",
    },
  },
};
</script>

<style lang="scss" scoped>
.base-banner {
  background: no-repeat left top;
  border-radius: 3px;
  color: $white;
  display: block;
  padding: 10px 12px;
  text-decoration: none;
  overflow: hidden;

  &:hover {
    color: $white;
  }

  &.base-banner--blue {
    background-color: #2c2292;

    @include media-breakpoint-up(md) {
      background-image: url("@/assets/pages/banner__blue-bg.png");
    }
  }

  &.base-banner--black {
    background-color: $black;

    @include media-breakpoint-up(md) {
      background-image: url("@/assets/pages/banner__black-bg.png");
    }
  }

  &.base-banner--car {
    background: url("@/assets/pages/banner__car-bg.png") 30% center no-repeat;
    background-color: $black;
    background-size: cover;

    @include media-breakpoint-up(md) {
      padding-bottom: 32px;
      padding-top: 32px;
    }
  }

  &__row {
    @include media-breakpoint-up(md) {
      align-items: center;
      display: grid;
      gap: 0 $grid-gutter-width;

      .base-banner--car & {
        grid-template-columns: auto 1fr auto;
      }

      .base-banner--blue &,
      .base-banner--black & {
        grid-template-columns: 1fr 2fr 1fr;
        min-height: 84px;
      }
    }
  }

  &__price {
    font-size: 22px;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      padding: 0 25px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 26px;
    }

    .base-banner--car & {
      border-bottom: 5px solid $primary;
      display: inline-block;
      font-size: 34px;
      line-height: 1;
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        border-right: 5px solid $primary;
        border-bottom: none;
        font-size: 64px;
        line-height: 80px;
        margin-bottom: 0;
        min-height: 80px;
      }
    }
  }

  &__rouble {
    .base-banner--car & {
      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 1;
      }
    }
  }

  &__text {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      .base-banner--blue &,
      .base-banner--black & {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0 $grid-gutter-width;
      }
    }
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;

    .base-banner--blue & {
      color: #00e5df;
    }

    @include media-breakpoint-up(xl) {
      font-size: 50px;
    }

    .base-banner--car & {
      font-size: 22px;
      font-weight: normal;
      line-height: 1;

      @include media-breakpoint-up(xl) {
        font-size: 32px;
      }
    }
  }

  &__subtitle {
    font-size: 20px;
    line-height: 1;

    @include media-breakpoint-up(xl) {
      font-size: 28px;
    }

    .base-banner--car & {
      font-size: 22px;
      font-weight: normal;
      line-height: 1;

      @include media-breakpoint-up(md) {
        font-size: 32px;
        font-weight: bold;
        line-height: 1;
      }
    }
  }

  &__btn {
    white-space: nowrap;
  }
}
</style>
