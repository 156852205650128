<template>
  <div class="adress-list-item">
    <div class="adress-list-item__title">
      {{ item.address }}
    </div>

    <div class="adress-list-item__description" v-html="description"></div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    description() {
      return this.item.description.replace(/\n/g, "<br />");
    },
  },
};
</script>

<style lang="scss" scoped>
.adress-list-item {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 125px;
  gap: $grid-gutter-width * 0.5;
  padding: 8px 16px;

  &:hover {
    background: $mint-100;
  }

  &__title {
    @extend %subtitle;

    :hover > & {
      color: $white;
    }
  }

  &__description {
    color: $grey-100;

    :hover > & {
      color: $white;
    }
  }
}
</style>
