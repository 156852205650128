<template>
  <form class="delivery-search-form" @submit.prevent="onSubmit">
    <div class="delivery-search-form__group">
      <input
        v-model="searchQuery"
        class="delivery-search-form__control"
        type="text"
        @focus="isShow = true"
        @blur="isShow = false"
      />
      <button class="delivery-search-form__btn">
        <base-icon name="package" width="24" height="24" />
      </button>
    </div>
    <div v-if="isShow" class="delivery-search-form__dropdown">
      <div class="delivery-search-form__content">
        <h4 class="delivery-search-form__title">Выберите способ доставки</h4>
        <ul class="delivery-search-form__list">
          <li class="delivery-search-form__item">
            <a class="delivery-search-form__link">Курьер</a>
            <dl class="delivery-search-form__variation">
              <dt aria-label="Срок">
                <base-icon
                  name="time-outline"
                  fill="#C7C7CC"
                  width="24"
                  height="24"
                />
              </dt>
              <dd>От 2 до 3 дней</dd>
              <dt aria-label="Стоимость">
                <base-icon
                  name="cash-outline"
                  fill="#C7C7CC"
                  width="24"
                  height="24"
                />
              </dt>
              <dd>2 406 ₽</dd>
            </dl>
          </li>
          <li class="delivery-search-form__item">
            <a class="delivery-search-form__link">Самовывоз</a>
            <dl class="delivery-search-form__variation">
              <dt aria-label="Срок">
                <base-icon
                  name="time-outline"
                  fill="#C7C7CC"
                  width="24"
                  height="24"
                />
              </dt>
              <dd>От 2 до 3 дней</dd>
              <dt aria-label="Стоимость">
                <base-icon
                  name="cash-outline"
                  fill="#C7C7CC"
                  width="24"
                  height="24"
                />
              </dt>
              <dd>700 ₽</dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    city: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchQuery: this.city,
      isShow: false,
    };
  },
  methods: {
    onSubmit() {
      console.info("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-search-form {
  &__group {
    display: flex;
  }

  &__control {
    @include font-size($input-font-size);
    background-clip: padding-box;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 4px;
    color: $coal;
    display: block;
    height: $input-height;
    line-height: $input-line-height;
    padding: $input-padding-y $input-padding-x;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &__btn {
    @extend %btn;
    background-color: $stone;
    border-radius: 4px;
    margin-left: 4px;
    width: 56px;
  }

  &__dropdown {
    margin-top: 4px;
  }

  &__content {
    background: $white;
    border-radius: 4px;
    padding: 10px 16px;
  }

  &__title {
    @extend %subtitle;
  }

  &__list {
    @include list-unstyled;
  }

  &__item {
    & + & {
      margin-top: 10px;
    }
  }

  &__link {
    color: $coal;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }

  &__variation {
    align-items: center;
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 10px 8px;
    margin: 10px 0 0 16px;

    > * {
      margin-bottom: 0;
    }
  }
}
</style>
