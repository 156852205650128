<template>
  <page-layout-full class="model-list" :breadcrumb="breadcrumb">
    <vin-search class="model-list__vin-search" />
    <model-search class="model-list__model-search" />
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import VinSearch from "../components/VinSearch";
import ModelSearch from "../components/ModelSearch";

export default {
  components: {
    PageLayoutFull,
    VinSearch,
    ModelSearch,
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Все бренды",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.model-list {
  &__vin-search {
    margin-bottom: 40px;
  }
}
</style>
