<template>
  <modal
    name="garage"
    :max-width="620"
    height="auto"
    adaptive
    class="garage-modal"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <vuescroll>
      <modal-layout @close="$modal.hide('garage')">
        <h4>{{ title }}</h4>

        <garage-form
          :value="value"
          @submit="onCallBack"
          @close="$modal.hide('garage')"
        />
      </modal-layout>
    </vuescroll>
  </modal>
</template>

<script>
import ModalLayout from "@/components/shared/ModalLayout";
import GarageForm from "./GarageForm";
import vuescroll from "vuescroll";

export default {
  components: {
    ModalLayout,
    GarageForm,
    vuescroll,
  },
  data() {
    return {
      title: null,
      onCallBack: null,
      value: null,
    };
  },
  methods: {
    onBeforeOpen(e) {
      const { params } = e;

      if (params) {
        this.title = params.title;
        this.onCallBack = params.callBack;

        this.value = params.value || null;
      }
    },
    onBeforeClose() {
      this.title = null;
      this.onCallBack = null;
    },
  },
};
</script>
