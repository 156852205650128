var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"category"},[(!_vm.isActive)?_c('header',{staticClass:"category__header"},[_c('h3',{staticClass:"category__title"},[_c('router-link',{staticClass:"category__title-link",attrs:{"to":{
          name: 'category-item',
          params: { town: _vm.$route.params.town, category: _vm.category.url },
        }},nativeOn:{"mouseenter":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_c('base-icon',{staticClass:"category__title-icon",attrs:{"name":_vm.isHover ? `${_vm.category.url}-hover` : _vm.category.url,"width":"32","height":"32"}}),_vm._v(" "+_vm._s(_vm.category.title)+" ")],1)],1)]):_vm._e(),_c('div',{staticClass:"category__content"},[_c('div',{staticClass:"row"},_vm._l((_vm.splittedList),function(chunk,chunkIndex){return _c('div',{key:`chunk-${chunkIndex}`,staticClass:"col-sm-6 col-lg-3"},[_c('ul',{staticClass:"category__list"},[(_vm.isAutoparts && chunkIndex === 0)?_c('li',{staticClass:"category__item"},[_c('a',{staticClass:"category__link text-bold",attrs:{"href":`${_vm.laximoPath}auto/lx/index`}},[_vm._v(" Каталоги для подбора автозапчастей ")])]):_vm._e(),_vm._l((chunk),function(item,index){return _c('li',{key:`item-${chunkIndex}-${index}`,staticClass:"category__item"},[_c('router-link',{staticClass:"category__link",attrs:{"to":{
                name: 'item-list',
                params: {
                  town: _vm.$route.params.town,
                  category: _vm.category.url,
                  subCategory: item.url,
                },
              }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),(
              _vm.isAutoparts &&
              chunkIndex === _vm.splittedList.length - 1 &&
              _vm.feedback.liveEnabled
            )?_c('li',{staticClass:"category__item category__item--has-button"},[_c('base-btn',{staticClass:"text-nowrap",attrs:{"size":"sm","view":"secondary"},on:{"click":function($event){return _vm.onOpenLivechat($event)}}},[_vm._v(" Помощь в подборе → ")])],1):_vm._e()],2)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }