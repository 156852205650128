var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-card"},[_c('div',{staticClass:"order-card__header"},[_c('div',{staticClass:"order-card__heading"},[_c('h3',{staticClass:"order-card__title"},[_c('router-link',{staticClass:"order-card__title-link",attrs:{"to":{ name: 'account-order-item', params: { orderId: _vm.item.id } }}},[_vm._v("Заказ № "+_vm._s(_vm.item.id))]),_c('a',{staticClass:"order-card__print",attrs:{"href":"#print-order","title":"Печатная версия заказа"},on:{"click":function($event){$event.preventDefault();return _vm.onGetOrder.apply(null, arguments)}}},[_c('base-icon',{attrs:{"name":"pdf","height":"24","width":"25"}}),_vm._v(" Заказ ")],1)],1),_c('p',{staticClass:"order-card__subtitle",class:[
          _vm.isDebt > 0
            ? 'order-card__subtitle--warning'
            : 'order-card__subtitle--success',
        ]},[_vm._v(" "+_vm._s(_vm.isDebt ? "Не оплачен" : "Оплачен")+" ")])]),_c('base-changer',{staticClass:"order-card__base-changer",attrs:{"id":`base-changer-${_vm.item.id}`,"label":"Посмотреть детали","value":_vm.isShow},on:{"change":_vm.onToggle}})],1),_c('div',{staticClass:"order-card__body"},[_c('div',{staticClass:"order-card__section"},[_c('dl',{staticClass:"order-card__info"},[_c('dt',[_vm._v("Дата оформления:")]),_c('dd',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.date,"dd MMMM yyy")))]),(_vm.item.paymentService)?[_c('dt',[_vm._v("Способ оплаты:")]),_c('dd',[_vm._v(_vm._s(_vm.item.paymentService.title))])]:_vm._e(),(_vm.item.deliveryPrice > 0)?[_c('dt',[_vm._v("Стоимость доставки:")]),_c('dd',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.item.deliveryPrice))+" ₽")])]:_vm._e(),_c('dt',[_vm._v("Общая сумма заказа:")]),_c('dd',[_c('strong',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.total))+" ₽")])]),(_vm.item.managerComment && _vm.item.managerComment.length > 0)?[_c('dt',[_vm._v("Комментарий:")]),_c('dd',[_vm._v(" "+_vm._s(_vm.item.managerComment)+" ")])]:_vm._e()],2)]),(_vm.isShow)?[_vm._l((_vm.item.items),function(good){return _c('order-card-item',{key:good.id,staticClass:"order-card__section",attrs:{"item":good}})}),_c('footer',{staticClass:"order-card__footer"},[(_vm.item.debt > 0)?_c('base-btn',{staticClass:"order-card__action-btn",attrs:{"size":"sm","tag":"router-link","to":{
            name: 'account-order-item-payment',
            params: { orderId: _vm.item.id },
          }}},[_vm._v(" Оплатить ")]):_vm._e()],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }