<template>
  <div class="product-list-item" :class="[`product-list-item--${view}`]">
    <router-link
      :to="{
        name: 'item',
        params: { town: $route.params.town, id: `${item.url}-${item.id}` },
      }"
      class="product-list-item__link"
    >
      <figure class="product-list-item__picture">
        <base-image
          class="product-list-item__image"
          :url="imageSrc"
          :width="imageSize"
          :height="imageSize"
        />
      </figure>
      <div class="product-list-item__body">
        <div v-if="item.brand || item.article" class="product-list-item__desc">
          <div v-if="item.brand" class="product-list-item__brand">
            {{ item.brand }}
          </div>
          <div v-if="item.article" class="product-list-item__article">
            {{ item.article }}
          </div>
        </div>
        <div class="product-list-item__title">
          {{ item.fulltitle }}
        </div>
      </div>
      <div v-if="item.quantity > 0" class="product-list-item__deliver">
        <base-badge v-if="item.quantity > 0" title="Количество:">
          {{ item.quantity }} шт
        </base-badge>
      </div>
    </router-link>
    <div v-if="item.brand && item.article" class="product-list-item__action">
      <base-btn
        class="product-list-item__btn"
        size="sm"
        tag="router-link"
        :to="{
          name: 'search-vendors',
          params: {
            town: $route.params.town,
            brand: item.brand,
            article: item.article,
          },
        }"
      >
        Найти у поставщиков
      </base-btn>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";
import BaseBtn from "@/components/shared/BaseBtn.vue";
import BaseBadge from "./BaseBadge.vue";
// utils
import formatNumber from "@/utils/formatNumber";

export default {
  components: {
    BaseBtn,
    BaseBadge,
    BaseImage,
  },
  filters: {
    formatNumber,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    view: {
      type: String,
      default: "col",
    },
  },
  computed: {
    imageSrc() {
      return this.item.image ? this.item.image.source : null;
    },
    imageSize() {
      return this.view === "row" ? "96" : "160";
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list-item {
  display: flex;
  word-break: break-all;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  &--col {
    flex-flow: column;
    height: 100%;
    padding-bottom: 24px;
    padding-top: 16px;

    @include media-breakpoint-up(lg) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &--row {
    align-items: center;
    padding: 8px 0;
  }

  &__link {
    color: inherit;
    display: block;
    text-decoration: none;

    .product-list-item--row & {
      align-items: center;
      display: flex;
      flex-grow: 1;
    }
  }

  &__picture {
    .product-list-item--col & {
      text-align: center;
      margin-bottom: 8px;
    }

    .product-list-item--row & {
      flex: 0 0 96px;
      max-width: 96px;
      margin-right: 16px;
    }
  }

  &__image {
    .product-list-item--col & {
      height: 120px;
      width: auto;
    }

    .product-list-item--row & {
      @include img-fluid;
    }
  }

  &__price-box {
    white-space: nowrap;

    .product-list-item--row & {
      order: 1;
      margin-left: auto;
    }
  }

  &__price-current {
    @extend %h3;

    .product-list-item__price-box--has-old & {
      color: $red;
    }
  }

  &__price-old {
    @extend %subtitle;

    color: $stone;
    margin-left: 10px;
    position: relative;

    &::after {
      border: 3px solid $red;
      content: "";
      left: 0;
      opacity: 0.7;
      position: absolute;
      right: 0;
      top: 7px;
      transform: matrix(1, -0.09, 0.08, 1, 0, 0);
      width: 100%;
    }
  }

  &__desc {
    align-items: baseline;
    display: flex;
    margin-bottom: 4px;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }

  &__brand {
    @extend %subtitle;
    margin-right: 16px;
  }

  &__article {
    color: $primary;
  }

  &__title {
    @extend %text-nav;
    color: $stone;
  }

  &__body,
  &__deliver {
    .product-list-item--col & {
      margin-bottom: 14px;
    }

    .product-list-item--row & {
      margin-right: 14px;
    }
  }

  &__body {
    .product-list-item--row & {
      flex-grow: 1;
    }
  }

  &__deliver {
    .product-list-item--row & {
      margin-left: auto;
    }
  }

  &__action {
    .product-list-item--row & {
      margin-left: 14px;
    }

    .product-list-item--col & {
      margin-top: auto;
    }
  }

  &__btn {
    white-space: nowrap;
  }
}
</style>
