<template>
  <div class="badge" :class="[`badge--${view}`]">
    <span v-if="title" class="badge__title">
      {{ title }}
    </span>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  @extend %text-sm;

  border-radius: 4px;
  display: inline-block;
  padding: 2px 8px;
  white-space: nowrap;

  &--default {
    background-color: $smoke;
  }

  &--yellow {
    background-color: $melon-40;
  }

  &__title {
    font-weight: bold;
  }
}
</style>
