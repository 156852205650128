<template>
  <header id="site-header" class="site-header">
    <div v-if="isLg" class="site-header__start">
      <base-container>
        <div class="row site-header__row">
          <div class="col-auto"><base-city /></div>
          <div class="col-auto"><base-phone /></div>
          <div class="col-auto ms-auto"><header-nav /></div>
          <div class="col-auto"><social view="simple" /></div>
        </div>
      </base-container>
    </div>
    <header-navbar v-else />

    <div class="site-header__end">
      <base-container>
        <div class="site-header__body">
          <div class="row site-header__row">
            <div class="col-md-auto">
              <logo class="site-header__logo" />
            </div>
            <div v-if="!isHome" class="d-none d-lg-block col-lg-auto">
              <base-btn
                id="catalogToggler"
                size="sm"
                :icon="{
                  name: 'down-outline',
                  width: '24',
                  height: '24',
                  position: 'right',
                }"
                :rotate="isCatalogShow"
                @click="isCatalogShow = !isCatalogShow"
              >
                Каталог товаров
              </base-btn>
            </div>
            <div class="col-md">
              <search-form />
            </div>
            <div class="d-none d-lg-block col-lg-3 col-xxl-2">
              <div class="site-header__actions">
                <site-action
                  v-if="feedback.liveEnabled"
                  class="site-header__action"
                  :icon="{
                    name: 'notification-solid',
                    width: '24',
                    height: '24',
                  }"
                  @click="onOpenLivechat($event)"
                >
                  Чат
                </site-action>

                <template v-if="isLogged">
                  <site-action
                    class="site-header__action"
                    tag="router-link"
                    :to="{ name: 'account-order-list' }"
                    :icon="{ name: 'doc-solid', width: '24', height: '24' }"
                  >
                    Заказы
                  </site-action>

                  <base-popup>
                    <template #title>
                      <site-action
                        class="site-header__action"
                        tag="router-link"
                        :to="{ name: 'account-profile' }"
                        :icon="{
                          name: 'user-solid',
                          width: '24',
                          height: '24',
                        }"
                      >
                        Профиль
                      </site-action>
                    </template>
                    <account-nav class="account-nav--base-popup" />
                  </base-popup>
                </template>

                <template v-else>
                  <site-action
                    class="site-header__action"
                    tag="router-link"
                    :to="{
                      name: 'content',
                      params: { alias: 'contacts', town: town },
                    }"
                    :icon="{
                      name: 'location-solid',
                      width: '24',
                      height: '24',
                    }"
                  >
                    Адреса
                  </site-action>

                  <site-action
                    class="site-header__action"
                    :icon="{ name: 'user-solid', width: '24', height: '24' }"
                    @click="$modal.show('login')"
                  >
                    Вход
                  </site-action>
                </template>

                <cart-link class="site-header__action" />
              </div>
            </div>
          </div>

          <div
            v-if="!isHome"
            v-show="isCatalogShow"
            v-click-outside="onClickOutside"
            class="site-header__catalog"
            :class="{ 'site-header__catalog--open': isCatalogShow }"
          >
            <catalog-box />
          </div>
        </div>
      </base-container>
    </div>
  </header>
</template>

<script>
import Logo from "./shared/Logo";
import Social from "./shared/Social";
import SearchForm from "./header/SearchForm";
import SiteAction from "./header/SiteAction";
import CartLink from "./header/CartLink";
import BaseCity from "./header/BaseCity";
import BasePhone from "./header/BasePhone";
import HeaderNav from "./header/HeaderNav";
import HeaderNavbar from "./header/HeaderNavbar";
import AccountNav from "./header/AccountNav";
import BasePopup from "../shared/BasePopup";
import BaseBtn from "../shared/BaseBtn";
import BaseContainer from "../shared/BaseContainer";
import CatalogBox from "@/modules/catalog/components/nav/CatalogBox";
// utils
import { mapGetters, mapState } from "vuex";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    Logo,
    SearchForm,
    SiteAction,
    CartLink,
    BaseCity,
    BasePhone,
    HeaderNav,
    HeaderNavbar,
    BasePopup,
    AccountNav,
    Social,
    BaseBtn,
    BaseContainer,
    CatalogBox,
  },
  directives: {
    "click-outside": {
      bind(el, binding) {
        const ourClickEventHandler = (event) => {
          if (
            !el.contains(event.target) &&
            el !== event.target &&
            !event.target.matches("#catalogToggler, #catalogToggler *")
          ) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unbind(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      },
    },
  },
  data() {
    return {
      isCatalogShow: false,
    };
  },
  computed: {
    ...mapGetters({
      isLg: "mq/getIsLg",
      isLogged: "auth/isLogged",
      town: "settings/getTown",
    }),
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
    isHome() {
      return this.$route.name === "home";
    },
  },
  watch: {
    $route() {
      this.isCatalogShow = false;
    },
  },
  mounted() {
    this.$eventHub.$on("close-nav", () => {
      this.isCatalogShow = false;
    });
  },
  methods: {
    onOpenLivechat,
    onClickOutside() {
      if (this.isCatalogShow) this.isCatalogShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.site-header {
  @include media-breakpoint-up(lg) {
    margin-bottom: 8px;
  }

  &__start {
    background: $coal;
    color: $white;
  }

  &__end {
    padding: 8px 0 28px;

    @include media-breakpoint-up(lg) {
      padding: 20px 0;
    }
  }

  &__body {
    text-align: center;

    @include media-breakpoint-up(lg) {
      position: relative;
      text-align: left;
    }
  }

  &__row {
    align-items: center;
  }

  &__logo {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(4, auto);
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
  }

  &__catalog {
    left: 0;
    position: absolute;
    top: 68px;
    z-index: 10;

    &.site-header__catalog--open {
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-name: slide-down;
    }
  }
}
</style>
