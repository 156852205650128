// utils
import higlightQuery from "@/utils/higlightQuery";

const suggestMixin = {
  data() {
    return {
      suggestionList: [],
      isFocus: false,
      pointer: -1,
    };
  },
  computed: {
    suggestionLength() {
      return this.suggestionList.length;
    },
  },
  filters: {
    higlightQuery,
  },
  methods: {
    onFocus() {
      this.isFocus = true;
      this.$emit("focus");
    },
    onBlur() {
      setTimeout(() => {
        this.isFocus = false;
      }, 200);
      this.$emit("blur");
    },
    onEsc() {
      this.isFocus = false;
    },
    onUp() {
      const pointer = this.pointer - 1;

      if (pointer < 0) this.pointer = this.suggestionLength - 1;
      else this.pointer = pointer;
    },
    onDown() {
      const pointer = this.pointer + 1;

      if (pointer >= this.suggestionLength) this.pointer = 0;
      else this.pointer = pointer;
    },
    onHover() {
      this.pointer = -1;
    },
  },
};

export default suggestMixin;
