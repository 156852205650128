<template>
  <div class="view-settings">
    <div class="view-settings__item">
      <div class="view-settings__title">На странице:</div>
      <button
        class="view-settings__btn"
        :class="{ 'view-settings__btn--active': limit === 20 }"
        type="button"
        @click="onSetField({ key: 'limit', value: 20 })"
      >
        20
      </button>
      <button
        class="view-settings__btn"
        :class="{ 'view-settings__btn--active': limit === 40 }"
        type="button"
        @click="onSetField({ key: 'limit', value: 40 })"
      >
        40
      </button>
      <button
        class="view-settings__btn"
        :class="{ 'view-settings__btn--active': limit === 100 }"
        type="button"
        @click="onSetField({ key: 'limit', value: 100 })"
      >
        100
      </button>
    </div>
    <div class="view-settings__item">
      <button
        class="view-settings__btn"
        :class="{ 'view-settings__btn--active': view === 'flat' }"
        type="button"
        @click="onSetField({ key: 'view', value: 'flat' })"
      >
        <base-icon
          class="view-settings__icon"
          name="apps-solid-1-1"
          width="24"
          height="24"
        />
      </button>
      <button
        class="view-settings__btn"
        :class="{ 'view-settings__btn--active': view === 'square' }"
        type="button"
        @click="onSetField({ key: 'view', value: 'square' })"
      >
        <base-icon
          class="view-settings__icon"
          name="apps-solid-2-2"
          width="24"
          height="24"
        />
      </button>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseIcon,
  },
  computed: {
    ...mapState("catalog", {
      view: (state) => state.view,
      limit: (state) => state.limit,
    }),
  },
  methods: {
    ...mapActions("catalog", {
      onSetField: "onSetField",
    }),
  },
};
</script>

<style lang="scss" scoped>
.view-settings {
  align-items: center;
  display: flex;

  &__item {
    align-items: center;
    display: flex;

    & + & {
      margin-left: 40px;
    }
  }

  &__title {
    font-weight: bold;
    margin-right: 24px;
  }

  &__btn {
    @extend %btn;
    color: $grey-100;

    &--active,
    &:hover {
      color: $link-color;
    }
  }

  &__icon {
    fill: $grey-100;

    .view-settings__btn:hover > &,
    .view-settings__btn--active & {
      fill: $link-color;
    }
  }
}
</style>
