<template>
  <span class="sorting-symbol">
    <template v-if="order === 'asc'">&#9652;</template>
    <template v-else-if="order === 'desc'">&#9662;</template>
  </span>
</template>

<script>
export default {
  props: {
    order: {
      type: String,
      required: true,
    },
  },
};
</script>
