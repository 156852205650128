<template>
  <div class="modal-filter-layout" :class="`modal-filter-layout--${view}`">
    <div class="modal-filter-layout__header">
      <close-btn position="top-left" @click="$emit('close')" />
      <slot name="title"></slot>
    </div>

    <div class="modal-filter-layout__body">
      <slot></slot>
    </div>

    <div class="modal-filter-layout__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import CloseBtn from "./CloseBtn";

export default {
  components: {
    CloseBtn,
  },
  props: {
    view: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-filter-layout {
  &__header {
    @extend %subtitle;

    border-bottom: 1px solid $grey-300;
    padding: 1rem 50px;
    text-align: center;
  }

  &__body {
    padding: 1rem;
  }

  &__footer {
    padding: 1rem 1rem 2rem;

    .modal-filter-layout--default & {
      padding-bottom: 4rem;
    }
  }
}
</style>
