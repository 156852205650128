<template>
  <modal name="gallery" width="100%" height="100%" class="gallery-modal">
    <div class="gallery-modal__body">
      <close-btn @click="$modal.hide('gallery')" />
      <img class="gallery-modal__image" :src="image" />
    </div>
  </modal>
</template>

<script>
import CloseBtn from "@/components/shared/CloseBtn";

export default {
  components: {
    CloseBtn,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-modal {
  &__body {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
