<template>
  <vuescroll
    v-if="list && list.length > 0"
    ref="scrollbar"
    :ops="scrollOptions"
  >
    <nav-tab-list class="featired-news-nav">
      <nav-tab-item
        ref="category-all"
        :to="{ name: routeName }"
        :active="active === 'all'"
      >
        Все
      </nav-tab-item>
      <nav-tab-item
        v-for="category in list"
        :key="`category-${category.id}`"
        :ref="`category-${category.slug}`"
        :to="{ name: routeName, query: { nca: category.slug } }"
        :active="category.slug === active"
      >
        {{ category.title }}
      </nav-tab-item>
    </nav-tab-list>
  </vuescroll>
</template>

<script>
import vuescroll from "vuescroll";
import NavTabList from "@/components/shared/NavTabList";
import NavTabItem from "@/components/shared/NavTabItem";

export default {
  components: {
    vuescroll,
    NavTabList,
    NavTabItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    active: {
      type: String,
      default: "all",
    },
  },
  data() {
    return {
      scrollOptions: {
        bar: {
          size: "3px",
        },
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    activeRef() {
      return `category-${this.active}`;
    },
  },
  watch: {
    list(val) {
      if (val && val.length > 0) {
        setTimeout(() => {
          this.updateScroll();
        }, 500);
      }
    },
    activeRef() {
      this.updateScroll();
    },
  },
  methods: {
    updateScroll() {
      const { scrollbar } = this.$refs;

      const item =
        this.activeRef === "category-all"
          ? this.$refs[this.activeRef]
          : this.$refs[this.activeRef][0];
      const offsetLeft = item.$el.offsetLeft;

      setTimeout(() => {
        scrollbar.scrollTo({ x: offsetLeft }, 100);
      }, 100);
    },
  },
};
</script>
