import { render, staticRenderFns } from "./SearchBrandsView.vue?vue&type=template&id=69c5c20b&scoped=true&"
import script from "./SearchBrandsView.vue?vue&type=script&lang=js&"
export * from "./SearchBrandsView.vue?vue&type=script&lang=js&"
import style0 from "./SearchBrandsView.vue?vue&type=style&index=0&id=69c5c20b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c5c20b",
  null
  
)

export default component.exports