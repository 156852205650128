var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-nav",class:`header-nav--${_vm.view}`},[_c('router-link',{staticClass:"header-nav__link",attrs:{"to":{
      name: 'content',
      params: { alias: 'contacts', town: _vm.town },
    }}},[_vm._v(" Контакты ")]),_c('router-link',{staticClass:"header-nav__link",attrs:{"to":{ name: 'content', params: { alias: 'sposoby-oplaty-zakazov' } }}},[_vm._v(" Оплата ")]),_c('router-link',{staticClass:"header-nav__link",attrs:{"to":{ name: 'content', params: { alias: 'dostavka-avtozapchastei' } }}},[_vm._v(" Доставка ")]),_c('router-link',{staticClass:"header-nav__link",attrs:{"to":{
      name: 'content',
      params: { alias: 'garantiya-i-usloviya-vozvrata-detalei' },
    }}},[_vm._v(" Возврат ")]),_c('router-link',{staticClass:"header-nav__link",attrs:{"to":{
      name: 'content',
      params: { alias: 'osago' },
    }}},[_vm._v(" ОСАГО ")]),_c('router-link',{staticClass:"header-nav__link",attrs:{"to":{
      name: 'content',
      params: { alias: 'kasko' },
    }}},[_vm._v(" КАСКО ")]),_c('a',{staticClass:"header-nav__link",attrs:{"href":"https://avtovelikan.ru/franchise","target":"_blank"}},[_vm._v(" Франшиза ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }