/**
 * Форматирует цену с разделениями на десятки
 * @param {Number} значение
 * @returns {String} значение
 */
const formatNumber = (value) => {
  if (!value) return value;
  if (value === 0) return value;

  let mutableValue = Math.round(parseFloat(value) * 100) / 100;
  mutableValue = String(mutableValue).replace(
    /(\d)(?=(\d{3})+([^\d]|$))/g,
    "$1 "
  );
  mutableValue = String(mutableValue).replace(/\./g, ",");

  return mutableValue;
};

export default formatNumber;
