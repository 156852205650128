<template>
  <div class="suggestion-list">
    <suggestion-list-table v-if="isMd">
      <template #original>
        <template v-if="filteredOriginalList.length > 0">
          <suggestion-list-table-tr
            v-for="(item, index) in filteredOriginalList"
            :key="`original-${index}`"
          >
            <template #info>
              <suggestion-list-info
                v-if="item.brandDescription"
                :title="item.brandDescription"
              />
            </template>
            <template #link>
              <suggestion-list-link
                :article="item.article"
                :brand="item.brand"
              />
            </template>
            <template #title>
              {{ item.title }}
            </template>
            <template #quantity>
              {{ item.quantity }}
            </template>
            <template #price> {{ item.price | formatNumber }} ₽</template>
            <template #warehouse>
              <base-warehouse
                :view="
                  item.contractor && item.contractor.color
                    ? item.contractor.color
                    : null
                "
              >
                {{ item.routeTitle }}
              </base-warehouse>
            </template>
            <template #delivery>
              <base-delivery
                :min="item.deliveryMinHours"
                :max="item.deliveryMaxHours"
              />
            </template>
            <template #cart>
              <add-cart
                :item="item"
                :image="image"
                :is-loading="isLoading"
                :name="`original-${index}`"
              />
            </template>
          </suggestion-list-table-tr>
        </template>
        <tr v-else-if="!isLoading">
          <td colspan="8" class="suggestion-list__empty">
            Оригинальных позиций не найдено.
          </td>
        </tr>
      </template>
      <template #replacement>
        <template v-if="filteredReplacementList.length > 0">
          <suggestion-list-table-tr
            v-for="(item, index) in filteredReplacementList"
            :key="`replacement-${index}`"
          >
            <template #info>
              <suggestion-list-info
                v-if="item.brandDescription"
                :title="item.brandDescription"
              />
            </template>
            <template #link>
              <suggestion-list-link
                :article="item.article"
                :brand="item.brand"
              />
            </template>
            <template #title>
              {{ item.title }}
            </template>
            <template #quantity>
              {{ item.quantity }}
            </template>
            <template #price> {{ item.price | formatNumber }} ₽</template>
            <template #warehouse>
              <base-warehouse
                :view="
                  item.contractor && item.contractor.color
                    ? item.contractor.color
                    : null
                "
              >
                {{ item.routeTitle }}
              </base-warehouse>
            </template>
            <template #delivery>
              <base-delivery
                :min="item.deliveryMinHours"
                :max="item.deliveryMaxHours"
              />
            </template>
            <template #cart>
              <add-cart
                :item="item"
                :image="image"
                :is-loading="isLoading"
                :name="`replacement-${index}`"
              />
            </template>
          </suggestion-list-table-tr>
        </template>
        <tr v-else-if="!isLoading">
          <td colspan="8" class="suggestion-list__empty">
            Аналогов для товара не найдено.
          </td>
        </tr>
      </template>
    </suggestion-list-table>

    <suggestion-list-block v-else>
      <template #original>
        <template v-if="filteredOriginalList.length > 0">
          <suggestion-list-block-item
            v-for="(item, index) in filteredOriginalList"
            :key="`original-${index}`"
          >
            <template #index>{{ index + 1 }}</template>
            <template #info>
              <suggestion-list-info
                v-if="item.brandDescription"
                :title="item.brandDescription"
              />
            </template>
            <template #link>
              <suggestion-list-link
                :article="item.article"
                :brand="item.brand"
              />
            </template>
            <template #title>
              {{ item.title }}
            </template>
            <template #quantity>
              {{ item.quantity }}
            </template>
            <template #price> {{ item.price | formatNumber }} ₽</template>
            <template #warehouse>
              <base-warehouse
                :view="
                  item.contractor && item.contractor.color
                    ? item.contractor.color
                    : null
                "
              >
                {{ item.routeTitle }}
              </base-warehouse>
            </template>
            <template #delivery>
              <base-delivery
                :min="item.deliveryMinHours"
                :max="item.deliveryMaxHours"
              />
            </template>
            <template #cart>
              <add-cart
                :item="item"
                :image="image"
                :is-loading="isLoading"
                :name="`original-${index}`"
              />
            </template>
          </suggestion-list-block-item>
        </template>
        <div v-else-if="!isLoading" class="suggestion-list__empty">
          Оригинальных позиций не найдено.
        </div>
      </template>
      <template #replacement>
        <template v-if="filteredReplacementList.length > 0">
          <suggestion-list-block-item
            v-for="(item, index) in filteredReplacementList"
            :key="`replacement-${index}`"
          >
            <template #index>{{ index + 1 }}</template>
            <template #info>
              <suggestion-list-info
                v-if="item.brandDescription"
                :title="item.brandDescription"
              />
            </template>
            <template #link>
              <suggestion-list-link
                :article="item.article"
                :brand="item.brand"
              />
            </template>
            <template #title>
              {{ item.title }}
            </template>
            <template #quantity>
              {{ item.quantity }}
            </template>
            <template #price> {{ item.price | formatNumber }} ₽</template>
            <template #warehouse>
              <base-warehouse
                :view="
                  item.contractor && item.contractor.color
                    ? item.contractor.color
                    : null
                "
              >
                {{ item.routeTitle }}
              </base-warehouse>
            </template>
            <template #delivery>
              <base-delivery
                :min="item.deliveryMinHours"
                :max="item.deliveryMaxHours"
              />
            </template>
            <template #cart>
              <add-cart
                :item="item"
                :image="image"
                :is-loading="isLoading"
                :name="`replacement-${index}`"
              />
            </template>
          </suggestion-list-block-item>
        </template>
        <div v-else-if="!isLoading" class="suggestion-list__empty">
          Аналогов для товара не найдено.
        </div>
      </template>
    </suggestion-list-block>
  </div>
</template>

<script>
import SuggestionListTable from "./SuggestionListTable";
import SuggestionListTableTr from "./SuggestionListTableTr";
import SuggestionListBlock from "./SuggestionListBlock";
import SuggestionListBlockItem from "./SuggestionListBlockItem";
import BaseDelivery from "./BaseDelivery";
import BaseWarehouse from "./BaseWarehouse";
import AddCart from "./AddCart";
import SuggestionListLink from "./SuggestionListLink";
import SuggestionListInfo from "./SuggestionListInfo";
// utils
import convertDaysToHours from "@/utils/convertDaysToHours";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import formatNumber from "@/utils/formatNumber";
import { mapGetters } from "vuex";

export default {
  components: {
    SuggestionListTable,
    SuggestionListTableTr,
    SuggestionListBlock,
    SuggestionListBlockItem,
    BaseDelivery,
    BaseWarehouse,
    AddCart,
    SuggestionListLink,
    SuggestionListInfo,
  },
  filters: {
    formatNumber,
  },
  props: {
    originalList: {
      type: Array,
      required: true,
    },
    replacementList: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      default: () => {},
    },
    image: {
      type: [Object, Boolean],
      default: null,
    },
    isLoading: Boolean,
  },
  computed: {
    ...mapGetters("mq", {
      isMd: "getIsMd",
    }),
    routeQuery() {
      return this.$route.query;
    },
    filteredOriginalList() {
      return this.onSortArray(
        this.onFilterArray(this.originalList),
        this.routeQuery
      );
    },
    filteredReplacementList() {
      return this.onSortArray(
        this.onFilterArray(this.replacementList),
        this.routeQuery
      );
    },
  },
  methods: {
    onFilterArray(list) {
      let newList = list;

      if (this.selected.brandList.length > 0) {
        newList = filter(newList, (o) => {
          return this.selected.brandList.includes(o.brand.toUpperCase());
        });
      }

      if (!isEmpty(this.selected.price)) {
        newList = filter(newList, (o) => {
          return (
            o.price >= this.selected.price.min &&
            o.price <= this.selected.price.max
          );
        });
      }

      if (!isEmpty(this.selected.quantity)) {
        newList = filter(newList, (o) => {
          return (
            o.quantity >= this.selected.quantity.min &&
            o.quantity <= this.selected.quantity.max
          );
        });
      }

      if (!isEmpty(this.selected.delivery)) {
        const min = convertDaysToHours(this.selected.delivery.min);
        const max = convertDaysToHours(this.selected.delivery.max);

        newList = filter(newList, (o) => {
          return o.deliveryMinHours >= min && o.deliveryMaxHours <= max;
        });
      }

      return newList;
    },
    onSortArray(list, query) {
      const sorting =
        query.sorting === "warehouse" ? "routeTitle" : "deliveryMinHours";

      const order = query.order === "desc" ? "desc" : "asc";

      return orderBy(list, [sorting], [order]);
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-list {
  &__empty {
    text-align: center;
    padding: 0.7rem;
  }
}
</style>
