<template>
  <div class="vin-search">
    <h3 class="vin-search__title">Каталоги автозапчастей</h3>

    <vin-search-form class="vin-search__form" />

    <vin-search-help class="vin-search__help" />
  </div>
</template>

<script>
import VinSearchForm from "@/modules/auto/components/VinSearchForm";
import VinSearchHelp from "@/modules/auto/components/VinSearchHelp";

export default {
  components: {
    VinSearchForm,
    VinSearchHelp,
  },
};
</script>

<style lang="scss" scoped>
.vin-search {
  @include media-breakpoint-up(lg) {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__title {
    margin-bottom: 0;
    white-space: nowrap;
  }

  &__form {
    margin: 8px 0;

    @include media-breakpoint-up(lg) {
      margin: 0 20px;
    }

    @include media-breakpoint-up(xxl) {
      flex: 0 0 441px;
      max-width: 441px;
    }
  }
}
</style>
