<template>
  <base-loading v-if="isLoading" />

  <page-layout-full
    v-else
    class="article-item"
    :breadcrumb="breadcrumb"
    view="sm"
  >
    <header class="article-item__header">
      <h1 class="article-item__title">{{ item.title }}</h1>
      <div class="article-item__desc">
        <router-link
          :to="{ name: 'article-list', params: { alias: item.category.slug } }"
          class="article-item__category"
        >
          {{ item.category.title }}
        </router-link>
        <span class="article-item__date">
          {{ date | formatDate("d MMMM yyyy") }}
        </span>
      </div>
    </header>
    <figure class="article-item__picture">
      <base-image
        :url="imageSrc"
        class="article-item__image"
        width="951"
        height="534"
      />
    </figure>
    <div class="article-item__text" v-html="text"></div>
    <image-gallery
      v-if="gallery.length > 0"
      class="article-item__gallery"
      :list="gallery"
    />
    <template #footer>
      <section class="article-item__news">
        <base-container>
          <div class="article-item__news-container">
            <h3 class="article-item__news-title">Другие новости</h3>

            <vuescroll>
              <div class="article-item__news-list">
                <div
                  v-for="newsItem in newsList"
                  :key="`news-${newsItem.slug}`"
                  class="article-item__item"
                >
                  <news-card :item="newsItem" />
                </div>
              </div>
            </vuescroll>

            <div class="article-item__news-footer">
              <base-btn
                view="outline-secondary"
                tag="router-link"
                :to="{ name: 'article-list' }"
                >Все новости</base-btn
              >
            </div>
          </div>
        </base-container>
      </section>
    </template>
  </page-layout-full>
</template>

<script>
import vuescroll from "vuescroll";
import BaseBtn from "@/components/shared/BaseBtn.vue";
import BaseImage from "@/components/shared/BaseImage.vue";
import BaseLoading from "@/components/shared/BaseLoading.vue";
import BaseContainer from "@/components/shared/BaseContainer.vue";
import NewsCard from "../components/NewsCard.vue";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import ImageGallery from "@/components/shared/ImageGallery.vue";
// utils
import fromUnixTime from "date-fns/fromUnixTime";
import formatDate from "@/utils/formatDate";
import onSetMeta from "@/utils/onSetMeta";

export default {
  components: {
    BaseBtn,
    BaseImage,
    BaseLoading,
    BaseContainer,
    NewsCard,
    PageLayoutFull,
    ImageGallery,
    vuescroll,
  },
  filters: {
    formatDate,
  },
  metaInfo() {
    return {
      title: this.item ? this.item.title : "Загружается",
      meta: this.metaList,
    };
  },
  data() {
    return {
      isLoading: true,
      item: null,
      newsList: [],
      metaList: [],
    };
  },
  computed: {
    alias() {
      return this.$route.params.alias;
    },
    date() {
      return fromUnixTime(this.item.publishTime);
    },
    imageSrc() {
      return this.item.image && this.item.image.files
        ? this.item.image.files["news-full"]
        : null;
    },
    breadcrumb() {
      return [
        {
          title: "Новости",
          to: {
            name: "article-list",
          },
        },
        {
          title: this.item.title,
        },
      ];
    },
    gallery() {
      if (!this.item) return [];
      return this.item.gallery ? this.item.gallery : [];
    },
    text() {
      if (!this.item) return null;
      return this.item.text.replace(/\[.*?\]/g, "");
    },
  },
  watch: {
    alias(newAlias, oldAlias) {
      if (newAlias !== oldAlias) this.onFetchData(newAlias);
    },
  },
  created() {
    if (this.alias) {
      this.onFetchData(this.alias);
    } else {
      this.$router.push({ name: "not-found" });
    }
  },
  methods: {
    onFetchData(alias) {
      this.isLoading = true;

      const params = {
        alias: alias,
      };

      this.$http
        .get("content/article/view", {
          params: params,
        })
        .then(({ data }) => {
          const { model, otherArticles, meta } = data;
          this.item = model;
          this.newsList = otherArticles;
          this.metaList = onSetMeta(meta);
          this.isLoading = false;
          this.$nextTick(() => {
            this.$meta().refresh();
            this.$nextTick(() => {
              document.body.classList.add("is-loaded");
            });
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$router.push({ name: "not-found" });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-item {
  &__header {
    margin-bottom: 1rem;
  }

  &__title {
    line-height: 1;
    margin-bottom: 1rem;
  }

  &__category {
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__date {
    @extend %text-nav;
    color: $grey-100;
    margin-left: 0.5rem;
  }

  &__text {
    margin: 0 auto;
    max-width: 700px;

    :deep img {
      @include img-fluid;
    }

    :deep iframe {
      display: block;
      margin: 40px auto;
      max-width: 100%;
    }
  }

  &__gallery {
    margin-top: 40px;
  }

  &__news {
    margin-top: 5rem;
  }

  &__news-container {
    border-top: 1px solid $grey-300;
    padding-top: 2.5rem;
  }

  &__news-title {
    margin-bottom: 1.8rem;
    text-align: center;
  }

  &__news-footer {
    margin-top: 1.8rem;
    text-align: center;
  }

  &__news-list {
    display: grid;
    grid-template-columns: repeat(5, 270px);
    gap: 0 1rem;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
</style>
