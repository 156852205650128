<template>
  <div
    class="footer-navbar-nav"
    :class="[
      `footer-navbar-nav--${view}`,
      isOpen ? 'footer-navbar-nav--open' : '',
    ]"
  >
    <header class="footer-navbar-nav__header" @click.prevent="onClick">
      <h4 class="footer-navbar-nav__title"><slot name="title"></slot></h4>
    </header>

    <div class="footer-navbar-nav__body">
      <ul class="footer-navbar-nav__list">
        <slot
          itemClass="footer-navbar-nav__item"
          linkClass="footer-navbar-nav__link"
        ></slot>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: !(this.view === "collapse"),
    };
  },
  methods: {
    onClick() {
      if (this.view === "collapse") {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-navbar-nav {
  &__header {
    margin-bottom: 24px;
  }

  &__title {
    @extend %subtitle;

    border-bottom: 2px solid $mint-100;
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 6px;

    .footer-navbar-nav--dark & {
      color: $smoke;
    }
  }

  &__body {
    display: none;

    .footer-navbar-nav--open & {
      display: block;
    }
  }

  &__list {
    @include list-unstyled;
    margin-bottom: 24px;

    .footer-navbar-nav--dark & {
      margin-bottom: 0;
    }
  }

  &__item {
    & + & {
      margin-top: 24px;
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    .footer-navbar-nav--dark & {
      color: $smoke;

      &:hover {
        color: $link-color;
      }
    }
  }
}
</style>
