import axios from "axios";
import store from "@/store";
import Vue from "vue";
import * as Sentry from "@sentry/vue";

export function onSetToken(token) {
  Vue.prototype.$http.defaults.headers.common.authorization = token;
}

function getTown() {
  const path = location.hostname.split(".");
  const subdomain = path.shift();

  if (["vue", "avtovelikan", "localhost"].includes(subdomain)) {
    const params = new URLSearchParams(window.location.search);

    if (params.has("town")) {
      return params.get("town");
    }

    return store.getters["settings/getTown"];
  }

  return subdomain;
}

export function onSetTown(town) {
  if (town) {
    Vue.prototype.$http.interceptors.request.use(
      function (config) {
        if (config.params && config.params.town) {
          return config;
        }

        if (!config.params) {
          config.params = {};
        }

        config.params.town = town;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}

export function onGetInstance() {
  const town = getTown();
  const token = store.getters["auth/getToken"];

  // api
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
  });

  if (token) {
    instance.defaults.headers.common.authorization = token;
  }

  if (town) {
    instance.interceptors.request.use(
      function (config) {
        if (config.params && config.params.town) {
          return config;
        }

        if (!config.params) {
          config.params = {};
        }

        config.params.town = town;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );

  return instance;
}

export function onInstanceGetData(options, cb) {
  Vue.prototype.$http
    .get(options.url, options.precedence)
    .then((response) => {
      cb(null, response);
    })
    .catch((error) => {
      cb(error, null);
    });
}
