var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout-full',{staticClass:"payment-result",attrs:{"breadcrumb":_vm.breadcrumb,"view":"sm","header":"center"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true}])},[_c('div',{staticClass:"payment-result__icon"},[(_vm.status === 'SUCCEEDED')?_c('base-icon',{attrs:{"fill":"#65c366","name":"payment-ok","width":"200","height":"200"}}):(_vm.status === 'CANCELED')?_c('base-icon',{attrs:{"fill":"#c62925","name":"payment-cancel","width":"200","height":"200"}}):_c('base-icon',{attrs:{"stroke":"#ffb400","name":"payment-wait","width":"200","height":"200"}})],1),(_vm.status === 'LOADING')?_c('p',{staticClass:"payment-result__info"},[_vm._v(" Платежная система еще не передала информацию о статусе оплаты."),_c('br'),_vm._v(" Проверка статуса будет выполнена автоматически через 1 минуту. ")]):_vm._e(),(_vm.status === 'SUCCEEDED')?_c('p',{staticClass:"payment-result__info"},[_c('base-btn',{attrs:{"to":{ name: 'account-order-list' },"tag":"router-link"}},[_vm._v(" К списку заказов ")])],1):_vm._e(),(_vm.status === 'CANCELED')?_c('action-card',{attrs:{"view":"row"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Повторить оплату")]},proxy:true},{key:"default",fn:function(){return [_c('p',[_vm._v("Попробуйте еще раз повторить оплату")])]},proxy:true},{key:"btn",fn:function(){return [_c('base-btn',{attrs:{"display":"block","to":_vm.orderId
            ? {
                name: 'account-order-item-payment',
                params: { orderId: _vm.orderId },
              }
            : { name: 'account-order-list' },"tag":"router-link"}},[_vm._v(" Повторить ")])]},proxy:true}],null,false,2276330099)}):_vm._e(),(_vm.status === 'LOADING')?_c('action-card',{attrs:{"view":"row"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Проверить статус платежа ")]},proxy:true},{key:"default",fn:function(){return [_c('p',[_vm._v("Запросить статус платежа вручную при длительном ожидании.")])]},proxy:true},{key:"btn",fn:function(){return [_c('base-btn',{attrs:{"display":"block","type":"button","view":"outline-secondary"},on:{"click":_vm.onFetchData}},[_vm._v(" Проверить ")])]},proxy:true}],null,false,3923512834)}):_vm._e(),(_vm.status === 'LOADING' || _vm.status === 'CANCELED')?_c('action-card',{attrs:{"view":"row"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Вернуться в личный кабинет")]},proxy:true},{key:"default",fn:function(){return [_c('p',[(_vm.status === 'LOADING')?[_vm._v(" Прервать оплату и вернуться к заказу. ")]:_vm._e(),_vm._v(" Вы можете выбрать другую платежную систему или поменять способ оплаты. ")],2)]},proxy:true},{key:"btn",fn:function(){return [_c('base-btn',{attrs:{"display":"block","view":"outline-danger","to":_vm.orderId
            ? { name: 'account-order-item', params: { orderId: _vm.orderId } }
            : { name: 'account-order-list' },"tag":"router-link"}},[_vm._v(" Вернуться ")])]},proxy:true}],null,false,390665456)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }