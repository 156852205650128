<template>
  <form class="vin-search-form" @submit.prevent="onSubmit">
    <form-group
      id="vinSearch"
      :show-label="false"
      size="sm"
      class="vin-search-form__form-group"
      :icon="{ name: 'search', width: '16', height: '16' }"
    >
      <template #label>VIN или номер кузова</template>
      <template #default="props">
        <input
          :id="props.id"
          :value="vin"
          type="text"
          :class="props.inputClass"
          placeholder="VIN или номер кузова"
          @input="onSetVin"
        />
      </template>
    </form-group>

    <base-btn
      size="sm"
      class="vin-search-form__btn"
      :disabled="vin === ''"
      type="submit"
    >
      <template v-if="isXXl">Найти</template>
      <base-icon v-else name="search-outline" width="20" height="20" />
    </base-btn>
  </form>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import BaseBtn from "@/components/shared/BaseBtn";
import BaseIcon from "@/components/shared/BaseIcon";
// utils
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {
    FormGroup,
    BaseBtn,
    BaseIcon,
  },
  computed: {
    ...mapState("favorite", {
      vin: (state) => state.vin,
    }),
    ...mapGetters("mq", {
      isXXl: "getIsXXl",
    }),
    laximoPath() {
      return process.env.VUE_APP_LAXIMO_PATH;
    },
  },
  methods: {
    ...mapActions("favorite", {
      setVin: "onSetVin",
    }),
    onSetVin(event) {
      this.setVin(event.target.value);
    },
    onSubmit() {
      const value = this.vin.trim();

      if (value.length >= 9) {
        location.href = `${this.laximoPath}auto/lx/vehicles-by-string?str=${value}`;
      } else {
        this.$toast.error("Неправильный номер");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vin-search-form {
  display: flex;

  &__form-group {
    margin-bottom: 0;
    margin-right: 8px;
    flex-grow: 1;

    @include media-breakpoint-up(xxl) {
      flex: 0 0 340px;
      max-width: 340px;
    }
  }

  &__btn {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 93px;
      max-width: 93px;
    }
  }
}
</style>
