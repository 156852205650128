<template>
  <div
    class="base-icon-block"
    :class="`base-icon-block--${view} base-icon-block--${align}`"
  >
    <base-icon
      v-if="icon"
      class="base-icon-block__icon"
      :class="iconClass"
      :name="icon.name"
      :width="icon.width"
      :height="icon.height"
      :fill="icon.fill"
      :stroke="icon.stroke"
    />
    <div class="base-icon-block__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from "./BaseIcon";

export default {
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: Object,
      required: true,
    },
    iconClass: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: "row",
    },
    align: {
      type: String,
      default: "flex-start",
    },
  },
};
</script>

<style lang="scss" scoped>
.base-icon-block {
  display: flex;

  &.base-icon-block--row {
    flex-flow: row;
  }

  &.base-icon-block--column {
    flex-flow: column;
  }

  &.base-icon-block--flex-start {
    align-items: flex-start;
  }

  &.base-icon-block--center {
    align-items: center;
  }

  &__icon {
    .base-icon-block--row & {
      margin-right: 8px;
    }

    .base-icon-block--column & {
      margin-bottom: 2px;
    }
  }
}
</style>
