<template>
  <div class="filter-range">
    <div class="filter-range__row">
      <div class="filter-range__col">
        <form-group :id="`range-${name}-min`" class="filter-range__form-group">
          <template #label>От</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.number="value[0]"
              type="number"
              :class="props.inputClass"
              :min="min"
              :max="max"
              :disabled="!isReady"
              @input="onInputMin"
              @change="onChangeInput"
            />
          </template>
        </form-group>
      </div>
      <div class="filter-range__col">
        <form-group :id="`range-${name}-max`" class="filter-range__form-group">
          <template #label>До</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.number="value[1]"
              :class="props.inputClass"
              :disabled="!isReady"
              :max="max"
              :min="min"
              type="number"
              @input="onInputMax"
              @change="onChangeInput"
            />
          </template>
        </form-group>
      </div>
    </div>

    <vue-slider
      v-if="isReady"
      v-model="value"
      :min="min"
      :max="max"
      :height="8"
      :dot-size="24"
      :disabled="!isReady"
      @drag-start="onDragStart"
      @drag-end="onDragEnd"
    ></vue-slider>
  </div>
</template>

<script>
import FormGroup from "@/components/shared/FormGroup";
import VueSlider from "vue-slider-component";

export default {
  components: {
    FormGroup,
    VueSlider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    isReady: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: [this.min, this.max],
      isDragging: false,
    };
  },
  watch: {
    min(val) {
      this.value[0] = val;
    },
    max(val) {
      this.value[1] = val;
    },
    selected: {
      handler(val) {
        const { min, max } = val;

        if (min && max) {
          this.value = [min, max];
        }
      },
      deep: true,
    },
  },
  mounted() {
    const { min, max } = this.selected;

    if (min && max) {
      this.value = [min, max];
    }

    this.$eventHub.$on(`reset-${this.name}`, () => {
      this.onReset();
    });
  },
  methods: {
    onInputMin(event) {
      if (event.target.value < this.min) {
        this.value[0] = this.min;
      }
    },
    onInputMax(event) {
      if (event.target.value > this.max) {
        this.value[1] = this.max;
      }
    },
    onChangeInput() {
      setTimeout(() => {
        this.$emit("change", {
          min: this.value[0],
          max: this.value[1],
        });
      }, 100);
    },
    onReset() {
      this.value = [this.min, this.max];
      this.$emit("reset");
    },
    onChange(value) {
      if (!this.isDragging) {
        this.$emit("change", {
          min: value[0],
          max: value[1],
        });
      }
    },
    onDragStart() {
      this.isDragging = true;
    },
    onDragEnd() {
      this.isDragging = false;

      setTimeout(() => {
        this.onChange(this.value);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-range {
  &__row {
    @include make-row(8px);

    > * {
      @include make-col-ready(8px);
    }
  }

  &__col {
    @include make-col(6);
  }

  &__form-group {
    margin-bottom: 16px;
  }
}
</style>
