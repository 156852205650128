<template>
  <base-loading v-if="isLoading" />
  <page-layout-sidebar
    v-else
    class="order-item"
    :breadcrumb="breadcrumb"
    :size="3"
  >
    <template #title>{{ title }}</template>

    <template #sidebar>
      <order-info
        class="order-item__info"
        :payment-service="order.paymentService"
        :delivery="order.delivery"
        :delivery-price="order.deliveryPrice"
        :order-id="order.id"
        :deadline="deadline"
        :delivery-payer="order.deliveryPayer"
        :is-debt="isDebt"
        :receiver-address="order.receiverAddress"
      />

      <action-card v-if="feedback.liveEnabled">
        <template #title>Остались вопросы?</template>
        <template #default>
          <p>Задайте вопрос и наши операторы ответят Вам в ближайшее время</p>
        </template>
        <template #btn>
          <base-btn
            display="block"
            view="outline-secondary"
            type="button"
            @click="onOpenLivechat($event)"
          >
            Задать вопрос
          </base-btn>
        </template>
      </action-card>

      <action-card>
        <template #title>История заказов</template>
        <template #default>
          <p>Вы можете контролировать Ваши заказы через личный кабинет</p>
        </template>
        <template #btn>
          <base-btn
            display="block"
            view="outline-secondary"
            :to="{ name: 'account-order-list' }"
            tag="router-link"
          >
            Мои заказы
          </base-btn>
        </template>
      </action-card>
    </template>

    <order-body :list="order.items" :price="order.itemsPriceWithDiscount">
      <template #title>Состав заказа</template>
      <template #action>
        <base-btn
          v-if="order.debt > 0"
          class="order-item__btn"
          tag="router-link"
          :to="{
            name: 'account-order-item-payment',
            params: { orderId: orderId },
          }"
        >
          Оплатить
        </base-btn>
      </template>
    </order-body>
  </page-layout-sidebar>
</template>

<script>
import PageLayoutSidebar from "@/components/page/PageLayoutSidebar.vue";
import BaseLoading from "@/components/shared/BaseLoading";
import ActionCard from "@/components/shared/ActionCard";
import BaseBtn from "@/components/shared/BaseBtn";
import OrderInfo from "../components/OrderInfo";
import OrderBody from "../components/OrderBody";
// utils
import { mapState } from "vuex";
import parse from "date-fns/parse";
import max from "date-fns/max";
import formatDate from "@/utils/formatDate";
import onOpenLivechat from "@/utils/onOpenLivechat";

export default {
  components: {
    PageLayoutSidebar,
    BaseLoading,
    ActionCard,
    BaseBtn,
    OrderInfo,
    OrderBody,
  },
  metaInfo() {
    return {
      title: this.orderId ? `Заказ №${this.orderId}` : "Загружается",
    };
  },
  data() {
    return {
      isLoading: true,
      order: null,
      title: null,
    };
  },
  computed: {
    ...mapState("settings", {
      feedback: (state) => state.feedback,
    }),
    orderId() {
      return this.$route.params.orderId;
    },
    deadline() {
      const dates = this.order.items.map((item) =>
        parse(item.deadline, "dd.MM.yyyy", new Date())
      );

      const maxDate = max(dates);

      return formatDate(maxDate, "dd.MM.yyyy");
    },
    isDebt() {
      const { debt, itemsPrice } = this.order;
      return debt > 0 && parseFloat(itemsPrice) > 0;
    },
    breadcrumb() {
      return [
        {
          title: "Личный кабинет",
          to: {
            name: "account-profile",
          },
        },
        {
          title: "Заказы",
          to: {
            name: "account-order-list",
          },
        },
        {
          title: `Заказ №${this.orderId}`,
        },
      ];
    },
  },
  created() {
    if (this.$route.params.newOrder) {
      this.title = "Ваш заказ принят!";
    } else if (this.$route.params.orderId) {
      this.title = `Заказ №${this.$route.params.orderId}`;
    } else {
      this.$router.replace({
        name: "account-order-list",
      });
    }

    this.onFetchData();
  },
  methods: {
    onOpenLivechat,
    onFetchData() {
      this.isLoading = true;

      this.$http
        .get(`shop/orders/${this.orderId}`)
        .then(({ data }) => {
          this.isLoading = false;
          this.order = data;
        })
        .catch((error) => {
          this.$toast.error("Не удалось получить заказ.");
          if (error.response.status === 401) {
            this.$eventHub.$emit("unauthorized");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-item {
  &__info {
    margin-bottom: 1rem;
  }

  &__btn {
    @include media-breakpoint-up(md) {
      min-width: 200px;
    }
  }
}
</style>
