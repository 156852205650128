<template>
  <form class="garage-form" @submit.prevent="onSubmit">
    <div class="row">
      <div class="col-lg-6">
        <form-group
          id="garageManufacturer"
          :has-error="$v.garageForm.manufacturerId.$error"
        >
          <template #label>Бренд</template>
          <template #default="props">
            <multiselect
              :id="props.id"
              v-model="manufacturer"
              :disabled="isDisabled"
              :options="manufacturerList"
              searchable
              :show-labels="false"
              :internal-search="false"
              :max-height="200"
              label="value"
              track-by="id"
              placeholder="Начните вводить бренд"
              @search-change="fetchManufacturer"
              @input="onChangeManufacturer"
            >
              <span slot="noOptions">
                <template v-if="isLoadingManufacturer">Подождите....</template>
                <template v-else>Список брендов пуст</template>
              </span>
              <span slot="noResult">
                Ой, ничего не найдено. Попробуйте ввести иначе.
              </span>
            </multiselect>
          </template>
        </form-group>
      </div>
      <div class="col-lg-6">
        <form-group id="garageModel" :has-error="$v.garageForm.modelId.$error">
          <template #label>Модель</template>
          <template #default="props">
            <multiselect
              :id="props.id"
              v-model="model"
              :disabled="isDisabled || !manufacturer"
              :options="modelList"
              searchable
              :show-labels="false"
              :internal-search="false"
              :max-height="200"
              label="value"
              track-by="id"
              placeholder="Выберите модель"
              @search-change="fetchModel"
              @input="onChangeModel"
            >
              <span slot="noOptions">
                <template v-if="isLoadingModel">Подождите....</template>
                <template v-else>Список моделей пуст.</template>
              </span>
              <span slot="noResult">
                Ой, ничего не найдено. Попробуйте ввести иначе.
              </span>
            </multiselect>
          </template>
        </form-group>
      </div>
      <div class="col-lg-6">
        <form-group
          id="garageVehicle"
          :has-error="$v.garageForm.vehicleId.$error"
        >
          <template #label>Модификация</template>
          <template #default="props">
            <multiselect
              :id="props.id"
              v-model="vehicle"
              :disabled="isDisabled || !manufacturer || !model"
              :options="vehicleList"
              searchable
              :show-labels="false"
              :internal-search="false"
              :max-height="200"
              label="value"
              track-by="id"
              placeholder="Выберите модификацию"
              @search-change="fetchVehicle"
              @input="onChangeVehicle"
            >
              <span slot="noOptions">
                <template v-if="isLoadingVehicle">Подождите....</template>
                <template v-else>Список модификаций пуст.</template>
              </span>
              <span slot="noResult">
                Ой, ничего не найдено. Попробуйте ввести иначе.
              </span>
            </multiselect>
          </template>
        </form-group>
      </div>
      <div class="col-lg-6">
        <form-group id="garageYear">
          <template #label>Год</template>
          <template #default="props">
            <multiselect
              :id="props.id"
              v-model="garageForm.year"
              :disabled="
                isDisabled ||
                !manufacturer ||
                !model ||
                !vehicle ||
                yearList.length === 0
              "
              :options="yearList"
              :show-labels="false"
              :max-height="200"
              placeholder="Выберите год"
            >
              <span slot="noOptions"> Список пуст. </span>
            </multiselect>
          </template>
        </form-group>
      </div>
      <div class="col-lg-6">
        <form-group id="garageVinNumber">
          <template #label>Vin-номер</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="garageForm.vinNumber"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
        </form-group>
      </div>
      <div class="col-lg-6">
        <form-group id="garageBodyNumber">
          <template #label>Номер кузова</template>
          <template #default="props">
            <input
              :id="props.id"
              v-model.trim="garageForm.bodyNumber"
              type="text"
              :class="props.inputClass"
              :disabled="isDisabled"
            />
          </template>
        </form-group>
      </div>
    </div>

    <div class="garage-form__footer">
      <base-btn class="garage-form__btn">Сохранить</base-btn>
      <base-btn
        type="button"
        view="outline-secondary"
        class="garage-form__btn"
        @click="$emit('close')"
      >
        Назад
      </base-btn>
    </div>
  </form>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import FormGroup from "@/components/shared/FormGroup";
// utils
import renderYears from "@/utils/renderYears";
import { required } from "vuelidate/lib/validators";

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

export default {
  components: {
    BaseBtn,
    FormGroup,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      garageForm: {
        year: null,
        vinNumber: null,
        bodyNumber: null,
        manufacturerId: null,
        modelId: null,
        vehicleId: null,
      },
      isDisabled: false,
      manufacturer: null,
      model: null,
      vehicle: null,
      manufacturerList: [],
      modelList: [],
      vehicleList: [],
      isLoadingManufacturer: true,
      isLoadingModel: false,
      isLoadingVehicle: false,
    };
  },
  validations: {
    garageForm: {
      manufacturerId: {
        required,
      },
      modelId: {
        required,
      },
      vehicleId: {
        required,
      },
    },
  },
  computed: {
    yearList() {
      if (this.vehicle) {
        const { yearFrom, yearTo } = this.vehicle;
        return yearTo > yearFrom
          ? renderYears(yearFrom, yearTo)
          : renderYears(yearFrom, TODAY.getFullYear());
      }
      return [];
    },
  },
  mounted() {
    this.isDisabled = false;

    if (this.value) {
      this.garageForm = {
        id: this.value.id,
        year: this.value.year === 0 ? null : this.value.year,
        vinNumber: this.value.vinNumber,
        bodyNumber: this.value.bodyNumber,
        manufacturerId: this.value.manufacturer.id || null,
        modelId: this.value.model.id || null,
        vehicleId: this.value.vehicleId || null,
      };
      this.onSetSelects(this.value.manufacturer, this.value.model, {
        id: this.value.vehicleId,
        ...this.value.vehicle,
      });
    } else {
      this.fetchManufacturer();
    }
  },
  methods: {
    onSetSelects(manufacturer, model, vehicle) {
      this.manufacturer = {
        id: manufacturer.id,
        label: manufacturer.title,
        text: manufacturer.title,
        value: manufacturer.title,
      };

      this.model = {
        id: model.id,
        value: model.title,
      };

      this.vehicle = {
        id: vehicle.id,
        value: vehicle.title,
        yearFrom: vehicle.yearFrom,
        yearTo: vehicle.yearTo,
      };

      this.fetchManufacturer();
      this.fetchModel();
      this.fetchVehicle();
    },
    fetchManufacturer(query) {
      const params = {
        index: "autoManufacturers",
        limit: 100,
        term: query,
      };

      this.isLoadingManufacturer = true;

      this.$http
        .get("data/index/search-values", {
          params: {
            ...params,
          },
        })
        .then(({ data }) => {
          this.isLoadingManufacturer = false;
          this.manufacturerList = data;
        })
        .catch(() => {
          this.isLoadingManufacturer = false;
          this.$toast.error("Не удалось получить список брендов.");
        });
    },
    fetchModel(query) {
      if (this.manufacturer.text) {
        const params = {
          index: "autoManufacturers",
          limit: 200,
          manufacturerTitle: this.manufacturer.text,
          term: query,
        };

        this.isLoadingModel = true;

        this.$http
          .get("auto/auto/search-models", {
            params: {
              ...params,
            },
          })
          .then(({ data }) => {
            this.isLoadingModel = false;
            this.modelList = data;
          })
          .catch(() => {
            this.isLoadingModel = false;
            this.$toast.error("Не удалось получить список моделей.");
          });
      }
    },
    fetchVehicle(query) {
      if (this.manufacturer.text && this.model.value) {
        const params = {
          index: "autoManufacturers",
          limit: 200,
          manufacturerTitle: this.manufacturer.text,
          modelTitle: this.model.value,
          term: query,
        };

        this.isLoadingVehicle = true;

        this.$http
          .get("auto/auto/search-vehicles", {
            params: {
              ...params,
            },
          })
          .then(({ data }) => {
            this.isLoadingVehicle = false;
            this.vehicleList = data;
          })
          .catch(() => {
            this.isLoadingVehicle = false;
            this.$toast.error("Не удалось получить список моделей.");
          });
      }
    },
    onChangeManufacturer(value) {
      this.garageForm.manufacturerId = value ? value.id : null;
      this.garageForm.year = null;
      this.modelList = [];
      this.vehicleList = [];
      this.model = null;
      this.vehicle = null;
      this.fetchModel();
    },
    onChangeModel(value) {
      this.garageForm.modelId = value ? value.id : null;
      this.vehicleList = [];
      this.vehicle = null;
      this.fetchVehicle();
    },
    onChangeVehicle(value) {
      this.garageForm.vehicleId = value ? value.id : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isDisabled = true;
        this.$emit("submit", this.garageForm);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.garage-form {
  &__footer {
    text-align: center;
  }

  &__btn {
    & + & {
      margin-left: 16px;
    }
  }
}
</style>
