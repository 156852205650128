<template>
  <modal name="phone" width="360" height="auto" scrollable class="phone-modal">
    <modal-filter-layout view="none" @close="onClose">
      <template #title> Все номера </template>

      <slot></slot>

      <template #footer>
        <base-btn
          size="sm"
          view="outline-secondary"
          display="block"
          @click="onClose"
        >
          Назад
        </base-btn>
      </template>
    </modal-filter-layout>
  </modal>
</template>

<script>
import ModalFilterLayout from "@/components/shared/ModalFilterLayout.vue";
import BaseBtn from "@/components/shared/BaseBtn.vue";

export default {
  components: {
    ModalFilterLayout,
    BaseBtn,
  },
  methods: {
    onClose() {
      this.$modal.hide("phone");
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-modal {
  &__header {
    border-bottom: 1px solid;
  }
}
</style>
