<template>
  <modal
    name="cart-alert"
    :max-width="360"
    adaptive
    height="auto"
    :click-to-close="false"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <modal-alert-layout
      :click-to-close="false"
      @close="$modal.hide('cart-alert')"
    >
      <p>Внимание! В корзине профиля уже есть товары, выберите действие:</p>

      <template #btns="props">
        <base-btn size="sm" :class="props.btnClass" @click="onNewCart">
          Новая корзина
        </base-btn>
        <base-btn size="sm" :class="props.btnClass" @click="onOldCart">
          Старая корзина
        </base-btn>
        <base-btn
          size="sm"
          class="mt-3"
          :class="props.btnClass"
          @click="onUnionCart"
        >
          Объединить
        </base-btn>
      </template>
    </modal-alert-layout>
  </modal>
</template>

<script>
import ModalAlertLayout from "@/components/shared/ModalAlertLayout";
import BaseBtn from "@/components/shared/BaseBtn";

export default {
  components: {
    ModalAlertLayout,
    BaseBtn,
  },
  data() {
    return {
      onCallback: null,
    };
  },
  methods: {
    onBeforeOpen(e) {
      const { params } = e;

      if (params) {
        const { onCallback } = params;
        this.onCallback = onCallback;
      }
    },
    onBeforeClose() {
      this.onCallback = null;
    },
    onClose(action) {
      this.onCallback(action);
      this.$modal.hide("cart-alert");
    },
    onOldCart() {
      this.onClose("old");
    },
    onNewCart() {
      this.onClose("new");
    },
    onUnionCart() {
      this.onClose("union");
    },
  },
};
</script>
