<template>
  <form class="reset-password-form" @submit.prevent="onSubmit">
    <password
      id="resetPassword"
      :has-error="$v.resetPasswordForm.password.$error"
      :is-disabled="isDisabled"
      :value="resetPasswordForm.password"
      @change="onChangePassword"
    />

    <base-btn display="block" type="submit" :loading="isDisabled">
      Отправить
    </base-btn>
  </form>
</template>

<script>
import Password from "./Password.vue";
import BaseBtn from "@/components/shared/BaseBtn.vue";
// utils
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    Password,
    BaseBtn,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDisabled: false,
      resetPasswordForm: {
        password: "",
      },
    };
  },
  validations: {
    resetPasswordForm: {
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    onChangePassword(password) {
      this.resetPasswordForm.password = password;
    },
    submit(password) {
      this.isDisabled = true;

      this.$http
        .post("users/recovery/reset", {
          userId: this.userId,
          code: this.code,
          password: password,
        })
        .then(({ data }) => {
          this.isDisabled = false;

          const { status } = data;

          if (status === "ok") {
            this.$toast.success("Пароль успешно изменен.");

            this.$router.push({ name: "home" });
            this.$nextTick(() => {
              this.$modal.show("login");
            });
          } else {
            this.$toast.error(
              "Не удалось изменить пароль. Попробуйте отправить запрос на сброс еще раз."
            );
          }
        })
        .catch(({ response }) => {
          this.isDisabled = false;

          const { errors } = response.data;

          if (errors && errors.code && errors.code.length > 0) {
            this.$toast.error(errors.code.join(". "));
          } else {
            this.$toast.error(
              "Не удалось изменить пароль. Попробуйте отправить запрос на сброс еще раз."
            );
          }
        });
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submit(this.resetPasswordForm.password);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-form {
  &__countdown {
    @include font-size(14px);
    color: $stone;
    margin-bottom: 1rem;
    text-align: center;
  }
}
</style>
