<template>
  <span class="warehouse" :style="itemStyle">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: null,
    },
  },
  computed: {
    itemStyle() {
      if (!this.view) return {};

      return {
        background: this.view,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.warehouse {
  border-radius: 4px;
  padding: 2px 8px;
  white-space: nowrap;
}
</style>
