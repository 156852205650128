/**
 * Проверяет, стар ли товар
 * @param {Date} date — дата добавления
 */

import differenceInHours from "date-fns/differenceInHours";
import parseJSON from "date-fns/parseJSON";

const isItemOld = (date) => {
  return differenceInHours(new Date(), parseJSON(date)) > 24;
};

export default isItemOld;
