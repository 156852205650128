<template>
  <div class="action-card" :class="`action-card--${view}`">
    <div class="action-card__content">
      <h3 class="action-card__title">
        <slot name="title"></slot>
      </h3>
      <div class="action-card__body">
        <slot></slot>
      </div>
    </div>
    <slot name="btn"></slot>
  </div>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: "column",
    },
  },
};
</script>

<style lang="scss" scoped>
.action-card {
  background: $grey-300;
  border-radius: 6px;
  padding: 1.5rem;

  &.action-card--row {
    @include media-breakpoint-up(md) {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 185px;
      gap: 1rem;
    }
  }

  & + & {
    margin-top: 1rem;
  }

  &__body {
    margin-bottom: 26px;

    > p:last-child {
      margin-bottom: 0;
    }

    .action-card--row & {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
