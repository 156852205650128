var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-list-table"},[_vm._m(0),_vm._l((_vm.list),function(item,index){return _c('router-link',{key:`cross-${index}`,staticClass:"brand-list-table__row",attrs:{"to":{
      name: 'search-vendors',
      params: {
        town: _vm.$route.params.town,
        brand: item.brand,
        article: item.article,
      },
    }}},[_c('div',{staticClass:"brand-list-table__col brand-list-table__col--number"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"brand-list-table__col brand-list-table__col--brand"},[_vm._v(" "+_vm._s(item.brand)+" ")]),_c('div',{staticClass:"brand-list-table__col"},[_vm._v(_vm._s(item.article))]),_c('div',{staticClass:"brand-list-table__col"},[_vm._v(_vm._s(item.title))])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-list-table__head"},[_c('div',{staticClass:"brand-list-table__title"},[_vm._v("#")]),_c('div',{staticClass:"brand-list-table__title"},[_vm._v("Бренд")]),_c('div',{staticClass:"brand-list-table__title"},[_vm._v("Артикул")]),_c('div',{staticClass:"brand-list-table__title"},[_vm._v("Название")])])
}]

export { render, staticRenderFns }