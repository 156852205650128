<template>
  <div class="garage-list-item">
    <div class="garage-list-item__body">
      <figure class="garage-list-item__picture">
        <base-image
          class="garage-list-item__image"
          :url="item.image"
          width="350"
          height="200"
        />
      </figure>
      <div class="garage-list-item__content">
        <h3 class="garage-list-item__title">
          {{ item.manufacturerTitle }} {{ item.modelTitle }}
          {{ item.vehicleTitle }}
        </h3>
        <dl class="garage-list-item__info">
          <template v-if="item.vinNumber">
            <dt>Vin номер</dt>
            <dd>{{ item.vinNumber }}</dd>
          </template>
          <template v-if="item.bodyNumber">
            <dt>Номер кузова</dt>
            <dd>{{ item.bodyNumber }}</dd>
          </template>
          <dt>Год</dt>
          <dd>{{ item.year }}</dd>
          <dt>Годы выпуска</dt>
          <dd>{{ years }}</dd>
          <dt>Кузов</dt>
          <dd></dd>
          <dt>Код двигателя</dt>
          <dd>{{ item.vehicle.engineModel }}</dd>
          <dt>Объём (см3)</dt>
          <dd></dd>
          <dt>Мощность (л.с.)</dt>
          <dd>{{ item.vehicle.enginePower }}</dd>
          <dt>Топливо</dt>
          <dd></dd>
        </dl>
      </div>
    </div>
    <div class="garage-list-item__footer">
      <slot
        :id="item.id"
        :code="item.model.laximoCode"
        btnClass="garage-list-item__btn"
      ></slot>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";

export default {
  components: {
    BaseImage,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    years() {
      const { yearFrom, yearTo } = this.item.vehicle;

      if (yearTo > 0) {
        return `${yearFrom} — ${yearTo}`;
      }

      return `${yearFrom} — по н.в.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.garage-list-item {
  border: 1px solid $grey-300;
  border-radius: 6px;
  padding: 1rem;

  &__body {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 0 0.5rem;
    }
  }

  &__picture {
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }

  &__image {
    @include img-fluid;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 0.5rem;
    margin-bottom: 0;

    & > * {
      margin-bottom: 0;
    }
  }

  &__btn {
    display: block;
    width: 100%;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      width: auto;
    }

    & + & {
      margin-top: 0.5rem;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    flex-flow: column;
    margin-top: 1rem;

    @include media-breakpoint-up(sm) {
      flex-flow: row;
    }
  }
}
</style>
