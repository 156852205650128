<template>
  <modal
    name="city"
    :max-width="1050"
    width="100%"
    adaptive
    height="auto"
    class="city-modal"
  >
    <div class="city-modal__body">
      <div class="city-modal__header">
        <h4 class="city-modal__title">Ваш город: {{ cityNameFull }}</h4>
        <close-btn @click="$modal.hide('city')" />
      </div>

      <city-search class="city-modal__content" />
    </div>
  </modal>
</template>

<script>
import CloseBtn from "../../shared/CloseBtn";
import CitySearch from "./CitySearch";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    CloseBtn,
    CitySearch,
  },
  computed: {
    ...mapGetters({
      cityNameFull: "settings/cityNameFull",
    }),
  },
};
</script>

<style lang="scss" scoped>
.city-modal {
  &__body {
    padding: 1rem 1rem 4rem;

    @include media-breakpoint-up(lg) {
      padding: 1rem 1.5rem;
    }
  }

  &__content {
    @include media-breakpoint-up(lg) {
      min-height: 590px;
    }
  }

  &__title {
    @extend %subtitle;
  }
}
</style>
