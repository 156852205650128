<template>
  <page-layout-sidebar
    class="search-vendors"
    :breadcrumb="breadcrumb"
    :is-filter-loading="contractorStatus < 100"
    filter-title="Фильтр поиска"
    :is-sticky="false"
  >
    <template #filter>
      <filter-item
        v-if="filterParams.price && isFilterReady"
        :is-ready="isFilterReady"
      >
        <template #title>Цена, ₽</template>
        <template #default="{ isReady }">
          <filter-range
            name="vendors-price"
            :min="filterParams.price.min"
            :max="filterParams.price.max"
            :is-ready="isReady"
            :selected="filterSelected.price"
            @change="onChangePrice"
            @reset="onResetPrice"
          />
        </template>
      </filter-item>

      <filter-item
        v-if="filterParams.delivery && isFilterReady"
        :is-ready="isFilterReady"
      >
        <template #title>Срок поставки, дни</template>
        <template #default="{ isReady }">
          <filter-range
            name="vendors-delivery"
            :min="filterParams.delivery.min"
            :max="filterParams.delivery.max"
            :is-ready="isReady"
            :selected="filterSelected.delivery"
            @change="onChangeDelivery"
            @reset="onResetDelivery"
          />
        </template>
      </filter-item>

      <filter-item
        v-if="filterParams.quantity && isFilterReady"
        :is-ready="isFilterReady"
      >
        <template #title>Наличие, штук</template>
        <template #default="{ isReady }">
          <filter-range
            name="vendors-quantity"
            :min="filterParams.quantity.min"
            :max="filterParams.quantity.max"
            :is-ready="isReady"
            :selected="filterSelected.quantity"
            @change="onChangeQuantity"
            @reset="onResetQuantity"
          />
        </template>
      </filter-item>

      <filter-item
        v-if="filterParams.brand.length > 0 && isFilterReady"
        :is-ready="isFilterReady"
      >
        <template #title>Бренд</template>
        <template #default="{ isReady }">
          <filter-checkbox-list
            name="vendors-brand"
            trackby="brand"
            labelby="brand"
            :is-ready="isReady"
            :list="filterParams.brand"
            :selected="filterSelected.brandList"
            @change="onChangeBrand"
            @reset="onResetBrand"
          >
            <template #label="props">
              <span class="search-vendors__label">
                <span class="search-vendors__brand">
                  {{ props.item.brand }}
                </span>
                <span v-if="props.item.price" class="search-vendors__price">
                  {{ props.item.price | formatNumber }} ₽
                </span>
              </span>
            </template>
          </filter-checkbox-list>
        </template>
      </filter-item>
    </template>

    <template #filter-action>
      <base-btn
        v-if="hasFilterSelected"
        size="sm"
        view="outline-secondary"
        display="block"
        @click="onClean"
      >
        Сбросить
      </base-btn>
    </template>

    {{ hasList && !isLoading }}

    <template v-if="hasList && !isLoading" #default>
      <header v-if="item" class="search-vendors__header">
        <good-info class="search-vendors__good-info" :image="item.image">
          <template #title>
            {{ title ? title : item.brand + " " + item.article }}
          </template>
          <template #subtitle>{{ item.title }}</template>
        </good-info>
        <action-list
          :good="{
            name: 'item',
            params: { town: $route.params.town, id: `${item.url}-${item.id}` },
          }"
        />
      </header>

      <base-alert class="search-vendors__line">
        Цены и сроки указаны с учетом доставки в
        <router-link
          :to="{
            name: 'content',
            params: { alias: 'contacts', town: town },
          }"
        >
          пункты выдачи заказов г. {{ cityName }}
        </router-link>
      </base-alert>

      <progress-bar class="search-vendors__line" :count="contractorStatus">
        Поиск на складах
      </progress-bar>

      <div class="search-vendors__suggestions">
        <h3>Список предложений</h3>
        <suggestion-list
          :original-list="originalList"
          :replacement-list="replacementList"
          :selected="filterSelected"
          :is-loading="contractorStatus < 100"
          :image="item ? item.image : null"
        />
      </div>
    </template>

    <base-loading v-else-if="isLoading" />

    <p v-else>Ничего не найдено.</p>
  </page-layout-sidebar>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading";
import PageLayoutSidebar from "@/components/page/PageLayoutSidebar.vue";
import BaseAlert from "@/components/shared/BaseAlert";
import BaseBtn from "@/components/shared/BaseBtn";
import ActionList from "../components/list/ActionList";
import GoodInfo from "../components/list/GoodInfo";
import ProgressBar from "../components/list/ProgressBar";
import SuggestionList from "../components/list/SuggestionList";
import FilterItem from "@/components/shared/FilterItem";
import FilterRange from "@/components/shared/FilterRange";
import FilterCheckboxList from "../components/list/FilterCheckboxList";
// utils
import { mapGetters } from "vuex";
import convertHoursToDays from "@/utils/convertHoursToDays";
import formatNumber from "@/utils/formatNumber";
import onSetMeta from "@/utils/onSetMeta";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";

export default {
  name: "SearchVendors",
  components: {
    BaseLoading,
    PageLayoutSidebar,
    BaseAlert,
    BaseBtn,
    ActionList,
    GoodInfo,
    ProgressBar,
    SuggestionList,
    FilterItem,
    FilterRange,
    FilterCheckboxList,
  },
  filters: {
    formatNumber,
  },
  data() {
    return {
      contractorList: [],
      originalList: [],
      replacementList: [],
      item: null,
      contractorStatus: 0,
      filterSelected: {
        brandList: [],
        quantity: {},
        price: {},
        delivery: {},
      },
      title: "",
      metaList: [],
      isLoading: false,
    };
  },
  metaInfo() {
    return {
      title: this.title
        ? this.title
        : this.item
        ? `Поиск ${this.item.brand} ${this.item.article}`
        : `Поиск ${this.$route.params.brand} ${this.$route.params.article}`,
      meta: this.metaList,
    };
  },
  computed: {
    ...mapGetters({
      cityName: "settings/cityName",
      town: "settings/getTown",
      isXl: "mq/getIsXl",
    }),
    routeParams() {
      return this.$route.params;
    },
    breadcrumb() {
      const list = [{ title: "Поиск" }];

      return list;
    },
    params() {
      const params = {};

      if (this.routeParams) {
        params.brand = this.routeParams.brand;
        params.article = this.routeParams.article;
      }

      if (this.$route.params && this.$route.params.town) {
        params.town = this.$route.params.town;
      }

      return params;
    },
    contractorStep() {
      const size = this.contractorList.length;

      if (size > 0) {
        return 100 / size;
      }

      return 0;
    },
    totalList() {
      return [...this.originalList, ...this.replacementList];
    },
    hasList() {
      return this.contractorList.length > 0;
    },
    isFilterReady() {
      return this.contractorStatus >= 100;
    },
    filterParams() {
      const filter = {};

      filter.brand = uniqBy(
        sortBy(
          this.totalList.map((item) => {
            return {
              brand: item.brand.toUpperCase(),
              price: item.price,
            };
          }),
          (o) => o.price
        ),
        (o) => o.brand
      );

      const quantity = sortBy(
        uniq(this.totalList.map((item) => item.quantity))
      );

      filter.quantity = {
        min: quantity.length > 0 ? quantity[0] : 0,
        max: quantity.length > 0 ? quantity[quantity.length - 1] : 0,
      };

      const price = sortBy(uniq(this.totalList.map((item) => item.price)));

      filter.price = {
        min: price.length > 0 ? price[0] : 0,
        max: price.length > 0 ? price[price.length - 1] : 0,
      };

      const delivery = sortBy(
        uniq(
          this.totalList.map(
            (item) => item.deliveryMaxHours || item.deliveryMinHours
          )
        )
      );

      filter.delivery = {
        min: delivery.length > 0 ? convertHoursToDays(delivery[0]) : 0,
        max:
          delivery.length > 0
            ? convertHoursToDays(delivery[delivery.length - 1])
            : 0,
      };

      return filter;
    },
    hasFilterSelected() {
      const { quantity, price, delivery, brandList } = this.filterSelected;

      return (
        !isEmpty(quantity) ||
        !isEmpty(price) ||
        !isEmpty(delivery) ||
        (brandList && brandList.length > 0)
      );
    },
  },
  watch: {
    "$route.params.brand"() {
      this.onFetchData();
    },
    "$route.params.article"() {
      this.onFetchData();
    },
  },
  created() {
    if (this.routeParams) this.onFetchData();
  },
  methods: {
    setList({ originals, replacements }) {
      this.originalList = this.originalList.concat(originals);
      this.replacementList = this.replacementList.concat(replacements);
    },
    fetchSupplyList(contractorList) {
      const size = contractorList.length;

      for (let i = 0; i < size; i++) {
        this.fetchSupply(contractorList[i]);
      }
    },
    fetchSupply({ id }) {
      this.$http
        .get("catalog/search/search-provider", {
          params: {
            ...this.params,
            provider: id,
          },
          timeout: 10000,
        })
        .then(({ data }) => {
          this.setList(data);
          this.contractorStatus = this.contractorStatus + this.contractorStep;
          if (this.contractorStatus > 50) {
            document.body.classList.add("is-loaded");
          }
        })
        .catch((error) => {
          if (!error.code === "ECONNABORTED") {
            this.$toast.error(error.toString());
          }

          this.contractorStatus = this.contractorStatus + this.contractorStep;
          if (this.contractorStatus > 50) {
            document.body.classList.add("is-loaded");
          }
        });
    },
    onFetchData() {
      this.isLoading = true;
      this.cleanData();

      this.$http
        .get("catalog/search/index", {
          params: this.params,
        })
        .then(({ data }) => {
          const { contractors, result, meta, title } = data;

          this.contractorList = contractors;
          this.metaList = onSetMeta(meta);

          const { item, originals, replacements } = result;

          this.title = title;
          this.item = item;
          this.originalList = originals;
          this.replacementList = replacements;

          this.$nextTick(() => {
            this.isLoading = false;
            this.resetFilter = false;
            this.fetchSupplyList(this.contractorList);
          });
        })
        .catch((error) => {
          this.$toast.error(error.toString());
          this.isLoading = false;
        });
    },
    cleanData() {
      this.resetFilter = true;

      this.contractorList = [];
      this.originalList = [];
      this.replacementList = [];
      this.item = null;
      this.contractorStatus = 0;
      this.filterSelected.brandList = [];
      this.filterSelected.price = {};
      this.filterSelected.delivery = {};
      this.filterSelected.quantity = {};
    },
    onChangeBrand(list) {
      this.filterSelected.brandList = list;
    },
    onResetBrand() {
      this.filterSelected.brandList = [];
    },
    onChangePrice(price) {
      this.filterSelected.price = price;
    },
    onResetPrice() {
      this.filterSelected.price = {};
    },
    onChangeDelivery(delivery) {
      this.filterSelected.delivery = delivery;
    },
    onResetDelivery() {
      this.filterSelected.delivery = {};
    },
    onChangeQuantity(quantity) {
      this.filterSelected.quantity = quantity;
    },
    onResetQuantity() {
      this.filterSelected.quantity = {};
    },
    onClean() {
      this.$eventHub.$emit("reset-vendors-price");
      this.$eventHub.$emit("reset-vendors-delivery");
      this.$eventHub.$emit("reset-vendors-quantity");
      this.$eventHub.$emit("reset-vendors-brand");
    },
    onResetFilter() {
      this.resetFilter = true;

      setTimeout(() => {
        this.resetFilter = false;

        this.$modal.hide("filter");
      }, 100);
    },
    onFilter() {
      this.$modal.hide("filter");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-vendors {
  &__header {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 0 1rem;
    }
  }

  &__good-info {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__line {
    margin-bottom: 1rem;
  }

  &__btn {
    margin-bottom: 0.7rem;
  }

  &__label {
    display: grid;
    grid-template-columns: 1fr auto;
    word-break: break-all;
  }

  &__price {
    padding-right: 0.7rem;
  }
}
</style>
