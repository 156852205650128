<template>
  <page-layout-full :breadcrumb="breadcrumb">
    <template #title>Лампы автомобильные</template>

    <lamp-area>
      <template #image="{ imageClass }">
        <img
          :class="imageClass"
          src="../../../assets/pages/lamp__forward.jpg"
          width="627"
          height="470"
        />
      </template>

      <lamp-group id="1">
        <template #title>Ближний, дальний свет</template>

        <lamp-item name="h1" brand="OSRAM" article="64150" />
        <lamp-item name="h7" brand="OSRAM" article="64210" />
        <lamp-item name="h9" brand="OSRAM" article="64213" />
        <lamp-item name="h4" brand="OSRAM" article="64193" />
        <lamp-item name="h13" brand="OSRAM" article="9008" />
        <lamp-item name="h15" brand="OSRAM" article="64176" />
        <lamp-item name="hb1" brand="OSRAM" article="9004" />
        <lamp-item name="hb3" article="hb3" />
        <lamp-item name="hb4" brand="OSRAM" article="9006" />
        <lamp-item name="hb5" brand="OSRAM" article="9007" />
        <lamp-item name="d1s" brand="OSRAM" article="66140" />
        <lamp-item name="d2r" brand="PHILIPS" article="85126" />
        <lamp-item name="d2s" brand="OSRAM" article="66240" />
        <lamp-item name="d3s" brand="OSRAM" article="66340" />
        <lamp-item name="d3r" brand="OSRAM" article="66350" />
        <lamp-item name="d4s" brand="OSRAM" article="66440" />
        <lamp-item name="d4r" brand="OSRAM" article="66450" />
      </lamp-group>

      <lamp-group id="2">
        <template #title>Лампы приборной панели</template>

        <lamp-item name="bax" brand="NARVA" article="17036" />
        <lamp-item name="w3w" brand="OSRAM" article="2821" />
        <lamp-item name="w5w" brand="OSRAM" article="2825" />
        <lamp-item name="w2w" brand="OSRAM" article="2722" />
        <lamp-item name="w1,2w" brand="OSRAM" article="2721" />
        <lamp-item name="mf1,5w" brand="OSRAM" article="2752mf" />
      </lamp-group>

      <lamp-group id="3">
        <template #title>Освещение «бардачка»</template>

        <lamp-item name="c5w" brand="OSRAM" article="6418" />
        <lamp-item name="r5w" article="r5w" />
        <lamp-item name="w3w" brand="OSRAM" article="2821" />
        <lamp-item name="w5w" brand="OSRAM" article="2825" />
      </lamp-group>

      <lamp-group id="4">
        <template #title>Повторители указателя поворота</template>

        <lamp-item name="h21w" brand="OSRAM" article="64136" />
        <lamp-item name="w3w" brand="OSRAM" article="2821" />
        <lamp-item name="w5w" brand="OSRAM" article="2825" />
        <lamp-item name="wy5w" brand="OSRAM" article="2827" />
        <lamp-item name="py27/7w" brand="OSRAM" article="3157" />
      </lamp-group>

      <lamp-group id="5">
        <template #title>Противотуманные фары</template>

        <lamp-item name="h27w/1" brand="OSRAM" article="800" />
        <lamp-item name="h27w/2" brand="OSRAM" article="881" />
        <lamp-item name="p13w" brand="PHILIPS" article="12277c1" />
        <lamp-item name="psx24w" brand="PHILIPS" article="12276c1" />
        <lamp-item name="h1" brand="OSRAM" article="64150" />
        <lamp-item name="h2" brand="OSRAM" article="64173" />
        <lamp-item name="h3" brand="OSRAM" article="64151" />
        <lamp-item name="h7" brand="OSRAM" article="64210" />
        <lamp-item name="h8" brand="OSRAM" article="64212" />
        <lamp-item name="h10" brand="OSRAM" article="9145RD" />
        <lamp-item name="h11" brand="NARVA" article="64211" />
        <lamp-item name="hb3" brand="OSRAM" article="9005" />
        <lamp-item name="hb4" brand="OSRAM" article="9006" />
        <lamp-item name="hb5" brand="OSRAM" article="9007" />
        <lamp-item name="psx24w" brand="OSRAM" article="12276c1" />
      </lamp-group>

      <lamp-group id="6">
        <template #title>Передние габаритные огни</template>

        <lamp-item name="w5w" brand="OSRAM" article="2825" />
        <lamp-item name="r5w" brand="OSRAM" article="5007" />
        <lamp-item name="t4w" brand="OSRAM" article="3893" />
        <lamp-item name="h6w" brand="OSRAM" article="64132" />
        <lamp-item name="h5w" brand="OSRAM" article="64111" />
        <lamp-item name="py27/7w" brand="NARVA" article="3157" />
      </lamp-group>

      <lamp-group id="7">
        <template #title>Указатели поворота — «аварийка»</template>

        <lamp-item name="h21w" brand="OSRAM" article="64135" />
        <lamp-item name="p21w" brand="OSRAM" article="7506" />
        <lamp-item name="py21w" brand="OSRAM" article="7507" />
        <lamp-item name="w21w" brand="OSRAM" article="7505" />
      </lamp-group>
    </lamp-area>

    <lamp-area>
      <template #image="{ imageClass }">
        <img
          :class="imageClass"
          src="../../../assets/pages/lamp__backward.jpg"
          width="627"
          height="470"
        />
      </template>

      <lamp-group id="8">
        <template #title>Подстветка багажника</template>

        <lamp-item name="c5w" brand="OSRAM" article="6418" />
        <lamp-item name="c10w" brand="OSRAM" article="6411" />
        <lamp-item name="r5w" brand="OSRAM" article="5007" />
      </lamp-group>

      <lamp-group id="9">
        <template #title>Повторитель стоп-сигнала</template>

        <lamp-item name="w3w" brand="OSRAM" article="2821" />
        <lamp-item name="w5w" brand="OSRAM" article="2825" />
        <lamp-item name="w16w" brand="OSRAM" article="921" />
      </lamp-group>

      <lamp-group id="10">
        <template #title>Подсветка заднего номера»</template>

        <lamp-item name="c5w" brand="OSRAM" article="6418" />
        <lamp-item name="r5w" brand="OSRAM" article="5007" />
        <lamp-item name="r10w" brand="OSRAM" article="5008" />
        <lamp-item name="w5w" brand="OSRAM" article="2825" />
      </lamp-group>

      <lamp-group id="11">
        <template #title>Фонарь заднего хода</template>

        <lamp-item name="h21w" brand="OSRAM" article="64135" />
        <lamp-item name="p21w" brand="OSRAM" article="7506" />
        <lamp-item name="w16w" brand="OSRAM" article="921" />
        <lamp-item name="w21w" brand="OSRAM" article="7505" />
      </lamp-group>

      <lamp-group id="12">
        <template #title>Внутреннее освещение</template>

        <lamp-item name="c5w" brand="OSRAM" article="6418" />
        <lamp-item name="w5w" brand="OSRAM" article="2825" />
        <lamp-item name="h5w" brand="OSRAM" article="64111" />
        <lamp-item name="c10w" brand="OSRAM" article="6411" />
      </lamp-group>

      <lamp-group id="13">
        <template #title>Стоп-сигналы</template>

        <lamp-item name="p21w" brand="OSRAM" article="7506" />
        <lamp-item name="p21w/4w" brand="OSRAM" article="7225" />
        <lamp-item name="p21w/5w" brand="OSRAM" article="7528" />
        <lamp-item name="w21w" brand="OSRAM" article="7505" />
        <lamp-item name="w21/5w" brand="OSRAM" article="7515" />
      </lamp-group>

      <lamp-group id="14">
        <template #title>Задние противотуманные огни</template>

        <lamp-item name="h21w" brand="OSRAM" article="64135" />
        <lamp-item name="p21w" brand="OSRAM" article="7506" />
        <lamp-item name="p21w/4w" brand="OSRAM" article="7225" />
        <lamp-item name="p21w/5w" brand="OSRAM" article="7528" />
        <lamp-item name="w21w" article="w21w" />
        <lamp-item name="w21/5w" brand="OSRAM" article="7515" />
      </lamp-group>

      <lamp-group id="15">
        <template #title>Задние габаритные огни</template>

        <lamp-item name="h6w" brand="OSRAM" article="64132" />
        <lamp-item name="r5w" brand="OSRAM" article="5007" />
        <lamp-item name="r10w" brand="OSRAM" article="5008" />
        <lamp-item name="t4w" brand="OSRAM" article="3893" />
        <lamp-item name="p27/7w" brand="OSRAM" article="3157" />
      </lamp-group>
    </lamp-area>
  </page-layout-full>
</template>

<script>
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import LampArea from "../components/lamp/LampArea";
import LampGroup from "../components/lamp/LampGroup";
import LampItem from "../components/lamp/LampItem";

export default {
  components: {
    PageLayoutFull,
    LampArea,
    LampGroup,
    LampItem,
  },
  metaInfo: {
    title: "Лампы автомобильные",
  },
  data() {
    return {
      breadcrumb: [
        {
          title: "Все каталоги",
          to: {
            name: "category-list",
          },
        },
        {
          title: "Лампы автомобильные",
        },
      ],
    };
  },
};
</script>
