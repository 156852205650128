var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:[
    {
      'form-group--error': _vm.hasError,
      'form-group--hide-label': !_vm.showLabel,
      'form-group--has-icon': !!_vm.icon,
    },
    `form-group--${_vm.size}`,
  ]},[_c('label',{staticClass:"form-group__label",attrs:{"for":_vm.id}},[_vm._t("label")],2),_c('div',{staticClass:"form-group__group"},[(_vm.icon)?_c('base-icon',{staticClass:"form-group__icon",attrs:{"name":_vm.icon.name,"width":_vm.icon.width,"height":_vm.icon.height}}):_vm._e(),_vm._t("default",null,{"id":_vm.id,"disabled":_vm.disabled,"inputClass":"form-group__control"}),(!!_vm.$slots.suggest)?_c('div',{staticClass:"form-group__suggest"},[_vm._t("suggest")],2):_vm._e()],2),(_vm.$slots.hint)?_c('div',{staticClass:"form-group__hint"},[_vm._t("hint")],2):_vm._e(),(_vm.hasError)?_c('div',{staticClass:"form-group__error"},[_vm._t("error")],2):_vm._e(),(_vm.$slots.extra)?_c('div',{staticClass:"form-group__extra"},[_vm._t("extra")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }