<template>
  <router-link v-if="to" class="popular-category-list-item" :to="to">
    <figure class="popular-category-list-item__picture">
      <base-image
        class="popular-category-list-item__image"
        :url="image"
        width="192"
        height="192"
      />
    </figure>
    {{ title }}
  </router-link>
</template>

<script>
import BaseImage from "@/components/shared/BaseImage";
// utils
import { mapGetters } from "vuex";

export default {
  components: {
    BaseImage,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      getCategoryById: "catalog/getCategoryById",
    }),
    to() {
      if (this.link.length > 0) {
        return {
          path: this.link,
        };
      }

      const data = this.getCategoryById(this.id);

      if (!data) return null;

      const { category, alias } = data;

      if (category && alias) {
        return {
          name: "item-list",
          params: {
            town: this.$route.params.town,
            category: alias,
            subCategory: category,
          },
        };
      } else if (alias) {
        return {
          name: "category-item",
          params: {
            town: this.$route.params.town,
            category: alias,
          },
        };
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-category-list-item {
  @extend %subtitle;

  color: inherit;
  display: block;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  padding: 0 0.5rem;
}
</style>
