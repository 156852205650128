import { render, staticRenderFns } from "./NavTabItem.vue?vue&type=template&id=1ed35392&scoped=true&"
import script from "./NavTabItem.vue?vue&type=script&lang=js&"
export * from "./NavTabItem.vue?vue&type=script&lang=js&"
import style0 from "./NavTabItem.vue?vue&type=style&index=0&id=1ed35392&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed35392",
  null
  
)

export default component.exports