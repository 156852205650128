<template>
  <base-loading v-if="isLoading" />

  <page-layout-full
    v-else
    class="content-view"
    :breadcrumb="breadcrumb"
    view="sm"
  >
    <template #title>{{ item.title }}</template>

    <div class="content-view__text" v-html="item.text"></div>

    <div v-if="hasScript" ref="script" class="content-view__extra"></div>

    <image-gallery
      v-if="gallery.length > 0"
      class="content-view__gallery"
      :list="gallery"
    />
  </page-layout-full>
</template>

<script>
import BaseLoading from "@/components/shared/BaseLoading.vue";
import PageLayoutFull from "@/components/page/PageLayoutFull.vue";
import ImageGallery from "@/components/shared/ImageGallery.vue";
// mixin
import mixinContent from "../mixin/mixinContent";
import mixinCalc from "../mixin/mixinCalc";

export default {
  components: {
    BaseLoading,
    PageLayoutFull,
    ImageGallery,
  },
  mixins: [mixinContent, mixinCalc],
};
</script>

<style lang="scss" scoped>
.content-view {
  &__gallery,
  &__extra {
    margin-top: 2rem;
  }
}
</style>
