<template>
  <div class="home">
    <base-container>
      <div v-show="hasNav && hasModel" class="row">
        <div v-if="isLg" class="col-auto">
          <catalog-box class="home__catalog-nav" />
        </div>
        <div class="col">
          <div class="home__search-model">
            <vin-search class="home__vin-search" />
            <model-short-list />
          </div>
        </div>
      </div>

      <base-banner v-if="noFranchise" view="car" class="home__base-banner" />

      <popular-category-list :list="categoryList" class="home__category" />

      <featured-good-list :list="goodList" class="home__featured" />
      <featured-news-list
        :list="newsList"
        :is-loading="isNewsLoading"
        class="home__featured"
      >
        <template #nav="props">
          <featured-news-nav
            :list="newsCategoryList"
            :active="newsCategoryAlias"
            :list-class="props.navClass"
          />
        </template>
      </featured-news-list>
    </base-container>
  </div>
</template>

<script>
import BaseContainer from "@/components/shared/BaseContainer";
import CatalogBox from "@/modules/catalog/components/nav/CatalogBox";
import VinSearch from "../components/VinSearch";
import ModelShortList from "../components/ModelShortList";
import BaseBanner from "../components/BaseBanner";
import PopularCategoryList from "../components/PopularCategoryList";
import FeaturedGoodList from "../components/FeaturedGoodList";
import FeaturedNewsList from "../components/FeaturedNewsList";
import FeaturedNewsNav from "../components/FeaturedNewsNav";
// utils
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    CatalogBox,
    BaseContainer,
    VinSearch,
    ModelShortList,
    BaseBanner,
    PopularCategoryList,
    FeaturedGoodList,
    FeaturedNewsList,
    FeaturedNewsNav,
  },
  data() {
    return {
      goodList: [],
      newsList: [],
      newsCategoryList: [],
      categoryList: [],
      isNewsLoading: true,
    };
  },
  computed: {
    ...mapState("settings", {
      noFranchise: (state) => state.noFranchise,
    }),
    ...mapGetters({
      isLg: "mq/getIsLg",
      hasNav: "catalog/hasList",
      hasModel: "auto/hasList",
    }),
    newsCategoryAlias() {
      if (this.$route.query && this.$route.query.nca) {
        return this.$route.query.nca;
      }

      return "all";
    },
  },
  watch: {
    newsCategoryAlias(alias) {
      this.onFetchNewsList(alias);
    },
  },
  mounted() {
    this.onFetchGoodList();
    this.onFetchNewsList(this.newsCategoryAlias);
    this.onFetchNewsCategoryList();
    this.onFetchCategoryList();
  },
  methods: {
    onFetchGoodList() {
      this.$http
        .get("catalog/default/featured")
        .then(({ data }) => {
          this.goodList = data;
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    onFetchNewsList(categoryAlias) {
      this.isNewsLoading = true;

      const params = {};

      if (categoryAlias && categoryAlias !== "all") {
        params.category = categoryAlias;
      }

      this.$http
        .get("content/article/index", {
          params: params,
        })
        .then(({ data }) => {
          this.newsList = data;
          this.isNewsLoading = false;
        })
        .catch((error) => {
          this.$toast.error(error);
          this.isNewsLoading = false;
        });
    },
    onFetchNewsCategoryList() {
      this.$http
        .get("content/article/categories")
        .then(({ data }) => {
          this.newsCategoryList = data;
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    onFetchCategoryList() {
      this.$http
        .get("app/site/popular-categories")
        .then(({ data }) => {
          this.categoryList = data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Ошибка. Не удалось получить данные.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__catalog-nav {
    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }

  &__search-model {
    @include media-breakpoint-up(lg) {
      border: 1px solid $grey-300;
      border-radius: 4px;
      height: 100%;
      min-height: 420px;
      padding: 26px 24px;
      position: relative;
    }
  }

  &__vin-search {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &__base-banner,
  &__category,
  &__featured {
    margin-top: 40px;
  }
}
</style>
