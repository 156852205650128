/**
 * Форматирует дату
 * @param {Date} дата
 * @param {Ыекштп} токен
 * @returns {String} значение
 */

import format from "date-fns/format";
import ru from "date-fns/locale/ru";

const formatDate = (date, token) => {
  if (!date) return "";

  return format(date, token, { locale: ru });
};

export default formatDate;
