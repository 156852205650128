<template>
  <nav v-if="navList.length > 0" ref="nav" class="catalog-box">
    <ul class="catalog-box__list">
      <li
        v-for="item in navList"
        :key="`item-${item.id}`"
        class="catalog-box__item"
      >
        <catalog-box-item
          :to="{
            name: 'category-item',
            params: { town: $route.params.town, category: item.url },
          }"
          :icon="item.url"
          :width="width"
        >
          {{ item.title }}

          <template #content>
            <catalog-box-content :list="item.items" :category="item.url">
              <template #title="props">
                <router-link
                  :to="{
                    name: 'category-item',
                    params: { town: $route.params.town, category: item.url },
                  }"
                  :class="props.linkClass"
                >
                  {{ item.title }}
                </router-link>
              </template>
            </catalog-box-content>
          </template>
        </catalog-box-item>
      </li>
    </ul>

    <footer class="catalog-box__footer">
      <base-btn
        tag="router-link"
        :to="{ name: 'category-list', params: { town: $route.params.town } }"
        size="sm"
        display="block"
        >Все категории</base-btn
      >
    </footer>
  </nav>
</template>

<script>
import BaseBtn from "@/components/shared/BaseBtn";
import CatalogBoxItem from "./CatalogBoxItem";
import CatalogBoxContent from "./CatalogBoxContent";
// utils
import throttle from "lodash/throttle";
import { mapState } from "vuex";

export default {
  components: {
    BaseBtn,
    CatalogBoxItem,
    CatalogBoxContent,
  },
  data() {
    return {
      width: 0,
      container: null,
    };
  },
  computed: {
    ...mapState("catalog", {
      navList: (state) => state.list,
    }),
  },
  mounted() {
    this.container = document.getElementsByClassName("base-container")[0];

    this.getSize();
  },
  created() {
    window.addEventListener("resize", this.optimizeResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.optimizeResize);
  },
  methods: {
    getSize() {
      this.width = this.container.clientWidth - 228 - 10 - 30;
    },
    optimizeResize: throttle(
      function () {
        this.getSize();
      },
      250,
      true
    ),
  },
};
</script>

<style lang="scss" scoped>
.catalog-box {
  background: $white;
  border: 1px solid $grey-300;
  border-radius: 4px;
  padding: 16px 0 24px;
  position: relative;
  width: 228px;

  &__list {
    @include list-unstyled;
  }

  &__footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
